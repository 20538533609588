/* eslint-disable @typescript-eslint/no-explicit-any, no-console,  @typescript-eslint/no-unnecessary-type-constraint */

import { Reducer } from 'redux';
import { PagedEntity, getDefaultMetaData } from '@tphglobal/common/models';
import { StepFormState } from '@tphglobal/common/models/genericEntities';
import {
  Action, RESULTS_FETCH_SUCCESS, RESULTS_RESET, SET_DISPLAY_COUNT, SET_STEP_NUMBER, SORT_RESULTS, STEP_FORM_DATA_SET,
} from '../actions';

export const createBasicReducer = <T extends unknown>(
  key: string, initialState: T,
) => (state: T = initialState, action: Action<T>): T => {
    switch (action.type) {
      case `${key}_UPDATE`: {
        const { payload } = action;
        if (typeof payload === 'object' && payload !== null) {
          if (Array.isArray(payload)) {
            return [...payload] as T;
          }
          return { ...state, ...payload };
        }
        return payload as T;
      }
      case `${key}_RESET`:
        return initialState;
      default:
        return state;
    }
  };

export const createPagedReducer = <T extends unknown>(
  key: string, initialEntity: T[],
): any => {
  const initialState: PagedEntity<T> = {
    metadata: getDefaultMetaData<T>(),
    records: initialEntity,
  };
  return (
    state: PagedEntity<T> = initialState,
    action: Action<PagedEntity<T>>,
  ): PagedEntity<T> => {
    switch (action.type) {
      case `${key}_PAGINATION_UPDATE`: {
        const { payload } = action;
        return {
          metadata: { ...payload.metadata },
          records: [...payload.records],
          requestDate: new Date(),
        };
      }
      case `${key}_PAGINATION_LOAD_MORE`: {
        const { payload } = action;
        return {
          metadata: { ...payload.metadata },
          records: [...state.records, ...payload.records],
          requestDate: new Date(),
        };
      }
      case `${key}_PAGINATION_RESET`:
        return initialState;
      default:
        return state;
    }
  };
};
export const createStepFormReducer = (
  key: string,
  initialState: StepFormState,
): Reducer<StepFormState> => (state: StepFormState = initialState, action: any): StepFormState => {
  switch (action.type) {
    case SET_STEP_NUMBER:
      return {
        ...state,
        currentPage: action.payload.stepNumber,
      };
    case STEP_FORM_DATA_SET:
      const { stepNumber, data } = action.payload;
      return {
        ...state,
        forms: {
          ...state.forms,
          [stepNumber]: data,
        },
      };
    default:
      return state;
  }
};

interface ResultsState<T> {
  results: any;
  displayCount: number;
  sortKey: string;
  sortDirection: 'asc' | 'desc';
}

const initialResultsState = (initialEntity: any[]): ResultsState<any> => ({
  results: initialEntity,
  displayCount: 10,
  sortKey: '',
  sortDirection: 'asc',
});
type ResultsAction<T> = Action<string> & {
  payload?: any;
};

export const createResultsReducer = (key: string, initialEntity: any[]): any => {
  const initialState = initialResultsState(initialEntity);
  return (state: ResultsState<any> = initialState, action: ResultsAction<any>): ResultsState<any> => {
    switch (action.type) {
      case `${RESULTS_FETCH_SUCCESS}`:
        return {
          ...state,
          results: action.payload?.result,
        };
      case `${SET_DISPLAY_COUNT}`:
        return {
          ...state,
          displayCount: action.payload,
        };
      case `${SORT_RESULTS}`:
        return {
          ...state,
          sortKey: action.payload.sortKey,
          sortDirection: action.payload.direction,
          results: {
            ...state.results,
            records: state.results.records.slice().sort((a : any, b : any) => {
              const key = action.payload.sortKey;
              const direction = action.payload.direction === 'asc' ? 1 : -1;
              return a[key] > b[key] ? direction : -direction;
            }),
          },
        };
      case `${RESULTS_RESET}`:
        return initialState;
      default:
        return state;
    }
  };
};
