import { Typography } from '@mui/material';
import { colors, greyScaleColour } from '@tphglobal/common/theme/style.palette';
import PhoneInput from 'react-phone-input-2';
import styled from 'styled-components';

export const StyledTelInput = styled(PhoneInput)`
  .flag-dropdown {
    display: flex;
    align-items: center;
    border: none;
    background: transparent !important;
  }

  .selected-flag {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 12px;
    border-width: 1px 0px 1px 1px;
    border-style: solid;
    border-color: ${greyScaleColour?.grey90};
    border-radius: 8px 0 0 8px !important;
  }

  .flag {
    margin-right: 4px;
  }

  .country-list {
    z-index: 1000;
    position: absolute;
    top: 100%;
    left: 0;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  .form-control {
    width: 100%;
    height: 44px;
    border: 1px solid ${greyScaleColour.grey90};
    border-radius: 8px;
    position: relative;
  }

  .open {

  }
`;

export const StyledPhoneInputContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const StyledError = styled(Typography)`
  text-align: left;
  color: ${colors.red100} !important;
  margin-top: 8px !important;
`;
