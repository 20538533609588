import { Grid } from '@mui/material';
import { Button, OutlinedContainer } from '@tphglobal/components';
import React from 'react';
import { fontSize, fontWeight } from '@tphglobal/common/theme/style.typography';
import { greyScaleColour } from '@tphglobal/common/theme/style.palette';
import { useDispatch } from 'react-redux';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { push } from 'connected-react-router';
import { iconStyles } from '../../../utils/constant';
import messages from '../../../messages';
import { StyledHeading } from '../styles';
import { routes } from '../../../utils';

interface Props {
  isMobile?: boolean;
}

const SignatureMobile: React.FC<Props> = () => {
  const reduxDispatch = useDispatch();

  return (
    <OutlinedContainer>
      <Grid rowSpacing={2} container justifyContent="space-between">
        <Grid item alignSelf="center">
          <StyledHeading
            fontWeight={fontWeight?.semiBold}
            fontSize={fontSize?.h5}
          >
            {messages?.profile?.signature?.heading}
          </StyledHeading>
          <StyledHeading
            color={greyScaleColour?.grey100}
            fontSize={fontSize?.b2}
          >
            {messages?.profile?.signature?.subHeading}
          </StyledHeading>
        </Grid>
        <Grid item alignSelf="center">
          <Button
            onClick={() => reduxDispatch(push(routes?.signatureMobile))}
            variant="outlined"
            color="primary"
            size="large"
            label={messages?.profile?.signature?.button}
            icon={<SettingsOutlinedIcon style={iconStyles} />}
          />
        </Grid>
      </Grid>
    </OutlinedContainer>
  );
};

export default SignatureMobile;
