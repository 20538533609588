import { ChipProps } from '@mui/material';
import React from 'react';
import { StyledChip } from './styles';

type CustomChipProps = ChipProps & {
    large?: boolean,
    label: React.ReactNode,
};

const CustomChip = ({ large, label, ...props }: CustomChipProps) => <StyledChip large={large} label={label} {...props}/>;

export default CustomChip;
