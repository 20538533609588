import { brandColour } from '@tphglobal/common/theme/style.palette';
import styled, { css } from 'styled-components';

export const Container = styled.div<{
  borderColor?: string;
  padding?: string;
  margin?: string;
  borderRadius?: string;
  noBorder?: boolean;
  centered?: boolean
}>`
${({centered}) =>
    centered
    && css`
      display: flex;
      align-items: center;
      justify-content: center;
    `
}
  ${({ noBorder, borderColor }) =>
    noBorder
      ? css`
          border: none;
        `
      : css`
          border: 1px solid
            ${borderColor || brandColour?.primary10};
        `}
  padding: ${(props) => props.padding || '24px'};
  margin: ${(props) => props.margin || '0'};
  border-radius: ${(props) => props.borderRadius || '16px'};
`;
