import messages from '../messages';

const ErrorHandler = (errorName: string): string => {
  let errorMessage: string;

  switch (errorName) {
    case 'UserNotFoundException':
      errorMessage = messages?.login?.form?.errors?.UserNotFoundException;
      break;
    case 'UserNotConfirmedException':
      errorMessage = messages?.login?.form?.errors?.UserNotFoundException;
      break;
    case 'NotAuthorizedException':
      errorMessage = messages?.login?.form?.errors?.NotAuthorizedException;
      break;
    case 'UsernameExistsException':
      errorMessage = messages?.login?.form?.errors?.UsernameExistsException;
      break;
    case 'InvalidParameterException':
      errorMessage = messages?.login?.form?.errors?.InvalidParameterException;
      break;
    case 'CodeMismatchException':
      errorMessage = messages?.login?.form?.errors?.CodeMismatchException;
      break;
    case 'LimitExceededException':
      errorMessage = messages?.login?.form?.errors?.LimitExceededException;
      break;
    case 'UserAlreadyAuthenticatedException':
      errorMessage = messages?.login?.form?.errors?.UserAlreadyAuthenticatedException;
      break;
    case 'UserUnAuthenticatedException':
      errorMessage = messages?.login?.form?.errors?.UserUnAuthenticatedException;
      break;
    case 'request entity too large':      
      errorMessage = messages?.general?.errors?.largeFile;
      break;
    case 'error.createTimeCards.unableToCreate':      
      errorMessage = messages?.login?.form?.errors?.createTimeCards_unableToCreate;
      break;
    case 'error.documents.unableToDelete':      
      errorMessage = messages?.login?.form?.errors?.documents_unableToDelete;
      break;
    case 'error.deleteTimeCard.unableToDelete':      
      errorMessage = messages?.login?.form?.errors?.deleteTimeCard_unableToDelete;
      break;
    case 'error.email.unableToSend':      
      errorMessage = messages?.login?.form?.errors?.email_unableToSend;
      break;
    case 'error.addCompany.emailAlreadyRegistered':      
      errorMessage = messages?.login?.form?.errors?.addCompany_emailAlreadyRegistered;
      break;
    case 'error.sysCompanies.unableToGet':      
      errorMessage = messages?.login?.form?.errors?.sysCompanies_unableToGet;
      break;
    case 'error.general.notFound':      
      errorMessage = messages?.general?.errors?.clearSignatureError;
      break;
    case 'error.updateLogin.unableToUpdate':      
      errorMessage = messages?.general?.errors?.updateLogin_unableToUpdate;
      break;
    case 'error.createPdf.signature.empty':      
      errorMessage = messages?.general?.errors?.signature_empty;
      break;
    case 'error.updateCompany.emailAlreadyRegistered':      
      errorMessage = messages?.general?.errors?.updateCompany_emailAlreadyRegistered;
      break;
    default:
      errorMessage = 'Unknown error. Contact support.';
  }
  return errorMessage;
};

export default ErrorHandler;
