import styled, { css } from 'styled-components';
import { Container } from '@mui/material';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import LogoutIcon from '@mui/icons-material/Logout';
import {
  adminColor, brandColour, colors, otherColour,
} from '@tphglobal/common/theme/style.palette';
import { respondTo } from '@tphglobal/common/theme/style.layout';

export const StyledContainer = styled(Container)<{ noPadding?: boolean, background?: string }>`
  display: flex !important;
  height: 100vh;
  padding: 24px 20px !important;
  gap: 20px;
  ${({ noPadding }) => (noPadding
    ? css`
          padding: 0 !important;
        `
    : css`
      padding: 24px 20px !important;
        `)};
  min-width: 100%;
  margin: auto 0 !important;
  background: ${(props) => (props?.background ? props?.background : colors.white)};

  ${respondTo.mobileOnlyPortrait} {
    padding: 0 !important;
    flex-direction: column-reverse;
    overflow-y: hidden;
    gap: 0;
  }
`;

export const StyledChildrenContainer = styled.div<{
  noPadding?: boolean;
  noMargin?: boolean;
  hasHeader?: boolean;
  maxHeight?: boolean;
  noBorderRadius?: boolean;
}>`
  margin: 0px !important;
  display: flex;
  overflow: hidden;
  gap: 8px;
  border-radius: ${({ noBorderRadius }) => (noBorderRadius ? '0' : '16px')};
  flex-direction: column;
  ${({ maxHeight }) => maxHeight
    && css`
      height: 100% !important;
    `}
  ${({ noPadding }) => noPadding
    && css`
      padding: 0 !important;
    `}
  ${({ noMargin }) => noMargin
    && css`
      margin: 0 !important;
    `}
  ${({ hasHeader }) => hasHeader
    && css`
      // max-height: 84vh !important;
    `}
  ${respondTo?.mobileOnlyPortrait} {
    height: 100% !important;
  }
`;

export const StyledContentContainer = styled.div<{ noMargin?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  gap: 27px;
  ${({ noMargin }) => noMargin
    && css`
      margin: 0 !important;
    `}

    ${respondTo.mobileOnlyPortrait} {
      gap: 0px;
    }
`;

export const StyledIconHeadingContainer = styled.div`
  display: flex;
  align-items: center;
`;
export const StyledLogoutMenuIcon = styled(LogoutIcon)`
  color: ${otherColour.errorDefault} !important;
`;

export const StyledProfileMenuIcon = styled(PersonOutlineIcon)`
  color: ${brandColour.primary100} !important;
`;

export const StyledMobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

export const StyledMobileHeader = styled.div`
  position: sticky;
  top: 0;
  z-index: 1000;
  width: 100%;
`;

export const StyledTabNavigation = styled.div`
  position: sticky;
  bottom: 0;
  z-index: 1000;
  width: 100%;
`;

export const StyledMobileContent = styled.div`
  flex-grow: 1;
  overflow-y: auto;
`;
