import React, { useEffect, useRef, useState } from 'react';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useFormReducer } from '@tphglobal/common/hooks';
import {
  Button,
  Form,
  FormError,
  FormRow,
  FormRowItem,
  MaterialAutocompleteInput,
  OutlinedContainer,
  PhoneInput,
  TextInput,
  Toast,
} from '@tphglobal/components';
import { brand, greyScaleColour } from '@tphglobal/common/theme/style.palette';
import {
  FixedTitle,
  HttpMethods,
  mapIdNameToOption,
} from '@tphglobal/common/utils';
import { RegionTypes, timeOptions } from '@tphglobal/common/utils/constants';
import { Option } from '@tphglobal/common/models';
import { toast } from 'react-toastify';
import { ProfileImage, StyledRow } from '../styles';
import { ReduxState } from '../../../redux/reducers';
import handleApiCall from '../../handleApiCall';
import {
  CREATE_ACTIVITY_LOGS_AU,
  CREATE_ACTIVITY_LOGS_UK,
  REMOVE_PROFILE_PIC_AU,
  REMOVE_PROFILE_PIC_UK,
  UPDATE_PROFILE_INFO_AU,
  UPDATE_PROFILE_INFO_UK,
  UPLOAD_PROFILE_PIC_AU,
  UPLOAD_PROFILE_PIC_UK,
} from '../../../api';
import messages from '../../../messages';
import {
  emailValidator, emptyPhoneValueValidator, emptyValueValidator, required,
} from '../../../utils';
import { getIPAddress } from '../../../utils/commonFunctions';

interface Props {
  onCancel: () => void;
  onSuccess: () => void;
}

const validators = {
  firstName: [required(messages?.general?.validations?.firstName), emptyValueValidator],
  lastName: [required(messages?.general?.validations?.lastName), emptyValueValidator],
  email: [
    required(messages?.general?.validations?.emailRequired),
    emailValidator,
  ],
  number: [required(messages?.general?.validations?.phoneRequired), emptyPhoneValueValidator],
  minutesStep: [required(messages?.general?.validations?.minutesStepRequired)],
  timeOption: [required(messages?.general?.validations?.timeOptionRequired)],
};

const EditInformation: React.FC<Props> = ({ onCancel, onSuccess }) => {
  const userProfile = useSelector((state: ReduxState) => state?.profile);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<string>(
    userProfile?.profile_pic,
  );
  const [base64Image, setBase64Image] = useState<string | null>(null);
  const selectedRegion = userProfile?.region;
  const fileInputRef = useRef(null);
  const reduxDispatch = useDispatch();
  const {
    submitError, handleSubmit, connectField, change, formValues,
  } = useFormReducer(validators);

  const handleImageUpload = (base64Image: string) => {
    const sanitizedBody = {
      base64Data: base64Image,
    };
    handleApiCall(
      reduxDispatch,
      selectedRegion === RegionTypes?.AU
        ? UPLOAD_PROFILE_PIC_AU
        : UPLOAD_PROFILE_PIC_UK,
      (response) => {
        onSuccess();
        setSubmitting(false);
      },
      HttpMethods.POST,
      selectedRegion === RegionTypes?.AU ? RegionTypes.AU : RegionTypes.UK,
      null,
      sanitizedBody,
    );
  };

  interface SubmitData {
    email : string
    firstName : string
    lastName : string
    minutesStep : string
    number : string
    timeOption : {id : string, label : string}
  }

  const handleCreateActivityLog = async () => {
    const ipAddress = await getIPAddress();

    const sanitizedBody = {
      Description: 'User Profile has been updated.',
      IPAddress: ipAddress,
      Type: 'PROFILE UPDATED',
    };
    handleApiCall(
      reduxDispatch,
      selectedRegion === RegionTypes.AU
        ? CREATE_ACTIVITY_LOGS_AU
        : CREATE_ACTIVITY_LOGS_UK,
      () => {},
      HttpMethods?.POST,
      selectedRegion === RegionTypes.AU ? RegionTypes.AU : RegionTypes.UK,
      null,
      sanitizedBody,
    );
  };

  const onSubmit = async (data: SubmitData) => {
    setSubmitting(true);

    const sanitizedBody = {
      FirstName: data?.firstName,
      LastName: data?.lastName,
      MinutsStep: data?.minutesStep,
      MobileNumber: data?.number,
      TimeOption: data?.timeOption?.id,
    };
    handleApiCall(
      reduxDispatch,
      selectedRegion === RegionTypes?.AU
        ? UPDATE_PROFILE_INFO_AU
        : UPDATE_PROFILE_INFO_UK,
      (response) => {
        if (base64Image) {
          handleImageUpload(base64Image);
        } else {
          onSuccess();
          setSubmitting(false);
        }
        
        handleCreateActivityLog()
      },
      HttpMethods.PUT,
      selectedRegion === RegionTypes?.AU ? RegionTypes.AU : RegionTypes.UK,
      messages?.profile?.editForm?.success?.updatedSuccessfully,
      sanitizedBody,
    );
  };

  const handleChangePhoto = () => {
    fileInputRef.current.click();
  };

  const handleRemovePhoto = () => {
    handleApiCall(
      reduxDispatch,
      selectedRegion === RegionTypes?.AU
        ? REMOVE_PROFILE_PIC_AU
        : REMOVE_PROFILE_PIC_UK,
      (response) => {
        onSuccess();
          setSubmitting(false);
          setSelectedImage(null)
      },
      HttpMethods.DELETE,
      selectedRegion === RegionTypes?.AU ? RegionTypes.AU : RegionTypes.UK,
      messages?.profile?.editForm?.success?.removedSuccessfully,
      null,
    );
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files[0];
    if (file) {
      const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];

      if (!validImageTypes.includes(file.type)) {
        toast(<Toast type="error" text="Only jpg/jpeg and png files are allowed!" />);
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result as string);
        setBase64Image(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    change('firstName', userProfile?.FirstName);
    change('lastName', userProfile?.LastName);
    change('email', userProfile?.EmailAddress);
    change('number', userProfile?.MobileNumber);
    change('minutesStep', userProfile?.MinutsStep);
    change('timeOption', {
      id: timeOptions?.find(
        (option: Option) => option?.id === userProfile?.TimeOption,
      )?.id,
      label: timeOptions?.find(
        (option: Option) => option?.id === userProfile?.TimeOption,
      )?.label,
    });
  }, []);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Grid padding="0 24px">
        <FormRow
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          rowGap={1}
        >
          <FormRowItem>
            <ProfileImage width="100px" height="100px" src={selectedImage || '/assets/images/profile_pic.png'} />
          </FormRowItem>
          <FormRowItem>
          <StyledRow gap='16px'>
            <FixedTitle
              varient="Fixed12Regular"
              color={brand?.secondaryMain}
              style={{ cursor: 'pointer' }}
              onClick={handleChangePhoto}
              >
              {messages?.profile?.changePhoto}
            </FixedTitle>
            <FixedTitle
              varient="Fixed12Regular"
              color={brand?.secondaryMain}
              style={{ cursor: 'pointer' }}
              onClick={handleRemovePhoto}
              >
              {messages?.profile?.removePhoto}
            </FixedTitle>
          </StyledRow>
          </FormRowItem>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileChange}
            accept="image/jpeg, image/png"
          />
        </FormRow>
        <FormRow marginTop="24px" marginBottom="16px" columnSpacing={2}>
          <FormRowItem>
            {connectField('firstName', {
              label: messages?.profile?.editForm?.firstName,
            })(TextInput)}
          </FormRowItem>
          <FormRowItem>
            {connectField('lastName', {
              label: messages?.profile?.editForm?.lastName,
            })(TextInput)}
          </FormRowItem>
        </FormRow>
        <FormRow>
          {connectField('email', {
            label: messages?.profile?.editForm?.emailAddress,
            disabled : true
          })(TextInput)}
        </FormRow>
        <FormRow>
          {connectField('number', {
            label: messages?.profile?.editForm?.phoneNumber,
          })(PhoneInput)}
        </FormRow>
        <FormRow>
          {connectField('minutesStep', {
            label: messages?.profile?.editForm?.minutesStep,
          })(TextInput)}
        </FormRow>
        <FormRow marginBottom={0}>
          {connectField('timeOption', {
            label: messages?.profile?.editForm?.timeOption,
            options: timeOptions?.map(mapIdNameToOption),
          })(MaterialAutocompleteInput)}
        </FormRow>
        {submitError && (
          <FormRow>
            <FormRowItem>
              <FormError
                message={messages?.departments?.addForm?.failure?.[submitError]}
              />
            </FormRowItem>
          </FormRow>
        )}
      </Grid>

      <OutlinedContainer
        noBorder
        borderRadius="0"
        padding="14px 24px"
        styles={{ backgroundColor: greyScaleColour?.grey60, marginTop: '24px' }}
      >
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  variant="outlined"
                  buttonColor={brand?.black}
                  color="primary"
                  size="large"
                  label={messages?.general?.cancel}
                  onClick={onCancel}
                />
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  type="submit"
                  disabled={submitting}
                  label={messages?.general?.update}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </OutlinedContainer>
    </Form>
  );
};

export default EditInformation;
