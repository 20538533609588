/* eslint-disable @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any */

import { HttpMethods } from '@tphglobal/common/utils';
import config from './config';
import { getToken } from './redux/reducers/auth';

const PING = '/api/ping';
const LOGIN = '/api/saas-admin/login';
export const CREATE_PRLEMP = '/createprlemp';

export const GET_USERS_AUS = `${config.API_URL_AU}/users`;
export const GET_USERS_UK = `${config.API_URL_UK}/users`;

export const GET_COMPANY_LIST_AU = `${config.API_URL_AU}/companies/list`;
export const GET_COMPANY_LIST_UK = `${config.API_URL_UK}/companies/list`;
export const GET_COMPANY_BY_ID_AU = `${config.API_URL_AU}/companies/get`;
export const GET_COMPANY_BY_ID_UK = `${config.API_URL_UK}/companies/get`;
export const ADD_COMPANY_AU = `${config.API_URL_AU}/companies/create`;
export const ADD_COMPANY_UK = `${config.API_URL_UK}/companies/create`;
export const UPDATE_COMPANY_AU = `${config.API_URL_AU}/companies/update`;
export const UPDATE_COMPANY_UK = `${config.API_URL_UK}/companies/update`;
export const DELETE_COMPANY_AU = `${config.API_URL_AU}/companies/delete`;
export const DELETE_COMPANY_UK = `${config.API_URL_UK}/companies/delete`;

export const GET_TIMECARD_AU = `${config.API_URL_AU}/timecards/get`
export const GET_TIMECARD_UK = `${config.API_URL_UK}/timecards/get`
export const CREATE_TIMECARD_AU = `${config.API_URL_AU}/timecards/create`;
export const CREATE_TIMECARD_UK = `${config.API_URL_UK}/timecards/create`;
export const DELETE_TIMECARD_AU = `${config.API_URL_AU}/timecards/delete`;
export const DELETE_TIMECARD_UK = `${config.API_URL_UK}/timecards/delete`;
export const UPDATE_TIMECARD_AU = `${config.API_URL_AU}/timecards/update`;
export const UPDATE_TIMECARD_UK = `${config.API_URL_UK}/timecards/update`;
export const UPDATE_TIMECARD_STATUS_AU = `${config.API_URL_AU}/timecards/update-status`;
export const UPDATE_TIMECARD_STATUS_UK = `${config.API_URL_UK}/timecards/update-status`;
export const GET_TIMECARD_LIST_AU = `${config.API_URL_AU}/timecards/list`;
export const GET_TIMECARD_LIST_UK = `${config.API_URL_UK}/timecards/list`;

export const GET_DT_API_URL_AU = `${config.API_URL_AU}/config/get-dt-api-url`;
export const GET_DT_API_URL_UK = `${config.API_URL_UK}/config/get-dt-api-url`;

export const SEND_EMAIL_AU = `${config.API_URL_AU}/email`;
export const SEND_EMAIL_UK = `${config.API_URL_UK}/email`;

export const GET_DOCUMENT_AU = `${config.API_URL_AU}/documents/get`;
export const GET_DOCUMENT_UK = `${config.API_URL_UK}/documents/get`;
export const ADD_DOCUMENT_AU = `${config.API_URL_AU}/documents/create`;
export const ADD_DOCUMENT_UK = `${config.API_URL_UK}/documents/create`;
export const DELETE_DOCUMENT_AU = `${config.API_URL_AU}/documents/delete`;
export const DELETE_DOCUMENT_UK = `${config.API_URL_UK}/documents/delete`;
export const GET_DOCUMENT_LIST_AU = `${config.API_URL_AU}/documents/list`;
export const GET_DOCUMENT_LIST_UK = `${config.API_URL_UK}/documents/list`;

export const UPDATE_LOGIN_AU = `${config.API_URL_AU}/users/update-login`;
export const UPDATE_LOGIN_UK = `${config.API_URL_UK}/users/update-login`;
export const GET_USER_DETAILS_AU = `${config.API_URL_AU}/users/get-user-detail`;
export const GET_USER_DETAILS_UK = `${config.API_URL_UK}/users/get-user-detail`;
export const UPDATE_PROFILE_INFO_AU = `${config.API_URL_AU}/users/update`;
export const UPDATE_PROFILE_INFO_UK = `${config.API_URL_UK}/users/update`;

export const GET_NEWS_FEED_AU = `${config.API_URL_AU}/news-feed/list`;
export const GET_NEWS_FEED_UK = `${config.API_URL_UK}/news-feed/list`;

export const GET_ACTIVITY_LOGS_AU = `${config.API_URL_AU}/activity-log/list`;
export const GET_ACTIVITY_LOGS_UK = `${config.API_URL_UK}/activity-log/list`;
export const CREATE_ACTIVITY_LOGS_AU = `${config.API_URL_AU}/activity-log/create`;
export const CREATE_ACTIVITY_LOGS_UK = `${config.API_URL_UK}/activity-log/create`;

export const UPLOAD_ATTACHMENT_AU = `${config.API_URL_AU}/attachments/upload`;
export const UPLOAD_ATTACHMENT_UK = `${config.API_URL_UK}/attachments/upload`;
export const FETCH_ATTACHMENT_AU = `${config.API_URL_AU}/attachments/fetch`;
export const FETCH_ATTACHMENT_UK = `${config.API_URL_UK}/attachments/fetch`;
export const REMOVE_ATTACHMENT_AU = `${config.API_URL_AU}/attachments/remove`;
export const REMOVE_ATTACHMENT_UK = `${config.API_URL_UK}/attachments/remove`;
export const GET_PROFILE_PIC_AU = `${config.API_URL_AU}/attachments/get-profile-pic`;
export const GET_PROFILE_PIC_UK = `${config.API_URL_UK}/attachments/get-profile-pic`;
export const REMOVE_PROFILE_PIC_AU = `${config.API_URL_AU}/attachments/remove-profile-pic`;
export const REMOVE_PROFILE_PIC_UK = `${config.API_URL_UK}/attachments/remove-profile-pic`;
export const UPLOAD_PROFILE_PIC_AU = `${config.API_URL_AU}/attachments/upload-profile-pic`;
export const UPLOAD_PROFILE_PIC_UK = `${config.API_URL_UK}/attachments/upload-profile-pic`;
export const GET_SIGNATURE_AU = `${config.API_URL_AU}/attachments/get-signature`;
export const GET_SIGNATURE_UK = `${config.API_URL_UK}/attachments/get-signature`;
export const UPLOAD_SIGNATURE_AU = `${config.API_URL_AU}/attachments/upload-signature`;
export const UPLOAD_SIGNATURE_UK = `${config.API_URL_UK}/attachments/upload-signature`;
export const CLEAR_SIGNATURE_AU = `${config.API_URL_AU}/attachments/clear-signature`;
export const CLEAR_SIGNATURE_UK = `${config.API_URL_UK}/attachments/clear-signature`;

export const GET_SETTINGS_AU = `${config.API_URL_AU}/settings/initialize`;
export const GET_SETTINGS_UK = `${config.API_URL_UK}/settings/initialize`;
export const UPDATE_SETTINGS_AU = `${config.API_URL_AU}/settings/update`;
export const UPDATE_SETTINGS_UK = `${config.API_URL_UK}/settings/update`;
export const CLEAR_DATA_AU = `${config.API_URL_AU}/settings/get-metadata-list`;
export const CLEAR_DATA_UK = `${config.API_URL_UK}/settings/get-metadata-list`;

export const GET_SYS_COMPANY_BY_ID_AU = `${config.API_URL_AU}/companies/get-syscompany`;
export const GET_SYS_COMPANY_BY_ID_UK = `${config.API_URL_UK}/companies/get-syscompany`;

export const TEXT_TO_IMAGE_AU = `${config.API_URL_AU}/text-to-image`;
export const TEXT_TO_IMAGE_UK = `${config.API_URL_UK}/text-to-image`;

export const CONTACT_US_AU = `${config.API_URL_AU}/email-contact-us`;
export const CONTACT_US_UK = `${config.API_URL_UK}/email-contact-us`;

export const PREVIEW_PDF_AU = `${config.API_URL_AU}/preview/pdf`;
export const PREVIEW_PDF_UK = `${config.API_URL_UK}/preview/pdf`;

// Add your api calls here

export const apiCall = (
  endpoint: string,
  method = HttpMethods.GET,
  body?: any,
  isFormData?: boolean,
): Promise<any> => {
  const headers = new Headers({
    Accept: 'application/json',
  });
  if (!isFormData) {
    headers.append('Content-Type', 'application/json');
  }
  const token = getToken();

  if (token) {
    headers.append('Authorization', `Bearer ${token}`);
  }
  let finalBody: string | null | undefined = body;

  if (body && !isFormData) {
    finalBody = JSON.stringify(body);
  }
  const url = endpoint;

  return new Promise<any>((resolve, reject) => {
    fetch(url, { body: finalBody, headers, method })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        // reject(error);
        console.log(error);
        
      });
  });
};

export const ping = (): Promise<string> => apiCall(PING);

export const login = (formData: any): Promise<string> => apiCall(LOGIN, HttpMethods.POST, formData);
