import React from "react";
import { fontWeight } from "../theme/style.typography";
import { SxProps, Theme, Typography } from "@mui/material";

interface FixedTitleProps {
  color ?: string,
  onClick ?: () => void,
  style?: React.CSSProperties;
  varient:
    | 'Fixed18Medium'
    | 'Fixed16Medium'
    | 'Fixed16Regular'
    | 'Fixed14Bold'
    | 'Fixed14Medium'
    | 'Fixed14Regular'
    | 'Fixed12Bold'
    | 'Fixed12Medium'
    | 'Fixed12Regular'
    | 'Fixed11Regular'
    | 'Fixed10Regular';
  children: React.ReactNode;
  width?: string
  textAlign?: string
}

export const FixedTitle: React.FC<FixedTitleProps> = ({
  varient,
  children,
  color,
  onClick,
  style,
  width,
  textAlign,
  ...props
}) => {
  let defaultStyle = {};

  switch (varient) {
    case 'Fixed18Medium':
      defaultStyle = {
        fontSize: '18px',
        fontWeight: fontWeight.medium,
        lineHeight: '23px',
        color: color || 'inherit'
      };
      break;
    case 'Fixed16Medium':
      defaultStyle = {
        fontSize: '16px',
        fontWeight: fontWeight.medium,
        lineHeight: '21px',
        color: color || 'inherit'
      };
      break;
    case 'Fixed16Regular':
      defaultStyle = {
        fontSize: '16px',
        fontWeight: fontWeight.regular,
        lineHeight: '21px',
        color: color || 'inherit'
      };
      break;
    case 'Fixed14Bold':
      defaultStyle = {
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '18px',
        color: color || 'inherit'
      };
      break;
    case 'Fixed14Medium':
      defaultStyle = {
        fontSize: '14px',
        fontWeight: fontWeight.medium,
        lineHeight: '18px',
        color: color || 'inherit'
      };
      break;
    case 'Fixed14Regular':
      defaultStyle = {
        fontSize: '14px',
        fontWeight: fontWeight.regular,
        lineHeight: '18px',
        color: color || 'inherit'
      };
      break;
    case 'Fixed12Bold':
      defaultStyle = {
        fontSize: '12px',
        fontWeight: 700,
        lineHeight: '16px',
        color: color || 'inherit'
      };
      break;
    case 'Fixed12Medium':
      defaultStyle = {
        fontSize: '12px',
        fontWeight: fontWeight.medium,
        lineHeight: '16px',
        color: color || 'inherit'
      };
      break;
    case 'Fixed12Regular':
      defaultStyle = {
        fontSize: '12px',
        fontWeight: fontWeight.regular,
        lineHeight: '16px',
        color: color || 'inherit'
      };
      break;
    case 'Fixed11Regular':
      defaultStyle = {
        fontSize: '11px',
        fontWeight: fontWeight.regular,
        lineHeight: '14px',
        color: color || 'inherit'
      };
      break;
    case 'Fixed10Regular':
      defaultStyle = {
        fontSize: '10px',
        fontWeight: fontWeight.regular,
        lineHeight: '13px',
        color: color || 'inherit'
      };
      break;
    default:
      break;
  }
  const mergedStyle = { ...defaultStyle, ...style };

  return (
    <Typography textAlign={textAlign} width={width} style={mergedStyle} onClick={onClick} {...props}>
      {children}
    </Typography>
  );
};
