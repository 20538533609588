/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  StyledButton,
  StyledCard,
  StyledCardButton,
  StyledCrossButtonContainer,
  StyledDeleteButton,
  StyledDetailRow,
  StyledDetailRowItem,
  StyledDetailsContainer,
  StyledDynamicFieldContainer,
  StyledFieldItem,
  StyledFieldList,
  StyledFieldsContainer,
} from './styles';
import Button from '../button';
import { FormRow, FormRowItem } from '../form';
import { InfoItem } from '../index';
import messages from '@tphglobal/common/messages';

interface Props {
  addItemToGroup?: any;
  connectNestedField: any;
  arrayList: any;
  group: string;
  formLayout: any;
  deleteItemFromGroup?: any;
  startZero?: boolean;
  singleSave?: boolean;
  handleRecursiveSubmit?: any;
  saveItemInState?: any;
  deleteItemFromState?: any;
  submitForm?: any;
  noAddButton?: boolean
  noDeleteButton?: boolean
}

export function compareKeys(key1: any, key2: any) {
  const processedKey1 = key1.replace(/\s+/g, '').toLowerCase();
  const processedKey2 = key2.replace(/\s+/g, '').toLowerCase();
  return processedKey1 === processedKey2;
}

const checkForDisplay = (
  grp: any,
  comparedValue: string,
  compareWith: string
) => {
  if (grp?.[comparedValue]?.value?.id) {
    return grp?.[comparedValue]?.value?.id !== compareWith
  } else if (grp?.[comparedValue]?.id) {
    return grp?.[comparedValue]?.id !== compareWith
  } else if (grp?.[comparedValue]?.value) {
    return grp?.[comparedValue]?.value !== compareWith
  } else {
    return grp?.[comparedValue] !== compareWith
  }
}

const mapFormLayout = (
  formLayout: any,
  connectNestedField: any,
  group: string,
  grp: any
) => (
  <StyledFieldItem>
    {formLayout.map((row: any, index : number) => (
      <FormRow key={row?.id} marginBottom={index === formLayout?.length - 1 ? '0' : "16px"}>
        {row?.map((field: any) => {
          const comparedValue = field?.props?.comparedValue;
          const compareWith = field?.props?.compareWith;
          return (
            <FormRowItem
              key={field.value}
              display={field?.props?.display === 'none' &&
                checkForDisplay(grp, comparedValue, compareWith)
                ? field?.props?.display
                : 'block'}
            >
              {
                connectNestedField(
                  `${field.value.replace(/\s/g, '').toLowerCase()
                  }_${grp?._uid
                  }_${group}`,
                  {
                    label: `${field.value}`,
                    multiline: field?.props.multiline,
                    rows: field?.props.rows,
                    maxWidth: field?.props.maxWidth,
                    options: field?.props.options,
                    type: field?.props.type,
                    multiple: field?.props.multiple,
                    required: field?.props?.required,
                    disabled: field?.props?.disabled,
                    disablePast: field?.props?.disablePast,
                    minDate: field?.props?.minDate,
                    maxDate: field?.props?.maxDate,
                    views: field?.props?.views,
                    dateFormat: field?.props?.dateFormat,
                    ...field?.props
                  },
                )(field.component)}
            </FormRowItem>
          )
        })}
      </FormRow>
    ))}
  </StyledFieldItem >
);

const renderAllActiveForms = (
  list: any,
  formLayout: any,
  connectNestedField: any,
  deleteItemFromGroup: any,
  group: string,
  noDeleteButton?: boolean
) => list.map((grp: any) => (
  <StyledFieldList key={grp._uid}>
    {mapFormLayout(formLayout, connectNestedField, group, grp)}
    {!noDeleteButton && <StyledCrossButtonContainer>
      <StyledDeleteButton
        onClick={() => deleteItemFromGroup(group, grp._uid)}
      >
        {messages?.general?.delete}
      </StyledDeleteButton>
    </StyledCrossButtonContainer>}
  </StyledFieldList>
));

const RecursiveFieldInput: React.FC<Props> = ({
  connectNestedField,
  arrayList,
  addItemToGroup,
  deleteItemFromGroup,
  group,
  formLayout,
  startZero,
  singleSave,
  saveItemInState,
  deleteItemFromState,
  handleRecursiveSubmit,
  submitForm,
  noAddButton,
  noDeleteButton,
}) => {
  const [list, setList] = useState(arrayList);
  const [updatedFormList, setUpdatedFormList] = useState(null);
  const [isEditing, setIsEditing] = useState(null);

  useEffect(() => {
    setList(arrayList);
  }, [arrayList]);

  const formsList = useSelector((state: any) => state?.forms?.[group]) || [];

  const renderFormViewAndEdit = () => {
    return formsList?.map((grp: any, grpIndex: number) => (isEditing === grp?._uid ? (
      <StyledCard bg="white" data-testid="edit">
        <StyledCardButton>
          <Button
            label={messages?.general?.cancel}
            variant="outlined"
            data-testid="cancel-button"
            onClick={() => {
              setIsEditing(null);
            }}
          />
          <Button
            label={messages?.general?.save}
            variant="contained"
            data-testid="save-button"
            onClick={() => {
              saveItemInState(group, grp?._uid);
              setIsEditing(null);
            }}
          />
        </StyledCardButton>
        {mapFormLayout(
          formLayout,
          connectNestedField,
          group,
          updatedFormList ? updatedFormList[grpIndex] : grp
        )}
      </StyledCard>
    ) : (
      <StyledCard data-testid="view">
        <StyledCardButton>
          <Button
            label={messages?.general?.delete}
            variant="outlined"
            data-testid="delete-button"
            onClick={() => {
              // Delete the group Item from state as well as formValues
              deleteItemFromState(group, grp?._uid);
            }}
          />
          <Button
            label={messages?.general?.edit}
            variant="contained"
            data-testid="edit-button"
            onClick={() => {
              setIsEditing(grp._uid);
            }}
          />
        </StyledCardButton>
        <StyledDetailsContainer>
          {formLayout.map((row: any) => (
            <StyledDetailRow container>
              {row.map((field: any) => {
                const key = field.value;
                const matchingKey = Object.keys(grp).find((grpKey) => compareKeys(grpKey, key));
                let value;
                if (Array.isArray(grp[matchingKey])) {
                  value = grp[matchingKey]?.map((item: any) => item?.label).join(', ');
                } else if (typeof grp[matchingKey] !== 'string') {
                  value = grp[matchingKey]?.label;
                } else {
                  value = grp[matchingKey]
                }
                return (
                  <StyledDetailRowItem item>
                    {value
                      ? ((field?.props?.comparedValue && field?.props?.compareWith) &&
                        checkForDisplay(grp, field?.props?.comparedValue, field?.props?.compareWith))
                        ? <></>
                        : < InfoItem label={field.value} value={value} />
                      : <></>
                    }
                  </StyledDetailRowItem>
                );
              })}
            </StyledDetailRow>
          ))}
        </StyledDetailsContainer>
      </StyledCard>
    )));
  };

  useEffect(() => {
    if (isEditing && formsList?.length && formsList !== list) {
      setUpdatedFormList(list);
    } else {
      setIsEditing(null);
    }
  }, [isEditing, list, formsList])

  return (
    <StyledDynamicFieldContainer >
      <StyledFieldsContainer startZero={startZero}>
        {!singleSave && !handleRecursiveSubmit && (
          <>
            {renderAllActiveForms(
              list,
              formLayout,
              connectNestedField,
              deleteItemFromGroup,
              group,
              noDeleteButton
            )}
            {!noAddButton && <Button
              variant="contained"
              label={messages?.general?.add}
              onClick={() => {
                addItemToGroup(group);
              }}
            />}
          </>
        )}

        {singleSave && !handleRecursiveSubmit && (
          <>
            {renderFormViewAndEdit()}
            {list.length > 0 && formsList.length !== list.length ? (
              <>
                {mapFormLayout(
                  formLayout,
                  connectNestedField,
                  group,
                  list[list.length - 1]
                )}
                {list?.length !== 1 &&
                  <StyledCrossButtonContainer data-testid="testIn">
                    <StyledDeleteButton
                      data-testid="delete-button"
                      onClick={() => deleteItemFromGroup(group, list[list.length - 1]?._uid)}
                    >
                      {messages?.general?.cancel}
                    </StyledDeleteButton>
                  </StyledCrossButtonContainer>}
                <StyledButton
                  display={list?.length === 1 && 'flex'}
                  padding={list?.length === 1 && '15px 0px 11px'}
                >
                  <Button
                    variant="contained"
                    data-testid="add-button"
                    label={messages?.general?.save}
                    onClick={() => {
                      saveItemInState(group, list[list.length - 1]?._uid);
                    }}
                  />
                </StyledButton>
              </>
            ) : (
              !noAddButton && <Button
                variant="contained"
                label={messages?.general?.add}
                data-testid="add-button"
                onClick={() => {
                  addItemToGroup(group);
                }}
              />
            )}
          </>
        )}

        {singleSave
          && handleRecursiveSubmit
          && (list.length > 0 ? (
            <>
              {mapFormLayout(
                formLayout,
                connectNestedField,
                group,
                list[list.length - 1]
              )}
              <StyledCrossButtonContainer data-testid="get">
                <StyledDeleteButton
                  data-testid="delete-button"
                  onClick={() => deleteItemFromGroup(group, list[list.length - 1]?._uid)}
                >
                  {messages?.general?.cancel}
                </StyledDeleteButton>
              </StyledCrossButtonContainer>
              <Button
                variant="contained"
                data-testid="save-button"
                label={messages?.general?.save}
                onClick={() => {
                  // Task is to call function with the filled data
                  submitForm(
                    handleRecursiveSubmit,
                    list[list.length - 1]?._uid,
                  );
                }}
              />
            </>
          ) : (
            !noAddButton && <Button
              variant="contained"
              label={messages?.general?.add}
              onClick={() => {
                addItemToGroup(group);
              }}
            />
          ))}
      </StyledFieldsContainer>
    </StyledDynamicFieldContainer>
  );
};

export default RecursiveFieldInput;
