import React, { useEffect, useState } from 'react';
import {
  Grid,
} from '@mui/material';
import { useFormReducer } from '@tphglobal/common/hooks';
import {
  Button,
  Form,
  FormError,
  FormRow,
  FormRowItem,
  MaterialTextInput,
  OutlinedContainer,
} from '@tphglobal/components';
import {
  brand,
  greyScaleColour,
  otherColour,
} from '@tphglobal/common/theme/style.palette';
import { FixedTitle } from '@tphglobal/common/utils';
import { fontSize, fontWeight } from '@tphglobal/common/theme/style.typography';
import messages from '../../messages';
import { emailValidator, required } from '../../utils';
import {
  StyledHeading,
} from './styles';

interface Props {
  onSubmit ?: (data: {email : string}) => Promise<unknown>;
  onCancel: () => void;
  email : string
  submittingTimeCard : boolean
}

const validators = {
  email: [required(messages?.general?.validations?.emailRequired), emailValidator],
};
const DigitalTimeSheet: React.FC<Props> = ({
  onSubmit,
  onCancel,
  email,
  submittingTimeCard,
}) => {
  const {
    submitError,
    handleSubmit,
    connectField,
    change,
  } = useFormReducer(validators);

  useEffect(() => {
    change('email', email);
  }, []);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Grid padding="0 24px">
        <FormRow marginBottom="6px">
          <StyledHeading
            fontSize={fontSize?.h5}
            fontWeight={fontWeight?.semiBold}
            lineHeight="21px"
          >
            {messages?.timeCard?.digitalTimesheet?.heading}
          </StyledHeading>
        </FormRow>
        <FormRow marginBottom="24px">
          <FixedTitle varient="Fixed12Regular" color={greyScaleColour?.grey100}>
            {messages?.timeCard?.digitalTimesheet?.subHeading}
          </FixedTitle>
        </FormRow>
        <FormRow minWidth="580px" marginBottom="24px">
          {connectField('email', {
            label: messages?.timeCard?.digitalTimesheet?.email,
          })(MaterialTextInput)}
        </FormRow>
        <FormRow marginBottom="0">
          <FixedTitle varient="Fixed12Regular" color={otherColour?.errorDefault}>
            {messages?.timeCard?.digitalTimesheet?.tnc}
          </FixedTitle>
        </FormRow>
        {submitError && (
          <FormRow>
            <FormRowItem>
              <FormError
                message={messages?.departments?.addForm?.failure?.[submitError]}
              />
            </FormRowItem>
          </FormRow>
        )}
      </Grid>
      <OutlinedContainer
        noBorder
        borderRadius="0"
        padding="14px 24px"
        styles={{ backgroundColor: greyScaleColour?.grey60, marginTop: '24px' }}
      >
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  variant="outlined"
                  buttonColor={brand?.black}
                  color="primary"
                  size="large"
                  onClick={onCancel}
                  label={messages?.general?.cancel}
                />
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  type="submit"
                  disabled={submittingTimeCard}
                  label={messages?.general?.submit}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </OutlinedContainer>
    </Form>
  );
};

export default DigitalTimeSheet;
