import { OutlinedContainer } from '@tphglobal/components';
import React from 'react';
import { fontSize, fontWeight } from '@tphglobal/common/theme/style.typography';
import { FixedTitle } from '@tphglobal/common/utils';
import { greyScaleColour } from '@tphglobal/common/theme/style.palette';
import messages from '../../messages';
import { StyledHeading } from './styles';

interface Props {
  isMobile ?: boolean
  feed ?: string
}

const NewFeed:React.FC<Props> = ({ isMobile, feed }) => (
  <OutlinedContainer
    styles={{
      display: 'flex', flexDirection: 'column', gap: '6px', padding: isMobile && '16px 0',
    }}
  >
    <StyledHeading
      lineHeight="20px"
      fontWeight={fontWeight?.semiBold}
      fontSize={fontSize?.h5}
    >
      {messages?.home?.newFeed}
    </StyledHeading>
    <FixedTitle varient="Fixed12Regular" color={greyScaleColour?.grey100}>
      {feed}
    </FixedTitle>
  </OutlinedContainer>
);

export default NewFeed;
