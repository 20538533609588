import { ButtonProps } from '@mui/material';
import React from 'react';
import { StyledButton, StyledIcon } from './styles';
import { FixedTitle } from '@tphglobal/common/utils';

type ButtonCustomProps = ButtonProps & {
  onClick?: () => void;
  label?: string;
  icon?: React.ReactNode;
  fontSize?: string;
  buttonColor?: string;
  noHover?: boolean;
  disabled?: boolean
};

const Button: React.FC<ButtonCustomProps> = ({
  label,
  icon,
  fontSize,
  buttonColor,
  noHover,
  disabled,
  ...props
}) => (
  <StyledButton disabled={disabled} noHover={noHover} buttonColor={buttonColor} {...props}>
    {icon && <StyledIcon>{icon}</StyledIcon>}
    <FixedTitle varient='Fixed14Medium'>{label}</FixedTitle>
  </StyledButton>
);

export default Button;
