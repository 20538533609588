/* eslint-disable  @typescript-eslint/no-explicit-any, no-console,  require-yield */
import {
  call, put, takeEvery, select,
} from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { HttpStatus } from '@tphglobal/common/utils';
import { MetaData } from '@tphglobal/common/models';
import {
  APICALL,
  ApiCall,
  FETCH_USER_PROFILE,
  UPDATE_USER_PROFILE,
  LOGIN,
  LOGOUT,
  PAGINATED_APICALL,
  POST_LOGIN,
  PagedApiCall,
  RESULTS_FETCH_REQUEST,
  RESULTS_FETCH_SUCCESS,
  action,
  fetchBaseData,
  updateUserProfileWithPayload,
  logout,
  profileModuleActions,
  removeToken,
  updateToken,
  postLogin,
} from '../actions';
import { apiCall, login, ping } from '../../api';
import {
  Role,
  defaultAuthState,
  getStateFromToken,
  getToken,
} from '../reducers/auth';
import { fetchAuthSession, signOut } from 'aws-amplify/auth';

declare type callback = (body: any) => any;
declare type errorHandler = (body: any, response: Response) => any;

export function* handleResponse(
  response: Response,
  callback: callback,
  error?: errorHandler,
  ignoreStatus = false,
  apiReCallingData ?: any,
): any {
  try {
    if (response.status === HttpStatus.Ok) {
      const body = yield response.json();
      const authToken = response?.headers
        ?.get('Authorization')
        ?.split('Bearer ')[1];
      if (authToken) {
        yield put(updateToken(authToken));
      }
      const callbackResult = callback(body);
      if (callbackResult) {
        yield* callbackResult;
      }
    } else if (
      !ignoreStatus
      && response.status === HttpStatus.Unauthorized
    ) {
      try {
          const { tokens } = yield fetchAuthSession({ forceRefresh: true });
          const idToken = tokens?.idToken?.toString();
    
          if (idToken) {
            yield put(postLogin({ token: idToken }));
            yield* doApiCall(apiReCallingData)
          } else {
            yield signOut();
            yield put(logout());
          }
      } catch (error) {
        error(error)
      }
    } else if (error) {
      const body = yield response.json();
      const callbackResult = error(body, response);
      if (callbackResult) {
        yield* callbackResult;
      }
    }
  } catch (e) {
    if (error) {
      const callbackResult = error(e, response);
      if (callbackResult) {
        yield* callbackResult;
      }
    }
  }
}

export function toArray<T>(param: T | T[] | void) {
  if (param) {
    return param instanceof Array ? param.filter((p) => !!p) : [param];
  }

  return [];
}

function* doApiCall<
  RequestProps,
  SuccessProps extends { _requestDate: Date },
  FailureProps extends Response
>(event: {
  payload: ApiCall<RequestProps, SuccessProps, FailureProps>;
}): Generator<any> {
  const {
    request: {
      requestProps: { payload, method, isFormData },
    },
    success,
    failure,
  } = event.payload;

  const {
    request: {
      requestProps: { endpoint },
    },
  } = event.payload;
  const date = new Date();

  const apiReCallingData = event
  try {
    const result: any = yield call(
      apiCall,
      endpoint,
      method,
      payload,
      isFormData,
    );

    yield* handleResponse(
      result,
      function* (body: SuccessProps) {
        const newBody = body;
        if (typeof body === 'object') {
          newBody._requestDate = date;
        }
        if (success.resolve) {
          const actions = toArray(success.resolve(newBody));
          yield* actions.map((act) => put(act));
        }
      },
      function* (body: FailureProps): any {
        if (failure.reject) {
          const actions = toArray(failure.reject(body));
          yield* actions.map((act) => put(act));
        }
      },
      false,
      apiReCallingData
    );
  } catch (e) {
    failure.reject(e);
  }
}

export function getPaginationParameters(filter: MetaData<any>): string {
  const {
    order, direction, page, limit, filters,
  } = filter;
  const simpleParams = {
    order,
    direction,
    page,
    limit,
  };

  let filterParams: string[] = [];

  Object.keys(filters).forEach((key) => {
    filterParams = filterParams.concat(
      filters[key] ? [`filter[${key}]=${filters[key]}`] : [],
    );
  });

  return Object.keys(simpleParams)
    .map((key: keyof typeof simpleParams) => (simpleParams[key] ? `${key}=${String(simpleParams[key])}` : ''))
    .concat(filterParams)
    .filter((value) => value)
    .join('&');
}

export function* doFetchBaseData(): Generator<any> {
  if (getToken()) {
    const result: any = {};
    yield* handleResponse(
      result,
      function* () {
        /** @Note Add actions to fetch initial data */
      },
      function* () {
        yield put(logout());
      },
      true,
    );
  } else {
    yield put(logout());
  }
}

export function* doLogin(event: any): Generator<any> {
  try {
    console.log('in login');
    const result: any = yield call(login, event?.payload?.formData);
    yield* handleResponse(
      result,
      function* (body) {
        const newState = getStateFromToken(defaultAuthState, body?.token);
        if (newState?.role === Role.INVALID) {
          yield event?.payload?.reject({
            message: 'error.login.invalidCredentials',
          });
        } else {
          yield put(updateToken(body?.token));
          yield put(fetchBaseData());
          yield event?.payload?.resolve(body);
        }
      },
      function* (body: any) {
        yield event?.payload?.reject(body);
      },
      true,
    );
  } catch (e) {
    event?.payload?.reject(e);
  }
}

export function* doLogout() {
  try {
    yield put(removeToken());
    yield put(profileModuleActions.reset());
  } catch (error) {
    console.log(error); // eslint-disable-line no-console
  }
}

export function* doFetchPostLogin(event: any): Generator<any> {
  if (event?.payload?.region) {
    yield put(updateUserProfileWithPayload({ region: event?.payload?.region }));
  }
  if (event?.payload?.profile_pic || event?.payload?.profile_pic === null) {
    yield put(updateUserProfileWithPayload({ profile_pic: event?.payload?.profile_pic }));
  }
  if (event?.payload?.token) {
    yield put(updateToken(event?.payload?.token));
  }
}

export function* doUpdateUserProfile(event: any): Generator<any> {
  yield put(profileModuleActions.update(event?.payload));
}

export function* doFetchUserProfile(event: any): Generator<any> {
  yield put(profileModuleActions.update(event?.payload?.result));
}

export function* fetchResultsSaga(event: any): Generator<any> {
  const { endpoint, params } = event.payload;
  try {
    const result: any = yield call(apiCall, endpoint);

    yield* handleResponse(
      result,
      function* (body) {
        yield put(action(RESULTS_FETCH_SUCCESS, body));
      },
      function* (error) {
        console.log(error);
      },
    );
  } catch (error) {
    console.log(error);
  }
}

export function* rootSaga(): SagaIterator<void> {
  yield takeEvery(APICALL, doApiCall as any);
  yield takeEvery(LOGIN, doLogin);
  yield takeEvery(LOGOUT, doLogout);
  yield takeEvery(RESULTS_FETCH_REQUEST, fetchResultsSaga);
  yield takeEvery(POST_LOGIN, doFetchPostLogin);
  yield takeEvery(UPDATE_USER_PROFILE, doUpdateUserProfile);
  yield takeEvery(FETCH_USER_PROFILE, doFetchUserProfile);
}
