import {
  Button,
  Card,
  Form,
  FormError,
  FormRow,
  FormRowItem,
  MaterialAutocompleteInput,
  OutlinedContainer,
  PhoneInput,
  SwitchInput,
  TextInput,
  Toast,
} from '@tphglobal/components';
import React, { useEffect, useState } from 'react';
import {
  brand,
  greyScaleColour,
  otherColour,
} from '@tphglobal/common/theme/style.palette';
import useWindowSize from '@tphglobal/common/hooks/windowSize';
import { breakpoints } from '@tphglobal/common/theme/style.layout';
import { Grid } from '@mui/material';
import { fontSize, fontWeight } from '@tphglobal/common/theme/style.typography';
import {
  emptyValueValidator,
  FixedTitle,
  HttpMethods,
  required,
} from '@tphglobal/common/utils';
import { useDispatch, useSelector } from 'react-redux';
import { useFormReducer } from '@tphglobal/common/hooks';
import { toast } from 'react-toastify';
import { RegionTypes } from '@tphglobal/common/utils/constants';
import { goBack } from 'connected-react-router';
import handleApiCall from '../../handleApiCall';
import { ReduxState } from '../../../redux/reducers';
import { UserSetting } from '../../../models';
import {
  GET_SETTINGS_AU,
  GET_SETTINGS_UK,
  UPDATE_SETTINGS_AU,
  UPDATE_SETTINGS_UK,
} from '../../../api';
import { HorizintalLine, ProfileImage, StyledHeading } from '../styles';
import { Container } from '../../../components';
import messages from '../../../messages';

const validators = {
  first: [required('')],
  second: [required('')],
  third: [required('')],
};

enum SettingsKey {
  FIRST = 'NotifyNewDocument',
  SECOND = 'StoreDataDigitalForms',
  THIRD = 'UseDataDigitalForms',
}

const SettingsMobile = () => {
  const reduxDispatch = useDispatch();
  const selectedRegion = useSelector(
    (state: ReduxState) => state?.profile?.region,
  );

  const {
    submitting,
    submitError,
    handleSubmit,
    connectField,
    change,
    setSubmitError,
  } = useFormReducer(validators);

  const [switch1, setSwitch1] = useState<boolean>();
  const [switch2, setSwitch2] = useState<boolean>();
  const [switch3, setSwitch3] = useState<boolean>();
  const [guid1, setGuid1] = useState<string>('');
  const [guid2, setGuid2] = useState<string>('');
  const [guid3, setGuid3] = useState<string>('');

  const updateSetting = (Key: string, Value: boolean, guid: string) => {
    const sanitizedBody = {
      Key,
      Value,
    };
    handleApiCall(
      reduxDispatch,
      `${
        selectedRegion === RegionTypes.AU
          ? UPDATE_SETTINGS_AU
          : UPDATE_SETTINGS_UK
      }/${guid}`,
      (response) => {
      },
      HttpMethods.PUT,
      selectedRegion === RegionTypes.AU ? RegionTypes.AU : RegionTypes.UK,
      null,
      sanitizedBody,
    );
  };

  useEffect(() => {
    change('first', switch1);
    change('second', switch2);
    change('third', switch3);
  }, [switch1, switch2, switch3]);

  const fetchSetting = () => {
    handleApiCall(
      reduxDispatch,
      selectedRegion === RegionTypes.AU ? GET_SETTINGS_AU : GET_SETTINGS_UK,
      (response) => {
        response?.result?.forEach((setting: UserSetting) => {
          switch (setting.Key) {
            case 'NotifyNewDocument':
              setSwitch1(setting.Value);
              setGuid1(setting.Guid);
              break;
            case 'StoreDataDigitalForms':
              setSwitch2(setting.Value);
              setGuid2(setting.Guid);
              break;
            case 'UseDataDigitalForms':
              setSwitch3(setting.Value);
              setGuid3(setting.Guid);
              break;
            default:
              break;
          }
        });
      },
      HttpMethods.POST,
      selectedRegion === RegionTypes.AU ? RegionTypes.AU : RegionTypes.UK,
    );
  };

  useEffect(() => {
    fetchSetting();
  }, []);

  const handleToggleFunction = async (value: boolean, key: string) => {
    if (key === SettingsKey.FIRST) {
      setSwitch1(value);
      updateSetting(SettingsKey.FIRST, value, guid1);
    }
    if (key === SettingsKey.SECOND) {
      setSwitch2(value);
      updateSetting(SettingsKey.FIRST, value, guid2);
    }
    if (key === SettingsKey.THIRD) {
      setSwitch3(value);
      updateSetting(SettingsKey.FIRST, value, guid3);
    }
  };

  return (
    <Container
      heading={messages?.profile?.settings?.heading}
      showGoBack
      background={otherColour?.adminBg100}
      maxHeight
    >
      <Card
        noContentPadding
        removeTitle
        contentCss={{ padding: '12px 16px !important' }}
        showGoBack
      >
        <Form>
          <Grid container>
            <Grid item xs={12} marginBottom="24px">
              <OutlinedContainer
                padding="18px 14px"
                borderColor={greyScaleColour?.grey70}
              >
                <FormRow marginBottom="6px">
                  <StyledHeading
                    fontSize={fontSize?.h5}
                    fontWeight={fontWeight?.semiBold}
                    lineHeight="21px"
                  >
                    {messages?.profile?.settings?.form?.heading1}
                  </StyledHeading>
                </FormRow>
                <FormRow marginBottom={3}>
                  <FixedTitle
                    varient="Fixed12Regular"
                    color={greyScaleColour?.grey100}
                  >
                    {messages?.profile?.settings?.form?.subHeading1}
                  </FixedTitle>
                </FormRow>
                <FormRow marginBottom="0">
                  {connectField('first', {
                    label: messages?.profile?.settings?.form?.option1of1,
                    handleToggle: (value: boolean) => handleToggleFunction(value, SettingsKey.FIRST),
                  })(SwitchInput)}
                </FormRow>
              </OutlinedContainer>
            </Grid>

            <Grid xs={12} marginBottom="0">
              <OutlinedContainer
                padding="18px 14px"
                borderColor={greyScaleColour?.grey70}
              >
                <FormRow marginBottom="6px">
                  <StyledHeading
                    fontSize={fontSize?.h5}
                    fontWeight={fontWeight?.semiBold}
                    lineHeight="21px"
                  >
                    {messages?.profile?.settings?.form?.heading2}
                  </StyledHeading>
                </FormRow>
                <FormRow marginBottom={3}>
                  <FixedTitle
                    varient="Fixed12Regular"
                    color={greyScaleColour?.grey100}
                  >
                    {messages?.profile?.settings?.form?.subHeading2}
                  </FixedTitle>
                </FormRow>
                <FormRow marginBottom="16px">
                  {connectField('second', {
                    label: messages?.profile?.settings?.form?.option1of2,
                    handleToggle: (value: boolean) => handleToggleFunction(value, SettingsKey.SECOND),
                  })(SwitchInput)}
                </FormRow>
                <HorizintalLine />
                <FormRow marginTop="16px" marginBottom={0}>
                  {connectField('third', {
                    label: messages?.profile?.settings?.form?.option2of2,
                    handleToggle: (value: boolean) => handleToggleFunction(value, SettingsKey.THIRD),
                  })(SwitchInput)}
                </FormRow>
              </OutlinedContainer>
            </Grid>

            {submitError && (
              <FormRow>
                <FormRowItem>
                  <FormError
                    message={
                      messages?.departments?.addForm?.failure?.[submitError]
                    }
                  />
                </FormRowItem>
              </FormRow>
            )}
          </Grid>
        </Form>
      </Card>
    </Container>
  );
};

export default SettingsMobile;
