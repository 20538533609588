import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useFormReducer } from '@tphglobal/common/hooks';
import { toast } from 'react-toastify';
import { formatStatus } from '@tphglobal/components/table';
import {
  Button,
  Form,
  FormError,
  FormRow,
  FormRowItem,
  OutlinedContainer,
  SwitchInput,
  Toast,
} from '@tphglobal/components';
import { brand, greyScaleColour } from '@tphglobal/common/theme/style.palette';
import { FixedTitle, HttpMethods } from '@tphglobal/common/utils';
import { fontSize, fontWeight } from '@tphglobal/common/theme/style.typography';
import { RegionTypes } from '@tphglobal/common/utils/constants';
import { StyledHeading, HorizintalLine } from '../styles';
import handleApiCall from '../../handleApiCall';
import { ReduxState } from '../../../redux/reducers';
import messages from '../../../messages';
import { emptyValueValidator, required } from '../../../utils';
import {
  GET_SETTINGS_AU, GET_SETTINGS_UK, UPDATE_SETTINGS_AU, UPDATE_SETTINGS_UK,
} from '../../../api';
import { UserSetting } from '../../../models';

interface Props {
  onCancel: () => void;
  onSuccess: () => void;
}

interface SubmitData {
  first : boolean
  second : boolean
  third : boolean
}

const validators = {
  first: [required('')],
  second: [required('')],
  third: [required('')],
};

enum SettingsKey {
  FIRST = 'NotifyNewDocument',
  SECOND = 'StoreDataDigitalForms',
  THIRD = 'UseDataDigitalForms'
}

const Setting: React.FC<Props> = ({ onCancel, onSuccess }) => {
  const reduxDispatch = useDispatch();
  const selectedRegion = useSelector((state : ReduxState) => state?.profile?.region);
  const [settingsList, setSettingsList] = useState<UserSetting[]>([]);
  const {
    submitting,
    submitError,
    handleSubmit,
    connectField,
    change,
    setSubmitError,
  } = useFormReducer(validators);

  const updateSetting = (Key : string, Value : boolean, guid : string) => {
    const sanitizedBody = {
      Key,
      Value,
    };
    handleApiCall(
      reduxDispatch,
      `${selectedRegion === RegionTypes.AU ? UPDATE_SETTINGS_AU : UPDATE_SETTINGS_UK}/${guid}`,
      (response) => {
        setSettingsList(response?.result);
      },
      HttpMethods.PUT,
      selectedRegion === RegionTypes.AU ? RegionTypes.AU : RegionTypes.UK,
      null,
      sanitizedBody,
    );
  };
  const onSubmit = async (data: SubmitData) => {
    const firstSetting = settingsList?.find((obj : UserSetting) => obj?.Key === SettingsKey.FIRST);
    const secondSetting = settingsList?.find((obj : UserSetting) => obj?.Key === SettingsKey.SECOND);
    const thirdSetting = settingsList?.find((obj : UserSetting) => obj?.Key === SettingsKey.THIRD);
    if (data?.first !== firstSetting?.Value) {
      updateSetting(firstSetting?.Key, data?.first, firstSetting?.Guid);
    }

    if (data?.second !== secondSetting?.Value) {
      updateSetting(secondSetting?.Key, data?.second, secondSetting?.Guid);
    }

    if (data?.third !== thirdSetting?.Value) {
      updateSetting(thirdSetting?.Key, data?.third, thirdSetting?.Guid);
    }

    onSuccess();
  };

  useEffect(() => {
    change('first', settingsList?.find((obj : UserSetting) => obj?.Key === SettingsKey.FIRST)?.Value);
    change('second', settingsList?.find((obj : UserSetting) => obj?.Key === SettingsKey.SECOND)?.Value);
    change('third', settingsList?.find((obj : UserSetting) => obj?.Key === SettingsKey.THIRD)?.Value);
  }, [settingsList]);

  const fetchSetting = () => {
    handleApiCall(
      reduxDispatch,
      selectedRegion === RegionTypes.AU ? GET_SETTINGS_AU : GET_SETTINGS_UK,
      (response) => {
        setSettingsList(response?.result);
      },
      HttpMethods.POST,
      selectedRegion === RegionTypes.AU ? RegionTypes.AU : RegionTypes.UK,
    );
  };

  useEffect(() => {
    fetchSetting();
  }, []);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Grid padding="0 24px">
        <FormRow marginBottom="24px">
          <OutlinedContainer styles={{ width: '475px' }}>
            <FormRow marginBottom="6px">
              <StyledHeading
                fontSize={fontSize?.h5}
                fontWeight={fontWeight?.semiBold}
                lineHeight="21px"
              >
                {messages?.profile?.settings?.form?.heading1}
              </StyledHeading>
            </FormRow>
            <FormRow marginBottom={4}>
              <FixedTitle
                varient="Fixed12Regular"
                color={greyScaleColour?.grey100}
              >
                {messages?.profile?.settings?.form?.subHeading1}
              </FixedTitle>
            </FormRow>
            <FormRow marginBottom="0">
              {connectField('first', {
                label: messages?.profile?.settings?.form?.option1of1,
              })(SwitchInput)}
            </FormRow>
          </OutlinedContainer>
        </FormRow>

        <FormRow marginBottom="0">
          <OutlinedContainer styles={{ width: '475px' }}>
            <FormRow marginBottom="6px">
              <StyledHeading
                fontSize={fontSize?.h5}
                fontWeight={fontWeight?.semiBold}
                lineHeight="21px"
              >
                {messages?.profile?.settings?.form?.heading2}
              </StyledHeading>
            </FormRow>
            <FormRow marginBottom={4}>
              <FixedTitle
                varient="Fixed12Regular"
                color={greyScaleColour?.grey100}
              >
                {messages?.profile?.settings?.form?.subHeading2}
              </FixedTitle>
            </FormRow>
            <FormRow marginBottom="16px">
              {connectField('second', {
                label: messages?.profile?.settings?.form?.option1of2,
              })(SwitchInput)}
            </FormRow>
            <HorizintalLine />
            <FormRow marginTop="16px" marginBottom={0}>
              {connectField('third', {
                label: messages?.profile?.settings?.form?.option2of2,
              })(SwitchInput)}
            </FormRow>
          </OutlinedContainer>
        </FormRow>

        {submitError && (
          <FormRow>
            <FormRowItem>
              <FormError
                message={messages?.departments?.addForm?.failure?.[submitError]}
              />
            </FormRowItem>
          </FormRow>
        )}
      </Grid>

      <OutlinedContainer
        noBorder
        borderRadius="0"
        padding="14px 24px"
        styles={{ backgroundColor: greyScaleColour?.grey60, marginTop: '24px' }}
      >
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  variant="outlined"
                  buttonColor={brand?.black}
                  color="primary"
                  size="large"
                  label={messages?.general?.cancel}
                  onClick={onCancel}
                />
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  type="submit"
                  label={messages?.general?.update}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </OutlinedContainer>
    </Form>
  );
};

export default Setting;
