import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  FloatingButton,
  OutlinedContainer,
} from '@tphglobal/components';
import { Grid } from '@mui/material';
import {
  greyScaleColour,
  otherColour,
} from '@tphglobal/common/theme/style.palette';
import {
  fontSize,
  fontSizeMobile,
  fontWeight,
} from '@tphglobal/common/theme/style.typography';
import { push } from 'connected-react-router';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { useDispatch, useSelector } from 'react-redux';
import { FixedTitle, HttpMethods } from '@tphglobal/common/utils';
import AddIcon from '@mui/icons-material/Add';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import useWindowSize from '@tphglobal/common/hooks/windowSize';
import { breakpoints } from '@tphglobal/common/theme/style.layout';
import { RegionTypes } from '@tphglobal/common/utils/constants';
import RecentActivity from './recentActivity';
import NewFeed from './newFeed';
import handleApiCall from '../handleApiCall';

import { iconStyles } from '../../utils/constant';
import { routes } from '../../utils';
import {
  Row,
  StyledColumn,
  StyledHeading,
  StyledLogo,
  StyledRow,
} from './styles';
import { Container } from '../../components';
import messages from '../../messages';
import { ReduxState } from '../../redux/reducers';
import {
  GET_ACTIVITY_LOGS_AU,
  GET_ACTIVITY_LOGS_UK,
  GET_COMPANY_LIST_AU,
  GET_COMPANY_LIST_UK,
  GET_NEWS_FEED_AU,
  GET_NEWS_FEED_UK,
  GET_PROFILE_PIC_AU,
  GET_PROFILE_PIC_UK,
  GET_TIMECARD_LIST_AU,
  GET_TIMECARD_LIST_UK,
  GET_USER_DETAILS_AU,
  GET_USER_DETAILS_UK,
} from '../../api';
import { fetchUserProfile, postLogin, resultsFetchRequest } from '../../redux/actions';
import { CompanyEntity } from '../../models';

export interface ActivityLog {
  Description : string
  Guid : string
  IPAddress : string
  TTL : number
  Timestamp : number
  Type : string
  UserGuid : string
}

const RightIcon = () => (
  <OutlinedContainer padding="6px" borderRadius="6px" borderColor="#A1D7EE">
    <Row gap="6px">
      <Row gap="2px">
        <AddIcon
          style={{
            height: fontSize?.b3,
            width: fontSize?.b3,
            fill: greyScaleColour?.white100,
          }}
        />
        <FixedTitle varient="Fixed12Medium" color={greyScaleColour?.grey60}>
          {messages?.timeCard?.buttonMobile}
        </FixedTitle>
      </Row>
      <CreditCardIcon
        style={{ ...iconStyles, fill: greyScaleColour?.white100 }}
      />
    </Row>
  </OutlinedContainer>
);

interface Props {
  isMobile ?: boolean
  feed ?: string
  activityFeed ?: ActivityLog[]
}

interface DashboardTab {
  id : string
  title : string
  path : string
  component : React.FC<Props>
}

const dashboardTabs: DashboardTab[] = [
  {
    id: 'newsFeed',
    title: messages?.home?.newFeed,
    path: routes.home.root,
    component: NewFeed,
  },
  {
    id: 'recentActivity',
    title: messages?.home?.activityFeed,
    path: routes.home.root,
    component: RecentActivity,
  },
];

interface LocationState {
  data : ActivityLog
}

const Home = () => {
  const reduxDispatch = useDispatch();
  const location = useLocation<LocationState>();
  const history = useHistory();
  const selectedRegion = useSelector(
    (state: ReduxState) => state?.profile?.region,
  );
  const timeCardListing = useSelector(
    (state: ReduxState) => state?.results?.results,
  );

  const [companyListing, setCompanyListing] = useState<CompanyEntity[]>([]);
  const [feed, setFeed] = useState<string>();
  const [activityLog, setActivityLog] = useState<ActivityLog[]>();
  const activeTab = location.state?.data || dashboardTabs[0]?.id;

  const { width } = useWindowSize();

  const isMobile = width <= breakpoints.mobile;

  const handleAddTimeCard = () => {
    history.push(routes?.addTimeCard?.root, {
      data: {
        isUpdate: false,
        companyListing,
        timeCardListing,
      },
    });
  };

  // Fetching timecard listing 
  const fetchTimeCardListing = () => {
    reduxDispatch(
      resultsFetchRequest(
        selectedRegion === RegionTypes.AU
          ? GET_TIMECARD_LIST_AU
          : GET_TIMECARD_LIST_UK,
        { page: 1, limit: 10 },
      ),
    );
  };

  // Fetching companies listing for drop down options
  const fetchCompanyListings = () => {
    handleApiCall(
      reduxDispatch,
      selectedRegion === RegionTypes.AU
        ? GET_COMPANY_LIST_AU
        : GET_COMPANY_LIST_UK,
      (res) => {
        setCompanyListing(res?.result);
      },
      HttpMethods?.GET,
      selectedRegion === RegionTypes.AU ? RegionTypes.AU : RegionTypes.UK,
    );
  };

  useEffect(() => {
    fetchTimeCardListing();
    fetchCompanyListings();
  }, []);

  // Fetch-User-Profile & profile-pic
  const fetchProfileImage = () => {
    handleApiCall(
      reduxDispatch,
      selectedRegion === RegionTypes.AU
        ? GET_PROFILE_PIC_AU
        : GET_PROFILE_PIC_UK,
      (response) => {
        reduxDispatch(postLogin({ profile_pic: response?.profile_pic }));
      },
      HttpMethods?.GET,
      selectedRegion === RegionTypes.AU ? RegionTypes.AU : RegionTypes.UK,
    );
  };
  useEffect(() => {
    fetchProfileImage();
    handleApiCall(
      reduxDispatch,
      selectedRegion === RegionTypes.AU
        ? GET_USER_DETAILS_AU
        : GET_USER_DETAILS_UK,
      (data) => {
        reduxDispatch(fetchUserProfile(data));
      },
      HttpMethods?.GET,
      selectedRegion === RegionTypes.AU ? RegionTypes.AU : RegionTypes.UK,
    );
  }, []);

  // Fetch-News-Feed
  useEffect(() => {
    handleApiCall(
      reduxDispatch,
      selectedRegion === RegionTypes.AU ? GET_NEWS_FEED_AU : GET_NEWS_FEED_UK,
      (feedObj) => {
        if(feedObj?.status) {
          setFeed(feedObj?.result[0]?.message);
        }
      },
      HttpMethods?.GET,
      selectedRegion === RegionTypes.AU ? RegionTypes.AU : RegionTypes.UK,
    );
  }, []);

  // Fetch-Activity-Logs
  useEffect(() => {
    handleApiCall(
      reduxDispatch,
      selectedRegion === RegionTypes.AU
        ? GET_ACTIVITY_LOGS_AU
        : GET_ACTIVITY_LOGS_UK,
      (activityLogObj) => {
        setActivityLog(activityLogObj?.result);
      },
      HttpMethods?.GET,
      selectedRegion === RegionTypes.AU ? RegionTypes.AU : RegionTypes.UK,
    );
  }, []);

  return (
    <Container
      background={otherColour?.adminBg100}
      maxHeight
      rightIcon={<RightIcon />}
      tabs={dashboardTabs}
      onRightIconPress={handleAddTimeCard}
    >
      <Card
        noContentPadding={isMobile}
        removeTitle
        contentCss={isMobile && { padding: '0 16px !important' }}
        header={(
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <StyledHeading
                fontSize={fontSize?.h2}
                fontWeight={fontWeight?.semiBold}
              >
                {messages?.home?.heading}
              </StyledHeading>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                size="large"
                label={messages?.home?.insertTimeCard}
                icon={<CreditCardIcon style={iconStyles} />}
                onClick={handleAddTimeCard}
              />
            </Grid>
          </Grid>
        )}
      >
        <>
          {!isMobile && (
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <NewFeed feed={feed} />
            </Grid>
            <Grid item xs={4}>
              <RecentActivity activityFeed={activityLog} />
            </Grid>
          </Grid>
          )}

          {isMobile
          && dashboardTabs
            .filter((tab: DashboardTab) => tab.id === activeTab)
            .map((tab: DashboardTab) => {
              const Component = tab.component;
              return <Component key={tab.id} isMobile={isMobile} feed={feed} activityFeed={activityLog} />;
            })}
        </>
        <FloatingButton
          onClick={() => reduxDispatch(push(routes?.support?.root))}
          ariaLabel="Help & Support"
          bottom="2.03%"
          right="1.36%"
        />
      </Card>
    </Container>
  );
};

export default Home;
