export const createResourceRoutes = (resource: string) => ({
  root: `/${resource}`,
  create: `/${resource}/create`,
  view: `/${resource}/view/:id`,
});

export const routes = {
  productmanagement: createResourceRoutes('product-management'),
  tenantmanagement: createResourceRoutes('tenant-management'),
  usermanagement: createResourceRoutes('user-management'),
  root: '/',
  login: '/login',
  logout: '/logout',
  signup: '/signup',
  getInTouch: '/get-in-touch',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',

  dashboard: createResourceRoutes('dashboard'),
  home: createResourceRoutes('home'),
  timeCard: createResourceRoutes('timeCard'),
  company: createResourceRoutes('company'),
  document: createResourceRoutes('document'),
  support: createResourceRoutes('support'),
  addTimeCard: createResourceRoutes('addTimeCard'),
  timeCardListing: createResourceRoutes('timeCardListing'),
  addTimeCardForm: createResourceRoutes('addTimeCardForm'),
  digitalTimeSheetMobile: createResourceRoutes('digitalTimeSheetMobile'),
  editTimeCard: createResourceRoutes('editTimeCard'),
  addCompany: createResourceRoutes('addCompany'),
  editCompany: createResourceRoutes('editCompany'),
  profile: '/profile',
  editInfoMobile: '/edit-profile',
  settingsMobile: '/update-settings',
  signatureMobile: '/update-signature',
  test: createResourceRoutes('test'),
};

export default routes;
