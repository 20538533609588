import React from 'react';
import { Grid } from '@mui/material';
import { OutlinedContainer } from '@tphglobal/components';
import { greyScaleColour } from '@tphglobal/common/theme/style.palette';
import TimeCardImage from '../../assets/images/timeCard.png';
import CompanyImage from '../../assets/images/company.png';

interface Props {
  type: string;
}

const Creating: React.FC<Props> = ({ type }) => (
  <Grid padding="0 24px 22px 24px">
    <OutlinedContainer
      padding="0"
      borderColor={greyScaleColour?.grey90}
      styles={{
        width: '1049px',
        height: '722px',
        backgroundImage: `url(${type === 'timeCard' ? TimeCardImage : CompanyImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    />
  </Grid>
);

export default Creating;
