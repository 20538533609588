import { Alert, Grid } from '@mui/material';
import { respondTo } from '@tphglobal/common/theme/style.layout';
import { css, styled } from 'styled-components';

export const StyledForm = styled.form<{ hasPadding?: boolean }>`
  ${({ hasPadding }) =>
    hasPadding &&
    css`
      margin: 24px 24px 0 24px;
    `}
  display: grid;
`;

export const StyledFormRow = styled(Grid)`
  margin-bottom: 16px;
  
  ${respondTo.mobileOnlyPortrait} {
    margin-bottom: 10px;
  }
`;
export const StyledFormRowItem = styled(Grid)`
  display: flex;
  flex: 1;
`;

export const StyledFormError = styled(Alert)`
  width: 100% !important;
  align-items :center;
`;
