import { TimePicker } from "@mui/x-date-pickers";
import styled, { css } from "styled-components";
import { Typography } from "@mui/material";
import { otherColour } from "@tphglobal/common/theme/style.palette";

export const StyledTimePicker = styled(TimePicker)<{
  fullWidth?: boolean;
  disableDropDown?: boolean;
}>`
  & .MuiInputBase-root {
    border-radius: 6px;
    input {
      padding: 13.5px !important;
    }
  }

  ${({ disableDropDown }) =>
    disableDropDown &&
    css`
      & .MuiTouchRipple-root {
        display: none !important;
      }
      & .MuiIconButton-root:hover {
        background-color: transparent !important;
      }
    `}
`;

export const StyledCloseContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: auto;
`;

export const StyledContainer = styled.div`
  width: 100%;
`;

export const StyledLabel = styled.label<{
  readOnly?: boolean;
  required?: boolean;
}>``;

export const StyledInputContainer = styled.div`
  display: flex;
`;

export const StyledInputText = styled(Typography)<{ disabled?: boolean }>``;

export const StyledError = styled(Typography)`
  color: ${otherColour?.errorBg};
  margin-top: 8px !important;
`;
