import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  FloatingButton,
  Modal,
  ModalAction,
  PdfView,
  SearchInput,
  Toast,
} from '@tphglobal/components';
import { usePagination, usePopupReducer } from '@tphglobal/common/hooks';
import {
  brand,
  brandColour,
  otherColour,
} from '@tphglobal/common/theme/style.palette';
import { Grid, Popover } from '@mui/material';
import { fontSize, fontWeight } from '@tphglobal/common/theme/style.typography';
import Table from '@tphglobal/components/table';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import useWindowSize from '@tphglobal/common/hooks/windowSize';
import { breakpoints } from '@tphglobal/common/theme/style.layout';
import EditIcon from '@mui/icons-material/Edit';
import { useHistory } from 'react-router-dom';

import { RegionTypes } from '@tphglobal/common/utils/constants';
import { HttpMethods } from '@tphglobal/common/utils';
import moment from 'moment';
import {
  DELETE_DOCUMENT_AU,
  DELETE_DOCUMENT_UK,
  FETCH_ATTACHMENT_AU,
  FETCH_ATTACHMENT_UK,
  GET_DOCUMENT_LIST_AU,
  GET_DOCUMENT_LIST_UK,
  REMOVE_ATTACHMENT_AU,
  REMOVE_ATTACHMENT_UK,
  UPDATE_TIMECARD_STATUS_AU,
  UPDATE_TIMECARD_STATUS_UK,
} from '../../api';
import handleApiCall from '../handleApiCall';
import { DocumentEntity } from '../../models';
import { filterList } from '../../utils/commonFunctions';
import AddDocument from './addDocument';
import { routes } from '../../utils';
import { headerIconStyles, iconStyles } from '../../utils/constant';
import {
  ListIcon,
  ListItem,
  ListText,
  StyledHeading,
  StyledLine,
  StyledPopover,
} from './styles';
import { Container } from '../../components';
import messages from '../../messages';
import { ReduxState } from '../../redux/reducers';
import { resultsFetchRequest } from '../../redux/actions';
import AddDocumentMobile from './mobileView/addDocumentMobile'; 

const mobileSpecs = [
  {
    id: 'Timestamp',
    label: messages?.document?.table?.date,
    format: (row: Date) => moment(row)?.format('DD/MM/YYYY '),
  },
  {
    id: 'DocDesc',
    label: messages?.document?.table?.description,
  },
];

const desktopSpecs = [
  {
    id: 'Timestamp',
    label: messages?.document?.table?.date,
    format: (row: Date) => moment(row)?.format('DD/MM/YYYY '),
  },
  {
    id: 'DocDesc',
    label: messages?.document?.table?.description,
  },
  {
    id: 'Production',
    label: messages?.document?.table?.production,
  },
  {
    id: 'DocType',
    label: messages?.document?.table?.type,
  },
];

const mobileIconStyles = {
  height: fontSize?.h5,
  width: fontSize?.h5,
};

const Document = () => {
  const documents = useSelector((state: ReduxState) => state?.results);
  const [filteredDocuments, setFilteredDocuments] = useState<DocumentEntity[]>(
    documents?.results,
  );
  const [searchInput, setSearchInput] = useState<string>('');
  const [rowId, setRowId] = useState<string>(null);
  const [timeCardGuid, setTimeCardGuid] = useState<string>('');
  const [attachmentUrl, setAttachmentUrl] = useState<string>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const selectedRegion = useSelector(
    (state: ReduxState) => state?.profile?.region,
  );
  const userProfile = useSelector((state: ReduxState) => state?.profile);
  const userId = userProfile?.Guid;
  const reduxDispatch = useDispatch();
  const history = useHistory();

  const {
    visibility: deleteVisibility,
    showPopup: showdeleteForm,
    hidePopup: hidedeleteForm,
    metaData: deleteFormMetadata,
  } = usePopupReducer();

  const {
    visibility: addFormVisibility,
    showPopup: showAddForm,
    hidePopup: hideAddForm,
    metaData: addFormMetadata,
  } = usePopupReducer();

  const {
    visibility: pdfVisibility,
    showPopup: showPdf,
    hidePopup: hidePdf,
    metaData: pdfMetadata,
  } = usePopupReducer<{ url: string }>();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isTimeCard, setIsTimeCard] = useState<boolean>(false);
  const [showAddDocumentMobile, setShowAddDocumentMobile] = useState<boolean>(false);
  const [docType, setDocType] = useState<string>('');
  const { width } = useWindowSize();

  const isMobile = width <= breakpoints.mobile;

  useEffect(() => {
    const filteredDocuments: DocumentEntity[] = filterList(
      searchInput,
      documents?.results,
    );
    setFilteredDocuments(filteredDocuments);
  }, [searchInput]);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAddDocument = () => {
    setShowAddDocumentMobile(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const uploadActionItems = [
    {
      id: 'delete',
      icon: (
        <DeleteOutlineIcon
          style={{ ...mobileIconStyles, fill: otherColour?.errorDefault }}
        />
      ),
      label: 'Delete',
      action: showdeleteForm,
    },
  ];

  const fetchDocumentListings = () => {
    reduxDispatch(
      resultsFetchRequest(
        selectedRegion === RegionTypes.AU
          ? GET_DOCUMENT_LIST_AU
          : GET_DOCUMENT_LIST_UK,
        { page: 1, limit: 10 },
      ),
    );
  };

  useEffect(() => {
    fetchDocumentListings();
  }, []);

  useEffect(() => {
    const filteredDocuments: DocumentEntity[] = filterList(
      searchInput,
      documents?.results,
    );
    setFilteredDocuments(filteredDocuments);
  }, [searchInput]);

  useEffect(() => {
    setFilteredDocuments(documents?.results);
  }, [documents?.results]);

  const handleFetchAttachment = (url: string) => {
    const sanitizedBody = {
      upload_path: `documents/${userId}/${url}`,
    };
    handleApiCall(
      reduxDispatch,
      selectedRegion === RegionTypes.AU
        ? FETCH_ATTACHMENT_AU
        : FETCH_ATTACHMENT_UK,
      (response) => {
        showPdf({ url: response?.result });
      },
      HttpMethods?.POST,
      selectedRegion === RegionTypes.AU ? RegionTypes.AU : RegionTypes.UK,
      null,
      sanitizedBody,
    );
  };

  const removeAttachment = (isEdit?: boolean) => {
    const sanitizedBody = {
      upload_path: attachmentUrl,
    };

    handleApiCall(
      reduxDispatch,
      selectedRegion === RegionTypes?.AU
        ? REMOVE_ATTACHMENT_AU
        : REMOVE_ATTACHMENT_UK,
      () => {
        setSubmitting(false);
        hidedeleteForm();
        fetchDocumentListings();
        setAnchorEl(null);
      },
      HttpMethods?.DELETE,
      selectedRegion === RegionTypes.AU ? RegionTypes.AU : RegionTypes.UK,
      !isEdit
        ? messages?.document?.deleteForm?.success?.heading
        : messages?.document?.editForm?.success?.heading,
      sanitizedBody,
    );
  };

  const handleDeleteDocument = (isEdit?: boolean) => {
    setSubmitting(true);
    handleApiCall(
      reduxDispatch,
      `${
        selectedRegion === RegionTypes?.AU
          ? DELETE_DOCUMENT_AU
          : DELETE_DOCUMENT_UK
      }/${rowId}`,
      () => {
        removeAttachment(isEdit);
      },
      HttpMethods?.DELETE,
      selectedRegion === RegionTypes.AU ? RegionTypes.AU : RegionTypes.UK,
      null,
      null,
      null,
      (errorMessage : string) => {                
        hidedeleteForm()
        setSubmitting(false)
        toast(<Toast type='error' text={errorMessage} />)
      }
    );
  };

  const handleEditForm = () => {
    const sanitizedBody = {
      Guid: timeCardGuid,
      Type: 'Draft',
    };
    handleApiCall(
      reduxDispatch,
      selectedRegion === RegionTypes?.AU
        ? UPDATE_TIMECARD_STATUS_AU
        : UPDATE_TIMECARD_STATUS_UK,
      () => {
        handleDeleteDocument(true);
      },
      HttpMethods?.PUT,
      selectedRegion === RegionTypes.AU ? RegionTypes.AU : RegionTypes.UK,
      null,
      sanitizedBody,
    );
  };

  interface Action {
    id: string;
    icon: JSX.Element;
    label: string;
    action: () => void;
  }

  const timeCardActionItems: Action[] = [
    {
      id: 'edit',
      icon: (
        <EditIcon style={{ ...mobileIconStyles, fill: brand?.secondaryMain }} />
      ),
      label: 'Edit',
      action: !isMobile ? () => handleEditForm() : () => handleAddDocument(),
    },
    {
      id: 'delete',
      icon: (
        <DeleteOutlineIcon
          style={{ ...mobileIconStyles, fill: otherColour?.errorDefault }}
        />
      ),
      label: 'Delete',
      action: showdeleteForm,
    },
  ];

  if (showAddDocumentMobile) {
    return (
      <AddDocumentMobile
        onSuccess={() => {
          setShowAddDocumentMobile(false);
          fetchDocumentListings();
        }}
        onCancel={() => {
          setShowAddDocumentMobile(false)
        }}
      />
    );
  }
  return (
    <Container
      onRightIconPress={() => handleAddDocument()}
      rightIcon={<AddIcon style={headerIconStyles} />}
      heading={messages?.document?.heading}
      background={otherColour?.adminBg100}
      maxHeight
    >
      <Card
        noContentPadding={isMobile}
        removeTitle
        contentCss={isMobile && { padding: '24px 16px !important' }}
        header={(
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <StyledHeading
                fontSize={fontSize?.h2}
                fontWeight={fontWeight?.semiBold}
              >
                {messages?.document?.heading}
              </StyledHeading>
            </Grid>
            <Grid item>
              <Grid container columnGap="18px">
                <Grid item>
                  <SearchInput
                    setSearchInput={setSearchInput}
                    searchInput={searchInput}
                  />
                </Grid>
                <Grid item>
                  <Button
                    onClick={() => showAddForm()}
                    variant="outlined"
                    color="primary"
                    size="large"
                    label={messages?.general?.addNew}
                    icon={<AddIcon style={iconStyles} />}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      >
        {isMobile && (
          <Grid container marginBottom="14px">
            <Grid item xs={12}>
              <SearchInput
                setSearchInput={setSearchInput}
                searchInput={searchInput}
              />
            </Grid>
          </Grid>
        )}
        <Table
          data={filteredDocuments || []}
          actions={[
            {
              id: 'article',
              component: (
                <ArticleOutlinedIcon
                  style={{ ...iconStyles, color: brandColour?.primaryDark }}
                />
              ),
              onClick: (row) => {
                handleFetchAttachment(row?.url);
              },
            },
            {
              id: 'edit',
              component: (
                <MoreVertOutlinedIcon
                  style={{ ...iconStyles, color: brand?.black }}
                />
              ),
              onClick: (
                row: DocumentEntity,
                event: React.MouseEvent<HTMLButtonElement>,
              ) => {
                setDocType(row?.DocType);
                setIsTimeCard(row?.DocType === 'Timecard');
                setAttachmentUrl(row?.url);
                setRowId(row?.Guid);
                setTimeCardGuid(row?.TimecardGuid);
                handleClick(event);
              },
            },
          ]}
          specs={isMobile ? mobileSpecs : desktopSpecs}
        />

        <FloatingButton
          onClick={() => reduxDispatch(push(routes?.support?.root))}
          ariaLabel="Help & Support"
          bottom="2.03%"
          right="1.36%"
        />
      </Card>
      <Modal
        icon={<ArticleOutlinedIcon />}
        show={addFormVisibility}
        heading={messages?.document?.addForm?.addDocument}
        onClose={hideAddForm}
        fitContent
      >
        <AddDocument
          onCancel={hideAddForm}
          onSuccess={() => {
            hideAddForm();
            fetchDocumentListings();
          }}
        />
      </Modal>
      <Modal
        show={deleteVisibility}
        icon={<DeleteOutlineIcon />}
        deleteForm
        onClose={hidedeleteForm}
        heading={messages?.document?.deleteForm?.heading}
        fitContent
      >
        <ModalAction
          noConfirmationDelete
          title={messages?.document?.deleteForm?.subHeading}
          onCancel={hidedeleteForm}
          buttonLabel="Document"
          customSubmit={() => handleDeleteDocument(false)}
          submitting={submitting}
        />
      </Modal>
      <Modal
        show={pdfVisibility}
        heading="TPH Payroll"
        onClose={hidePdf}
        fitContent
        maxWidth={!isMobile && '1212px'}
        isPdf
      >
        <PdfView url={pdfMetadata?.url} />
      </Modal>
      <StyledPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        style={{ padding: '16px' }}
      >
        {(docType === 'Timecard'
          ? timeCardActionItems
          : uploadActionItems
        )?.map((obj: Action, index) => (
          <React.Fragment key={obj?.id}>
            <ListItem
              onClick={obj.action}
              style={{
                padding: (() => {
                  if (isTimeCard) {
                    return index !== timeCardActionItems?.length - 1
                      ? '0 0 12px 0'
                      : '12px 0 0 0';
                  }
                  return '0';
                })(),
              }}
            >
              <ListIcon>{obj?.icon}</ListIcon>
              <ListText>{obj?.label}</ListText>
            </ListItem>

            {isTimeCard && index !== timeCardActionItems?.length - 1 && (
              <StyledLine />
            )}
          </React.Fragment>
        ))}
      </StyledPopover>
    </Container>
  );
};

export default Document;
