import { fontSize } from '@tphglobal/common/theme/style.typography';
import { greyScaleColour } from '@tphglobal/common/theme/style.palette';
import messages from '../messages';

export const headerItems = [
  {
    key: 'manage-locations',
    label: messages?.general?.headings?.manageLocations,
  },
  {
    key: 'manage-users',
    label: messages?.general?.headings?.manageUsers,
  },
  {
    key: 'about',
    label: messages?.general?.headings?.about,
  },
];

export const iconStyles = {
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  height: fontSize?.h4,
  width: fontSize?.h4,
};

export const headerIconStyles = {
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  height: fontSize?.h2,
  width: fontSize?.h2,
  fill: greyScaleColour?.white100,
};

export const MAX_FILE_SIZE = 4;

export const daysOfWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];
