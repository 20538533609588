import React from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { goBack, push } from 'connected-react-router';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton } from '@mui/material';
import {
  FixedTitle,
  HttpMethods,
  emailValidator,
  required,
} from '@tphglobal/common/utils';

import {
  Button,
  Form,
  FormError,
  FormRow,
  FormRowItem,
  MaterialAutocompleteInput,
  PasswordInput,
  TextInput,
  Toast,
} from '@tphglobal/components';
import { useFormReducer } from '@tphglobal/common/hooks';
import useWindowSize from '@tphglobal/common/hooks/windowSize';
import { breakpoints } from '@tphglobal/common/theme/style.layout';
import { brand, greyScaleColour } from '@tphglobal/common/theme/style.palette';
import { confirmResetPassword } from 'aws-amplify/auth';
import { useLocation } from 'react-router-dom';
import { Container } from '../../components';
import SidePanel from './sidePanel';
import {
  NoAccountTextContainer,
  StyledContent,
  StyledContentContainer,
  StyledFormContainer,
  StyledFormDetails,
  StyledFormHeading,
  StyledFormSubHeading,
  StyledHeaderContainer,
  StyledInfoContainer,
  StyledLogo,
  StyledScreenWrapper,
  StyledSidePanelLowerTextMobile,
  StyledSmallLogo,
} from './styles';
import messages from '../../messages';
import {
  confirmPassword,
  passwordValidator,
  routes,
} from '../../utils';
import ErrorHandler from '../../utils/errorHandler';

interface Props {
  onCancel: () => void;
}

const validators = {
  newPassword: [
    required(messages?.general?.validations?.newPasswordRequired),
    passwordValidator,
  ],
  confirmNewPassword: [
    required(messages?.general?.validations?.confirmNewPassword),
    confirmPassword(
      messages?.general?.validations?.passwordUnmatched,
      'newPassword',
    ),
  ],
};
interface LocationStateData {
  username: string
  confirmationCode : string
}

interface LocationState {
  data : LocationStateData
}

interface SubmitData {
  newPassword : string
  confirmNewPassword : string
}

const ResetPassword: React.FC<Props> = ({
  onCancel,
}) => {
  const {
    submitting,
    handleSubmit,
    connectField,
    setSubmitError,
    submitError,
  } = useFormReducer(validators);
  const reduxDispatch = useDispatch();
  const { width } = useWindowSize();
  const location = useLocation<LocationState>();

  const passedData = location?.state?.data;

  const isMobile = width <= breakpoints.mobile;

  const onSubmit = async (data: SubmitData) => {
    try {
      await confirmResetPassword({
        username: passedData?.username,
        confirmationCode: passedData?.confirmationCode,
        newPassword: data?.confirmNewPassword,
      });

      reduxDispatch(push(routes?.login));
    } catch (error) {
      setSubmitError(ErrorHandler(error?.name));
    }
  };

  return (
    <Container hideSidebar noBorderRadius noPadding hasHeader={false} noMargin>
      <StyledScreenWrapper>
        <StyledFormContainer>
          <StyledContentContainer>
            <StyledHeaderContainer>
              {isMobile ? (
                <StyledSmallLogo
                  src="/assets/images/smallLoginLogo.svg"
                  alt="smallLogo"
                />
              ) : (
                <StyledLogo src="/assets/images/loginLogo.svg" alt="logo" />
              )}
              <StyledInfoContainer>
                <StyledFormHeading>
                  {messages?.resetPassword?.heading}
                </StyledFormHeading>
                <StyledFormSubHeading>
                  {messages?.resetPassword?.subHeading}
                </StyledFormSubHeading>
              </StyledInfoContainer>
            </StyledHeaderContainer>

            <StyledContent>
              <StyledFormDetails>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <FormRow>
                    <FormRowItem>
                      {connectField('newPassword', {
                        label: messages?.resetPassword?.form?.newPassword,
                      })(PasswordInput)}
                    </FormRowItem>
                  </FormRow>
                  <FormRow>
                    <FormRowItem>
                      {connectField('confirmNewPassword', {
                        label:
                          messages?.resetPassword?.form?.confirmNewPassword,
                      })(PasswordInput)}
                    </FormRowItem>
                  </FormRow>
                  {submitError && (
                    <FormRow>
                      <FormRowItem>
                        <FormError message={submitError} />
                      </FormRowItem>
                    </FormRow>
                  )}
                  <FormRow columnGap={2}>
                    <FormRowItem>
                      <Button
                        label={messages?.general?.cancel}
                        type="submit"
                        disabled={submitting}
                        variant="outlined"
                        onClick={() => reduxDispatch(goBack())}
                      />
                    </FormRowItem>
                    <FormRowItem>
                      <Button
                        label={messages?.resetPassword?.heading}
                        type="submit"
                        disabled={submitting}
                        variant="outlined"
                      />
                    </FormRowItem>
                  </FormRow>
                </Form>
              </StyledFormDetails>
            </StyledContent>
            <StyledSidePanelLowerTextMobile mt="245px">
              <NoAccountTextContainer>
                <FixedTitle
                  varient="Fixed14Regular"
                  color={!isMobile ? brand.white : greyScaleColour.grey100}
                >
                  {messages?.sidePanel?.needHelp}
                </FixedTitle>
                <FixedTitle
                  varient={isMobile ? 'Fixed14Medium' : 'Fixed14Bold'}
                  onClick={() => reduxDispatch(push(routes.getInTouch))}
                  color={!isMobile ? brand.white : brand?.secondaryMain}
                  style={{ cursor: 'pointer' }}
                >
                  {messages?.sidePanel?.getInTouch}
                </FixedTitle>
              </NoAccountTextContainer>
            </StyledSidePanelLowerTextMobile>
          </StyledContentContainer>
        </StyledFormContainer>
        <SidePanel />
      </StyledScreenWrapper>
    </Container>
  );
};

export default ResetPassword;
