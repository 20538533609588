import { Grid } from '@mui/material';
import { Button, Modal, OutlinedContainer } from '@tphglobal/components';
import React from 'react';
import { fontSize, fontWeight } from '@tphglobal/common/theme/style.typography';
import { greyScaleColour } from '@tphglobal/common/theme/style.palette';
import { useDispatch } from 'react-redux';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { push } from 'connected-react-router';
import { iconStyles } from '../../../utils/constant';
import messages from '../../../messages';
import { StyledHeading } from '../styles';
import { routes } from '../../../utils';

interface Props {
  isMobile?: boolean;
}

const HelpNSupport: React.FC<Props> = ({ isMobile }) => {
  const reduxDispatch = useDispatch();

  return (
    <OutlinedContainer>
      <Grid rowSpacing={2} container justifyContent="space-between">
        <Grid item alignSelf="center">
          <StyledHeading
            fontWeight={fontWeight?.semiBold}
            fontSize={fontSize?.h5}
          >
            {messages?.profile?.helpNSupport?.heading}
          </StyledHeading>
          <StyledHeading
            color={greyScaleColour?.grey100}
            fontSize={fontSize?.b2}
          >
            {messages?.profile?.helpNSupport?.subHeading}
          </StyledHeading>
        </Grid>
        <Grid item alignSelf="center">
          <Button
            onClick={() => reduxDispatch(push(routes?.support?.root))}
            variant="outlined"
            color="primary"
            size="large"
            label={messages?.profile?.helpNSupport?.button}
            icon={<HelpOutlineIcon style={iconStyles} />}
          />
        </Grid>
      </Grid>
    </OutlinedContainer>
  );
};

export default HelpNSupport;
