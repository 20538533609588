import React from 'react';
import { TextFieldProps } from '@mui/material';
import { StyledError, StyledInputContainer, StyledTextField } from './styles';

interface Props {
  value?: string;
  onChange?: any;
  error?: string;
  disableErrorMode?: boolean;
  maxWidth?: string;
  isHeader?: boolean;
  multiline?: boolean;
  rows?: number;
  maxLength ?: number
  label?: string;
  autoHeight?: boolean;
  disabled ?: boolean
}

const TextInput: React.FC<Props & TextFieldProps> = ({
  value,
  onChange,
  error,
  disableErrorMode,
  maxWidth,
  isHeader,
  multiline,
  rows,
  label,
  autoHeight,
  maxLength = null,
  disabled,
  ...props
}) => (
  <StyledInputContainer
    autoHeight={autoHeight}
    isHeader={isHeader}
    maxWidth={maxWidth}
  >
    <StyledTextField
      {...props}
      multiline={multiline}
      rows={rows}
      value={value || ''}
      disabled={disabled}
      error={disableErrorMode ? undefined : !!error}
      onChange={(event) => {
        if (onChange) {
          onChange(event?.currentTarget?.value);
        }
      }}
      // inputProps={{ maxLength: maxLength}}
      label={label}
    />
    {!disableErrorMode && error && (
      <StyledError variant='body2'>{error}</StyledError>
    )}
  </StyledInputContainer>
);

export default TextInput;
