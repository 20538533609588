import { Grid } from '@mui/material';
import {
  Button, Modal, OutlinedContainer, Toast,
} from '@tphglobal/components';
import React from 'react';
import { fontSize, fontWeight } from '@tphglobal/common/theme/style.typography';
import { greyScaleColour } from '@tphglobal/common/theme/style.palette';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { usePopupReducer } from '@tphglobal/common/hooks';
import { toast } from 'react-toastify';
import messages from '../../../messages';
import { routes } from '../../../utils';
import { iconStyles } from '../../../utils/constant';
import Setting from './editSettingsForm';
import {
  StyledHeading,
} from '../styles';

interface Props {
  isMobile?: boolean;
}

const Settings: React.FC<Props> = ({ isMobile }) => {
  const reduxDispatch = useDispatch();

  const {
    visibility: settingFormVisibility,
    showPopup: showSettingForm,
    hidePopup: hideSettingForm,
  } = usePopupReducer();

  const handleSettingsClick = () => {
    if (isMobile) {
      reduxDispatch(push(routes?.settingsMobile));
    } else {
      showSettingForm();
    }
  };

  return (
    <>
      <OutlinedContainer>
        <Grid rowSpacing={2} container justifyContent="space-between">
          <Grid item alignSelf="center">
            <StyledHeading
              fontWeight={fontWeight?.semiBold}
              fontSize={fontSize?.h5}
            >
              {messages?.profile?.settings?.heading}
            </StyledHeading>
            <StyledHeading
              color={greyScaleColour?.grey100}
              fontSize={fontSize?.b2}
            >
              {messages?.profile?.settings?.subHeading}
            </StyledHeading>
          </Grid>
          <Grid item alignSelf="center">
            <Button
              onClick={handleSettingsClick}
              variant="outlined"
              color="primary"
              size="large"
              label={messages?.profile?.settings?.button}
              icon={<SettingsOutlinedIcon style={iconStyles} />}
            />
          </Grid>
        </Grid>
      </OutlinedContainer>
      <Modal
        icon={<SettingsOutlinedIcon />}
        show={settingFormVisibility}
        heading="Settings"
        onClose={hideSettingForm}
        fitContent
      >
        <Setting
          onCancel={hideSettingForm}
          onSuccess={() => {
            hideSettingForm();
            toast(<Toast text={messages?.profile?.settings?.form?.success} />);
          }}
        />
      </Modal>
    </>
  );
};

export default Settings;
