/* eslint-disable camelcase */
import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useFormReducer } from '@tphglobal/common/hooks';
import {
  Button,
  Card,
  DragAndDrop,
  Form,
  FormError,
  FormRow,
  FormRowItem,
  OutlinedContainer,
  TextInput,
} from '@tphglobal/components';
import {
  brand,
  greyScaleColour,
  otherColour,
} from '@tphglobal/common/theme/style.palette';
import { fontSize, fontWeight } from '@tphglobal/common/theme/style.typography';
import useWindowSize from '@tphglobal/common/hooks/windowSize';
import { breakpoints } from '@tphglobal/common/theme/style.layout';
import { RegionTypes } from '@tphglobal/common/utils/constants';
import { HttpMethods } from '@tphglobal/common/utils';
import { Location } from 'history';
import { useLocation } from 'react-router-dom';
import { Container } from '../../../components';
import handleApiCall from '../../handleApiCall';
import { ReduxState } from '../../../redux/reducers';
import { convertFileToBase64 } from '../../../utils/commonFunctions';
import { StyledHeading } from '../styles';
import messages from '../../../messages';
import {  required } from '../../../utils';
import {
  ADD_DOCUMENT_AU,
  ADD_DOCUMENT_UK,
  UPLOAD_ATTACHMENT_AU,
  UPLOAD_ATTACHMENT_UK,
} from '../../../api';
import { FileAttachment } from '../../../models';

interface Props {
  onSuccess : () => void
  onCancel : () => void
}
interface LocationState {
  isUpdate?: boolean;
}
interface FileAttachmentObject {
  file: FileAttachment;
  fileId: string;
}

const validators = {
  description: [required(messages?.general?.validations?.descriptionRequired)],
  fileUpload: [required(messages?.general?.validations?.fileRequired)],
};

const AddDocumentMobile: React.FC<Props> = ({onSuccess,onCancel}) => {
  const reduxDispatch = useDispatch();
  const location: Location = useLocation<{state: LocationState}>();

  const [submitting, setSubmitting] = useState<boolean>(false);
  const userProfile = useSelector((state: ReduxState) => state?.profile);
  const userId = userProfile?.Guid;
  const selectedRegion = userProfile?.region;
  const isUpdate = (location.state as LocationState)?.isUpdate;
  const {
    submitError, handleSubmit, connectField, change, setSubmitError,
  } = useFormReducer(validators);

  const { width } = useWindowSize();

  const isMobile = width <= breakpoints.mobile;
  const [files, setFiles] = useState<FileAttachmentObject[]>([]);
  const [uploadFile, setUploadFile] = useState<File>();

  interface SubmitData {
    description : string
    fileUpload : FileAttachment
  }
  const handledocumentSummit = (data: SubmitData, file_name : string) => {
    const file = files[0]?.file;

    const ext = file?.filename.split('.').pop();
    const sanitizedBody = {
      Company: '//',
      ContentType: file?.ContentType,
      DocDesc: data?.description,
      DocumentType: ext.toUpperCase(),
      Encrypted: 'No',
      Filename: file?.filename,
      Production: null,
      PWHash: null,
      Salt: 'GYUFYDTFKHFYV',
      Timestamp: new Date().getTime(),
      url: file_name,
      // TimecardGuid : null, 
      // SysCoyGuid : null,
      DocType: 'Upload',
    };

    
    handleApiCall(
      reduxDispatch,
      selectedRegion === RegionTypes.AU ? ADD_DOCUMENT_AU : ADD_DOCUMENT_UK,
      () => {
        setSubmitting(false);
        onSuccess()
      },
      HttpMethods.POST,
      selectedRegion === RegionTypes.AU ? RegionTypes.AU : RegionTypes.UK,
      messages?.document?.addForm?.success?.heading,
      sanitizedBody,
      setSubmitError,
    );
  };
  const onSubmit = async (data: SubmitData) => {
    const file = files[0]?.file;
    const ext = file?.filename?.split('.')?.pop();
    const file_name = `${new Date().getTime()}.${ext}`;
    const upload_path = `documents/${userId}/${file_name}`;
    const content_type = file?.ContentType;
    let base64Data;
    if (uploadFile) {
      base64Data = await convertFileToBase64(uploadFile);
    }
    const sanitizedBody = {
      upload_path,
      content_type,
      base64Data,
    };

    setSubmitting(true);
    handleApiCall(
      reduxDispatch,
      selectedRegion === RegionTypes.AU
        ? UPLOAD_ATTACHMENT_AU
        : UPLOAD_ATTACHMENT_UK,
      () => {
        handledocumentSummit(data,file_name);
      },
      HttpMethods.POST,
      selectedRegion === RegionTypes.AU ? RegionTypes.AU : RegionTypes.UK,
      null,
      sanitizedBody,
      setSubmitError,
    );
  };

  return (
    <Container
      heading={
        messages?.document?.addForm?.[isUpdate ? 'editHeading' : 'addDocument']
      }
      background={otherColour?.adminBg100}
      maxHeight
      showGoBack
    >
      <Card
        noContentPadding
        removeTitle
        contentCss={{ padding: '24px 16px !important' }}
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {connectField('description', {
                label: messages?.document?.addForm?.description,
                multiline: true,
                rows: 3,
                autoHeight: true,
              })(TextInput)}
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <StyledHeading
                    fontSize={fontSize?.h4}
                    fontWeight={fontWeight?.semiBold}
                    lineHeight="26.04px"
                  >
                    {messages?.document?.addForm?.attachment}
                  </StyledHeading>
                </Grid>
                <Grid item xs={12}>
                  {connectField('fileUpload', {
                    acceptedFiles: '.pdf, image/png, image/jpg, image/jpeg',
                    isPictureView: true,
                    multiple: false,
                    files,
                    setFiles,
                    setUploadFile,
                    uploadFile,
                  })(DragAndDrop)}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {submitError && (
            <FormRow marginTop={2}>
              <FormRowItem>
                <FormError
                  message={
                    submitError
                  }
                />
              </FormRowItem>
            </FormRow>
          )}

          <OutlinedContainer
            noBorder
            borderRadius="0"
            padding="14px 0"
            styles={{
              backgroundColor: !isMobile && greyScaleColour?.grey60,
              marginTop: '14px',
            }}
          >
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Grid container spacing={2}>
                  <Grid item>
                    <Button
                      onClick={onCancel}
                      variant="outlined"
                      buttonColor={brand?.black}
                      color="primary"
                      size="large"
                      label={messages?.general?.cancel}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="large"
                      type="submit"
                      disabled={submitting}
                      label={messages?.general?.add}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </OutlinedContainer>
        </Form>
      </Card>
    </Container>
  );
};

export default AddDocumentMobile;
