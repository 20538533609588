import React, { useState } from 'react';
import {
  Header,
  MobileHeader,
  Sidebar,
  TabNavigation,
  Toast,
} from '@tphglobal/components';
import useWindowSize from '@tphglobal/common/hooks/windowSize';
import { breakpoints } from '@tphglobal/common/theme/style.layout';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import GridViewIcon from '@mui/icons-material/GridView';
import { fontSize } from '@tphglobal/common/theme/style.typography';
import { greyScaleColour } from '@tphglobal/common/theme/style.palette';
import { signOut } from 'aws-amplify/auth';
import { useDispatch, useSelector } from 'react-redux';
import { RegionTypes } from '@tphglobal/common/utils/constants';
import { toast } from 'react-toastify';
import { SxProps, Theme } from '@mui/material';
import { logout } from '../../redux/actions';
import configAmplify from '../../config/awsConfig';
import DEFAULTPIC from '../../assets/images/profile_pic.png'
import { routes } from '../../utils';
import messages from '../../messages';
import {
  StyledChildrenContainer,
  StyledContainer,
  StyledContentContainer,
} from './styles';
import { ReduxState } from '../../redux/reducers';

const iconStyles = {
  height: fontSize?.h4,
  width: fontSize?.h4,
  fill: greyScaleColour?.white100,
};
const menuItems = [
  {
    key: 'home',
    label: messages?.sidebar?.menuItems?.home,
    src: '../../assets/images/homeIcon.svg',
    icon: <GridViewIcon style={iconStyles} />,
    path: routes.home.root,
  },
  {
    key: 'timeCard',
    label: messages?.sidebar?.menuItems?.timeCard,
    src: '../../assets/images/timeCardIcon.svg',
    icon: <CreditCardIcon style={iconStyles} />,
    path: routes.timeCard.root,
  },
  {
    key: 'company',
    label: messages?.sidebar?.menuItems?.company,
    icon: <CorporateFareIcon style={iconStyles} />,
    src: '../../assets/images/companyIcon.svg',
    path: routes.company.root,
  },
  {
    key: 'document',
    label: messages?.sidebar?.menuItems?.document,
    src: '../../assets/images/documentIcon.svg',
    icon: <ArticleOutlinedIcon style={iconStyles} />,
    path: routes.document.root,
  },
];

interface DashboardTab {
  id: string;
  title: string;
  path: string;
  component: React.FC;
}

interface Props {
  children?: JSX.Element | JSX.Element[];
  hideSidebar?: boolean;
  cardCss?: SxProps<Theme>;
  contentCss?: SxProps<Theme>;
  heading?: string;
  showGoBack?: boolean;
  hasHeader?: boolean;
  noPadding?: boolean;
  noMargin?: boolean;
  background?: string;
  maxHeight?: boolean;
  noBorderRadius?: boolean;
  hideTabNavigation?: boolean;
  customHeading?: React.ReactNode;
  rightIcon?: React.ReactNode;
  tabs?: DashboardTab[];
  onRightIconPress?: () => void;
  customGoBack?: () => void;
}

const Container: React.FC<Props> = ({
  children,
  hideSidebar,
  heading,
  showGoBack,
  hasHeader = true,
  noPadding,
  noMargin = false,
  background,
  maxHeight,
  noBorderRadius,
  hideTabNavigation,
  customHeading,
  rightIcon,
  tabs,
  onRightIconPress,
  customGoBack,
  ...styleProps
}) => {
  const reduxDispatch = useDispatch();
  const [sidebarOpen, setSidebarOpen] = useState(window.innerWidth < 1400);
  const userProfile = useSelector((state: ReduxState) => state?.profile);
  const selectedRegion = userProfile?.region;
  const { width } = useWindowSize();

  const handleLogout = async () => {
    configAmplify(
      selectedRegion === RegionTypes.AU ? RegionTypes.AU : RegionTypes.UK,
    );
    try {
      await signOut();
      reduxDispatch(logout());
    } catch (error) {
      toast(
        <Toast type="error" text="Logout unsuccessfull. Please try again." />,
      );
    }
  };

  const isMobile = width <= breakpoints.mobile;
  return (
    <StyledContainer
      noPadding={noPadding}
      background={background}
      {...styleProps}
    >
      {!hideSidebar && (
        <Sidebar menuItems={menuItems} sidebarOpen={sidebarOpen} />
      )}
      {!hideTabNavigation && isMobile && (
        <TabNavigation
          profileImage={userProfile?.profile_pic ? userProfile?.profile_pic : DEFAULTPIC}
          handleLogout={handleLogout}
        />
      )}
      <StyledContentContainer noMargin={noMargin}>
        {hasHeader && (
          <Header
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
            heading={heading}
            showGoBack={showGoBack}
            handleLogout={handleLogout}
            profileImage={userProfile?.profile_pic ? userProfile?.profile_pic : DEFAULTPIC}
            userProfile={userProfile}
          />
        )}
        {hasHeader && isMobile && (
          <MobileHeader
            onRightIconPress={onRightIconPress}
            tabs={tabs}
            rightIcon={rightIcon}
            customHeading={customHeading}
            heading={heading}
            showGoBack={showGoBack}
            customGoBack={customGoBack}
          />
        )}
        <StyledChildrenContainer
          noBorderRadius={noBorderRadius || isMobile}
          maxHeight={maxHeight}
          noMargin={noMargin}
          hasHeader={hasHeader}
        >
          {children}
        </StyledChildrenContainer>
      </StyledContentContainer>
    </StyledContainer>
  );
};

export default Container;
