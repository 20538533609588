import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  FloatingButton,
  Modal,
  ModalAction,
  Toast,
} from '@tphglobal/components';
import { brand, otherColour } from '@tphglobal/common/theme/style.palette';
import { Grid } from '@mui/material';
import { fontSize, fontWeight } from '@tphglobal/common/theme/style.typography';
import Table from '@tphglobal/components/table';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import AddIcon from '@mui/icons-material/Add';
import EditOutlineIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { usePopupReducer } from '@tphglobal/common/hooks';
import useWindowSize from '@tphglobal/common/hooks/windowSize';
import { breakpoints } from '@tphglobal/common/theme/style.layout';

import { RegionTypes } from '@tphglobal/common/utils/constants';
import moment from 'moment';
import { HttpMethods } from '@tphglobal/common/utils';
import {
  DELETE_TIMECARD_AU, DELETE_TIMECARD_UK, GET_COMPANY_LIST_AU, GET_COMPANY_LIST_UK, GET_TIMECARD_LIST_AU, GET_TIMECARD_LIST_UK,
} from '../../api';
import { Company, CompanyEntity, Timecard } from '../../models';
import handleApiCall from '../handleApiCall';
import { headerIconStyles, iconStyles } from '../../utils/constant';
import { routes } from '../../utils';
import { resultsFetchRequest } from '../../redux/actions';
import { StyledHeading } from './styles';
import { Container } from '../../components';
import messages from '../../messages';
import { ReduxState } from '../../redux/reducers';

const desktopSpecs = [
  {
    id: 'PeriodEnding',
    label: messages?.timeCard?.table?.payEnding,
    format: (row: Date) => moment(row)?.format('DD/MM/YYYY '),
  },
  {
    id: 'Company',
    label: messages?.timeCard?.table?.productionName,
    format: (row: [Company]) => row[0]?.Name,
  },
  {
    id: 'TotalHours',
    label: messages?.timeCard?.table?.totalHours,
  },
];

const mobileSpecs = [
  {
    id: 'PeriodEnding',
    label: messages?.timeCard?.table?.payEnding,
    format: (row: Date) => moment(row)?.format('DD/MM/YYYY '),
  },
  {
    id: 'Company',
    label: messages?.timeCard?.table?.productionName,
    format: (row: [Company]) => row[0]?.Name,
  },
];

const TimeCard = () => {
  const history = useHistory();
  const reduxDispatch = useDispatch();
  const timeCardListing = useSelector(
    (state: ReduxState) => state?.results?.results,
  );
  const [companyListing, setCompanyListing] = useState<CompanyEntity[]>([]);
  const [timeCard, setTimeCard] = useState<Timecard[]>([]);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const { width } = useWindowSize();
  const selectedRegion = useSelector(
    (state: ReduxState) => state?.profile?.region,
  );
  const isMobile = width <= breakpoints.mobile;

  const {
    visibility: deleteVisibility,
    showPopup: showdeleteForm,
    hidePopup: hidedeleteForm,
    metaData: deleteFormMetadata,
  } = usePopupReducer<{timeCardId : string}>();

  const handleAddTimeCard = () => {
    history.push(routes?.addTimeCard?.root, {
      data: {
        isUpdate: false,
        companyListing,
        timeCardListing,
      },
    });
  };

  // Fetching timecard listing 
  const fetchTimeCardListing = () => {
    reduxDispatch(
      resultsFetchRequest(
        selectedRegion === RegionTypes.AU
          ? GET_TIMECARD_LIST_AU
          : GET_TIMECARD_LIST_UK,
        { page: 1, limit: 10 },
      ),
    );
  };

  // Fetching companies listing for drop down options
  const fetchCompanyListings = () => {
    handleApiCall(
      reduxDispatch,
      selectedRegion === RegionTypes.AU
        ? GET_COMPANY_LIST_AU
        : GET_COMPANY_LIST_UK,
      (res) => {
        setCompanyListing(res?.result);
      },
      HttpMethods?.GET,
      selectedRegion === RegionTypes.AU ? RegionTypes.AU : RegionTypes.UK,
    );
  };

  useEffect(() => {
    fetchTimeCardListing();
    fetchCompanyListings();
  }, []);

  useEffect(() => {
    const updateListing = timeCardListing?.filter(
      (card: Timecard) => card?.Type === 'Draft',
    );
    setTimeCard(updateListing);
  }, [timeCardListing]);

  const handleDeleteTimeCard = () => {
    setSubmitting(true);
    handleApiCall(
      reduxDispatch,
      `${
        selectedRegion === RegionTypes.AU
          ? DELETE_TIMECARD_AU
          : DELETE_TIMECARD_UK
      }/${deleteFormMetadata?.timeCardId}`,
      () => {
        setSubmitting(false);
        hidedeleteForm();
        fetchTimeCardListing();
      },
      HttpMethods?.DELETE,
      selectedRegion === RegionTypes.AU ? RegionTypes.AU : RegionTypes.UK,
      messages?.timeCard?.deleteForm?.success,
      null,
      null,
      (errorMessage : string) => {
        hidedeleteForm()
        setSubmitting(false)
        toast(<Toast type='error' text={errorMessage} />)
      }
    );
  };

  return (
    <Container
      onRightIconPress={handleAddTimeCard}
      rightIcon={<AddIcon style={headerIconStyles} />}
      heading={messages?.timeCard?.heading}
      background={otherColour?.adminBg100}
      maxHeight
    >
      <Card
        noContentPadding={isMobile}
        removeTitle
        contentCss={isMobile && { padding: '24px 16px !important' }}
        header={(
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <StyledHeading
                fontSize={fontSize?.h2}
                fontWeight={fontWeight?.semiBold}
              >
                {messages?.timeCard?.heading}
              </StyledHeading>
            </Grid>
            <Grid item>
              <Button
                onClick={handleAddTimeCard}
                variant="outlined"
                color="primary"
                size="large"
                label={messages?.general?.addNew}
                icon={<AddIcon style={iconStyles} />}
              />
            </Grid>
          </Grid>
        )}
      >
        <Table
          data={timeCard}
          initialSortColumn="Company"
          disableSorting={['PeriodEnding', 'TotalHours']}
          actions={[
            {
              id: 'edit',
              component: (
                <EditOutlineIcon
                  style={{ ...iconStyles, color: brand?.secondaryMain }}
                />
              ),
              onClick: (row: Timecard) => {
                const dataToPass = {
                  isUpdate: true,
                  details: row,
                  companyListing,
                  timeCardListing,
                  updatingCompanyBrief: companyListing?.find((company : CompanyEntity) => row?.SysCoyGuid === company?.SysCoyGuid),
                };

                history.push({
                  pathname: routes?.editTimeCard?.root,
                  state: { data: dataToPass },
                });
              },
            },
            {
              id: 'delete',
              component: (
                <DeleteOutlineIcon
                  style={{ ...iconStyles, color: otherColour?.errorDefault }}
                />
              ),
              onClick: (row: Timecard) => {
                showdeleteForm({ timeCardId: row?.Guid });
              },
            },
          ]}
          specs={isMobile ? mobileSpecs : desktopSpecs}
        />
        <FloatingButton
          icon={<img src="../../assets/images/helpIcon.svg" alt="Help icon" />}
          onClick={() => reduxDispatch(push(routes?.support?.root))}
          ariaLabel="Help & Support"
          bottom="2.03%"
          right="1.36%"
        />
      </Card>
      <Modal
        show={deleteVisibility}
        icon={<DeleteOutlineIcon />}
        deleteForm
        onClose={hidedeleteForm}
        heading={messages?.timeCard?.deleteForm?.heading}
        fitContent
      >
        <ModalAction
          noConfirmationDelete
          title={messages?.timeCard?.deleteForm?.subHeading}
          onCancel={hidedeleteForm}
          buttonLabel="Timecard"
          submitting={submitting}
          customSubmit={handleDeleteTimeCard}
        />
      </Modal>
    </Container>
  );
};

export default TimeCard;
