import React from 'react';
import { HttpMethods } from '@tphglobal/common/utils';
import { toast } from 'react-toastify';
import { Toast } from '@tphglobal/components';
import { RegionTypes } from '@tphglobal/common/utils/constants';
import { apiCall, logout, postLogin } from '../redux/actions';
import configAmplify from '../config/awsConfig';
import ErrorHandler from '../utils/errorHandler';

interface ReduxAction {
  type: string;
  payload?: unknown;
}

type DispatchFunction = (action: ReduxAction) => void;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ApiResponse = any;

const handleApiCall = async (
  dispatch: DispatchFunction,
  apiName: string,
  onSuccess: (data?: ApiResponse) => void,
  requestType: HttpMethods,
  region: RegionTypes,
  successMsg?: string,
  body?: unknown,
  setSubmitError?: (error?: string) => void,
  onFailure?: (errMessage ?: string) => void
) => {
  configAmplify(region);
    const response: ApiResponse = await new Promise<ApiResponse>((resolve, reject) => {
      dispatch(
        apiCall(
          apiName,
          resolve,
          (err) => {
            setSubmitError && setSubmitError(ErrorHandler(err?.message))
            onFailure && onFailure(ErrorHandler(err?.message))
          },
          HttpMethods[requestType],
          body || null,
        ),
      );
    });

    onSuccess && onSuccess(response);
    successMsg && toast(() => <Toast text={successMsg} />);
};

export default handleApiCall;
