import Button from '@mui/material/Button';
import styled, { css } from 'styled-components';

export const StyledButton = styled(Button)<{
  buttonColor?: string;
  noHover?: boolean;
}>`
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  white-space: nowrap;

  ${({ buttonColor }) =>
    buttonColor &&
    css`
      border-color: ${buttonColor} !important;
      color: ${buttonColor} !important;
    `}

  &:hover {
    ${({ noHover, buttonColor }) =>
      noHover
        ? css`
            background-color: transparent !important;
            color: inherit;
          `
        : 
          css`
            background-color: ${buttonColor} !important;
            color: white !important;
          `}
  }
`;

export const StyledIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
