import React from 'react';
import { CheckboxProps, FormControlLabel } from '@mui/material';
import { StyledCheckboxContainer, StyledCheckbox, StyledError, StyledLabel, StyledFormControlLabel } from './styles';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { brand, greyScaleColour } from '@tphglobal/common/theme/style.palette';
import { fontSize } from '@tphglobal/common/theme/style.typography';
import { FixedTitle } from '@tphglobal/common/utils';

interface Props {
  checked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error?: string;
  disableErrorMode?: boolean;
  label?: string;
  maxWidth?: string;
  flexDirection?: string;
  value?: boolean
}

const boxStyle={
  width: fontSize?.h3,
  height: fontSize?.h3,
  fill: greyScaleColour?.grey90
}
const checkedStyle={
  width: fontSize?.h3,
  height: fontSize?.h3,
  fill: brand?.secondaryMain
}

const CustomCheckbox: React.FC<Props & CheckboxProps> = ({
  checked,
  onChange,
  error,
  disableErrorMode,
  label,
  maxWidth,
  flexDirection,
  value,
  ...props
}) => (
  <StyledCheckboxContainer  maxWidth={maxWidth}>
    <StyledFormControlLabel
      flexDirection={flexDirection}
      control={
        <StyledCheckbox
          {...props}
          icon={<CheckBoxOutlineBlankIcon style={boxStyle} />}
          checkedIcon={<CheckBoxIcon style={checkedStyle} />}
          checked={value || false}
          onChange={(event) => {
            if (onChange) {
              let isChecked : any = event.target.checked
              onChange(isChecked);
            }
          }}
        />
      }
      label={<FixedTitle varient='Fixed14Regular'>{label}</FixedTitle>}
    />
    {!disableErrorMode && error && <StyledError>{error}</StyledError>}
  </StyledCheckboxContainer>
);

export default CustomCheckbox;
