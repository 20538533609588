import {
  Button,
  Card,
  Modal,
  ModalAction,
  OutlinedContainer,
} from '@tphglobal/components';
import React, { useEffect, useState } from 'react';
import {
  brand,
  greyScaleColour,
  otherColour,
} from '@tphglobal/common/theme/style.palette';
import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material';
import { fontSize, fontWeight } from '@tphglobal/common/theme/style.typography';
import {
  FixedTitle,
  HttpMethods,
} from '@tphglobal/common/utils';
import { useDispatch, useSelector } from 'react-redux';
import { usePopupReducer } from '@tphglobal/common/hooks';
import EditOutlineIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { goBack } from 'connected-react-router';
import { RegionTypes } from '@tphglobal/common/utils/constants';
import { iconStyles } from '../../../utils/constant';
import { ReduxState } from '../../../redux/reducers';
import handleApiCall from '../../handleApiCall';
import {
  Icons,
  Image,
  StyledDiv,
  StyledHeading,
} from '../styles';
import messages from '../../../messages';
import { Container } from '../../../components';
import {
  GET_SIGNATURE_AU, GET_SIGNATURE_UK, UPLOAD_SIGNATURE_AU, UPLOAD_SIGNATURE_UK,
} from '../../../api';
import UpdateSignature from '../signature/updateSignature';

interface SignatureBody {
  base64Data ?: string
  Typed ?: boolean
}

const SignatureMobile = () => {
  const reduxDispatch = useDispatch();
  const selectedRegion = useSelector(
    (state: ReduxState) => state?.profile?.region,
  );
  const [signature, setSignature] = useState({
    drawnSignature: '',
    typedSignature: '',
  });

  const {
    visibility: deleteSignatureVisibility,
    showPopup: showSignaturedeleteForm,
    hidePopup: hidedeleteSignatureForm,
    metaData: deleteSignatureFormMetadata,
  } = usePopupReducer<{type : string}>();

  const {
    visibility: editSignatureFormVisibility,
    showPopup: showEditSignatureForm,
    hidePopup: hideEditSignatureForm,
    metaData: editSignatureMetadata,
  } = usePopupReducer<{type : string}>();

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [selectedValue, setSelectedValue] = React.useState('1');
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  const controlProps = (item: string) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    inputProps: { 'aria-label': item },
  });

  const fetchUserSignatures = () => {
    handleApiCall(
      reduxDispatch,
      selectedRegion === RegionTypes.AU ? GET_SIGNATURE_AU : GET_SIGNATURE_UK,
      (res) => {
        setSignature({
          drawnSignature: res?.result?.signature,
          typedSignature: res?.result?.typed_signature,
        });
      },
      HttpMethods.GET,
      selectedRegion === RegionTypes.AU ? RegionTypes.AU : RegionTypes.UK,
    );
  };

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const handleImageError = () => {
    setImageLoaded(false);
  };

  const handleUpdateSignature = (body: SignatureBody, type: string, onSuccess ?: () => void, toast?: string) => {
    handleApiCall(
      reduxDispatch,
      selectedRegion === RegionTypes.AU
        ? UPLOAD_SIGNATURE_AU
        : UPLOAD_SIGNATURE_UK,
      () => {
        if (onSuccess) {
          onSuccess();
        } else {
          hideEditSignatureForm();
        }
        fetchUserSignatures();
      },
      HttpMethods.POST,
      selectedRegion === RegionTypes.AU ? RegionTypes.AU : RegionTypes.UK,
      toast || messages?.profile?.signature?.editForm?.success?.[type],
      body,
    );
  };

  const handleClearSignature = () => {
    setSubmitting(true);
    const sanitizedBody = deleteSignatureFormMetadata?.type === 'typed' ? { Typed: true } : {};
    handleUpdateSignature(
      sanitizedBody,
      deleteSignatureFormMetadata?.type,
      () => {
        setSubmitting(false);
        hidedeleteSignatureForm();
      },
      messages?.profile?.signature?.deleteForm?.success,
    );
  };

  useEffect(() => {
    fetchUserSignatures();
  }, []);

  return (
    <Container
      heading={messages?.profile?.signature?.mobileHeading}
      showGoBack
      background={otherColour?.adminBg100}
      maxHeight
    >
      <Card
        noContentPadding
        removeTitle
        contentCss={{ padding: '24px 16px !important' }}
        showGoBack
      >
        <Grid container marginBottom={1}>
          <StyledHeading
            fontSize={fontSize?.h5}
            fontWeight={fontWeight?.semiBold}
            lineHeight="18px"
          >
            {messages?.general?.select}
          </StyledHeading>
        </Grid>
        <Grid container rowSpacing={2}>
          <Grid item paddingLeft={1}>
            <FormControl>
              <RadioGroup name="radio-buttons-group">
                <FormControlLabel
                  control={(
                    <Radio
                      {...controlProps('1')}
                      sx={{
                        '& .MuiSvgIcon-root': {
                          fontSize: 20,
                          margin: '10px 0',
                        },
                      }}
                    />
                  )}
                  label={(
                    <StyledHeading
                      fontSize={fontSize?.h5}
                      fontWeight={fontWeight?.semiBold}
                      lineHeight="21px"
                    >
                      {messages?.profile?.signature?.draw}
                    </StyledHeading>
                  )}
                />
                <FormControlLabel
                  control={(
                    <Radio
                      {...controlProps('2')}
                      sx={{
                        '& .MuiSvgIcon-root': {
                          fontSize: 20,
                          margin: '10px 0',
                        },
                      }}
                    />
                  )}
                  label={(
                    <StyledHeading
                      fontSize={fontSize?.h5}
                      fontWeight={fontWeight?.semiBold}
                      lineHeight="21px"
                    >
                      {messages?.profile?.signature?.type}
                    </StyledHeading>
                  )}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <OutlinedContainer padding="16px">
              <Grid
                xs={12}
                item
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                marginBottom={2}
              >
                <StyledHeading
                  fontWeight={fontWeight?.semiBold}
                  fontSize={fontSize?.h5}
                >
                  {
                    messages?.profile?.signature?.[
                      selectedValue === '1' ? 'draw' : 'type'
                    ]
                  }
                </StyledHeading>
                <FixedTitle
                  onClick={() => showSignaturedeleteForm({ type: selectedValue === '1' ? 'drawn' : 'typed' })}
                  style={{ cursor: 'pointer' }}
                  color={otherColour?.errorDefault}
                  varient="Fixed14Medium"
                >
                  {messages?.general?.clear}
                </FixedTitle>
              </Grid>
              <Grid xs={12} item alignSelf="center">
                <StyledDiv>
                  <Image
                    src={
                      selectedValue === '1'
                        ? signature?.drawnSignature
                        : signature?.typedSignature
                    }
                    onLoad={handleImageLoad}
                    onError={handleImageError}
                    style={{ display: imageLoaded ? 'block' : 'none' }}
                    alt="Background"
                  />
                  <Icons>
                    <EditOutlineIcon
                      onClick={() => { showEditSignatureForm({ type: selectedValue === '1' ? 'drawn' : 'typed' }); }}
                      style={{
                        ...iconStyles,
                        fill: brand?.secondaryMain,
                        cursor: 'pointer',
                      }}
                    />
                  </Icons>
                </StyledDiv>
              </Grid>
            </OutlinedContainer>
          </Grid>
        </Grid>
        <Grid container marginTop={2} columnSpacing={2}>
          <Grid item alignSelf="center" xs={3.9}>
            <Button
              onClick={() => reduxDispatch(goBack())}
              variant="outlined"
              buttonColor={greyScaleColour?.black100}
              size="large"
              label={messages?.general?.cancel}
            />
          </Grid>
          <Grid item alignSelf="center" xs={8.1}>
            <Button
              onClick={() => reduxDispatch(goBack())}
              variant="outlined"
              color="primary"
              size="large"
              label={messages?.profile?.signature?.button}
            />
          </Grid>
        </Grid>
      </Card>
      <Modal
        icon={<DeleteOutlineIcon />}
        show={deleteSignatureVisibility}
        heading={messages?.profile?.signature?.deleteForm?.heading}
        onClose={hidedeleteSignatureForm}
        deleteForm
        fitContent
      >
        <ModalAction
          noConfirmationDelete
          title={messages?.profile?.signature?.deleteForm?.subHeading}
          onCancel={hidedeleteSignatureForm}
          buttonLabel="signature"
          customSubmit={handleClearSignature}
          submitting={submitting}
        />
      </Modal>
      <Modal
        icon={<EditOutlineIcon />}
        show={editSignatureFormVisibility}
        heading={
          messages?.profile?.signature?.editForm?.[editSignatureMetadata?.type]
        }
        onClose={hideEditSignatureForm}
        fitContent
      >
        <UpdateSignature
          type={editSignatureMetadata?.type}
          onCancel={hideEditSignatureForm}
          updateSignature={handleUpdateSignature}
          isMobile
        />
      </Modal>
    </Container>
  );
};

export default SignatureMobile;
