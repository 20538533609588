import React from 'react';
import { useDispatch } from 'react-redux';
import { Form, FormRow, FormRowItem, FormError } from '../form';
import Button from '../button';
import { HttpMethods, required } from '@tphglobal/common/utils';
import messages from '../messages';
import { useFormReducer } from '@tphglobal/common/hooks';
import { apiCall } from '@tphglobal/common/redux/actions';
import { StyledHeading } from './styles';
import { fontSize, fontWeight } from '@tphglobal/common/theme/style.typography';
import { Grid } from '@mui/material';
import { brand, greyScaleColour } from '@tphglobal/common/theme/style.palette';
import OutlinedContainer from '../outlinedContainer';
import useWindowSize from '@tphglobal/common/hooks/windowSize';
import { breakpoints } from '@tphglobal/common/theme/style.layout';

interface Props {
  title?: string;
  info?: string;
  noConfirmationDelete?: boolean;
  onCancel?: any;
  onSuccess?: () => void;
  endpoint?: string;
  buttonLabel?: string
  completeLabel?: boolean
  customSubmit?: () => void
  submitting?: boolean
}
const validators = {
  name: [required(messages?.general?.errors?.nameToBeDeleted)],
};
const ModalAction: React.FC<Props> = ({
  title,
  info,
  onSuccess,
  onCancel,
  endpoint,
  buttonLabel,
  completeLabel,
  customSubmit,
  submitting,
}) => {
  const {
    submitError,
    handleSubmit,
    connectField,
    setSubmitError,
  } = useFormReducer(validators);
  const reduxDispatch = useDispatch();
  const { width } = useWindowSize();

  const isMobile = width <= breakpoints.mobile;
  const onSubmit = async () => {
    return new Promise<any>((resolve, reject) => {
      reduxDispatch(apiCall(endpoint, resolve, reject, HttpMethods.DELETE));
    })
      .then(() => {
        onSuccess();
      })
      .catch((error) => {
        setSubmitError(error?.message);
      });
  };
  return (
    <Form onSubmit={handleSubmit(onSubmit)} >
      <Grid padding={isMobile ? '0 16px' : '0 24px'}>
        {title && (
          <FormRow marginBottom={0} maxWidth={'402px'}>
            <StyledHeading
              fontWeight={fontWeight?.semiBold}
              fontSize={fontSize?.[isMobile ? 'h5' : 'h4']}
              lineHeight={isMobile ? '20px' : '26px'}
            >
              {title}
            </StyledHeading>
          </FormRow>
        )}
        {submitError && (
          <FormRow>
            <FormRowItem>
              <FormError
                message={
                  messages?.general?.error?.serverError?.[submitError] ||
                  messages?.projects?.failure?.[submitError]
                }
              />
            </FormRowItem>
          </FormRow>
        )}
      </Grid>
      <OutlinedContainer
        noBorder
        borderRadius='0'
        padding='14px 24px'
        styles={{ backgroundColor: greyScaleColour?.grey60, marginTop: '16px' }}
      >
        <Grid container justifyContent='flex-end'>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item>
                <Button
                onClick={onCancel}
                  variant='outlined'
                  buttonColor={brand?.black}
                  color='primary'
                  size='large'
                  label={messages?.general?.cancel}
                />
              </Grid>
              <Grid item>
                <Button
                  variant='contained'
                  color='secondary'
                  size='large'
                  type={customSubmit ? 'button' : 'submit'}
                  disabled={submitting}
                  onClick={customSubmit && customSubmit}
                  label={!completeLabel ? (messages?.general?.delete).concat(" ").concat(buttonLabel) : buttonLabel}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </OutlinedContainer>
    </Form>
  );
};
export default ModalAction;
