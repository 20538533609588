import React, { JSX } from 'react';
import { SxProps, Theme, Grid } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  CardTitle,
  StyledCard,
  StyledCardContent,
  StyledCardHeader,
  StyledHeader,
} from './styles';
import { goBack } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import useWindowSize from '@tphglobal/common/hooks/windowSize';
import { breakpoints } from '@tphglobal/common/theme/style.layout';

interface Props {
  children?: JSX.Element | JSX.Element[];
  cardCss?: SxProps<Theme>;
  contentCss?: SxProps<Theme>;
  header?: JSX.Element | JSX.Element[];
  headerCss?: React.CSSProperties;
  noHeader?: boolean;
  title?: string;
  noHeaderPadding?: boolean;
  index?: number;
  value?: number;
  showGoBack?: boolean;
  noContentPadding?: boolean
  // tabContent?: { heading: string; subHeading?: string; buttonComponent?: any };
  orientation?: 'vertical' | 'horizontal';
  noBottomMargin?: boolean
  removeTitle?: boolean
}

const Card: React.FC<Props> = ({
  children,
  cardCss,
  header,
  contentCss,
  headerCss,
  noHeader,
  title,
  index,
  value,
  noHeaderPadding,
  showGoBack,
  noContentPadding,
  noBottomMargin,
  removeTitle,
  ...other
}) => {
  const reduxDispatch = useDispatch();
  const { width } = useWindowSize();

  const isMobile = width <= breakpoints.mobile;
  let removeTitleMobile = removeTitle && isMobile
  return (
    <StyledCard sx={cardCss} hidden={value !== index} {...other}>
      {(!noHeader && !removeTitleMobile) && (
        <StyledCardHeader style={headerCss} noBottomMargin={noBottomMargin} noHeaderPadding={noHeaderPadding}>
          <StyledHeader>
            {showGoBack && (
              <ArrowBackIcon style={{cursor: 'pointer'}} onClick={() => reduxDispatch(goBack())} fontSize='large' />
            )}

            {header}
          </StyledHeader>
          {title && (
            <Grid container alignItems='center' justifyContent='space-between'>
              <Grid item>
                <CardTitle variant='subtitle1'>{title}</CardTitle>
              </Grid>
            </Grid>
          )}
        </StyledCardHeader>
      )}
      <StyledCardContent noContentPadding={noContentPadding} sx={contentCss}>{children}</StyledCardContent>
    </StyledCard>
  );
};

export default Card;
