export const adminColor = {
  background: '#FDFDFD',
  calendarSchedule1: '#DBE9B3',
  calendarSchedule2: '#E9DAB3',
  calendarSchedule3: '#B3D9E9',
  calendarSchedule4: '#B3E9B8',
};

export const colors = {
  black: '#000000',
  white: '#FFFFFF',
  danger: '#DC3545',
  grey30: '#EEEEEE',
  orange100: '#E4A428',
  information: '#007BFF',
  lightGrey: '#F8F8F8',
  success: '#28A745',
  warning: '#FFC107',
  orange: '#F27935',
  red100: '#E43030',
  grey100: '#787880',
  grey50: '#BBBBBF',
  grey25: '#DDDDDF',
  grey10: '#F2F2F3',
  bgColor: '#FCFCFC',
  tableBorder: '#E9E7FD',
  orange100: '#E4A428',
  yellow100: '#F2CF13',
  unreadMessage: '#D34141',
  successMessage: '#339900',
  red20: '#FAD6D6',
  orange20: '#FAEDD4',
  yellow20: '#F9F8D0',
  green: '#D4F8D3',
  darkNavy: '#1c2025',
  royalBlue: '#4967ff',
  softBlueGray: 'rgba(163, 169, 195, 0.15)',
};

export const brand = {
  primaryMain: '#3FB3E4',
  secondaryMain: '#03599A',
  textColour: '#1A1A1A',
  black: '#000000',
  white: '#FFFFFF',
};

export const brandColour = {
  primaryDark: '#13A1DD',
  primary100: '#3FB3E4',
  primary50: '#3FB3E480',
  primary20: '#D9F0FA',
  primary10: '#3FB3E41A',
};


export const greyScaleColour = {
  black100: '#232323',
  grey100: '#737373',
  grey90: '#CACACA',
  grey80: '#D0D0D2  ',
  grey70: '#E8E8E8',
  grey60: '#F8F8F8',
  white100: '#FFFFFF',
  white25: '#FFFFFF40',
  white15: '#FFFFFF26'
}

export const otherColour = {
  adminBg100 : 'linear-gradient(to right , #045A9A, #3FB3E4)', 
  // gradientBg5: 'linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(249.55deg, rgba(63, 179, 228, 0.05) 2.85%, rgba(4, 90, 154, 0.05) 98.26%)',
  gradientBg5: '#F4F9FC',
  errorHover: '#D13E1D',
  errorDefault: '#FF5630',
  errorBg: '#FFE4DE',
  successDefault: '#118D57',
  successLight: '#23C272',
  successBg: '#DBF6E5',
  warning: '#EF6C00',
  warningBg: '#FFF4E5',
  information: '#0077B8',
  informationBg: '#C9E5F1',
};
