import { Typography } from "@mui/material";
import { Input as BaseInput } from '@mui/base/Input';
import { colors } from "@tphglobal/common/theme/style.palette";
import styled from "styled-components";

export const StyledError = styled(Typography)`
  text-align: center;
  color: ${colors.red100} !important;
  margin-top: 8px !important;
  
`;

export const StyledBaseInput = styled(BaseInput) `
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
`