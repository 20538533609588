import styled, { css } from 'styled-components';
import { FormControlLabel, Typography } from '@mui/material';
import {
  fontSize,
  fontWeight,
  baseFontFamily,
} from '@tphglobal/common/theme/style.typography';
import {
  brand,
  brandColour,
  colors,
  greyScaleColour,
  otherColour,
} from '@tphglobal/common/theme/style.palette';
import { respondTo } from '@tphglobal/common/theme/style.layout';

export const StyledSidebarContainer = styled.div<{ sidebarOpen?: boolean }>`
  display: flex;
  flex: 1;
  width: ${(props) => (!props.sidebarOpen ? '210px' : '64px')};
  transition: all 0.2s ease-in-out;
  background-color: ${otherColour.sidebar};
  position: relative;
  min-height: calc(100vh - 80px);

  ${respondTo?.mobileOnlyPortrait} {
    display: none;
  }
`;

export const StyledLogoIcon = styled.img<{
  position?: string;
  right?: string;
  top?: string;
  padding?: string;
  cursor?: string;
  height?: string;
  width?: string;
}>`
  position: ${(props) => props.position};
  right: ${(props) => props.right};
  top: ${(props) => props.top};
  padding: ${(props) => props.padding};
  cursor: ${(props) => props.cursor || 'pointer'};
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  max-width: 100%;
  max-height: 100%;
`;


export const StyledSidebarInnerContainer = styled.div`
  height: 100%;
  width: 290px;
  display: flex;
  flex-direction: column;
`;

export const StyledHeadingIconContainer = styled.div`
  display: flex;
  align-self: center;
`;

export const StyledSidebarIcon = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

export const StyledMenuItemContainer = styled.div`
  display: flex;
  margin-top: 55px;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
`;

export const StyledMenuItemBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  font-weight: ${fontWeight.semiBold};
  font-size: ${fontSize.b2};
  line-height: 20px;
`;

export const StyledSubMenuItemBoxContainer = styled.div``;

export const StyledSubMenuItemContainer = styled.a<{ active?: boolean }>`
  padding: 11px 16px;
  display: flex;
  gap: 20px;
  cursor: pointer;
  text-decoration: none;
  background-color: ${(props) => props.active && brandColour.primary70};
  border-width: ${(props) => props.active && '0px 0px 0px 3px'};
  border-radius: ${(props) => props.active && '0px 6px 6px 0px'};
  border-style: ${(props) => props.active && 'solid'};
  border-color: ${(props) => props.active && brand.primaryMain};
  color: ${(props) =>
    props.active ? brand.primaryMain : greyScaleColour.secondaryMain};
`;

export const StyledSubMenuSecondaryContainer = styled.a<{ active?: boolean }>`
  display: flex;
  gap: 20px;
  cursor: pointer;
  text-decoration: none;
  background-color: ${(props) => props.active && brandColour.primary70};
  border-width: ${(props) => props.active && '0px 0px 0px 3px'};
  border-radius: ${(props) => props.active && '0px 6px 6px 0px'};
  border-style: ${(props) => props.active && 'solid'};
  border-color: ${(props) => props.active && brand.primaryMain};
  color: ${(props) =>
    props.active ? brand.primaryMain : greyScaleColour.secondaryMain};
`;

export const StyledIconContainer = styled.div`
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledSecondarySubMenuContainer = styled.a<{ active?: boolean }>`
  padding: 11px 16px;
  display: flex;
  gap: 20px;
  cursor: pointer;
  text-decoration: none;
  color: ${(props) =>
    props.active ? brand.primaryMain : greyScaleColour.secondaryMain};
  user-select: none;
`;

export const StyledSecondaryMenuSubMenuContainer = styled.div`
  padding: 16px 16px;
  gap: 10px;
  border: 1px solid ${brandColour.primary100};
  border-radius: 10px;
  margin: 6px 0px;
  display: flex;
  flex-direction: column;
  background-color: ${greyScaleColour.white100};
`;

export const StyledSubMenuItemText = styled(Typography)`
  font-size: ${fontSize.h5} !important;
  font-weight: ${fontWeight.regular} !important;
  line-height: 24px !important;
`;

export const StyledSubMenuSecondaryItemText = styled(Typography)`
  font-size: ${fontSize.b1} !important;
  font-weight: ${fontWeight.regular} !important;
  line-height: 20px !important;
`;


export const StyledMenuItem = styled.a<{ active?: boolean, sidebarOpen?: boolean }>`
  display: flex;
  padding: 12px 24px;
  width: auto;
  gap: 10px;
  text-decoration: none;
  align-items: center;
  ${({ active }) => (active
    ? css`
          background: ${greyScaleColour?.white15};
          color: ${greyScaleColour?.white100};
          font-weight: ${fontWeight.bold};
          border-radius: 6px;
        `
    : css`
          /* min-width: 191px; */
        `)}
`;
export const StyledIcon = styled.span<{
  position?: string;
  right?: string;
  top?: string;
  padding?: string;
  cursor?: string;
  height?: string;
  width?: string;
}>`
  // position: ${(props) => props.position};
  right: ${(props) => props.right};
  top: ${(props) => props.top};
  // padding: ${(props) => props.padding};
  padding-left: 0 !important;
  cursor: ${(props) => props.cursor || 'pointer'};
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  max-width: 100%;
  max-height: 100%;

  svg {
    height: 100%;
    width: 100%;
    color: white
  }
`;

export const StyledSidebarToggleIcon = styled.img<{
  position?: string;
  right?: string;
  top?: string;
  padding?: string;
  cursor?: string;
  height?: string;
  width?: string;
}>`
  position: ${(props) => props.position};
  right: ${(props) => props.right};
  top: ${(props) => props.top};
  padding: ${(props) => props.padding};
  cursor: ${(props) => props.cursor || 'pointer'};
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.3);
  background: linear-gradient(to bottom, #fff, #eee);
  border-radius: 50%;
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  margin-left: 0px !important;
  .MuiFormControlLabel-label {
    font-size: ${fontSize.b1};
    font-weight: ${fontWeight.medium};
    font-family: ${baseFontFamily};
    color: ${greyScaleColour.secondaryMain};
  }
`;

export const StyledText = styled.p<{
  fontSize?: string;
  color?: string;
  margin?: string;
  fontWeight?: string;
}>`
  font-size: ${(props) => props.fontSize};
  color: ${(props) => props.color};
  margin: ${(props) => props.margin};
  font-weight: ${(props) => props.fontWeight};
`;

export const StyledMenuItemText = styled(Typography)<{ active?: boolean }>`
  color: ${colors.white} !important;
  font-size: ${fontSize.b1} !important;
  margin: 0 !important;
  line-height: 18.23px !important;
  ${({ active }) =>
    active &&
    css`
      font-weight: ${fontWeight.medium} !important;
    `}
`;

export const StyledRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`