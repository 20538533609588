import React, {
  CSSProperties,
  Dispatch,
  DragEvent,
  SetStateAction,
  useRef,
  useState,
  useEffect,
} from "react";
import { Grid, LinearProgress } from "@mui/material";
import { toast } from "react-toastify";
import {
  StyledDragActiveText,
  StyledDragDropForm,
  StyledDragLabel,
  StyledDragLabelDiv,
  StyledInputFile,
  StyledUploadButtonText,
  StyledText,
  StyledColumn,
  StyledContainerDirection,
  StyledRow,
} from "./styles";
import { StyledIcon } from "./styles";
import CloseIcon from "@mui/icons-material/Close";
import {
  fileSizeCheckFunction,
  validFileTypeCheckFunction,
  truncateString,
} from "@tphglobal/common/utils/commonFunctions";
import { allowedFiles } from "@tphglobal/common/utils/constants";
import Toast from "../toast";
import { StyledError } from "../textInput/styles";
import messages from "../messages";
import { FixedTitle } from "@tphglobal/common/utils";
import { greyScaleColour } from "@tphglobal/common/theme/style.palette";
import OutlinedContainer from "../outlinedContainer";
import { fontSize } from "@tphglobal/common/theme/style.typography";
import ProgressBar from "../progressBar";

interface Props {
  acceptedFiles?: string;
  files?: any;
  setFiles?: Dispatch<SetStateAction<any[]>>;
  multiple?: boolean;
  setSingleFile?: Dispatch<SetStateAction<any>>;
  isPdf?: boolean;
  error?: string;
  maxFileSize?: number;
  row?: boolean;
  isPictureView?: boolean;
  setIsUploadCompleted?: React.Dispatch<React.SetStateAction<boolean>>;
  onChange?: (file: any) => void;
  uploadFile?: any;
  setUploadFile?: any;
  isUploading?: boolean;
  showLoading?: boolean;
  UPLOADICON?: any;
  warningSize?: number;
  fullWidth?:boolean
  showProgress?: boolean
}

const iconStyle = {
  height: fontSize?.h3,
  width: fontSize?.h3,
  fill: greyScaleColour?.grey100,
  alignSelf: "center",
};

export const formatSize = (sizeInBytes: any) => {
  if (!sizeInBytes) {
    return;
  }
  const KB = 1024;
  const MB = 1024 * 1024;

  if (sizeInBytes < KB) {
    return `${Math.round(sizeInBytes)} B`;
  }
  if (sizeInBytes < MB) {
    return `${Math.round(sizeInBytes / KB)} KB`;
  }
  return `${Math.round(sizeInBytes / MB)} MB`;
};

const DragDropComponent: React.FC<Props> = ({
  acceptedFiles,
  files,
  setFiles,
  uploadFile,
  setUploadFile,
  multiple = true,
  isPdf = false,
  error,
  maxFileSize,
  setIsUploadCompleted,
  isPictureView,
  row,
  onChange,
  isUploading,
  UPLOADICON,
  showLoading,
  warningSize,
  fullWidth = true,
  showProgress,
  ...props
}) => {
  const [dragActive, setDragActive] = useState(false);
  const [progresses, setProgresses] = useState<any>({});
  const [loadingFile, setLoadingFile] = useState<File>(null);

  const inputRef = useRef<any>(null);

  const onButtonClick = () => {
    inputRef.current.click();
  };

  const fileAlreadyExists = (file: any) => {
    return files?.some((value: any) => {
      const fileNameAfterTimestamp = file?.filename?.split("-");
      fileNameAfterTimestamp.shift();

      const existingFileNameAfterTimestamp = value?.file?.filename?.split("-");
      existingFileNameAfterTimestamp.shift();

      return (
        fileNameAfterTimestamp.join() === existingFileNameAfterTimestamp.join()
      );
    });
  };

  const handleDeleteAttachment = (file: any) => {
    if (files && file) {
      setFiles &&
        setFiles([...files.filter((val: any) => file.fileId !== val.fileId)]);

      if (files?.length === 1) {
        onChange(null);
      }
    }
  };

  useEffect(() => {
    setProgresses((prevProgresses: any) => ({
      ...prevProgresses,
      [loadingFile?.name]: showLoading,
    }));
  }, [showLoading]);

  const simulateUpload = (file: File) => {
    setLoadingFile(file);
    setProgresses((prevProgresses: any) => ({
      ...prevProgresses,
      [file?.name]: true,
    }));
  };

  const updateFileFormat = (file: File) => {
    var ext = file?.name.split(".").pop();
    var file_name = new Date().getTime() + "-" + file?.name;

    const fileAttachment = {
      ContentType: file?.type,
      DocDesc: "Timecard Document",
      DocumentType: ext?.toUpperCase(),
      Encrypted: "No",
      filename: file_name,
      PWHash: "//",
      Salt: "GYUFYDTFKHFYV",
      Timestamp: new Date().getTime(),
      size: file?.size,
    };

    return fileAttachment;
  };

  const handleFileUpload = (file: File[]) => {
    const newFile = file[0];
    const fileName = newFile.name;
    const newFileId = newFile.name;
    const updatedNewFile = updateFileFormat(newFile);

    setUploadFile && setUploadFile(newFile);
    if (multiple) {
      if (fileAlreadyExists(updatedNewFile)) {
        toast(() => <Toast text={"File already exist"} type="error" />);
        return;
      }

      if (files?.length) {
        if (files?.length < 7) {
          setFiles &&
            setFiles([...files, { fileId: newFileId, file: updatedNewFile }]);
          onChange(newFile);
        } else {
          toast(() => <Toast subText={"Max. 7 files allowed."} type="error" />);
          return;
        }
      } else {
        setFiles && setFiles([{ fileId: newFileId, file: updatedNewFile }]);
        onChange(newFile);
      }
    } else {
      setFiles && setFiles([{ fileId: newFileId, file: updatedNewFile }]);
      onChange(newFile);
    }
    setProgresses((prev: any) => ({ ...prev, [fileName]: 0 }));
    simulateUpload(newFile);
  };

  const handleFile = (files: File[]) => {
    const file = files?.[0];
    const fileType = file?.type;
    const allowedFilesArray: string | string[] = allowedFiles;

    if (fileSizeCheckFunction(file?.size, maxFileSize || 7)) {
      toast(() => (
        <Toast
          subText={`Try uploading a file less than ${maxFileSize || "7"} MB`}
          type="error"
        />
      ));
      return;
    }
    //wARNING
    if (warningSize && fileSizeCheckFunction(file?.size, warningSize)) {
      toast(() => (
        <Toast
          subText={`Try uploading a file less than ${warningSize} MB`}
          type="warning"
        />
      ));
      return;
    }
    if (!validFileTypeCheckFunction(fileType, allowedFilesArray)) {
      toast(() => (
        <Toast
          subText={messages?.dragAndDrop?.error?.fileTypeError}
          type="error"
        />
      ));
      return;
    }
    handleFileUpload(files);
  };

  const handleChange = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setDragActive(false);
    if (setIsUploadCompleted) setIsUploadCompleted(false);
    const fileInput = e.target;
    const file = fileInput.files?.[0];

    if (file) {
      handleFile([file]);
      fileInput.value = null;
    }
  };

  const handleDrag = (e: DragEvent<any>) => {
    e.stopPropagation();
    e.preventDefault();
    if (setIsUploadCompleted) setIsUploadCompleted(false);
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setDragActive(false);

    const file = e.dataTransfer?.files;
    if (file && file.length > 0) {
      handleFile(file);
      inputRef.current.value = "";
    }
  };

  const viewContainerStyle: CSSProperties = row
    ? {
        gap: "10px",
        maxHeight: "221px",
        overflowY: "auto",
      }
    : {
        flexDirection: "column",
        gap: "10px",
        flexWrap: "nowrap",
      };
  return (
    <StyledContainerDirection row={row}>
      <StyledDragDropForm
        onDragEnter={handleDrag}
        onClick={onButtonClick}
        onDrop={handleDrop}
        setMaxWidthNone={files?.length !== 0}
      >
        <StyledInputFile
          ref={inputRef}
          type="file"
          multiple={multiple}
          onChange={handleChange}
          accept={acceptedFiles}
        />
        <StyledDragLabel htmlFor="input-file-upload" dragActive={dragActive}>
          <StyledDragLabelDiv>
            <StyledIcon
              width="80px"
              height="60px"
              src={"/assets/images/uploadIcon.svg"}
              alt="upload"
              draggable={false}
            />
            <StyledText>
              <FixedTitle varient="Fixed16Medium" style={{ padding: "5px" }}>
                {messages?.dragAndDrop?.dragText}
                <StyledUploadButtonText>
                  {messages?.dragAndDrop?.browse}
                </StyledUploadButtonText>
              </FixedTitle>
              <FixedTitle
                varient="Fixed12Regular"
                color={greyScaleColour?.grey100}
                style={{ padding: "5px" }}
              >
                {messages?.dragAndDrop?.supported}
              </FixedTitle>
            </StyledText>
          </StyledDragLabelDiv>
        </StyledDragLabel>
        {dragActive && (
          <StyledDragActiveText
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          />
        )}
      </StyledDragDropForm>
      {files && files?.length !== 0 && (
        <Grid container style={{ ...viewContainerStyle, padding: "10px 5px" }}>
          {files
            .sort((a: any, b: any) => b.file.Timestamp - a.file.Timestamp)
            .map((file: any) => {
              const fileValue = file?.fileId;
              let srcImage: string;
              if (file.file.DocumentType === "PDF") {
                srcImage = "/assets/images/pdfIcon.svg";
              } else if (file.file.DocumentType === "PNG") {
                srcImage = "/assets/images/pngIcon.svg";
              } else {
                srcImage = "/assets/images/jpgIcon.svg";
              }
              return (
                <Grid item xs={!fullWidth ? 9 : 12} key={file?.filename}>
                  <OutlinedContainer
                    padding="16px"
                    borderRadius="10px"
                    borderColor={greyScaleColour?.grey90}
                  >
                    <Grid container xs={12}>
                      <Grid item xs={10}>
                        <StyledRow gap="8px" style={{justifyContent: 'flex-start'}}>
                          <StyledIcon src={srcImage} />
                          <StyledColumn>
                            <FixedTitle
                              varient="Fixed14Medium"
                              style={{
                                width: "100%",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                wordBreak: "break-word"
                              }}
                            >
                              {file?.file?.filename}
                            </FixedTitle>
                            {file.file.size && (
                              <FixedTitle varient="Fixed12Regular">
                                {(file.file.size / 1024 / 1024).toFixed(2)}MB
                              </FixedTitle>
                            )}
                          </StyledColumn>
                        </StyledRow>
                      </Grid>
                      <Grid item xs={2} alignSelf={"center"} textAlign={"right"}>
                        <CloseIcon
                          style={iconStyle}
                          onClick={() => handleDeleteAttachment(file)}
                        />
                      </Grid>
                    </Grid>
                    {showProgress && <Grid container xs={12} gap={"4px"}>
                      {progresses?.[fileValue] && (
                        <Grid item xs={12} marginTop={1}>
                          <LinearProgress />
                        </Grid>
                      )}
                    </Grid>}
                  </OutlinedContainer>
                </Grid>
              );
            })}
        </Grid>
      )}

      {error && <StyledError variant="body2">{error}</StyledError>}
    </StyledContainerDirection>
  );
};

export default DragDropComponent;
