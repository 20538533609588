import styled from "styled-components";
import { Typography } from "@mui/material";
import { colors,greyScaleColour } from "@tphglobal/common/theme/style.palette";
import { FormControlLabel } from '@mui/material';
import {
  fontSize,
  fontWeight,
  baseFontFamily,
} from '@tphglobal/common/theme/style.typography';

export const StyledContainer = styled.div`
  display: flex;
  width: 100%;
  .MuiFormControlLabel-root {
    width: 100% !important;
  }
`;

export const StyledError = styled(Typography)`
  margin-top: 8px !important;
  color: ${colors.red100};
`;


export const StyledFormControlLabel = styled(FormControlLabel)`
  margin-left: 0px !important;
  .MuiFormControlLabel-label {
    font-size: ${fontSize.b1};
    color: ${greyScaleColour.black100};
    line-height: 18px;
  }
`;