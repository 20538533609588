import { Typography } from '@mui/material';
import { colors } from '@tphglobal/common/theme/style.palette';
import styled from 'styled-components';

export const StyledHeading = styled(Typography)`
`;

export const StyledSearch = styled.div`
display: flex;
align-items: center;
align-self: center;
gap: 3px;
cursor: pointer;
`;

export const StyledColumn = styled.div<{cursor ?: boolean}>`
    display: flex;
    flex-direction: column;
    cursor: ${({ cursor }) => cursor && 'pointer'};
`;
export const StyledRow = styled.div`
    display: flex;
    align-items: stretch;
    gap: 4px;
`;
export const StyledError = styled(Typography)`
  text-align: left;
  color: ${colors.red100} !important;
  margin-top: 8px !important;
`;
export const Column = styled.div`
    display: flex;
    width : auto;
    flex-direction : column;
`;
