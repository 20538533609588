import React from 'react';
import { Grid, Modal as MuiModal } from '@mui/material';
import Card from '../card';
import {
  StyledContainer,
  StyledHeaderContainer,
  StyledSubHeading,
  StyledHeadingImgContainer,
  StyledHeadingImg,
  StyledCloseContainer,
  StyledButtonContainer,
  StyledHeading,
  StyledRow,
  StyledChip,
  StyledChildren,
  StyledColumn,
} from './styles';
import CloseIcon from '@mui/icons-material/Close';
import { fontSize } from '@tphglobal/common/theme/style.typography';
import {
  brand,
  brandColour,
  greyScaleColour,
  otherColour,
} from '@tphglobal/common/theme/style.palette';
import { FixedTitle } from '@tphglobal/common/utils';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import messages from '../messages';
import useWindowSize from '@tphglobal/common/hooks/windowSize';
import { breakpoints } from '@tphglobal/common/theme/style.layout';

interface ModalCustomProps {
  children?: JSX.Element | JSX.Element[];
  show?: boolean;
  onClose?: () => void;
  heading?: string;
  headingImgSrc?: string;
  subHeading?: string;
  fitContent?: boolean;
  noCloseIcon?: boolean;
  isCreateOrEditForm?: boolean;
  icon?: any;
  chipLabel?: string;
  subHeadingLink?: boolean;
  deleteForm?: boolean;
  maxWidth?: string;
  maxHeight?: string;
  isPdf?: boolean;
  handleHeadingClick?: () => void;
}

const iconStyles = {
  height: fontSize?.h3,
  width: fontSize?.h3,
  fill: greyScaleColour?.black100,
};
const headerIcon = {
  height: fontSize?.h1,
  width: fontSize?.h1,
  fill: brand?.secondaryMain,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
const headerMobileIcon = {
  height: fontSize?.h3,
  width: fontSize?.h3,
  fill: brand?.secondaryMain,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const subHeadingIconStyles = {
  height: fontSize?.h5,
  width: fontSize?.h5,
  fill: brandColour?.primary100,
  cursor: 'pointer',
};
const Modal = ({
  children,
  heading,
  show,
  onClose,
  subHeading,
  fitContent,
  deleteForm,
  isCreateOrEditForm = false,
  noCloseIcon = false,
  chipLabel,
  subHeadingLink,
  icon,
  maxWidth,
  maxHeight,
  isPdf,
  handleHeadingClick,
}: Readonly<ModalCustomProps>) => {
  const { width } = useWindowSize();

  const isMobile = width <= breakpoints.mobile;
  return (
    <MuiModal
      open={!!show}
      onClose={(event: React.MouseEvent<HTMLElement>, reason: string) => {
        if (
          isCreateOrEditForm &&
          (reason === 'escapeKeyDown' || reason === 'backdropClick')
        ) {
          event.preventDefault();
        } else {
          onClose();
        }
      }}
    >
      <StyledContainer fitContent={fitContent} maxWidth={maxWidth}>
        <Card
          contentCss={{
            overflowY: 'auto',
            maxHeight: maxHeight ? maxHeight : '852px',
            padding: 0,
          }}
          cardCss={{
            borderRadius: '10px',
            padding: isPdf ? '24px 0' : isMobile ? '16px 0 0 0' : '24px 0 0 0',
            background: `${greyScaleColour?.white100} !important`,
          }}
          noHeaderPadding
          noContentPadding
          noBottomMargin
          header={
            <StyledHeaderContainer>
              <Grid container rowSpacing={'8px'} xs={12}>
                <Grid item xs={12}>
                  <Grid
                    container
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <Grid item display={'flex'} gap={'6px'}>
                      {icon &&
                        React.cloneElement(icon, {
                          style: {
                            ...(isMobile ? headerMobileIcon : headerIcon),
                            fill: deleteForm
                              ? otherColour?.errorDefault
                              : brand?.secondaryMain,
                          },
                        })}
                      {heading && (
                        <StyledHeading
                          color={deleteForm && otherColour?.errorDefault}
                        >
                          {heading}
                        </StyledHeading>
                      )}
                      {chipLabel && (
                        <StyledChip>
                          <FixedTitle varient='Fixed12Medium'>
                            {chipLabel}
                          </FixedTitle>
                        </StyledChip>
                      )}
                    </Grid>
                    <Grid item>
                      {!noCloseIcon && (
                        <StyledCloseContainer onClick={onClose}>
                          <CloseIcon style={iconStyles} />
                        </StyledCloseContainer>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                {subHeading && (
                  <Grid item xs={12}>
                    <StyledSubHeading variant='body1'>
                      {subHeading}
                    </StyledSubHeading>
                  </Grid>
                )}
                {subHeadingLink && (
                  <Grid item>
                    <StyledRow>
                      <ContentCopyIcon style={subHeadingIconStyles} />
                      <FixedTitle
                        onClick={handleHeadingClick}
                        varient='Fixed12Medium'
                        color={brandColour?.primary100}
                        style={{ cursor: 'pointer' }}
                      >
                        {messages?.timeCard?.updateTime?.copy}
                      </FixedTitle>
                    </StyledRow>
                  </Grid>
                )}
              </Grid>
            </StyledHeaderContainer>
          }
        >
          <StyledChildren removeOverflow={isPdf} deleteForm={deleteForm}>
            {children}
          </StyledChildren>
        </Card>
      </StyledContainer>
    </MuiModal>
  );
};

export default Modal;
