import React from 'react';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { StyledDatePicker, StyledInputContainer } from './styles';
import { brandColour, colors, greyScaleColour } from '@tphglobal/common/theme/style.palette';
import { StyledError } from '../materialTextInput/styles';

interface Props {
  label?: string;
  value?: string;
  onChange?: any;
  error?: string;
  disableErrorMode?: boolean;
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  dateFormat?: string;
  onReadOnlyCtaClick?: () => void;
  onlyVisibleDay?: string;
}

const DateInput: React.FC<Props> = ({
  label,
  error,
  value,
  onChange,
  disableErrorMode,
  required,
  readOnly,
  disabled,
  fullWidth,
  dateFormat,
  onReadOnlyCtaClick,
  onlyVisibleDay,
  ...props
}) => {
  const shouldDisableDate = (date: Date) => {
    if (!onlyVisibleDay) return false;

    const dayOfWeek = new Date(date).getDay();
    const daysOfWeekMap: any = {
      Sunday: 0,
      Monday: 1,
      Tuesday: 2,
      Wednesday: 3,
      Thursday: 4,
      Friday: 5,
      Saturday: 6,
    };
    return dayOfWeek !== daysOfWeekMap[onlyVisibleDay];
  };

  return (
    <StyledInputContainer>
      <StyledDatePicker
        {...props}
        label={label}
        format={dateFormat || 'DD/MM/YYYY'}
        fullWidth={fullWidth}
        disabled={disabled}
        value={value || null}
        onChange={(newValue) => {
          if (onChange) {
            onChange(newValue);
          }
        }}
        shouldDisableDate={shouldDisableDate}
        slotProps={{
          textField: {
            required,
            error: disableErrorMode ? undefined : !!error,
          },
          desktopPaper: {
            sx: {
              '.MuiPickersYear-yearButton.Mui-selected': {
                backgroundColor: `${brandColour.primary100} !important`,
                color: `${colors.white} !important`,
              },
              '.MuiPickersMonth-monthButton.Mui-selected': {
                backgroundColor: `${brandColour.primary100} !important`,
                color: `${colors.white} !important`,
              },
              '.MuiPickersDay-root.Mui-selected': {
                backgroundColor: `${brandColour.primary100} !important`,
                color: `${colors.white} !important`,
              },
            },
          },
        }}
        slots={{
          openPickerIcon: () => (
            <CalendarMonthOutlinedIcon
              style={{
                color: greyScaleColour?.black100,
                width: '21px',
                height: '21px',
              }}
            />
          ),
        }}
      />
      {!disableErrorMode && error && (
        <StyledError variant='body2'>{error || ''}</StyledError>
      )}
    </StyledInputContainer>
  );
};

export default DateInput;
