import React from 'react';
import Table from '@tphglobal/components/table';
import { useDispatch } from 'react-redux';
import { FixedTitle } from '@tphglobal/common/utils';
import { brand } from '@tphglobal/common/theme/style.palette';
import { Modal } from '@tphglobal/components';
import { usePopupReducer } from '@tphglobal/common/hooks';
import { StyledHeading } from './styles';
import messages from '../../messages';
import Creating from './creating';

const Guides = () => {
  const reduxDispatch = useDispatch();

  const {
    visibility: creatingFormVisibility,
    showPopup: showCreatingForm,
    hidePopup: hideCreatingForm,
    metaData: creatingMetaData,
  } = usePopupReducer<{heading : string}>();

  return (
    <>
      <Table
        data={[
          {
            guideTitle: 'Creating a TimeCard',
          },
          {
            guideTitle: 'Creating a Company',
          },
        ]}
        actions={[
          {
            id: 'view',
            component: (
              <FixedTitle varient="Fixed12Regular" color={brand?.secondaryMain}>
                {messages?.general?.view}
              </FixedTitle>
            ),
            onClick: (row: {guideTitle : string}) => {
              showCreatingForm({ heading: row?.guideTitle });
            },
          },
        ]}
        specs={[
          {
            id: 'guideTitle',
            label: messages?.support?.table?.guideTitle,
          },
        ]}
      />
      <Modal
        show={creatingFormVisibility}
        heading={creatingMetaData?.heading}
        onClose={hideCreatingForm}
        fitContent
        maxWidth="1212px"
      >
        <Creating
          type={
            creatingMetaData?.heading === 'Creating a TimeCard'
              ? 'timeCard'
              : 'company'
          }
        />
      </Modal>
    </>
  );
};

export default Guides;
