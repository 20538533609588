import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import { useFormReducer } from '@tphglobal/common/hooks';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  Form,
  FormError,
  FormRow,
  FormRowItem,
  MaterialTextInput,
  OutlinedContainer,
  Toast,
} from '@tphglobal/components';
import {
  brand,
  greyScaleColour,
  otherColour,
} from '@tphglobal/common/theme/style.palette';
import { FixedTitle } from '@tphglobal/common/utils';
import { fontSize, fontWeight } from '@tphglobal/common/theme/style.typography';
import { Container } from '../../../components';
import messages from '../../../messages';
import { emailValidator, emptyValueValidator, required } from '../../../utils';
import { StyledHeading, Column } from '../styles';
import { Validators } from '../../../models';

interface Props {
  setSubmitTimeCardScreen : (submitTimeCardScreen : boolean) => void,
  email : string
  handleTimeCardSubmit :(data: {email : string}) => Promise<unknown>
  submittingTimeCard : boolean
}

const validators : Validators = {
  email: [required(messages?.general?.validations?.emailRequired), emailValidator],
};

const headerIconStyles = {
  height: fontSize?.h1,
  width: fontSize?.h1,
  fill: brand?.secondaryMain,
};

const DigitalTimeSheetMobile: React.FC<Props> = ({
  setSubmitTimeCardScreen, email, handleTimeCardSubmit, submittingTimeCard,
}) => {
  const {
    submitting,
    submitError,
    handleSubmit,
    connectField,
    change,
    setSubmitError,
  } = useFormReducer(validators);

  useEffect(() => {
    change('email', email);
  }, []);

  return (
    <Container
      showGoBack
      heading={messages?.timeCard?.form?.subHeading}
      background={otherColour?.adminBg100}
      maxHeight
    >
      <Card
        showGoBack
        noContentPadding
        removeTitle
        contentCss={{ padding: '24px 16px !important' }}
        header={(
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <StyledHeading
                fontSize={fontSize?.h2}
                fontWeight={fontWeight?.semiBold}
              >
                {messages?.timeCard?.form?.heading}
              </StyledHeading>
            </Grid>
          </Grid>
        )}
      >
        <Form onSubmit={handleSubmit(handleTimeCardSubmit)}>
          <Grid container rowSpacing={2}>
            <Grid item xs={12}>
              <Column gap="6px">
                <TextSnippetOutlinedIcon style={headerIconStyles} />
                <StyledHeading
                  fontSize={fontSize?.h5}
                  fontWeight={fontWeight?.semiBold}
                  lineHeight="21px"
                >
                  {messages?.timeCard?.digitalTimesheet?.heading}
                </StyledHeading>
                <FixedTitle
                  varient="Fixed12Regular"
                  color={greyScaleColour?.grey100}
                >
                  {messages?.timeCard?.digitalTimesheet?.subHeading}
                </FixedTitle>
              </Column>
            </Grid>
            <Grid item xs={12}>
              {connectField('email', {
                label: messages?.timeCard?.digitalTimesheet?.email,
              })(MaterialTextInput)}
            </Grid>
            <Grid item xs={12}>
              <FixedTitle
                varient="Fixed12Regular"
                color={otherColour?.errorDefault}
              >
                {messages?.timeCard?.digitalTimesheet?.tnc}
              </FixedTitle>
            </Grid>
            {submitError && (
              <FormRow>
                <FormRowItem>
                  <FormError
                    message={
                      messages?.departments?.addForm?.failure?.[submitError]
                    }
                  />
                </FormRowItem>
              </FormRow>
            )}
          </Grid>
          <OutlinedContainer
            noBorder
            borderRadius="0"
            padding="16px 0"
            styles={{
              backgroundColor: greyScaleColour?.grey60,
              marginTop: '16px',
            }}
          >
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Grid container spacing={2}>
                  <Grid item>
                    <Button
                      variant="outlined"
                      buttonColor={brand?.black}
                      color="primary"
                      size="large"
                      onClick={() => setSubmitTimeCardScreen(false)}
                      label={messages?.general?.cancel}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="large"
                      type="submit"
                      disabled={submittingTimeCard}
                      label={messages?.general?.submit}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </OutlinedContainer>
        </Form>
      </Card>
    </Container>
  );
};

export default DigitalTimeSheetMobile;
