import React from 'react';
import { CheckBoxInput, MaterialAutocompleteInput, TextInput } from '@tphglobal/components';
import { mapIdNameToOption } from '@tphglobal/common/utils';
import { CustomField, CustomFieldTypes } from '../../models';
import { toCamelCase } from '../../utils/commonFunctions';

interface Props {
  field: CustomField;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  connectField: any;
}

const CustomFieldComponent: React.FC<Props> = ({ field, connectField }) => {
  const getInputComponent = () => {
    switch (field?.Type) {
      case CustomFieldTypes.Text:
      case CustomFieldTypes.String:
      case CustomFieldTypes.Numeric:
        return TextInput;
      case CustomFieldTypes.DropList:
        return MaterialAutocompleteInput;
      case CustomFieldTypes.Checkbox:
        return CheckBoxInput;
      default:
        return TextInput;
    }
  };

  const InputComponent = getInputComponent();
  const optionsArray = (field?.defaultValue?.split('|'));
  const dropListOptions = optionsArray?.map((item, index) => ({
    id: index + 1,
    label: item,
  }));

  return connectField(toCamelCase(field?.Prompt), {
    label: field?.Prompt,
    required: field?.required === 1 && field?.required,
    defaultValue: field?.defaultValue,
    readonly: field?.readonly,
    hide: field?.hide,
    options: dropListOptions?.map(mapIdNameToOption),
  })(InputComponent);
};

export default CustomFieldComponent;
