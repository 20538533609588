/* eslint-disable camelcase */
import { Grid } from '@mui/material';
import { Button, Modal, OutlinedContainer } from '@tphglobal/components';
import React, { useEffect } from 'react';
import { fontSize, fontWeight } from '@tphglobal/common/theme/style.typography';
import { greyScaleColour } from '@tphglobal/common/theme/style.palette';
import EditOutlineIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { usePopupReducer } from '@tphglobal/common/hooks';
import { RegionTypes, timeOptions } from '@tphglobal/common/utils/constants';
import { HttpMethods } from '@tphglobal/common/utils';
import { Option } from '@tphglobal/common/models';
import messages from '../../../messages';
import { routes } from '../../../utils';
import { iconStyles } from '../../../utils/constant';
import EditInformation from './editInformationForm';
import { ReduxState } from '../../../redux/reducers';
import handleApiCall from '../../handleApiCall';
import { GET_PROFILE_PIC_AU, GET_PROFILE_PIC_UK } from '../../../api';
import { postLogin } from '../../../redux/actions';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import {
  ProfileDetails,
  ProfileDetailsWrapper,
  ProfileImage,
  Row,
  StyledHeading,
  VerticalLine,
} from '../styles';

interface Props {
  isMobile?: boolean;
  fetchProfileOnSuccess?: () => void;
}

const ProfileInformation: React.FC<Props> = ({
  isMobile,
  fetchProfileOnSuccess,
}) => {
  const reduxDispatch = useDispatch();
  const userProfile = useSelector((state: ReduxState) => state?.profile);
  const selectedRegion = userProfile?.region;
  let phone = userProfile?.MobileNumber?.substring(0,1) === '+' ? userProfile?.MobileNumber : '+'.concat(userProfile?.MobileNumber)
  const user_name = `${userProfile?.FirstName} ${userProfile?.LastName}`;

  const {
    visibility: infoFormVisibility,
    showPopup: showInfoForm,
    hidePopup: hideInfoForm,
  } = usePopupReducer();

  const handleEditProfile = () => {
    if (isMobile) {
      reduxDispatch(push(routes?.editInfoMobile));
    } else {
      showInfoForm();
    }
  };

  const fetchProfileImage = () => {
    handleApiCall(
      reduxDispatch,
      selectedRegion === RegionTypes.AU
        ? GET_PROFILE_PIC_AU
        : GET_PROFILE_PIC_UK,
      (response) => {
        reduxDispatch(postLogin({ profile_pic: response?.profile_pic ? response?.profile_pic : null }));
      },
      HttpMethods?.GET,
      selectedRegion === RegionTypes.AU ? RegionTypes.AU : RegionTypes.UK,
    );
  };

  useEffect(() => {
    fetchProfileImage();
  }, []);

  return (
    <>
      <OutlinedContainer>
        <Grid
          container
          justifyContent="space-between"
          rowSpacing="17px"
          columnSpacing={2}
        >
          <Grid xs={isMobile && 12} item alignSelf="center">
            <ProfileDetailsWrapper>
              <ProfileImage src={userProfile?.profile_pic ? userProfile?.profile_pic : '/assets/images/profile_pic.png'} />
              <ProfileDetails gap="4px">
                <StyledHeading
                  lineHeight="20px"
                  fontWeight={fontWeight?.semiBold}
                  fontSize={fontSize?.h4}
                >
                  {user_name}
                </StyledHeading>
                <StyledHeading color={greyScaleColour?.grey100}>
                  {userProfile?.EmailAddress}
                </StyledHeading>
                <StyledHeading color={greyScaleColour?.grey100}>
                  {`+${parsePhoneNumberFromString(phone)?.countryCallingCode} ${parsePhoneNumberFromString(phone)?.nationalNumber}`}
                </StyledHeading>
              </ProfileDetails>
            </ProfileDetailsWrapper>
          </Grid>
          {!isMobile && (
          <Grid item>
            <VerticalLine />
          </Grid>
          )}
          <Grid xs={isMobile && 6} item alignSelf="center">
            <ProfileDetails gap="8px">
              <Row gap="4px">
                <StyledHeading
                  fontWeight={fontWeight?.medium}
                  lineHeight="18px"
                >
                  {messages?.profile?.timeOption}
                </StyledHeading>
                <StyledHeading lineHeight="18px">
                  {timeOptions?.find((option : Option) => option?.id === userProfile?.TimeOption)?.label}
                </StyledHeading>
              </Row>
              <Row gap="4px">
                <StyledHeading
                  fontWeight={fontWeight?.medium}
                  lineHeight="18px"
                >
                  {messages?.profile?.minutesStep}
                </StyledHeading>
                <StyledHeading lineHeight="18px">
                  {userProfile?.MinutsStep}
                </StyledHeading>
              </Row>
            </ProfileDetails>
          </Grid>
          {!isMobile && (
          <Grid item>
            <VerticalLine />
          </Grid>
          )}
          <Grid xs={isMobile && 6} item alignSelf="center">
            <Button
              onClick={handleEditProfile}
              variant="outlined"
              color="primary"
              size="large"
              label={messages?.profile?.editInfo}
              icon={<EditOutlineIcon style={iconStyles} />}
            />
          </Grid>
        </Grid>
      </OutlinedContainer>
      <Modal
        icon={<PermIdentityIcon />}
        show={infoFormVisibility}
        heading={messages?.profile?.editForm?.heading}
        onClose={hideInfoForm}
        fitContent
      >
        <EditInformation
          onCancel={hideInfoForm}
          onSuccess={() => {
            fetchProfileImage();
            fetchProfileOnSuccess();
            hideInfoForm();
          }}
        />
      </Modal>
    </>
  );
};

export default ProfileInformation;
