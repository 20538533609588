export const breakpoints = {
  mobile: 767,
  tablet: 1279,
  smallLaptop: 1439,
  desktop: 1919,
  largeDesktop: 2560,
};

export const respondTo = {
  // Mobile devices in portrait mode (<= 767px)
  mobileOnlyPortrait: `@media only screen and (max-width: ${breakpoints.mobile}px) and (orientation: portrait)`,
  // Mobile devices in landscape mode
  mobileOnlyLandscape: `@media only screen and (max-width: ${breakpoints.mobile}px) and (orientation: landscape)`,
  
  // Tablets in portrait mode (768px - 1279px)
  tabletOnlyPortrait: `@media only screen and (min-width: ${breakpoints.mobile + 1}px) and (max-width: ${breakpoints.tablet}px)`,
  // Tablets in landscape mode
  tabletOnlyLandscape: `@media only screen and (min-width: ${breakpoints.mobile + 1}px) and (max-width: ${breakpoints.tablet}px) and (orientation: landscape)`,
  
  // Tablets and above (>= 768px)
  tabletUp: `@media only screen and (min-width: ${breakpoints.mobile + 1}px)`,

  // Small laptops only (1280px - 1439px)
  smallLaptopOnly: `@media only screen and (min-width: ${breakpoints.tablet + 1}px) and (max-width: ${breakpoints.smallLaptop}px)`,
  // Small laptops and above (>= 1280px)
  smallLaptopUp: `@media only screen and (min-width: ${breakpoints.tablet + 1}px)`,

  // Desktop only (1440px - 1919px)
  desktopOnly: `@media only screen and (min-width: ${breakpoints.smallLaptop + 1}px) and (max-width: ${breakpoints.desktop}px)`,
  // Desktop and above (>= 1440px)
  desktopUp: `@media only screen and (min-width: ${breakpoints.smallLaptop + 1}px)`,

  // Large desktop only (1920px - 2560px)
  largeDesktopOnly: `@media only screen and (min-width: ${breakpoints.desktop + 1}px) and (max-width: ${breakpoints.largeDesktop}px)`,
  // Large desktop and above (>= 1920px)
  largeDesktopUp: `@media only screen and (min-width: ${breakpoints.desktop + 1}px)`,

  // Extra large screens (>= 2560px)
  xlUp: `@media only screen and (min-width: ${breakpoints.largeDesktop + 1}px)`,

  screenDown: sizeInPixel => `@media only screen and (max-width: ${sizeInPixel}px)`,
  screenUp: sizeInPixel => `@media only screen and (min-width: ${sizeInPixel}px)`,
  screenRange: (sizeInPixelL, sizeInPixelH) =>
    `@media only screen and (min-width: ${sizeInPixelL}px) and (max-width: ${sizeInPixelH}px)`,
  screenHeight: sizeInPixel => `@media only screen and (max-height: ${sizeInPixel}px)`,
};

// Z-Index and Interaction Constants
export const constants = {
  zIndex: 1001,
  interactionTypeRowSpan: 4,
};

// Grid Configuration
export const grid = {
  gridRowHeight: 9,
  gridGutterWidth: 20,
  gridColumnWidth: 60,
  gridContainerWidth: 1320,
};
