import styled from 'styled-components';
import { Chip } from '@mui/material';
import { brand, brandColour } from '@tphglobal/common/theme/style.palette';

export const StyledChip = styled(Chip)<{large?: boolean}>`
    width: fit-content !important;
    background-color: ${brandColour?.primary20} !important;
    padding: ${({large}) => large ? '6px 8px' : '4px 6px' } !important;
    color: ${brand?.secondaryMain} !important;
    border-radius: 6px !important;
`;
