// FloatingButton.tsx
import React from 'react';
import { FabProps } from '@mui/material/Fab';
import { Button } from './styles';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { brand } from '@tphglobal/common/theme/style.palette';

interface FloatingButtonProps extends Omit<FabProps, 'color' | 'size'> {
  icon?: React.ReactNode;
  color?: 'default' | 'inherit' | 'primary' | 'secondary';
  size?: 'small' | 'medium' | 'large';
  ariaLabel: string;
  left?: string;
  bottom?: string;
  top?: string;
  right?: string;
}

const FloatingButton: React.FC<FloatingButtonProps> = ({
  icon,
  onClick,
  color = 'primary',
  size = 'medium',
  ariaLabel,
  left,
  bottom,
  top,
  TouchRippleProps,
  right,
  ...rest
}) => {
  return (
    <Button
      size={size}
      color={color}
      onClick={onClick}
      aria-label={ariaLabel}
      left={left}
      right={right}
      top={top}
      bottom={bottom}
      {...rest}
    >
      {icon ? (
        icon
      ) : (
        <HelpOutlineIcon
          style={{ height: '26px', width: '26px', fill: brand?.secondaryMain }}
        />
      )}
    </Button>
  );
};

export default FloatingButton;
