import React, { useEffect, useState } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Box from '@mui/material/Box';
import { greyScaleColour } from '@tphglobal/common/theme/style.palette';
import { DailyTime } from '../../../models';
import { truncateText } from '../../../utils/commonFunctions';

interface Props {
  setWeekData: (update: (weekData: DailyTime[]) => DailyTime[]) => void;
  fieldKey: keyof DailyTime;
  rowIndex: number;
  value?: string;
}

const TextInputField: React.FC<Props> = ({
  setWeekData,
  fieldKey,
  rowIndex,
  value,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState<string>(value);

  const handleClick = () => {
    setIsEditing(true);
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    setWeekData((prevWeekData: DailyTime[]) => {
      const newWeekData: DailyTime[] = [...prevWeekData];

      if (newWeekData[rowIndex]) {
        newWeekData[rowIndex] = {
          ...newWeekData[rowIndex],
          [fieldKey]: event.target.value,
        };
      }

      return newWeekData;
    });
  };

  const handleClickAway = () => {
    if (isEditing) {
      setIsEditing(false);
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box position="relative" display="inline-block">
        {isEditing ? (
          <ClickAwayListener onClickAway={handleClickAway}>
            <input
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              onBlur={() => setIsEditing(false)}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              style={{
                border: 'none',
                backgroundColor: 'transparent',
              }}
            />
          </ClickAwayListener>
        ) : (
          <Box
            component="span"
            onClick={handleClick}
            sx={{
              cursor: 'pointer',
              color: greyScaleColour?.grey100,
            }}
          >
            {truncateText(inputValue, 30)}
          </Box>
        )}
      </Box>
    </ClickAwayListener>
  );
};

export default TextInputField;
