import {
  Grid,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import {
  Button,
  Modal,
  ModalAction,
  OutlinedContainer,
  Toast,
} from '@tphglobal/components';
import React, { useEffect, useState } from 'react';
import { fontSize, fontWeight } from '@tphglobal/common/theme/style.typography';
import {
  brand,
  greyScaleColour,
  otherColour,
} from '@tphglobal/common/theme/style.palette';
import EditOutlineIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useDispatch, useSelector } from 'react-redux';
import { usePopupReducer } from '@tphglobal/common/hooks';
import { FixedTitle, HttpMethods } from '@tphglobal/common/utils';
import { toast } from 'react-toastify';
import { RegionTypes } from '@tphglobal/common/utils/constants';
import { iconStyles } from '../../../utils/constant';
import Overlay from '../overlay';
import UpdateSignature from './updateSignature';
import handleApiCall from '../../handleApiCall';
import { ReduxState } from '../../../redux/reducers';
import {
  EmptyBackground,
  Icons,
  Image,
  Placeholder,
  Row,
  StyledDiv,
  StyledHeading,
} from '../styles';
import messages from '../../../messages';
import {
  CLEAR_SIGNATURE_AU,
  CLEAR_SIGNATURE_UK,
  GET_SIGNATURE_AU,
  GET_SIGNATURE_UK,
  UPLOAD_SIGNATURE_AU,
  UPLOAD_SIGNATURE_UK,
} from '../../../api';

interface Props {
  isMobile?: boolean;
}

interface SignatureBody {
  base64Data ?: string
  Typed ?: boolean
}

const SignatureDesktop: React.FC<Props> = ({ isMobile }) => {
  const reduxDispatch = useDispatch();
  const selectedRegion = useSelector(
    (state: ReduxState) => state?.profile?.region,
  );
  const [signature, setSignature] = useState({
    drawnSignature: '',
    typedSignature: '',
  });
  const [imageLoaded, setImageLoaded] = useState(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = React.useState('1');
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };
  const {
    visibility: editSignatureFormVisibility,
    showPopup: showEditSignatureForm,
    hidePopup: hideEditSignatureForm,
    metaData: editSignatureMetadata,
  } = usePopupReducer<{type : string}>();

  const {
    visibility: deleteSignatureVisibility,
    showPopup: showSignaturedeleteForm,
    hidePopup: hidedeleteSignatureForm,
    metaData: deleteSignatureFormMetadata,
  } = usePopupReducer<{type : string}>();

  const controlProps = (item: string) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    inputProps: { 'aria-label': item },
  });

  const fetchUserSignatures = () => {
    handleApiCall(
      reduxDispatch,
      selectedRegion === RegionTypes.AU ? GET_SIGNATURE_AU : GET_SIGNATURE_UK,
      (res) => {
        setSignature({
          drawnSignature: res?.result?.signature,
          typedSignature: res?.result?.typed_signature,
        });
      },
      HttpMethods.GET,
      selectedRegion === RegionTypes.AU ? RegionTypes.AU : RegionTypes.UK,
    );
  };

  useEffect(() => {
    fetchUserSignatures();
  }, []);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const handleImageError = () => {
    setImageLoaded(false);
  };

  const handleUpdateSignature = (body: SignatureBody, type: string, onSuccess ?: () => void, toast?: string) => {
    handleApiCall(
      reduxDispatch,
      selectedRegion === RegionTypes.AU
        ? UPLOAD_SIGNATURE_AU
        : UPLOAD_SIGNATURE_UK,
      () => {
        if (onSuccess) {
          onSuccess();
        } else {
          hideEditSignatureForm();
        }
        fetchUserSignatures();
      },
      HttpMethods.POST,
      selectedRegion === RegionTypes.AU ? RegionTypes.AU : RegionTypes.UK,
      toast || messages?.profile?.signature?.editForm?.success?.[type],
      body,
    );
  };

  const handleClearSignature = () => {
    setSubmitting(true);
    const sanitizedBody = deleteSignatureFormMetadata?.type === 'typed' ? { Typed: true } : {Typed: false};
    handleApiCall(
      reduxDispatch,
      selectedRegion === RegionTypes.AU
        ? CLEAR_SIGNATURE_AU
        : CLEAR_SIGNATURE_UK,
      () => {
        setSubmitting(false);
        hidedeleteSignatureForm();
        fetchUserSignatures()
      },
      HttpMethods.DELETE,
      selectedRegion === RegionTypes.AU ? RegionTypes.AU : RegionTypes.UK,
      messages?.profile?.signature?.deleteForm?.success,
      sanitizedBody,
      null,
      (errMessage : string) => {
        toast(<Toast type='error' text={errMessage} />)
        setSubmitting(false)
        hidedeleteSignatureForm()
      }
    );
  };

  return (
    <>
      <OutlinedContainer>
        <Grid rowSpacing={2} container justifyContent="space-between">
          <Grid xs={12} item alignSelf="center">
            <StyledHeading
              fontWeight={fontWeight?.semiBold}
              fontSize={fontSize?.h5}
            >
              {messages?.profile?.signature?.heading}
            </StyledHeading>
            <StyledHeading
              color={greyScaleColour?.grey100}
              fontSize={fontSize?.b2}
            >
              {messages?.profile?.signature?.subHeading}
            </StyledHeading>
          </Grid>
          <Grid item>
            <Grid container alignItems="center" columnGap="10px">
              <Grid item>
                <StyledHeading
                  fontSize={fontSize?.h5}
                  fontWeight={fontWeight?.semiBold}
                  lineHeight="18px"
                >
                  {messages?.general?.select}
                </StyledHeading>
              </Grid>
              <Grid item paddingLeft={1}>
                <FormControl>
                  <RadioGroup row name="radio-buttons-group">
                    <FormControlLabel
                      value="1"
                      control={(
                        <Radio
                          {...controlProps('1')}
                          sx={{
                            '& .MuiSvgIcon-root': {
                              fontSize: 20,
                            },
                          }}
                        />
                      )}
                      label={(
                        <StyledHeading
                          fontSize={fontSize?.h5}
                          fontWeight={fontWeight?.semiBold}
                          lineHeight="42px"
                        >
                          {messages?.profile?.signature?.draw}
                        </StyledHeading>
                      )}
                    />
                    <FormControlLabel
                      value="2"
                      control={(
                        <Radio
                          {...controlProps('2')}
                          sx={{
                            '& .MuiSvgIcon-root': {
                              fontSize: 20,
                            },
                          }}
                        />
                      )}
                      label={(
                        <StyledHeading
                          fontSize={fontSize?.h5}
                          fontWeight={fontWeight?.semiBold}
                          lineHeight="42px"
                        >
                          {messages?.profile?.signature?.type}
                        </StyledHeading>
                      )}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid xs={12} container columnSpacing="16px">
              <Grid item xs={6}>
                <OutlinedContainer styles={{ position: 'relative' }}>
                  {selectedValue !== '1' && <Overlay />}
                  <Grid xs={12} item alignSelf="center" marginBottom={4}>
                    <Row justifyContent="space-between">
                      <Grid item>
                        <StyledHeading
                          fontWeight={fontWeight?.semiBold}
                          fontSize={fontSize?.h5}
                        >
                          {messages?.profile?.signature?.draw}
                        </StyledHeading>
                      </Grid>
                      <Grid item>
                        <FixedTitle
                          onClick={() => showSignaturedeleteForm({ type: 'drawn' })}
                          varient="Fixed14Medium"
                          color={otherColour?.errorDefault}
                          style={{ cursor: 'pointer' }}
                        >
                          {messages?.general?.clear}
                        </FixedTitle>
                      </Grid>
                    </Row>
                  </Grid>
                  <Grid xs={12} item alignSelf="center">
                    <StyledDiv>
                      {signature?.drawnSignature ? (
                        selectedValue === '1' && (
                          <Image
                            src={signature?.drawnSignature}
                            alt="Background"
                            onLoad={handleImageLoad}
                            onError={handleImageError}
                            style={{ display: imageLoaded ? 'block' : 'none' }}
                          />
                        )
                      ) : (
                        <EmptyBackground />
                      )}
                      {selectedValue === '1' && (
                        <Icons>
                          <EditOutlineIcon
                            onClick={() => showEditSignatureForm({ type: 'drawn' })}
                            style={{
                              ...iconStyles,
                              fill: brand?.secondaryMain,
                              cursor: 'pointer',
                            }}
                          />
                        </Icons>
                      )}
                    </StyledDiv>
                  </Grid>
                </OutlinedContainer>
              </Grid>
              <Grid item rowGap="32px" xs={6}>
                <OutlinedContainer styles={{ position: 'relative' }}>
                  {selectedValue !== '2' && <Overlay />}
                  <Grid xs={12} item alignSelf="center" marginBottom={4}>
                    <Row justifyContent="space-between">
                      <Grid item>
                        <StyledHeading
                          fontWeight={fontWeight?.semiBold}
                          fontSize={fontSize?.h5}
                        >
                          {messages?.profile?.signature?.type}
                        </StyledHeading>
                      </Grid>
                      <Grid item>
                        <FixedTitle
                          onClick={() => showSignaturedeleteForm({ type: 'typed' })}
                          varient="Fixed14Medium"
                          color={otherColour?.errorDefault}
                          style={{ cursor: 'pointer' }}
                        >
                          {messages?.general?.clear}
                        </FixedTitle>
                      </Grid>
                    </Row>
                  </Grid>
                  <Grid xs={12} item alignSelf="center">
                    <StyledDiv>
                      {signature?.typedSignature ? (
                        selectedValue === '2' && (
                          <Image
                            src={signature?.typedSignature}
                            alt="Background"
                            onLoad={handleImageLoad}
                            onError={handleImageError}
                            style={{ display: imageLoaded ? 'block' : 'none' }}
                          />
                        )
                      ) : (
                        <EmptyBackground />
                      )}
                      {selectedValue === '2' && (
                        <Icons>
                          <EditOutlineIcon
                            onClick={() => showEditSignatureForm({ type: 'typed' })}
                            style={{
                              ...iconStyles,
                              fill: brand?.secondaryMain,
                              cursor: 'pointer',
                            }}
                          />
                        </Icons>
                      )}
                    </StyledDiv>
                  </Grid>
                </OutlinedContainer>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </OutlinedContainer>
      <Modal
        icon={<EditOutlineIcon />}
        show={editSignatureFormVisibility}
        heading={
          messages?.profile?.signature?.editForm?.[editSignatureMetadata?.type]
        }
        onClose={hideEditSignatureForm}
        fitContent
      >
        <UpdateSignature
          type={editSignatureMetadata?.type}
          onCancel={hideEditSignatureForm}
          updateSignature={handleUpdateSignature}
          isMobile={isMobile}
        />
      </Modal>
      <Modal
        icon={<DeleteOutlineIcon />}
        show={deleteSignatureVisibility}
        heading={messages?.profile?.signature?.deleteForm?.heading}
        onClose={hidedeleteSignatureForm}
        deleteForm
        fitContent
      >
        <ModalAction
          noConfirmationDelete
          title={messages?.profile?.signature?.deleteForm?.subHeading}
          onCancel={hidedeleteSignatureForm}
          buttonLabel="signature"
          customSubmit={handleClearSignature}
          submitting={submitting}
        />
      </Modal>
    </>
  );
};

export default SignatureDesktop;
