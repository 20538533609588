import {
  Grid, Link, Typography, Chip, FormControlLabel,
} from '@mui/material';
import styled, { css } from 'styled-components';
import { respondTo } from '@tphglobal/common/theme/style.layout';
import { fontSize, fontWeight } from '@tphglobal/common/theme/style.typography';
import {
  brand,
  brandColour,
  colors,
  greyScaleColour,
} from '@tphglobal/common/theme/style.palette';

export const StyledPanel = styled.div`
  display: flex;
  flex: 1 1 48.34%;
  justify-content: center;
  position: relative;
  align-items: center;
  height: calc(100vh);
  border-radius: 32px 0 0 32px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('/assets/images/banner.png');

  ${respondTo.mobileOnlyPortrait} {
    background-image: url(/assets/images/mobile-banner.png);
    flex: 100%;
    border-radius: 0 0 24px 24px;
    height: 27.8vh;
    margin-bottom: 16px;
  }
`;

export const StyledSubscaleTabContainer = styled.div<{ noMargin?: boolean }>`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: ${({ noMargin }) => (noMargin ? '0px' : '16px')};
`;

export const StyledSubscaleTab = styled.div<{ active?: boolean }>`
  padding: 0 6px 10px 6px;
  cursor: pointer;
  ${({ active }) => active
    && css`
      padding-bottom: 8px;
      border-bottom: 2px solid ${brand?.secondaryMain};
    `}
`;

export const StyledSubscaleTabText = styled(Typography)<{ active?: boolean }>`
  line-height: 21px !important;
  font-size: ${fontSize.h5} !important;
  font-weight: ${fontWeight.semiBold} !important;
  color: ${greyScaleColour?.grey100};
  
  ${({ active }) => active && css`
  font-weight: ${fontWeight?.regular};
  color: ${brand.secondaryMain} !important;
  `}

  ${respondTo?.mobileOnlyPortrait} { 
    font-size: ${fontSize?.h5} !important;
    font-weight: ${fontWeight?.regular} !important;
  }
`;

export const StyledTopPanel = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  width: auto;
  // height: 41.2vh; 
  padding: 90.5px 50px; 
  border-radius: 0 0 24px 24px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(/assets/images/getInTouchBanner.png);

  ${respondTo?.mobileOnlyPortrait} {
    background-image: url(/assets/images/mobile-banner.png);
    border-radius: 0 0 24px 24px;
    // height: 27.8vh;
    padding: 44px 14px 33px;
    margin-bottom: 13.5px;
  }
`;

export const MobileContainer = styled.div`
  ${respondTo?.mobileOnlyPortrait} {
    padding: 0 16px;
    margin-bottom: 53.5px;
  }
`;

export const StyledSidePanelLogoImage = styled.div`
  display: flex;
  width: 79.45%;
  height: 78.11%;
  position: relative;
  border-radius: 23.48px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(/assets/images/overBanner.png);
  gap: 6px;

  ${respondTo.mobileOnlyPortrait} {
    width: 100%;
    background-image: url('');
  }
`;

export const StyledSidePanelUpperMiddleText = styled(Typography)`
  font-weight: ${fontWeight.semiBold} !important;
  line-height: 63.25px !important;
  font-size: 55px !important;
  color: ${greyScaleColour.white100} !important;
  height: auto;
  
  ${respondTo.mobileOnlyPortrait} {
    font-weight: ${fontWeight.semiBold} !important;
    font-size: ${fontSize.h2} !important;
    line-height: 36px !important;
  }

  ${respondTo.tabletOnlyPortrait} {
    font-weight: ${fontWeight.semiBold} !important;
    font-size: 40px !important;
    line-height: 40px !important;
  }

  ${respondTo.screenHeight(750)} {
    font-weight: ${fontWeight.light} !important;
    font-size: ${fontSize.h2} !important;
    line-height: 30px !important;
  }
`;
export const StyledSidePanelLowerMiddleText = styled(Typography)`
  line-height: 18.23px !important;
  font-weight: ${fontWeight.regular} !important;
  font-size: ${fontSize.b1} !important;
  color: ${greyScaleColour.white100} !important;
  height: auto;
  width: 86.43%;
  
  ${respondTo.tabletOnlyPortrait} {
    font-weight: ${fontWeight.regular} !important;
    font-size: ${fontSize.h4} !important;
    line-height:22px !important;
  }

  ${respondTo.mobileOnlyPortrait} {
    position: unset;
  }

  ${respondTo.screenHeight(900)} {
    font-weight: ${fontWeight.regular} !important;
    font-size: ${fontSize.b1} !important;
    width : 100%;
  }
  ${respondTo.screenHeight(750)} {
    font-weight: ${fontWeight.regular} !important;
    font-size: ${fontSize.b1} !important;
    line-height: 15px;
    width : 100%;
  }
`;

export const StyledHeadingContent = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 33px;
  left: 32px;
  width: 86.43%;
  height: auto;
  gap: 6px;

  ${respondTo.mobileOnlyPortrait} {
    top: 13px;
    left: 16px;
    width: 61%;
    gap: 10px;
  }

  ${respondTo.screenHeight(750)} {
    top : 15px;
  }
`;

export const StyledSidePanelLowerText = styled(Typography)`
  position: absolute;
  top: 5vh;
  right: 7%;
  line-height: 18px !important;
  color: ${greyScaleColour.grey60} !important;

  ${respondTo?.mobileOnlyPortrait} {
    display: none;
  }
`;
export const StyledSidePanelLowerTextMobile = styled(Typography)<{mt ?: string}>`
  display: none;
  margin-top: ${({ mt }) => mt};

  ${respondTo.mobileOnlyPortrait} {
    display: block;
  } 
`;

export const StyledReactOutContainer = styled.div`

`;

export const StyledGridContainer = styled(Grid)`
  padding: 40px;
  padding-bottom: 142px;
  display: flex;
  flex-direction: column;
  color: white;
`;

export const StyledScreenWrapper = styled.div`
  display: flex;

  ${respondTo.mobileOnlyPortrait} {
    flex-wrap: wrap-reverse;
    overflow-y: scroll;
    &::-webkit-scrollbar {
    display: none; /* Hide the scrollbar */
  }
  }
`;

export const StyledFormContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  flex: 1 1 51.67%;

  // ${respondTo.mobileOnlyPortrait} {
  //   flex: 100%;
  //   margin: 0;
  // }
`;

export const StyledInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: auto;
  padding-bottom: 8px;

  ${respondTo.mobileOnlyPortrait} {
    margin-top: 0 !important;
    padding: 0;
  }
`;

export const StyledIconText = styled(Typography)<{
  weight?: string;
  size?: string;
  color?: string;
}>`
  font-weight: ${(props) => (props.weight ? props.weight : fontWeight.medium)} !important;
  font-size: ${(props) => (props.size ? props.size : fontSize.b1)} !important;
  color: ${(props) => (props.color ? props.color : '')};
  line-height: 18px !important;
`;

export const StyledLine = styled.div`
  display: flex;
  flex: 1;
  height: 0;
  border-top: 1px solid ${greyScaleColour.grey90};
`;

export const StyledContent = styled.div`
  padding: 0 24px 24px 24px;
  gap: 16px;

  ${respondTo.mobileOnlyPortrait} {
    padding: 0;
  }
  ${respondTo.screenHeight(750)} {
    gap: 8px;
  }
`;
export const StyledFormDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom:16px;
  
  ${respondTo.mobileOnlyPortrait} {
    gap: 10px;
    margin-bottom:10px;
  }

   ${respondTo.screenHeight(750)} {
    gap: 8px;
    margin-bottom: 4px;
  }
`;

export const StyledButton = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  gap: 10px;
  justify-content: center;
  border: 1px solid ${greyScaleColour.grey90};
  border-radius: 4px;
  padding: 15px 19px 15px 19px;
  cursor: pointer;
`;

export const StyledAuthSignup = styled.div`
  display: flex;
  gap: 8px;
  height: 54px;
  width: 100%;
`;

export const OrLogin = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  font-size: ${fontSize.b2};
`;
export const StyledContentContainer = styled.div`
  width: 68%;

  ${respondTo.mobileOnlyPortrait} {
    width: 100%;
    padding: 16px;
  }

  ${respondTo.tabletOnlyPortrait} {
    width: 100%;
  }
`;
export const StyledHeaderContainer = styled.div`
  display: flex;
  padding: 24px 24px 16px 24px;
  gap: 24px;
  flex-direction: column;

  ${respondTo.mobileOnlyPortrait} {
    flex-direction: row;
    gap: 8px;
    padding: 0;
    margin-bottom: 24px;
  }

  ${respondTo.screenHeight(750)} {
    padding: 8px 24px;
  }
`;
export const NoAccountText = styled(Typography)<{cursor ?: string}>`
  color: ${greyScaleColour.grey100};
  cursor: ${(props) => (props?.cursor ? props?.cursor : '')};

  ${respondTo.mobileOnlyPortrait} {
    font-size: ${fontSize.h5} !important;
  }
`;
export const NoAccountTextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: center;
  line-height: 18px;
`;
export const StyledFormHeading = styled(Typography)`
  font-weight: ${fontWeight.semiBold} !important;
  font-size: ${fontSize.h3} !important;
  line-height: 31px !important;

  ${respondTo.mobileOnlyPortrait} {
    font-weight: ${fontWeight.semiBold} !important;
    font-size: 18px !important;
    line-height: 23px !important;
  }
  
`;
export const StyledFormSubHeading = styled(Typography)`
  font-weight: ${fontWeight.regular} !important;
  font-size: ${fontSize.b1} !important;
  color: ${greyScaleColour.grey100};
  line-height: 18px !important;
`;
export const StyledLink = styled(Link)`
  font-size: ${fontSize.b1} !important;
  font-weight: ${fontWeight.regular} !important;
  line-height: 18px !important;
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  margin-left: 0px !important;
  color: ${greyScaleColour.grey100} !important;
`;

export const StyledLogoContainer = styled(Grid)`
  width: 141px;
  height: 37px;
`;

export const StyledSmallLogo = styled.img`
  width: 36px;
  height: auto;
  max-width: 100%;
  max-height: 100%;
`;

export const StyledIcon = styled.img`
  width: 24px;
  height: 53px
`;
export const StyledLogo = styled.img`
  width: 200px;
  height: auto;
  max-width: 100%;
  max-height: 100%;
`;

export const StyledPanelInfo = styled(Typography)`
  color: ${colors.white};
  font-size: 56px !important;
  font-weight: ${fontWeight.semiBold} !important;
  line-height: 120% !important;
`;
export const StyledPanelSubInfo = styled(Typography)<{
  fontsize?: string;
  fontweight?: number;
}>`
  color: ${colors.white};
  font-size: ${({ fontsize }) => fontsize || fontSize.b1} !important;
  font-weight: ${({ fontweight }) => fontweight || fontWeight.regular} !important;
  line-height: normal !important;
`;
export const StyledWord = styled.span`
  color: ${brand.primaryMain};
`;

export const StyledLinkContainer = styled.a`
display: flex;
align-items: center;
gap: 4px;
  &:hover {
    color: ${brand.primaryMain} !important;
    & .MuiSvgIcon-root {
      color: ${brand.primaryMain} !important;
    }
  }
  text-decoration: none;
`;

export const StyledImageContainer = styled(Grid)`
  padding: 0 !important;
  padding-left: 1px !important;
`;

export const StyledImageContent = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 113px;
  padding: 0 !important;
`;

export const StyledLoginChip = styled(Chip)`
  &&.MuiChip-root {
    height: 26px !important;
    padding: 12px 4px !important;
    font-size: ${fontSize.b2} !important;
    background-color: ${brand.primaryMain} !important;
    font-weight: ${fontWeight.medium} !important;
    color: ${brand.primaryMain} !important;
  }
`;

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction : column;
  &::-webkit-scrollbar {
    display: none; /* Hide the scrollbar */
  }
  ${respondTo?.mobileOnlyPortrait} {
    overflow-y: auto;
  }
`;

export const StyledGoBackContainer = styled.div``;
export const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction : column;
  gap: 1.5vh;
  margin-right:auto;
  width: 50%;

  ${respondTo?.mobileOnlyPortrait} {
    width: 100%;
    gap: 19px;
  }
`;

export const StyledHeading = styled(Typography)`
`;

export const StyledLocation = styled.iframe`
  width: 619px;
  height: 100%; 
  border-radius: 16px; 

  ${respondTo.mobileOnlyPortrait} {
    height: 242px;
    width: auto;
  }
  `;

export const GoBackWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 4px;
`;

export const GoBackIcon = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

export const GoBackText = styled(Typography)`
  cursor: pointer;
`;

export const StyledHeadingWrapper = styled.div`
  width: 33.2%;
  display: flex;
  flex-direction: column;
  width: 71.34%;

  ${respondTo?.mobileOnlyPortrait} {
    width: 60.4%;
    gap: 8px;
  }
`;

export const StyledHeader = styled(Typography)`
  color : ${brand?.white} !important;
`;

export const StyledOverBannerWrapper = styled.div`
    width: 26.72%;
    padding: 20px 24px;
    border-radius: 23.48px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(/assets/images/overGetInTouch.png);

    
  ${respondTo?.mobileOnlyPortrait} {
    display: none;
  }
  `;
export const StyledOverBannerWrapperMobile = styled.div`
    width: auto;
    padding: 21px 18px;
    border-radius: 23.48px;
    gap: 10px;
    background-color: ${brandColour?.primary20};
    margin-bottom: 24px;

    ${respondTo?.tabletUp} {
      display: none;
    }
  `;

export const StyledOverWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
  `;

export const StyledContactButton = styled.div`
    border: 1px solid ${brand.white};
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    gap: 8px;
    padding: 14px 20px;
  `;

export const StyledContainer = styled.div`
    padding : 16px 24px;

    ${respondTo?.mobileOnlyPortrait} {
      padding: 16px 0;
    }
  `;

export const StyledColumn = styled.div<{gap ?: string}>`
    display: flex;
    flex-direction: column;
    row-gap: ${({ gap }) => (gap || '16px')};

    ${respondTo?.mobileOnlyPortrait} {
      row-gap: 8px;
    }
  `;
