import React from 'react';
import BlockIcon from '@mui/icons-material/Block';
import { greyScaleColour } from '@tphglobal/common/theme/style.palette';
import { fontSize, fontWeight } from '@tphglobal/common/theme/style.typography';
import { Column, OverlayContainer, StyledHeading } from './styles';

const iconStyles = {
  height: '40px',
  width: '40px',
  fill: greyScaleColour?.grey100,
};
const Overlay = () => (
  <OverlayContainer>
    <Column>
      <BlockIcon style={iconStyles} />
      <StyledHeading
        fontSize={fontSize?.h5}
        fontWeight={fontWeight?.semiBold}
        lineHeight="21px"
        color={greyScaleColour?.grey100}
      >
        Only one type of signature is allowed.
      </StyledHeading>
    </Column>
  </OverlayContainer>
);

export default Overlay;
