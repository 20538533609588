import { respondTo } from '@tphglobal/common/theme/style.layout';
import {
  brand,
  colors,
  greyScaleColour,
} from '@tphglobal/common/theme/style.palette';
import { fontSize } from '@tphglobal/common/theme/style.typography';
import styled from 'styled-components';

export const StyledSearchInput = styled.input<{ maxWidth?: string }>`
min-width: 235px;
  padding: 0 !important;
  border: none;
  outline: none;
  display: flex;
  font-size: ${fontSize.h5};
  align-items: center;
  line-height: 18.23px;
  color: ${greyScaleColour?.grey100};
  background : transparent !important;
  &::placeholder {
    color: ${greyScaleColour?.grey90};
    font-size: ${fontSize?.b1}
    line-height: 18.23px;
  }
  &::focus {
    border: none; 
  }

  ${respondTo?.mobileOnlyPortrait} {
    min-width: 93%;
  }
`;

export const StyledActionItem = styled.div<{ lastItem?: boolean }>`
  display: flex;
  white-space: nowrap;
`;

export const  SearchInputContainer = styled.div`
  display: flex;
  padding: 0 12px !important;
  align-items: center;
  border: 1px solid ${greyScaleColour?.grey90};
  border-radius: 8px;
  &:focus-within {
    border-color: ${colors?.black};
  }

  ${respondTo?.mobileOnlyPortrait} {
    width: 100%;
  }
`;
