import { respondTo } from '@tphglobal/common/theme/style.layout';
import styled from 'styled-components';

export const StyledView = styled.iframe`
    width: 1164px;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;

    ::-webkit-scrollbar {
    display: none
    }
}
`;

export const StyledViewContainer = styled.div`
  width: 100%;
  height: 720px;
  overflow: hidden;

  ${respondTo?.mobileOnlyPortrait} {
    overflow: auto;
    height: 400px;
  }
`;
