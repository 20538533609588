import React, { Ref, useRef } from 'react';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useFormReducer } from '@tphglobal/common/hooks';
import {
  Button,
  Form,
  FormError,
  FormRow,
  FormRowItem,
  MaterialTextInput,
  OutlinedContainer,
  SignatureInput,
} from '@tphglobal/components';
import { brand, greyScaleColour } from '@tphglobal/common/theme/style.palette';
import { RegionTypes } from '@tphglobal/common/utils/constants';
import { HttpMethods } from '@tphglobal/common/utils';
import messages from '../../../messages';
import { emptyValueValidator, required } from '../../../utils';
import handleApiCall from '../../handleApiCall';
import { ReduxState } from '../../../redux/reducers';
import { TEXT_TO_IMAGE_AU, TEXT_TO_IMAGE_UK } from '../../../api';
import { Validators } from '../../../models';

const validators : Validators = {
  type: [required(''), emptyValueValidator],
};

interface SubmitData {
  base64Data : string
  Typed ?: boolean
}

interface Props {
  updateSignature: (body: SubmitData, type: string) => void;
  onCancel: () => void;
  type: string;
  isMobile?: boolean;
}

interface SignatureComponent {
  sign?: () => string;
  clear?: () => void
}

const UpdateSignature: React.FC<Props> = ({
  updateSignature,
  type,
  onCancel,
  isMobile,
}) => {
  const reduxDispatch = useDispatch();
  const {
    submitError, handleSubmit, connectField, setSubmitError,
  } = useFormReducer(validators);
  const selectedRegion = useSelector(
    (state: ReduxState) => state?.profile?.region,
  );
  const signatureInputRef = useRef<SignatureComponent>(null);

  const onSubmit = async (data: {type : string}) => {
    let sanitizedBody : SubmitData;

    if (type === 'drawn') {
      const updatedSignatureData = signatureInputRef.current.sign();

      if (!updatedSignatureData) {
        setSubmitError('Please draw a signature.');
        return;
      }
      setSubmitError('');
      sanitizedBody = updatedSignatureData
          && {
            Typed: false,
            base64Data: updatedSignatureData,
          };
      updateSignature(sanitizedBody, type);
    } else if (!data?.type) {
      setSubmitError('Please enter your signature.');
    } else {
      handleApiCall(
        reduxDispatch,
        selectedRegion === RegionTypes.AU
          ? TEXT_TO_IMAGE_AU
          : TEXT_TO_IMAGE_UK,
        (res) => {
          sanitizedBody = {
            Typed: true,
            base64Data: res?.base64Image,
          };
          updateSignature(sanitizedBody, type);
        },
        HttpMethods.POST,
        selectedRegion === RegionTypes.AU ? RegionTypes.AU : RegionTypes.UK,
        null,
        { SignatureText: data?.type },
      );
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Grid padding="0 24px">
        {type === 'drawn' ? (
          <FormRow marginBottom="0" width={!isMobile ? '525px' : '100%'}>
            <SignatureInput height={200} ref={signatureInputRef} />
          </FormRow>
        ) : (
          <FormRow marginBottom="0" width={!isMobile ? '525px' : '100%'}>
            {connectField('type', {
              label: messages?.profile?.signature?.editForm?.type,
            })(MaterialTextInput)}
          </FormRow>
        )}

        {submitError && (
          <FormRow marginTop="16px">
            <FormRowItem>
              <FormError
                message={submitError}
              />
            </FormRowItem>
          </FormRow>
        )}
      </Grid>

      <OutlinedContainer
        noBorder
        borderRadius="0"
        padding="14px 24px"
        styles={{ backgroundColor: greyScaleColour?.grey60, marginTop: '24px' }}
      >
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  variant="outlined"
                  buttonColor={brand?.black}
                  color="primary"
                  size="large"
                  label={messages?.general?.cancel}
                  onClick={onCancel}
                />
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  label={messages?.general?.update}
                  type="submit"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </OutlinedContainer>
    </Form>
  );
};

export default UpdateSignature;
