import { createTheme } from '@mui/material/styles';
import {
  baseFontFamily,
  fontSize,
  fontSizeMobile,
  fontWeight,
} from './style.typography';
import {
  brand,
  colors,
  brandColour,
  greyScaleColour,
  otherColour,
} from './style.palette';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0, // Extra small devices (up to 767px)
      sm: 768, // Small devices (768px and up)
      md: 1024, // Medium devices (1024px and up)
      lg: 1600, // Large devices (1600px and up)
      xl: 1921, // Extra large devices (1920px and up)
    },
  },
  typography: {
    fontFamily: baseFontFamily,
    h1: {
      fontSize: fontSize.h1,
      fontWeight: fontWeight.semiBold,
      color: greyScaleColour?.black100,
    },
    h2: {
      fontSize: fontSize.h2,
      fontWeight: fontWeight.semiBold,
      color: greyScaleColour?.black100,
    },
    h3: {
      fontSize: fontSize.h3,
      fontWeight: fontWeight.semiBold,
      color: greyScaleColour?.black100,
    },
    h4: {
      fontSize: fontSize.h4,
      fontWeight: fontWeight.semiBold,
      color: greyScaleColour?.black100,
    },
    h5: {
      fontSize: fontSize.h5,
      fontWeight: fontWeight.semiBold,
      color: greyScaleColour?.black100,
    },
    body1: {
      fontSize: fontSize.b1,
      fontWeight: fontWeight.regular,
      color: greyScaleColour?.black100,
    },
    body2: {
      fontSize: fontSize.b2,
      fontWeight: fontWeight.regular,
      color: greyScaleColour?.black100,
    },
    subtitle1: {
      fontSize: fontSize.b1,
      fontWeight: fontWeight.medium,
      color: greyScaleColour?.black100,
    },
    subtitle2: {
      fontSize: fontSize.b2,
      fontWeight: fontWeight.medium,
      color: greyScaleColour?.black100,
    },
  },
  palette: {
    primary: {
      main: brand.primaryMain,
    },
    secondary: {
      main: brand.secondaryMain,
    },
  },
  shape: {
    borderRadius: 6,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '@media (max-width: 767px)': {
          h1: {
            fontSize: fontSizeMobile.h1,
          },
          h2: {
            fontSize: fontSizeMobile.h2,
          },
          h3: {
            fontSize: fontSizeMobile.h3,
          },
          h4: {
            fontSize: fontSizeMobile.h4,
          },
          h5: {
            fontSize: fontSizeMobile.h5,
          },
          body1: {
            fontSize: fontSizeMobile.b1,
          },
          body2: {
            fontSize: fontSizeMobile.b2,
          },
          subtitle1: {
            fontSize: fontSizeMobile.b1,
          },
          subtitle2: {
            fontSize: fontSizeMobile.b2,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          margin: '0px',
          fontFamily: baseFontFamily,
          fontSize: fontSize.b1,
          fontWeight: fontWeight.medium,
          color: greyScaleColour.white100,
          borderRadius: '8px',
          boxShadow: 'none',
          padding: '14px 20px',
          width: '100%',
          lineHeight: '18.23px',
        },
        containedPrimary: {
          backgroundColor: brandColour.primary100,
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: brand.primaryMain,
          },
          '&.Mui-disabled': {
            color: greyScaleColour.grey90,
            backgroundColor: greyScaleColour.grey70,
          },
        },
        containedError: {
          backgroundColor: otherColour.errorDefault,
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: otherColour.errorHover,
          },
          '&.Mui-disabled': {
            color: greyScaleColour.grey90,
            backgroundColor: greyScaleColour.grey70,
          },
        },
        outlinedSecondary: {
          backgroundColor: 'none',
          color: brand.secondaryMain,
          padding: '11px 20px',
          border: `1px solid ${brand.secondaryMain}`,
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: brand.secondaryMain,
            color: greyScaleColour.white100,
          },
          '&.Mui-disabled': {
            color: greyScaleColour.grey90,
            backgroundColor: greyScaleColour.grey70,
          },
        },
        containedSecondary: {
          backgroundColor: otherColour?.errorDefault,
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: otherColour?.errorDefault,
          },
        },
        sizeMedium: {
          fontWeight: fontWeight.medium,
          borderRadius: '8px',
        },
        textPrimary: {
          backgroundColor: 'inherit',
          color: brand.primaryMain,
          fontSize: fontSize.b2,
          fontWeight: fontWeight.regular,
          padding: '0 !important',
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: 'inherit',
          },
        },
        textSecondary: {
          backgroundColor: 'inherit',
          color: brand.primaryMain,
          fontSize: fontSize.b1,
          fontWeight: fontWeight.regular,
          padding: '0 !important',
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: 'inherit',
          },
        },
        outlinedPrimary: {
          borderColor: brand.secondaryMain,
          color: brand.secondaryMain,
          background: 'inherit',
          '&:hover': {
            background: brand.secondaryMain,
            borderColor: brand.secondaryMain,
            color: brand.white,
            boxShadow: 'none',
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: greyScaleColour.grey100,
          padding: '0 0 0 11px !important',
          marginRight: '4px !important',
          '&.Mui-checked': {
            color: brand.secondaryMain,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '10px',
          borderBottom: 'none',
        },
        head: {
          padding: '16px',
          color: greyScaleColour?.black100,
          backgroundColor: brandColour?.primary10,
        },
        body: {
          lineHeight: '18px !important',
          fontSize: fontSize.b1,
          fontWeight: fontWeight.medium,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '999px',
          padding: '4px 12px',
          '&.MuiChip-label': {
            padding: 0,
          },
        },
        label: {
          padding: 0,
          fontSize: fontSize.b2,
          fontWeight: fontWeight.medium,
        },
        filledPrimary: {
          backgroundColor: brandColour.primary100,
          color: brandColour.primary100,
        },
        outlinedPrimary: {
          border: `1px solid ${brandColour.primary100}`,
          color: brandColour.primary100,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '0',
          boxShadow: 'none',
          width: '100%',
          position: 'relative',
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          padding: '16px',
          borderRadius: '8px',
          boxShadow: '0px 4px 25px 0px #0000001A'
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          position: 'absolute',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '0',
          display: 'flex',
          flexDirection: 'column',
          '&:last-child': {
            paddingBottom: '16px',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: fontSize.b1,
          borderRadius: '10px',
          borderColor: colors.black,
          backgroundColor: 'transparent',
          border: 'none',
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: `${colors.black} !important`,
          },
          '&.Mui-error': {
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: `${colors.red100} !important`,
            },
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: '1px !important',
            borderColor: `${colors.grey100} !important`,
          },
          '&.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: `${colors.red100} !important`,
          },
          '&.Mui-disabled': {
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: '#BBBBBF !important',
            },
          },
        },
        input: {
          padding: '14px 16px',
        },
        inputMultiline: {
          padding: '0px !important',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: '14px 16px',
        },
        notchedOutline: {
          borderColor: colors.grey50,
          borderRadius: '8px',
          borderWidth: '1px !important',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          top: -2,
          color: greyScaleColour?.grey90,
          fontSize: fontSize.b1,
          fontWeight: fontWeight.regular,
          '&.Mui-focused': {
            color: colors.grey100,
          },
          '&.Mui-error': {
            color: colors.red100,
          },
        },
        shrink: {
          top: 0,
        },
        asterisk: {
          color: colors.red100,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
          color: colors.grey100,
          '&:hover': {
            color: brand.primaryMain,
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '& .MuiAutocomplete-input': {
              padding: '0px',
            },
          },
          '& .MuiAutocomplete-inputRoot': {
            padding: '0px 12px !important',
          },
        },
        input: {
          padding: '0px',
          minWidth: '200px !important',
        },
        option: {
          '&.Mui-focused': {
            backgroundColor: `${colors.grey10} !important`,
          },
          '&[aria-selected="true"]': {
            backgroundColor: `${brand.primaryMain} !important`,
            color: `${colors.white} !important`,
          },
        },
        paper: {
          boxShadow: '0px 2px 15px 0px rgba(0, 0, 0, 0.12)',
        },
        tag: {
          display: 'flex',
          padding: '2px 10px',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: brand.primaryMain,
          color: colors.white,
          borderRadius: '60px',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          '@media (min-width: 768px)': {
            paddingLeft: 0,
            paddingRight: 0,
            minHeight: 0,
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: '0 !important',
          minHeight: 'unset !important',
        },
        content: {
          margin: '0 !important',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none !important',
          backgroundColor: otherColour?.gradientBg5,
          background: otherColour?.gradientBg5
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          marginLeft: 'auto !important'
        }
      }
    },
    MuiLinearProgress : {
      styleOverrides: {
        root: {
          height: '5px !important',
          borderRadius: '100px !important',
          backgroundColor: greyScaleColour?.grey70
        },
        bar : {
          borderRadius: 'inherit'
        }
      }
    },
    MuiToggleButton : {
      styleOverrides:{
        root:{
          padding : '10px 12px !important',
          border : 'none',
          lineHeight : '18px',
          color : 'black',
          '&.Mui-selected': {
            color: `${greyScaleColour?.white100} !important`,
            fontWeight : fontWeight?.medium,
            backgroundColor : `${brand?.secondaryMain} !important`
          },
        },
      }
    },
    MuiToggleButtonGroup : {
      styleOverrides : {
        root: {
          border : `1px solid ${greyScaleColour?.grey90}`,
          borderRadius: '8px',
        }
      }
    }
  },
});

export default theme;
