import { Typography } from '@mui/material';
import styled from 'styled-components';
import { colors } from '@tphglobal/common/theme/style.palette';
import { respondTo } from '@tphglobal/common/theme/style.layout';


export const StyledInputContainer = styled.div`
    width : 100%;
    min-width: 344px;

    ${respondTo?.mobileOnlyPortrait} {
        min-width: 150px;
    }
`

export const StyledError = styled(Typography)`
    color : ${colors.danger} !important;
    margin-top:8px !important;
`