import styled, { css } from 'styled-components';
import { Checkbox, FormControlLabel, FormHelperText, Typography } from '@mui/material';
import { fontSize, fontWeight } from '@tphglobal/common/theme/style.typography';
import { colors } from '@tphglobal/common/theme/style.palette';

export const StyledCheckboxContainer = styled.div<{ maxWidth?: string }>`
  display: flex;
  align-self: center;
  flex-direction: column;
  max-width: ${({ maxWidth }) => maxWidth || '100%'};
`;

export const StyledCheckbox = styled(Checkbox)`
  padding: 4px !important;
`;

export const StyledError = styled(Typography)`
  text-align: left;
  color: ${colors.red100} !important;
  margin-top: 8px !important;
`;

export const StyledLabel = styled.span`
  font-weight: ${fontWeight.regular};
  font-size: ${fontSize.b1};
`;

export const StyledFormControlLabel = styled(FormControlLabel)<{
  flexDirection?: string;
}>`
margin: 0 !important;
display: flex;
align-items: center;
gap:4px;
  ${({ flexDirection }) =>
    flexDirection &&
    css`
    align-items: center;
      flex-direction: row-reverse !important;
    `}
`;
