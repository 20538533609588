import { Grid } from '@mui/material';
import {
  Button, Modal, OutlinedContainer, Toast,
} from '@tphglobal/components';
import React from 'react';
import { fontSize, fontWeight } from '@tphglobal/common/theme/style.typography';
import { greyScaleColour } from '@tphglobal/common/theme/style.palette';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useDispatch } from 'react-redux';
import { usePopupReducer } from '@tphglobal/common/hooks';
import { toast } from 'react-toastify';
import messages from '../../../messages';
import { iconStyles } from '../../../utils/constant';
import ChangePassword from './changePasswordForm';
import {
  StyledHeading,
} from '../styles';

interface Props {
  isMobile?: boolean;
}

const Password: React.FC<Props> = ({ isMobile }) => {
  const reduxDispatch = useDispatch();

  const {
    visibility: passwordFormVisibility,
    showPopup: showPasswordForm,
    hidePopup: hidePasswordForm,
  } = usePopupReducer();

  return (
    <>
      <OutlinedContainer>
        <Grid container justifyContent="space-between" rowSpacing={2}>
          <Grid item alignSelf="center" rowSpacing="6px">
            <StyledHeading
              fontWeight={fontWeight?.semiBold}
              fontSize={fontSize?.h5}
            >
              {messages?.profile?.password?.heading}
            </StyledHeading>
            <StyledHeading
              color={greyScaleColour?.grey100}
              fontSize={fontSize?.b2}
            >
              {messages?.profile?.password?.subHeading}
            </StyledHeading>
          </Grid>
          <Grid item alignSelf="center">
            <Button
              onClick={() => showPasswordForm()}
              variant="outlined"
              color="primary"
              size="large"
              label={messages?.profile?.password?.button}
              icon={<LockOutlinedIcon style={iconStyles} />}
            />
          </Grid>
        </Grid>
      </OutlinedContainer>
      <Modal
        icon={<LockOutlinedIcon />}
        show={passwordFormVisibility}
        heading={messages?.profile?.changePasswordForm?.heading}
        onClose={hidePasswordForm}
        fitContent
      >
        <ChangePassword
          onCancel={hidePasswordForm}
          onSuccess={() => {
            hidePasswordForm();
            toast(<Toast text={messages?.profile?.changePasswordForm?.success} />);
          }}
        />
      </Modal>
    </>
  );
};

export default Password;
