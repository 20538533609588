import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  FloatingButton,
  Modal,
  ModalAction,
  SearchInput,
  Toast,
} from '@tphglobal/components';
import { brand, otherColour } from '@tphglobal/common/theme/style.palette';
import { alertClasses, Grid } from '@mui/material';
import { fontSize, fontWeight } from '@tphglobal/common/theme/style.typography';
import Table from '@tphglobal/components/table';
import { usePopupReducer } from '@tphglobal/common/hooks';
import AddIcon from '@mui/icons-material/Add';
import EditOutlineIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useWindowSize from '@tphglobal/common/hooks/windowSize';
import { breakpoints } from '@tphglobal/common/theme/style.layout';
import { RegionTypes } from '@tphglobal/common/utils/constants';
import { HttpMethods } from '@tphglobal/common/utils';
import { toast } from 'react-toastify';
import { CompanyEntity, DocumentEntity, ListEntity } from '../../models';

import handleApiCall from '../handleApiCall';
import {
  DELETE_COMPANY_AU,
  DELETE_COMPANY_UK,
  GET_COMPANY_LIST_AU,
  GET_COMPANY_LIST_UK,
} from '../../api';
import { routes } from '../../utils';
import { headerIconStyles, iconStyles } from '../../utils/constant';
import { StyledHeading } from './styles';
import { Container } from '../../components';
import messages from '../../messages';
import { ReduxState } from '../../redux/reducers';
import { filterList } from '../../utils/commonFunctions';
import { resultsFetchRequest } from '../../redux/actions';

const mobileSpecs = [
  {
    id: 'Name',
    label: messages?.company?.table?.companyName,
  },
];
const desktopSpecs = [
  {
    id: 'Name',
    label: messages?.company?.table?.companyName,
  },
  {
    id: 'Production',
    label: messages?.company?.table?.productionName,
  },
  {
    id: 'EmailAddress',
    label: messages?.company?.table?.email,
  },
];
const Company = () => {
  const companies = useSelector((state: ReduxState) => state?.results);
  const [filteredCompanies, setFilteredCompanies] = useState<CompanyEntity[]>(
    companies?.results,
  );
  const [searchInput, setSearchInput] = useState<string>('');
  const [submitting, setSubmitting] = useState<boolean>(false);
  const selectedRegion = useSelector(
    (state: ReduxState) => state?.profile?.region,
  );

  const reduxDispatch = useDispatch();
  const history = useHistory();
  const { width } = useWindowSize();

  const isMobile = width <= breakpoints.mobile;
  const {
    visibility: deleteVisibility,
    showPopup: showdeleteForm,
    hidePopup: hidedeleteForm,
    metaData: deleteFormMetadata,
  } = usePopupReducer<{ companyId: string }>();

  const handleAddCompany = () => {
    history.push(routes?.addCompany?.root, {
      data: { companies: companies?.results },
    });
  };

  useEffect(() => {
    const filteredCompanies: CompanyEntity[] = filterList(
      searchInput,
      companies?.results,
    );
    setFilteredCompanies(filteredCompanies);
  }, [searchInput]);

  useEffect(() => {
    setFilteredCompanies(companies?.results);
  }, [companies?.results]);

  const fetchCompanyListings = () => {
    try {
      reduxDispatch(
        resultsFetchRequest(
          selectedRegion === RegionTypes.AU
            ? GET_COMPANY_LIST_AU
            : GET_COMPANY_LIST_UK,
          { page: 1, limit: 10 },
        ),
      );
    } catch (error) {
      toast(<Toast type="error" text={messages?.company?.error?.heading} />);
    }
  };
  useEffect(() => {
    fetchCompanyListings();
  }, []);
  const handleDeleteCompany = () => {
    setSubmitting(true);
    handleApiCall(
      reduxDispatch,
      `${
        selectedRegion === RegionTypes.AU
          ? DELETE_COMPANY_AU
          : DELETE_COMPANY_UK
      }/${deleteFormMetadata?.companyId}`,
      () => {
        setSubmitting(false);
        reduxDispatch(push(routes?.company?.root));
        hidedeleteForm();
        fetchCompanyListings();
      },
      HttpMethods?.DELETE,
      selectedRegion === RegionTypes.AU ? RegionTypes.AU : RegionTypes.UK,
      messages?.company?.deleteForm?.success?.heading,
    );
  };
  return (
    <Container
      onRightIconPress={handleAddCompany}
      rightIcon={<AddIcon style={headerIconStyles} />}
      heading={messages?.company?.heading}
      background={otherColour?.adminBg100}
      maxHeight
    >
      <Card
        noContentPadding={isMobile}
        removeTitle
        contentCss={isMobile && { padding: '24px 16px !important' }}
        header={(
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <StyledHeading
                fontSize={fontSize?.h2}
                fontWeight={fontWeight?.semiBold}
              >
                {messages?.company?.heading}
              </StyledHeading>
            </Grid>
            <Grid item>
              <Grid container columnGap="18px">
                <Grid item>
                  <SearchInput
                    setSearchInput={setSearchInput}
                    searchInput={searchInput}
                  />
                </Grid>
                <Grid item>
                  <Button
                    onClick={handleAddCompany}
                    variant="outlined"
                    color="primary"
                    size="large"
                    label={messages?.general?.addNew}
                    icon={<AddIcon style={iconStyles} />}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      >
        {isMobile && (
          <Grid container marginBottom="14px">
            <Grid item xs={12}>
              <SearchInput
                setSearchInput={setSearchInput}
                searchInput={searchInput}
              />
            </Grid>
          </Grid>
        )}
        <Table
          data={filteredCompanies || []}
          disableSorting={['Production', 'EmailAddress']}
          initialSortColumn="Name"
          actions={[
            {
              id: 'edit',
              component: (
                <EditOutlineIcon
                  style={{ ...iconStyles, color: brand?.secondaryMain }}
                />
              ),
              onClick: (row: CompanyEntity) => {
                const dataToPass = {
                  data: {
                    id: row?.SysCoyGuid,
                    EmailAddress: row?.EmailAddress,
                    Dept: row?.Dept,
                    Position: row?.Position,
                    Guid: row?.Guid,
                    SysCoyGuid: row?.SysCoyGuid,
                  },
                  isUpdate: true,
                };

                history.push({
                  pathname: routes?.editCompany?.root,
                  state: { data: dataToPass },
                });
              },
            },
            {
              id: 'delete',
              component: (
                <DeleteOutlineIcon
                  style={{ ...iconStyles, color: otherColour?.errorDefault }}
                />
              ),
              onClick: (row: CompanyEntity) => {
                showdeleteForm({ companyId: row?.Guid });
              },
            },
          ]}
          specs={isMobile ? mobileSpecs : desktopSpecs}
        />
        <FloatingButton
          onClick={() => reduxDispatch(push(routes?.support?.root))}
          ariaLabel="Help & Support"
          bottom="2.03%"
          right="1.36%"
        />
      </Card>
      <Modal
        show={deleteVisibility}
        icon={<DeleteOutlineIcon />}
        deleteForm
        onClose={hidedeleteForm}
        heading={messages?.company?.deleteForm?.heading}
        fitContent
      >
        <ModalAction
          noConfirmationDelete
          title={messages?.company?.deleteForm?.subHeading}
          onCancel={hidedeleteForm}
          buttonLabel="Company"
          submitting={submitting}
          customSubmit={handleDeleteCompany}
        />
      </Modal>
    </Container>
  );
};

export default Company;
