import { Typography } from '@mui/material';
import { brandColour, greyScaleColour } from '@tphglobal/common/theme/style.palette';
import { fontSize, fontSizeMobile, fontWeight } from '@tphglobal/common/theme/style.typography';
import styled from 'styled-components';

export const StyledMobileHeader = styled.div`
`;
export const StyledHeadingContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 16px;
`;
export const StyledIcon = styled.img``;
export const StyledTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const StyledTitleText = styled(Typography)`
    font-weight: ${fontWeight?.semiBold} !important;
    font-size: ${fontSizeMobile?.h1} !important;
    line-height: 31px !important;
    color: ${greyScaleColour?.white100} !important;
`

export const StyledHeading = styled(Typography)``

export const StyledButtonContainer = styled.div`
  cursor: pointer;
`

export const StyledLeftContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex: 1;
`;

export const StyledCenterContainer = styled.div`
  display: flex;
  justify-content: center;
  flex: 3;
`;

export const StyledRightContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;
`;

export const StyledTabContainer = styled.div`
  border-top: 1px solid ${brandColour?.primary10};
  display : flex;
`

export const StyledTab = styled.div<{active ?: boolean}>`
  padding: 14px 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  border-bottom: ${({active}) => active && `2px solid ${greyScaleColour?.white100}`} ;
  flex: 1;
`