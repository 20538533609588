import React, { useEffect, useState } from 'react';
import {
  Grid,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { useFormReducer } from '@tphglobal/common/hooks';
import {
  Button,
  Form,
  FormError,
  FormRow,
  FormRowItem,
  MaterialAutocompleteInput,
  OutlinedContainer,
  TextInput,
  Toast,
} from '@tphglobal/components';
import {
  brand,
  greyScaleColour,
} from '@tphglobal/common/theme/style.palette';
import { HttpMethods, mapIdNameToOption } from '@tphglobal/common/utils';
import { regions, RegionTypes } from '@tphglobal/common/utils/constants';
import useWindowSize from '@tphglobal/common/hooks/windowSize';
import { breakpoints } from '@tphglobal/common/theme/style.layout';
import messages from '../../messages';
import { emailValidator, emptyValueValidator, required } from '../../utils';
import handleApiCall from '../handleApiCall';
import { CONTACT_US_AU, CONTACT_US_UK } from '../../api';

interface Props {
  onCancel: () => void;
  onSuccess: () => void;
}
interface SubmitData {
  firstName : string
  lastName : string
  email : string
  message : string
  region : {id : RegionTypes, label : RegionTypes}
}

const validators = {
  firstName: [
    required(messages?.general?.validations?.firstName),
    emptyValueValidator,
  ],
  lastName: [
    required(messages?.general?.validations?.lastName),
    emptyValueValidator,
  ],
  region: [
    required(messages?.general?.validations?.regionRequired),
  ],
  email: [required(messages?.general?.validations?.emailRequired), emailValidator],
  message: [required(messages?.general?.validations?.messageRequired), emptyValueValidator],
};

const ContactUs: React.FC<Props> = ({
  onCancel,
  onSuccess,
}) => {
  const reduxDispatch = useDispatch();
  const [submitting, setSubmitting] = useState<boolean>(false);

  const {
    submitError,
    handleSubmit,
    connectField,
  } = useFormReducer(validators);

  const onSubmit = async (data: SubmitData) => {
    const sanitizedBody = {
      from: data?.email,
      message: `[ From: ${data?.message} ${data?.email}] ${data?.message}`,
      name: `${data?.firstName} ${data?.lastName}`,
      subject: 'Feedback Frelance Portal',
      Preview : false
    };
    setSubmitting(true);
    handleApiCall(
      reduxDispatch,
      data?.region?.id === RegionTypes.AU ? CONTACT_US_AU : CONTACT_US_UK,
      () => {
        setSubmitting(false);
        onSuccess();
      },
      HttpMethods.POST,
      data?.region?.id === RegionTypes.AU ? RegionTypes.AU : RegionTypes.UK,
      messages?.support?.contactUsForm?.success,
      sanitizedBody,
    );
  };

  const { width } = useWindowSize();

  const isMobile = width <= breakpoints.mobile;

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Grid padding={isMobile ? '0 16px' : '0 24px'}>
        <FormRow minWidth={!isMobile && '354px'}>
          {connectField('region', {
            label: messages?.login?.form?.region,
            options: regions?.map(mapIdNameToOption),
          })(MaterialAutocompleteInput)}
        </FormRow>
        <FormRow columnSpacing={2}>
          <FormRowItem>
            {connectField('firstName', {
              label: messages?.signup?.form?.firstName,
            })(TextInput)}
          </FormRowItem>
          <FormRowItem>
            {connectField('lastName', {
              label: messages?.signup?.form?.lastName,
            })(TextInput)}
          </FormRowItem>
        </FormRow>
        <FormRow>
          {connectField('email', {
            label: messages?.support?.contactUsForm?.email,
          })(TextInput)}
        </FormRow>
        <FormRow marginBottom={0}>
          {connectField('message', {
            label: messages?.support?.contactUsForm?.message,
            multiline: true,
            rows: 3,
            autoHeight: true,
          })(TextInput)}
        </FormRow>
        {submitError && (
          <FormRow>
            <FormRowItem>
              <FormError
                message={messages?.departments?.addForm?.failure?.[submitError]}
              />
            </FormRowItem>
          </FormRow>
        )}
      </Grid>

      <OutlinedContainer
        noBorder
        borderRadius="0"
        padding="14px 24px"
        styles={{ backgroundColor: greyScaleColour?.grey60, marginTop: '24px' }}
      >
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  onClick={() => onCancel()}
                  variant="outlined"
                  buttonColor={brand?.black}
                  color="primary"
                  size="large"
                  label={messages?.general?.cancel}
                />
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  type="submit"
                  disabled={submitting}
                  label={messages?.general?.sendMsg}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </OutlinedContainer>
    </Form>
  );
};

export default ContactUs;
