import { combineReducers, Reducer } from 'redux';
import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';
import { LoaderState, StepFormState } from '@tphglobal/common/models/genericEntities';
import auth, { AuthState } from './auth';
import {
  createBasicReducer, createPagedReducer, createResultsReducer, createStepFormReducer,
} from './utils';
import {
  SYSTEM_LOADER, FETCH_USER_PROFILE, APICALL, STEP_FORM,
  RESULTS,
  // Add more Action types here
} from '../actions';
import { ProfileState } from '../../models';

export interface ReduxState {
  router: RouterState;
  auth: AuthState;
  profile: ProfileState;
  loader: LoaderState;
  stepForm ?: StepFormState
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
results: any;

  // Add more State here
}

const createRootReducer = (history: History): Reducer => combineReducers<ReduxState>({
  /* Start Third party reducers */
  router: connectRouter(history),
  /* End Third party reducers */
  auth,
  profile: createBasicReducer<ProfileState>(FETCH_USER_PROFILE, {
    EmailAddress: '',
    MobileNumber: '',
    CountryCode: '',
    TimeOption: '',
    Guid: '',
    MinutsStep: 0,
    FirstName: '',
    LastLogin: 0,
    LastName: '',
    region: '',
    profile_pic: '',
  }),
  loader: createBasicReducer<LoaderState>(SYSTEM_LOADER, {
    visibility: false,
  }),
  stepForm: createStepFormReducer(STEP_FORM, {
    currentPage: 0,
    forms: {},
    validationErrors: {},
  }),
  results: createResultsReducer(RESULTS, []),
  // Add more Reducers here
});
export default createRootReducer;
