export interface CompanyEntity {
  SysCoyGuid: string;
  EmailAddress: string;
  PCode: string;
  City: string;
  UserGUID: string;
  Country: string;
  PayEnding: string;
  TaxID: string;
  Dept: string;
  Production: string;
  State: string;
  TimeStamp: number;
  Guid: string;
  PayFreq: string;
  Position: string | null;
  Add1: string;
  Add2: string;
  Name: string;
}

export interface FileAttachment {
  Salt: string;
  filename: string;
  ContentType: string;
  Encrypted: string;
  DocumentType: string;
  PWHash: string;
  DocDesc: string;
  Timestamp: number;
  url: string;
  size?: number
}

export interface DocumentEntity {
  SysCoyGuid: string | null;
  PWHash: string;
  Salt: string;
  UserGuid: string;
  Encrypted: string;
  DocumentType: string;
  Production: string;
  url: string;
  DocDesc: string;
  Timestamp: number;
  Guid: string;
  ContentType: string;
  Filename: string;
  TimecardGuid: string;
  DocType: string;
  Company: string;
}

export interface ListEntity {
  status: boolean;
  result: [];
}

export interface ProfileState {
  EmailAddress: string;
  MobileNumber: string;
  CountryCode: string;
  TimeOption: string;
  Guid: string;
  MinutsStep: number;
  FirstName: string;
  LastLogin: number;
  LastName: string;
  region?: string;
  profile_pic?: string;
}

export interface CustomField {
  hide: number;
  Type: string;
  readonly: number;
  Length: number;
  defaultValue: string | null;
  Value: string | number | null;
  Prompt: string;
  Flag: string | null;
  required: number;
}

export interface Payas {
  Value: string;
  Flag: string;
}

export interface Department {
  name : string
}

export interface IsRequiredField {
  department : boolean;
  position : boolean;
}

export interface CompanyDetailedEntity {
  TaxID: string;
  CustomTimeFields: CustomField[];
  disabledTypedSig: boolean;
  Timesheet: string;
  timeSheetName: string;
  Contact: string;
  Payas: Payas[];
  TravelFromHide: boolean;
  TravelToHide: boolean;
  Guid: string;
  Fax: string | null;
  Name: string;
  PayFrequency: string;
  Phone: string;
  City: string;
  EmailAddress?: string;
  Dept?: string;
  departments ?: Department[]
  Position?: string;
  Country: string;
  PayEnding: string;
  Production: string;
  State: string;
  Email: string;
  CustomFields: CustomField[];
  PostCode: string;
  Add1: string;
  Logo: string | null;
  Add2: string;
  requiredFeilds ?: IsRequiredField
}

export interface UserSetting {
  Guid: string;
  UserGuid: string;
  Value: boolean;
  Key: string;
}

export interface Company {
  Guid: string;
  Name: string;
  Payas: Payas[];
  Production: string;
}

export interface DailyTime {
  StartMeal: string;
  StartMeal2: string;
  StartMeal3: string;
  FinishMeal1: string;
  FinishMeal3: string;
  FinishMeal2: string;
  MB1ND: string;
  MB2ND: string;
  MB3ND: string;
  Start: string;
  Finish: string;
  TotalHours: string;
  Date: string;
  TimeStamp: number;
  Payas: string;
  CustomTimeFields: CustomField[];
  customNote: string | null;
  Travel1: string;
  Note: string | null;
  Travel2: string;
  TotalMBDed: string;
  Day: string;
}

interface Times {
  CustomTimeFields: CustomField[];
  DailyTimes: DailyTime[];
  TotalHours: string;
}

export interface Timecard {
  SysCoyGuid: string;
  Times: Times[];
  UserGuid: string;
  Dept: string;
  TotalHours: string;
  Type: string;
  CustomTimeCardFields: CustomField[];
  FileAttachments: FileAttachment[];
  Activity: string | null;
  Guid: string;
  Position: string;
  Company: Company[];
  PeriodEnding: number;
  PayFrequency: string;
}

export const fieldTypeOptions = [
  { id: 'Text', label: 'Text' },
  { id: 'String', label: 'String' },
  { id: 'Numeric', label: 'Numeric' },
  { id: 'Checkbox', label: 'Checkbox' },
  { id: 'DropList', label: 'Drop List' },
];

export enum CustomFieldTypes {
  Text = 'Text',
  String = 'String',
  Numeric = 'Numeric',
  Checkbox = 'Checkbox',
  DropList = 'DropList',
}

export enum TimeOption {
  DropList = 'DropList',
  Type = 'Type'
}

type ValidatorFunction = (value: string) => string | void;

export type Validators = {
  [key: string]: ValidatorFunction[];
};
