import React from 'react';
import {
  StyledButtonContainer,
  StyledCenterContainer,
  StyledHeadingContainer,
  StyledLeftContainer,
  StyledMobileHeader,
  StyledRightContainer,
  StyledTab,
  StyledTabContainer,
  StyledTitle,
  StyledTitleText,
} from './styles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { fontSize } from '@tphglobal/common/theme/style.typography';
import { greyScaleColour } from '@tphglobal/common/theme/style.palette';
import { FixedTitle } from '@tphglobal/common/utils';
import { useHistory, useLocation } from 'react-router-dom';
import { goBack } from 'connected-react-router';
import { useDispatch } from 'react-redux';

interface Props {
  customHeading?: React.ReactNode;
  heading?: string;
  showGoBack?: boolean;
  rightIcon?: React.ReactNode;
  tabs?: any[];
  onRightIconPress?: () => void
  customGoBack?: () => void
}

const goBackStyles = {
  height: fontSize?.h4,
  width: fontSize?.h4,
  padding: '4px 4px',
  fill: greyScaleColour?.white100,
  cursor: 'pointer',
};

const MobileHeader: React.FC<Props> = ({
  heading,
  showGoBack,
  rightIcon,
  customHeading,
  tabs = [],
  onRightIconPress,
  customGoBack,
}) => {
  const reduxDispatch = useDispatch()
  const history = useHistory()
  const location : any = useLocation();

  const activeTab = location.state?.data || tabs[0]?.id;
  return (
    <StyledMobileHeader>
      <StyledHeadingContainer>
        {customHeading && customHeading}
        {/* Left */}
        <StyledLeftContainer>
          {showGoBack && (
            <ArrowBackIosIcon onClick={() => customGoBack ? customGoBack() : reduxDispatch(goBack())} fontSize='small' style={goBackStyles} />
          )}
        </StyledLeftContainer>
        {/* Center */}
        <StyledCenterContainer>
          {!customHeading && heading && (
            <StyledTitle>
              <StyledTitleText>{heading}</StyledTitleText>
            </StyledTitle>
          )}
        </StyledCenterContainer>
        {/* Right */}
        <StyledRightContainer>
          {rightIcon && (
            <StyledButtonContainer onClick={onRightIconPress}>{rightIcon}</StyledButtonContainer>
          )}
        </StyledRightContainer>
      </StyledHeadingContainer>
      {tabs && <StyledTabContainer>
        {tabs?.map((tab : any) => {
          const isActive = activeTab === tab?.id
          return (
          <StyledTab active={isActive} onClick={() => history.push(tab.path, {data : tab?.id})
          }>
            {
              isActive ? (
                <FixedTitle varient='Fixed14Bold' color={greyScaleColour?.white100}>
                  {tab?.title}
                </FixedTitle>
              ) : (
                <FixedTitle varient='Fixed14Regular' color={greyScaleColour?.white100}>
                  {tab?.title}
                </FixedTitle>
              )
            }
          </StyledTab>
          )
        })}
      </StyledTabContainer>}
    </StyledMobileHeader>
  );
};

export default MobileHeader;
