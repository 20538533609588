/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CheckBoxInput,
  DragAndDrop,
  Form,
  FormError,
  FormRow,
  FormRowItem,
  MaterialAutocompleteInput,
  Modal,
  OutlinedContainer,
  PdfView,
  TextInput,
  Toast,
} from '@tphglobal/components';
import {
  brand,
  brandColour,
  greyScaleColour,
  otherColour,
} from '@tphglobal/common/theme/style.palette';
import { Grid } from '@mui/material';
import { fontSize, fontWeight } from '@tphglobal/common/theme/style.typography';
import Table from '@tphglobal/components/table';
import { useFormReducer, usePopupReducer } from '@tphglobal/common/hooks';
import DateInput from '@tphglobal/components/materialDateInput';
import {
  FixedTitle,
  HttpMethods,
  mapIdNameToOption,
} from '@tphglobal/common/utils';
import EditOutlineIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { useHistory, useLocation } from 'react-router-dom';
import moment, { Moment } from 'moment';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import useWindowSize from '@tphglobal/common/hooks/windowSize';
import { breakpoints } from '@tphglobal/common/theme/style.layout';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useDispatch, useSelector } from 'react-redux';
import { goBack, push } from 'connected-react-router';
import { RegionTypes } from '@tphglobal/common/utils/constants';
import { toast } from 'react-toastify';
import { Location } from 'history';
import { fetchAuthSession } from 'aws-amplify/auth';
import UpdateTimeForm from './updateTimeForm';
import DigitalTimeSheet from './digitalTimeSheet';
import { apiCall, resultsFetchRequest } from '../../redux/actions';
import { ReduxState } from '../../redux/reducers';
import {
  ADD_COMPANY_UK,
  ADD_DOCUMENT_AU,
  ADD_DOCUMENT_UK,
  CREATE_ACTIVITY_LOGS_AU,
  CREATE_ACTIVITY_LOGS_UK,
  CREATE_PRLEMP,
  CREATE_TIMECARD_AU,
  CREATE_TIMECARD_UK,
  FETCH_ATTACHMENT_AU,
  FETCH_ATTACHMENT_UK,
  GET_DT_API_URL_AU,
  GET_DT_API_URL_UK,
  GET_SIGNATURE_AU,
  GET_SIGNATURE_UK,
  GET_SYS_COMPANY_BY_ID_AU,
  GET_SYS_COMPANY_BY_ID_UK,
  GET_USERS_AUS,
  GET_USERS_UK,
  PREVIEW_PDF_AU,
  PREVIEW_PDF_UK,
  SEND_EMAIL_AU,
  SEND_EMAIL_UK,
  UPDATE_TIMECARD_AU,
  UPDATE_TIMECARD_STATUS_AU,
  UPDATE_TIMECARD_STATUS_UK,
  UPDATE_TIMECARD_UK,
  UPLOAD_ATTACHMENT_AU,
  UPLOAD_ATTACHMENT_UK,
} from '../../api';
import {
  CompanyDetailedEntity,
  CompanyEntity,
  CustomField,
  DailyTime,
  FileAttachment,
  ProfileState,
  Timecard,
  Validators,
} from '../../models';
import {
  convertFormValues,
  generateWeekData,
  getDefaultWeekDay,
  getIPAddress,
  mapDepartmentNameToOption,
  NewFormValues,
  toCamelCase,
} from '../../utils/commonFunctions';
import handleApiCall from '../handleApiCall';
import CustomFieldComponent from './customFieldComponent';
import TimeInputField from './tableInputs/timeInputField';
import DropListInput from './tableInputs/dropListInput';
import TextInputField from './tableInputs/textInputField';
import { required, routes } from '../../utils';
import { Column, Row, StyledHeading } from './styles';
import { Container } from '../../components';
import messages from '../../messages';
import TimeCardTable from './mobileView/timeCardTable';
import DigitalTimeSheetMobile from './mobileView/digitalTimeSheetMobile';

interface FormFieldValue {
  id: string;
  label: string;
}

interface FormValuesData {
  [key: string]: string | FormFieldValue | Moment | boolean;
}

interface TimeCardResponse {
  message?: string;
  status: boolean;
  result: Timecard;
}

interface AttachmentData {
  filename: string;
  content: string;
  encoding: 'base64';
  size: string;
}

interface LocationStateData {
  companyListing: CompanyEntity[];
  isUpdate: boolean;
  timeCardListing?: Timecard[];
  updatingCompanyBrief?: CompanyEntity;
  details: Timecard;
}

interface LocationState {
  data: LocationStateData;
}

interface FileAttachmentObject {
  file: FileAttachment;
  fileId: string;
}

interface SignatureObject {
  base64: string;
  url: string;
}
interface Activity {
  date: string;
  msg: string;
}
interface FinalData {
  approver_chain: unknown;
  group_guid: unknown;
  current_approver: unknown;
  approve_status: unknown;
  ChequeNumber: string | null;
  PayslipNote: string | null;
  WorkState: string | null;
  PayWeek: string | null;
  ChartSort: string | null;
  ChartCode: string | null;
  Flags: string | null;
  InternalNotes: string | null;
  PaymentFlags: string | null;
  PeriodEndingDate: string | null;
  ADTimes: unknown;
  PRLTimes: unknown;
  PayTimes: unknown;
  Batch: number;
  Employee: unknown;
  freelanceuser: unknown;
  TenantGuid: string | null;
  comments: unknown[];
  changes: unknown[];
  activities: Activity[];
  senderid: unknown;
  timesheetImage: string | false;
  freelanceuser_signature: string | null;
  freelanceuser_email: string | null;
  EmailAttachments: unknown;
}

interface Screens {
  screen1: boolean;
  screen2: boolean;
  screen3: boolean;
}

const defaultValidators: Validators = {
  production: [required(messages?.general?.validations?.productionRequired)],
  periodEnding: [
    required(messages?.general?.validations?.periodEndingRequired),
  ],
  department: [required('')],
  position: [required('')],
};

let validators: Validators = {
  production: [required(messages?.general?.validations?.productionRequired)],
  periodEnding: [
    required(messages?.general?.validations?.periodEndingRequired),
  ],
  department: [required('')],
  position: [required('')],
};

const rightArrowStyles = {
  height: fontSize?.h3,
  width: fontSize?.h3,
  color: brand?.secondaryMain,
};

const iconStyles = {
  height: fontSize?.h4,
  width: fontSize?.h4,
  color: brandColour?.primary100,
};

// formatting Attachments
const formattedAttachments = (attachments: FileAttachment[]) => attachments?.map((attachment: FileAttachment) => ({
  file: { ...attachment },
  fileId: attachment?.filename,
}));

const formatTimedCardAttachments = (
  attachment: FileAttachment,
): FileAttachment => {
  const { size, ...rest }: FileAttachment = attachment;
  return rest;
};

const AddTimeCard = () => {
  const reduxDispatch = useDispatch();
  const { width } = useWindowSize();
  const location: Location = useLocation<LocationState>();
  const state = location.state as LocationState | undefined;
  const isUpdateForm = state?.data?.isUpdate;
  const timeCardListing: Timecard[] = state?.data?.timeCardListing;
  const timeCardDetails: Timecard = state?.data?.details;
  const companyOptions = state?.data?.companyListing?.map(
    (item: CompanyEntity) => ({
      id: item?.SysCoyGuid,
      label: item?.Name,
    }),
  );
  const selectedRegion = useSelector(
    (state: ReduxState) => state?.profile?.region,
  );
  const userProfile = useSelector((state: ReduxState) => state?.profile);
  const timeOption = userProfile?.TimeOption;

  const isMobile = width <= breakpoints.mobile;

  const [customFields, setCustomFields] = useState<CustomField[]>();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [yesterdayTimings, setYesterdayTimings] = useState<DailyTime>();
  const [submittingPreview, setSubmittingPreview] = useState<boolean>(false);
  const [submittingTimeCard, setSubmittingTimeCard] = useState<boolean>(false);
  const [uploadFile, setUploadFile] = useState<File>(null);
  const [weekData, setWeekData] = useState<DailyTime[]>([]);

  const [selectedCompanyDetails, setSelectedCompanyDetails] = useState<CompanyDetailedEntity>();
  const [selectedCompanyBriefDetails, setSelectedCompanyBriefDetails] = useState<CompanyEntity>(
    ((location?.state as LocationState)?.data as LocationStateData)
      ?.updatingCompanyBrief,
  );
  const [submitError, setSubmitError] = useState<string>('');
  const [showLoading, setShowLoading] = useState<boolean>(false)
  const [files, setFiles] = useState<FileAttachmentObject[]>(
    formattedAttachments(timeCardDetails?.FileAttachments) || [],
  );
  const [submitionAttachments, setSubmitionAttachments] = useState<
    AttachmentData[]
  >([]);
  const [dtApiUrl, setDtApiUrl] = useState<string>();
  const [screenIndex, setScreenIndex] = useState<Screens>({
    screen1: true,
    screen2: false,
    screen3: false,
  });
  const [submitTimeCardScreen, setSubmitTimeCardScreen] = useState<boolean>(false);

  const {
    visibility: editFormVisibility,
    showPopup: showEditForm,
    hidePopup: hideEditForm,
    metaData: editFormMetadata,
  } = usePopupReducer<{ data: DailyTime; rowIndex: number }>();
  const {
    visibility: digitalFormVisibility,
    showPopup: showDigitalForm,
    hidePopup: hideDigitalForm,
    metaData: DigitalFormMetadata,
  } = usePopupReducer();

  const {
    handleSubmit, connectField, change, formValues, addPristine, resetSpecific
  } = useFormReducer(validators);

  // Get region
  const getRegion = (): RegionTypes => (selectedRegion === RegionTypes.AU ? RegionTypes.AU : RegionTypes.UK);

  // Get/Set signatures
  const setUserSignature = async (url: string): Promise<string> => {
    const response = await fetch(url);
    const data = await response.blob();
    const metadata = {
      type: 'image/jpeg',
    };
    const file = new File([data], 'Signature.jpg', metadata);
    const reader = new FileReader();

    return new Promise<string>((resolve, reject) => {
      reader.onload = () => {
        if (reader.result) {
          resolve(reader.result as string);
        } else {
          reject(new Error('Failed to read file.'));
        }
      };
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  const getUserSignature = async (signatures: {
    signature?: string;
    typed_signature?: string;
  }): Promise<SignatureObject> => {
    const response = await fetch(signatures.signature || '');

    if (response.ok) {
      const signature = await setUserSignature(signatures.signature || '');
      return { base64: signatures.signature || '', url: signature };
    }

    if (selectedCompanyDetails?.disabledTypedSig) {
      return { base64: '', url: '' };
    }
    const responseTyped = await fetch(signatures.typed_signature || '');
    if (responseTyped.ok) {
      const signature = await setUserSignature(
        signatures.typed_signature || '',
      );
      return { base64: signatures.signature || '', url: signature };
    }
    return { base64: '', url: '' };
  };

  //Fetch attachments
  const fetchEmailAttachments = async () => {
    if (files?.length === 0) return;  
    const attachmentPromises = files.map(async (attachment: FileAttachmentObject) => {
      const sanitizedBody = {
        upload_path: attachment?.file?.url,
      };
  
      return new Promise<AttachmentData>((resolve, reject) => {
        handleApiCall(
          reduxDispatch,
          getRegion() === RegionTypes.AU ? FETCH_ATTACHMENT_AU : FETCH_ATTACHMENT_UK,
          async (res) => {
            if (res?.status) {
              try {
                let fetchData = await fetch(res.result);
                let data = await fetchData.blob();
                const metadata = {
                  type: attachment?.file?.ContentType,
                };
                let file = new File([data], attachment?.file?.filename, metadata);
                const sizeInMB = (file.size / (1024 * 1024)).toFixed(2);
  
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                  const attachmentData: AttachmentData = {
                    filename: attachment?.file?.filename,
                    content: reader.result?.toString().replace(/^data:.+;base64,/, '') || '',
                    encoding: 'base64',
                    size: sizeInMB,
                  };
                  resolve(attachmentData);
                };
                reader.onerror = reject;
              } catch (error) {
                reject(error); 
              }
            } else {
              reject(new Error("Failed to fetch attachment"));
            }
          },
          HttpMethods.POST,
          getRegion(),
          null,
          sanitizedBody
        );
      });
    });
  
    try {
      const attachmentsData = await Promise.all(attachmentPromises);
      setSubmitionAttachments(attachmentsData);
    } catch (error) {
      console.error("Error fetching attachments:", error);
    }
  };
  
  useEffect(() => {
    fetchEmailAttachments()
  },[])
  // Create activity
  const handleCreateActivityLog = async (message ?: string) => {
    const ipAddress = await getIPAddress();

    const sanitizedBody = {
      Description: message ? message : `Timecard has been ${isUpdateForm ? 'updated' : 'created'}`,
      IPAddress: ipAddress,
      Type: 'TIMECARD',
    };
    handleApiCall(
      reduxDispatch,
      selectedRegion === RegionTypes.AU
        ? CREATE_ACTIVITY_LOGS_AU
        : CREATE_ACTIVITY_LOGS_UK,
      () => {
        setSubmitting(false);
      },
      HttpMethods?.POST,
      getRegion(),
      null,
      sanitizedBody,
    );
  };

  // Validate week data
  const validateWeekData = (): boolean => {
    if (!weekData) return false;

    const hasNegativeTotalHours = weekData.some((day: DailyTime) => {
      const totalHoursStr = day.TotalHours || '00:00';
      const [hours, minutes] = totalHoursStr.split(':').map(Number);

      const totalDayMinutes = hours * 60 + minutes;

      return totalDayMinutes < 0;
    });

    return hasNegativeTotalHours;
  };

  // setting initial value on isUpdate
  const getDtAppiUrl = () => new Promise((_, reject) => {
    reduxDispatch(
      apiCall(
        selectedRegion === RegionTypes.AU
          ? GET_DT_API_URL_AU
          : GET_DT_API_URL_UK,
        (res) => {
          if (res?.status) {
            setDtApiUrl(res?.dt_api_url);
          }
        },
        (error) => {
          fetchAuthSession({ forceRefresh: true });
        },
        HttpMethods.GET,
      ),
    );
  });

  useEffect(() => {
    if (isUpdateForm) {
      change('production', {
        id: timeCardDetails?.SysCoyGuid,
        label: timeCardDetails?.Company[0]?.Name,
      });
    }
    getDtAppiUrl();
  }, []);

  // Fetching Selected company details by Id
  const fetchCompanyDetailsById = (companyId: string) => {
    handleApiCall(
      reduxDispatch,
      `${
        selectedRegion === RegionTypes.AU
          ? GET_SYS_COMPANY_BY_ID_AU
          : GET_SYS_COMPANY_BY_ID_UK
      }/${companyId || formValues?.companyId?.value}`,
      (companyObj) => {
        setSelectedCompanyDetails(companyObj?.result);
        setCustomFields(companyObj?.result?.CustomFields);
      },
      HttpMethods?.GET,
      getRegion(),
    );
  };

  useEffect(() => {
    if (formValues?.production?.value) {
        fetchCompanyDetailsById(formValues?.production?.value?.id);
      const briefCompanyDetailsObject = (
        location?.state as LocationState
      )?.data?.companyListing?.find(
        (company: CompanyEntity) => company?.SysCoyGuid === formValues?.production?.value?.id,
      );

      setSelectedCompanyBriefDetails(briefCompanyDetailsObject);
      validators = defaultValidators;
      resetSpecific('production')
    }
  }, [formValues?.production?.value]);

  // setting initial field values and validators for the company
  useEffect(() => {
    const newValidators: Validators = { ...validators };
    selectedCompanyDetails?.CustomFields?.forEach((field: CustomField) => {
      if (field?.required === 1) {
        newValidators[toCamelCase(field?.Prompt)] = [
          required('Field cannot be empty, please enter some value.'),
        ];
      }
    });

    if (selectedCompanyDetails?.requiredFeilds) {
      if (selectedCompanyDetails?.requiredFeilds?.department) {
        newValidators[
          selectedCompanyDetails?.departments ? 'departments' : 'department'
        ] = [
          required(
            messages?.general?.validations?.[
              selectedCompanyDetails?.departments
                ? 'departmentsRequired'
                : 'departmentRequired'
            ],
          ),
        ];
      }
      if (selectedCompanyDetails?.requiredFeilds?.position) {
        newValidators.position = [
          required(messages?.general?.validations?.positionRequired),
        ];
      }
    }

    validators = newValidators;

    if (
      isUpdateForm
      && timeCardDetails?.SysCoyGuid === formValues?.production?.value?.id
    ) {
      timeCardDetails?.CustomTimeCardFields?.forEach((field: CustomField) => {
        change(toCamelCase(field?.Prompt), field?.Value);
      });
    } else if (selectedCompanyDetails) {
      selectedCompanyDetails?.CustomFields?.forEach((field: CustomField) => {
        change(toCamelCase(field?.Prompt), field?.Value);
      });
    }
    if (selectedCompanyDetails?.departments) {
      change(
        'departments',
        isUpdateForm && timeCardDetails?.Dept &&
          { id: timeCardDetails?.Dept, label: timeCardDetails?.Dept }
      );
    }

    if (isUpdateForm) {
      if (selectedCompanyDetails?.departments) {
        change(
          'departments',
          timeCardDetails?.Dept && { id: timeCardDetails?.Dept, label: timeCardDetails?.Dept },
        );
      } else {
        change('department', timeCardDetails?.Dept);
      }
    }
    addPristine();
  }, [selectedCompanyDetails]);

  useEffect(() => {
    if (
      isUpdateForm
      && timeCardDetails?.SysCoyGuid === formValues?.production?.value?.id
    ) {
      if (selectedCompanyDetails?.departments) {
        change(
          'departments',
          { id: timeCardDetails?.Dept, label: timeCardDetails?.Dept } || null,
        );
      } else {
        change('department', timeCardDetails?.Dept);
      }
      change('position', timeCardDetails?.Position);
      change('periodEnding', moment(timeCardDetails?.PeriodEnding));
    } else if (selectedCompanyBriefDetails) {
      change('department', selectedCompanyBriefDetails?.Dept);
      change('position', selectedCompanyBriefDetails?.Position);
      change('periodEnding', null);
      setWeekData(null);
    }
  }, [selectedCompanyBriefDetails, formValues?.production?.value]);

  // Setting calender based on selected date
  useEffect(() => {
    if (
      isUpdateForm
      && moment(timeCardDetails?.PeriodEnding).format('DD/MM/YYYY')
        === formValues?.periodEnding?.value?.format('DD/MM/YYYY')
    ) {
      setWeekData(timeCardDetails?.Times[0]?.DailyTimes);
    } else if (formValues?.periodEnding?.value) {
      const tableData = generateWeekData(formValues?.periodEnding?.value);
      const updatedTableData = tableData?.map((day: DailyTime) => ({
        ...day,
        CustomTimeFields: selectedCompanyDetails?.CustomTimeFields || [],
      }));
      setWeekData(updatedTableData);
    }
  }, [formValues?.periodEnding?.value]);

  // Functions for forming the body of adding timeCard
  const updateCustomFieldValues = (
    data: FormValuesData,
    customFields: CustomField[],
  ): CustomField[] => {
    const updatedCustomFields = customFields?.map((field) => ({ ...field }));

    const updatedFields = updatedCustomFields?.map((field: CustomField) => {
      const fieldKey = toCamelCase(field?.Prompt);
      
      let value: string | number | null = null;
      if (data?.[fieldKey]) {
        if (typeof data?.[fieldKey] === 'object') {
          value = (data?.[fieldKey] as FormFieldValue)?.label || null;
        } else if(typeof data?.[fieldKey] === 'boolean') {
          value = (data?.[fieldKey] as boolean) ? '1' : null 
        } else {
          value = data?.[fieldKey] as string;
        }
      }
      return {
        ...field,
        Value: value,
      };
    });

    return updatedFields;
  };
  const updateFileAttachmentsValue = (): FileAttachment[] => {
    const timeCardAttachments: FileAttachment[] = [];

    files?.forEach((fileObj: FileAttachmentObject) => {
      if (fileObj?.file) {
        const updatedFile: FileAttachment = formatTimedCardAttachments(
          fileObj?.file,
        );

        const updatedFileWithUrl = {...updatedFile, url : `documents/${userProfile?.Guid}/${updatedFile?.filename}`}
        timeCardAttachments.push(updatedFileWithUrl);
      }
    });

    return timeCardAttachments;
  };
  const updateEmailAttachmentValue = () => {
    const updatedSubmissionResult = submitionAttachments?.filter(
      (subAttach: AttachmentData) => files?.some(
        (fileObj: FileAttachmentObject) => fileObj?.file?.filename === subAttach?.filename,
      ),
    );

    return updatedSubmissionResult;
  };
  const getTotalHours = (): string => {
    if (!weekData || weekData.length === 0) {
      return '00:00';
    }

    let totalMinutes = 0;
    weekData.forEach((day: DailyTime) => {
      const totalHoursStr = day.TotalHours || '00:00';
      const [hours, minutes] = totalHoursStr.split(':').map(Number);

      const totalDayMinutes = hours * 60 + minutes;

      if (totalDayMinutes >= 0) {
        totalMinutes += totalDayMinutes;
      }
    });

    const totalHours = Math.floor(totalMinutes / 60);
    const remainingMinutes = totalMinutes % 60;

    const formattedTotalHours = String(totalHours).padStart(2, '0');
    const formattedRemainingMinutes = String(remainingMinutes).padStart(2, '0');

    return `${formattedTotalHours}:${formattedRemainingMinutes}`;
  };

  // Uploading attachments
  const handleUploadAttachment = (
    {
      upload_path,
      content_type,
      base64Data,
    }: {
      upload_path: string;
      content_type: string;
      base64Data: string | ArrayBuffer;
    },
    attachment?: AttachmentData,
    submitCall ?: () => void
  ) => {
    const sanitizedBody = {
      upload_path,
      content_type,
      base64Data,
    };
    setShowLoading(true)
    handleApiCall(
      reduxDispatch,
      selectedRegion === RegionTypes.AU
        ? UPLOAD_ATTACHMENT_AU
        : UPLOAD_ATTACHMENT_UK,
      (res) => {
        if(submitCall) {
          submitCall()
          return 
        }
        if (res?.status) {
          setShowLoading(false)
          if (attachment) {
            setSubmitionAttachments((prev: AttachmentData[]) => [
              ...prev,
              attachment,
            ]);
          }
        } else {
          toast(
            <Toast type="error" text="Error in uploading, Please try again." />,
          );
        }
      },
      HttpMethods.POST,
      getRegion(),
      null,
      sanitizedBody,
    );
  };
  const handleUploadFile = async (file: File) => {
    const ext = file?.name.split('.').pop();
    const file_name = files?.find(
      (fileObj: FileAttachmentObject) => fileObj?.fileId === file?.name,
    )?.file?.filename;
    const upload_path = `documents/${userProfile?.Guid}/${file_name}`;
    const sizeInMB = (file.size / (1024 * 1024)).toFixed(2);
    const content_type = file.type;
    const reader = new FileReader();

    const fileReadPromise = new Promise<string>((resolve, reject) => {
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = reject;
    });

    reader.readAsDataURL(file);

    const result = await fileReadPromise;

    const attachment: AttachmentData = {
      filename: file_name,
      content: result.replace(/^data:.+;base64,/, ''),
      encoding: 'base64',
      size: sizeInMB,
    };

    await handleUploadAttachment(
      {
        upload_path,
        content_type,
        base64Data: reader.result,
      },
      attachment,
    );
  };
  useEffect(() => {
    if (uploadFile) {
      handleUploadFile(uploadFile);
    }
  }, [uploadFile]);

  // Saving/Updating timeCard
  const onSaveTimeCard = async (data: FormValuesData): Promise<void> => {
    if (validateWeekData()) {
      toast(
        <Toast
          type="error"
          text={messages?.general?.errors?.invalidTotalHrs}
        />,
      );
      return;
    }

    const periodEnding: Moment | undefined = moment.isMoment(data?.periodEnding)
      ? data?.periodEnding
      : undefined;
      
    const sanitizedBody = {
      Activity: timeCardDetails?.Activity ? timeCardDetails?.Activity : "//",
      Company: [
        {
          Guid: selectedCompanyBriefDetails?.Guid,
          Name: selectedCompanyDetails?.Name || null,
          Payas: selectedCompanyDetails?.Payas,
          Production: selectedCompanyDetails?.Production || null,
        },
      ],
      CustomTimeCardFields: updateCustomFieldValues(
        data,
        selectedCompanyDetails?.CustomFields,
      ),
      Dept: selectedCompanyDetails?.departments
        ? (data?.departments as FormFieldValue)?.label || null
        : data?.department || null,
      FileAttachments: updateFileAttachmentsValue(),
      PayFrequency: selectedCompanyDetails?.PayFrequency,
      PeriodEnding: periodEnding
        ? periodEnding?.format('YYYY-MM-DD')
        : null,
      Position: data?.position,
      Times: [
        {
          CustomTimeFields: selectedCompanyDetails?.CustomTimeFields,
          DailyTimes: weekData,
          TotalHours : getTotalHours()
        },
      ],
      TotalHours: getTotalHours(),
      Type: 'Draft',
      SysCoyGuid: selectedCompanyBriefDetails?.SysCoyGuid,
    }

    const getEndpoint = (): string => {
      if (selectedRegion === RegionTypes.AU) {
        return isUpdateForm
          ? `${UPDATE_TIMECARD_AU}/${timeCardDetails?.Guid}`
          : CREATE_TIMECARD_AU;
      }
      return isUpdateForm
        ? `${UPDATE_TIMECARD_UK}/${timeCardDetails?.Guid}`
        : CREATE_TIMECARD_UK;
    };

    setSubmitting(true);

    try {
      await handleApiCall(
        reduxDispatch,
        getEndpoint(),
        () => {
          if (isUpdateForm) {
            setSubmitting(false);
          } 
          handleCreateActivityLog();
          reduxDispatch(goBack())
        },
        isUpdateForm ? HttpMethods.PUT : HttpMethods.POST,
        getRegion(),
        messages?.timeCard?.form?.success?.[
          isUpdateForm ? 'udpateHeading' : 'savedHeading'
        ],
        sanitizedBody,
        setSubmitError,
        () => {
          setSubmitting(false)
        }
      );
    } finally {
      setSubmitting(false);
    }
  };
  const timeInputField = (
    row: string,
    fieldKey: keyof DailyTime,
    rowIndex: number,
  ) => (
    <TimeInputField
      setWeekData={setWeekData}
      fieldKey={fieldKey}
      rowIndex={rowIndex}
      value={row}
      timeOption={timeOption}
    />
  );
  const dropListField = (
    row: string,
    fieldKey: keyof DailyTime,
    rowIndex: number,
  ) => (
    <DropListInput
      setWeekData={setWeekData}
      list={selectedCompanyDetails?.Payas}
      fieldKey={fieldKey}
      rowIndex={rowIndex}
      periodEnding={formValues?.periodEnding?.value}
      value={row}
    />
  );
  const textInputField = (
    row: string,
    fieldKey: keyof DailyTime,
    rowIndex: number,
  ) => (
    <TextInputField
      fieldKey={fieldKey}
      setWeekData={setWeekData}
      rowIndex={rowIndex}
      value={row}
    />
  );

  // Submitting TimeCard
  const handleUpdateDocumentType = (timeCardResponse: TimeCardResponse,file_name : string) => {
      var sanitizedBody = {
        Company: selectedCompanyBriefDetails?.Name,
        ContentType: "application/pdf",
        DocDesc: "Crew Timesheet",
        DocumentType: "PDF",
        Encrypted: "No",
        Filename: file_name,
        Production: selectedCompanyBriefDetails?.Production,
        PWHash: "f56e46w#3fefew",
        Salt: "GYUFYDTFKHFYV",
        url: file_name,
        TimecardGuid: timeCardResponse?.result?.Guid,
        DocType: "Timecard",
      };

      handleApiCall(
        reduxDispatch,
        selectedRegion === RegionTypes.AU
          ? ADD_DOCUMENT_AU
          : ADD_DOCUMENT_UK,
        null,
        HttpMethods.POST,
        getRegion(),
        null,
        sanitizedBody,
        null,
        (errMessage : string) => {
          toast(<Toast type='error' text={errMessage} />)
          setSubmittingTimeCard(false)
        }
      );
  }
  const handleSubmitEmail = (
    timeCardResponse: TimeCardResponse,
    timesheetImage: string,
    email: string
  ) => {
    const subject = `${userProfile?.LastName} ${userProfile?.FirstName} ${moment(
      new Date(timeCardResponse?.result?.PeriodEnding),
    ).format('DDMMMMYYYY')} `
      + ` ${timeCardResponse?.result?.Dept && timeCardResponse?.result?.Dept} ${timeCardResponse?.result?.Position && timeCardResponse?.result?.Position} ${timeCardResponse?.result?.Company[0]?.Production}`;

    const subject2 = `${userProfile?.LastName}_${userProfile?.FirstName}_${moment(
      new Date(timeCardResponse?.result?.PeriodEnding),
    ).format('DDMMMMYYYY')}_${timeCardResponse?.result?.Dept}_${timeCardResponse?.result?.Position}_${timeCardResponse?.result?.Company[0]?.Production}`

    const sanitizedBody = {
      to: `${email || selectedCompanyBriefDetails?.EmailAddress},${userProfile?.EmailAddress}`,
      subject : subject2,
      message: 'Timecard attached',
      time_sheet_name: subject2,
      Preview: timesheetImage,
      AttachmentDocs: submitionAttachments,
    };

    handleApiCall(
      reduxDispatch,
      selectedRegion === RegionTypes.AU ? SEND_EMAIL_AU : SEND_EMAIL_UK,
      (res) => {
        if (res?.status) {
          handleCreateActivityLog();
          reduxDispatch(goBack())
        }
      },
      HttpMethods.POST,
      getRegion(),
      messages?.timeCard?.form?.success?.submittedSuccess,
      sanitizedBody,
      null,
      (errorMessage : string) => {
        toast(<Toast type='error' text={errorMessage} />)
        setSubmittingTimeCard(false)
        hideDigitalForm()
        reduxDispatch(goBack())
      }
    );
  };
  const updateTimeCardStatus = (
    timeCardResponse: TimeCardResponse,
    timesheetImage: string,
    email : string
  ) => {
    const sanitizedBody = {
      Guid: timeCardResponse?.result?.Guid,
      Type: 'submitted',
    };
    const file_name = `${new Date().getTime()}-${
      timeCardResponse?.result?.Guid
    }.pdf`;
    const upload_path = `documents/${userProfile?.Guid}/${file_name}`;
    handleApiCall(
      reduxDispatch,
      selectedRegion === RegionTypes.AU
        ? UPDATE_TIMECARD_STATUS_AU
        : UPDATE_TIMECARD_STATUS_UK,
      (res) => {
        const message = "Timecard has been submitted" 
        handleCreateActivityLog(message)
        handleUploadAttachment({
          upload_path,
          content_type: 'application/pdf',
          base64Data: timesheetImage,
        });
        handleUpdateDocumentType(timeCardResponse, file_name)
        handleSubmitEmail(timeCardResponse, timesheetImage, email);
      },
      HttpMethods.PUT,
      getRegion(),
      null,
      sanitizedBody,
      null,
      (errMessage : string) => {
        toast(<Toast type='error' text={errMessage} />)
        setSubmittingTimeCard(false)
      }
    );
  };
  const handleCreateprlempOnSubmit = async (
    freelancerData: ProfileState,
    timeCardResponse: TimeCardResponse,
    timesheetImage: string,
    signatureObj: SignatureObject,
    email : string
  ) => {
    if (timeCardResponse?.status) {
      const timecarddata = timeCardResponse?.result;

      const Employee: unknown = [];
      const freelanceuser = freelancerData || false;
      const ADTimes: unknown = [];
      const current_approver = 'No user';
      const approve_status = 'Draft';
      const group_guid: unknown = null;
      const tmp_app: unknown = [];

      const finaldata: FinalData = {
        approver_chain: tmp_app,
        group_guid,
        current_approver,
        approve_status,
        ChequeNumber: null,
        PayslipNote: null,
        WorkState: null,
        PayWeek: null,
        ChartSort: null,
        ChartCode: null,
        Flags: null,
        InternalNotes: null,
        PaymentFlags: null,
        PeriodEndingDate: null,
        ADTimes,
        PRLTimes: timecarddata,
        PayTimes: timecarddata,
        Batch: 1,
        Employee,
        freelanceuser,
        TenantGuid: selectedCompanyBriefDetails?.SysCoyGuid,
        comments: [],
        changes: [],
        activities: [
          {
            date: moment(Date.now()).format('l h:mma'),
            msg: 'Timecard created by Freelance Portal.',
          },
        ],
        senderid: null,
        timesheetImage: timesheetImage
          ? `data:application/pdf;base64,${timesheetImage}`
          : false,
        freelanceuser_signature: signatureObj ? signatureObj?.url : null,
        freelanceuser_email: userProfile?.EmailAddress
          ? userProfile?.EmailAddress
          : null,
        EmailAttachments: updateEmailAttachmentValue(),
      };

      if (freelancerData) {
        reduxDispatch(
          apiCall(
            `${dtApiUrl}${CREATE_PRLEMP}`,
            (result) => {
              const res = result?.TimecardWebhook_response?.result[0];
              if (res.status === 'Success') {
                updateTimeCardStatus(timeCardResponse, timesheetImage, email);
              }
            },
            (err) => {
              <Toast
                type="error"
                text="Timecard has been saved, but not submitted. Please try again."
              />;
            },
            HttpMethods.POST,
            finaldata,
          ),
        );
      }
    } else {
      toast(
        <Toast
          type="error"
          text={
            messages?.timeCard?.digitalTimeSheet?.error?.[
              isUpdateForm ? 'editTimeCardError' : 'createTimeCardError'
            ]
          }
        />,
      );
    }
  };
  const handleGetUserOnSubmit = (
    timesheetImage: string,
    timeCardResponse: TimeCardResponse,
    signatureObj: SignatureObject,
    email: string
  ) => new Promise((resolve, reject) => {
    reduxDispatch(
      apiCall(
        selectedRegion === RegionTypes.AU ? GET_USERS_AUS : GET_USERS_UK,
        (res) => {
          if (res?.status) {
            handleCreateprlempOnSubmit(
              res?.result,
              timeCardResponse,
              timesheetImage,
              signatureObj,
              email
            );
          } else {
            toast(
              <Toast
                type="error"
                text={
                    messages?.timeCard?.digitalTimeSheet?.error?.getUserError
                  }
              />,
            );
          }
        },
        (err) => {
          toast(<Toast type="error" text={err || messages?.timeCard?.digitalTimeSheet?.error?.getUserError} />);
          setSubmittingTimeCard(false)
        },
        HttpMethods.GET,
      ),
    );
  });
  const handlePdfOnSubmit = (
    signatureObj: SignatureObject,
    data: FormValuesData,
    timeCardResponse: TimeCardResponse,
    email:string
  ) => {
    const periodEnding: Moment = moment.isMoment(data?.periodEnding) && data?.periodEnding;
    const sanitizedBody = {
      mrtFile: selectedCompanyDetails?.Timesheet || 'default',
      status: true,
      items: [
          {
            UserFirstName: userProfile?.FirstName ? userProfile?.FirstName : null,
            UserLastName: userProfile?.LastName ? userProfile?.LastName : null,
            Signature: signatureObj?.base64,
            CreatedDate: moment(new Date()).format('DD/MM/YYYY'),
            Activity: timeCardDetails?.Activity || '//',
            Company: timeCardDetails?.Company
            ? [
              {
                Guid: timeCardDetails?.Company[0].Guid,
                Name: timeCardDetails?.Company[0].Name
                  ? timeCardDetails?.Company[0].Name
                  : null,
                Payas: selectedCompanyDetails?.Payas
                  ? selectedCompanyDetails?.Payas
                  : null,
                Production: selectedCompanyDetails?.Production
                  ? selectedCompanyDetails?.Production
                  : null,
              },
            ]
            : [
              {
                Guid: selectedCompanyBriefDetails?.Guid,
                Name: selectedCompanyBriefDetails?.Name
                  ? selectedCompanyBriefDetails?.Name
                  : null,
                Payas: selectedCompanyDetails?.Payas
                  ? selectedCompanyDetails?.Payas
                  : null,
                Production: selectedCompanyDetails?.Production
                  ? selectedCompanyDetails?.Production
                  : null,
              },
          ],
          CustomTimeCardFields: updateCustomFieldValues(
            data,
            selectedCompanyDetails?.CustomFields,
          ),
          Dept: selectedCompanyDetails?.departments
          ? (data?.departments as FormFieldValue)?.label || null
          : data?.department || null,
          FileAttachments: updateFileAttachmentsValue(),
          PayFrequency: selectedCompanyDetails?.PayFrequency,
          PeriodEnding:  periodEnding?.format('YYYY/MM/DD'),
          Position: data?.position ? data?.position : null,
          EmailTo: selectedCompanyBriefDetails?.EmailAddress,
          IPAddress: getIPAddress(),
          Times: [
            {
              CustomTimeFields: selectedCompanyDetails?.CustomTimeFields,
              DailyTimes: weekData,
              TotalHours: getTotalHours(),
            },
          ],
          TotalHours: getTotalHours(),
          Type: 'submitted',
        }
      ]
    }
    handleApiCall(
      reduxDispatch,
      selectedRegion === RegionTypes.AU ? PREVIEW_PDF_AU : PREVIEW_PDF_UK,
      (response) => {
        if (response.status) {
          handleGetUserOnSubmit(
            response?.pdfData,
            timeCardResponse,
            signatureObj,
            email
          );
        }
      },
      HttpMethods.POST,
      getRegion(),
      null,
      sanitizedBody,
      null,
      (errMessage : string) => {
        toast(<Toast type='error' text={errMessage || messages?.timeCard?.digitalTimeSheet?.error?.createPdfError} />)
        setSubmittingTimeCard(false)
      }
    );
  };
  const saveEditTimeCardOnSubmit = (
    data: FormValuesData,
    signatureObj: SignatureObject,
    email : string
  ) => {
    const periodEnding: Moment = moment.isMoment(data?.periodEnding) && data?.periodEnding;
    const sanitizedBody = {
      Activity: timeCardDetails?.Activity ? timeCardDetails?.Activity : "//",
      Company: [
        {
          Guid: selectedCompanyBriefDetails?.Guid,
          Name: selectedCompanyDetails?.Name || null,
          Payas: selectedCompanyDetails?.Payas,
          Production: selectedCompanyDetails?.Production || null,
        },
      ],
      CustomTimeCardFields: updateCustomFieldValues(
        data,
        selectedCompanyDetails?.CustomFields,
      ),
      Dept: selectedCompanyDetails?.departments
      ? (data?.departments as FormFieldValue)?.label || null
      : data?.department || null,
      FileAttachments: updateFileAttachmentsValue(),
      PayFrequency: selectedCompanyDetails?.PayFrequency,
      PeriodEnding: periodEnding
        ? periodEnding?.format('YYYY-MM-DD')
        : null,
      Position: data?.position || null,
      Times: [
        {
          CustomTimeFields: selectedCompanyDetails?.CustomTimeFields,
          DailyTimes: weekData,
          TotalHours : getTotalHours()
        },
      ],
      TotalHours: getTotalHours(),
      Type: 'Draft',
      SysCoyGuid: selectedCompanyBriefDetails?.SysCoyGuid,
    };

    const getEndpoint = (): string => {
      if (selectedRegion === RegionTypes.AU) {
        return isUpdateForm
          ? `${UPDATE_TIMECARD_AU}/${timeCardDetails?.Guid}`
          : CREATE_TIMECARD_AU;
      }
      return isUpdateForm
        ? `${UPDATE_TIMECARD_UK}/${timeCardDetails?.Guid}`
        : CREATE_TIMECARD_UK;
    };
    setSubmitting(true);
    handleApiCall(
      reduxDispatch,
      getEndpoint(),
      (res) => {
        if (res?.status) {
          handlePdfOnSubmit(signatureObj, data, res, email);
        } else {
          toast(
            <Toast
              type="error"
              text={
                messages?.timeCard?.digitalTimeSheet?.error?.[
                  isUpdateForm ? 'editTimeCardError' : 'createTimeCardError'
                ]
              }
            />,
          );
        }
      },
      isUpdateForm ? HttpMethods.PUT : HttpMethods.POST,
      getRegion(),
      null,
      sanitizedBody,
      null,
      (errMessage : string) => {
        toast(<Toast type='error' text={errMessage} />)
        setSubmittingTimeCard(false)
      }
    );
  };
  const onSubmitTimeCard = (email : string) => {
    if (validateWeekData()) {
      toast(
        <Toast
          type="error"
          text="One or more entries have negative total hours for a day."
        />,
      );
      return;
    }

    const data: NewFormValues = convertFormValues(formValues);
    
    handleApiCall(
      reduxDispatch,
      selectedRegion === RegionTypes.AU ? GET_SIGNATURE_AU : GET_SIGNATURE_UK,
      async (res) => {
        if (res?.status) {
          const signatureObj: SignatureObject = await getUserSignature(
            res?.result,
          );

          if (signatureObj?.base64 === null) {
            if (selectedCompanyDetails?.disabledTypedSig) {
              toast(<Toast type="error" text="Drawn signature required" />);
            } else {
              toast(<Toast type="error" text="Signature required" />);
            }
            return;
          }
          saveEditTimeCardOnSubmit(data, signatureObj, email);
        } else {
          toast(
            <Toast
              type="error"
              text={
                messages?.timeCard?.digitalTimeSheet?.error?.fetchSignatureError
              }
            />,
          );
        }
        setSubmittingPreview(false);
      },
      HttpMethods.GET,
      getRegion(),
      null,
      null,
      null,
      (errMessage : string) => {
        toast(<Toast type='error' text={errMessage} />)
        setSubmittingTimeCard(false)
      }
    );
  };

  // Preview
  const handlePdf = (signature: string, data: FormValuesData) => {
    const periodEnding: Moment = moment.isMoment(data?.periodEnding) && data?.periodEnding;
    const sanitizedBody = {
      mrtFile: selectedCompanyDetails?.Timesheet || 'default',
      status: true,
      items: [
        {
          UserFirstName: userProfile?.FirstName ? userProfile?.FirstName : null,
          UserLastName: userProfile?.LastName ? userProfile?.LastName : null,
          Signature: signature,
          CreatedDate: moment(new Date()).format('DD/MM/YYYY'),
          Activity: timeCardDetails?.Activity || '//',
          Company: timeCardDetails?.Company
            ? [
              {
                Guid: timeCardDetails?.Company[0].Guid,
                Name: timeCardDetails?.Company[0].Name
                  ? timeCardDetails?.Company[0].Name
                  : null,
                Payas: selectedCompanyDetails?.Payas
                  ? selectedCompanyDetails?.Payas
                  : null,
                Production: selectedCompanyDetails?.Production
                  ? selectedCompanyDetails?.Production
                  : null,
              },
            ]
            : [
              {
                Guid: selectedCompanyBriefDetails?.Guid,
                Name: selectedCompanyBriefDetails?.Name
                  ? selectedCompanyBriefDetails?.Name
                  : null,
                Payas: selectedCompanyDetails?.Payas
                  ? selectedCompanyDetails?.Payas
                  : null,
                Production: selectedCompanyDetails?.Production
                  ? selectedCompanyDetails?.Production
                  : null,
              },
            ],
            CustomTimeCardFields: updateCustomFieldValues(
              data,
              selectedCompanyDetails?.CustomFields,
            ),
            Dept: selectedCompanyDetails?.departments
            ? (data?.departments as FormFieldValue)?.label || null
            : data?.department || null,
            FileAttachments: updateFileAttachmentsValue(),
            PayFrequency: selectedCompanyDetails?.PayFrequency,
            PeriodEnding: periodEnding?.format('YYYY/MM/DD'),
            Position: data?.position ? data?.position : null,
            EmailTo: selectedCompanyBriefDetails?.EmailAddress,
            IPAddress: getIPAddress(),
            Times: [
              {
                // CustomTimeFields: selectedCompanyDetails?.CustomTimeFields,
                DailyTimes: weekData,
                TotalHours: getTotalHours(),
              },
            ],
            TotalHours: getTotalHours(),
            Type: 'Draft',
          }
      ]
    }
    handleApiCall(
      reduxDispatch,
      selectedRegion === RegionTypes.AU ? PREVIEW_PDF_AU : PREVIEW_PDF_UK,
      (response) => {
        if (response?.pdfData) {
          try {
            const pdfWindow = window.open();
            if (pdfWindow) {
              pdfWindow.document.open();
              pdfWindow.document.write(
                `<html><head><title>Preview</title></head><body style='margin:0; text-align: center;'><style>body{margin:0;} .previewLoading { display:none; } </style><iframe width='100%'  height='1000' style='border:0;' src='data:application/pdf;base64, ${encodeURIComponent(
                  response.pdfData,
                )}'></iframe></body></html>`,
              );
              pdfWindow.document.close();
            } else {
              toast(
                <Toast
                  type="error"
                  text="Failed to open a new window for PDF preview."
                />,
              );
            }
          } catch (error) {
            toast(
              <Toast
                type="error"
                text={`An error occurred while trying to preview the PDF: ${error}`}
              />,
            );
          }
        } else {
          toast(
            <Toast type="error" text="No PDF data found in the response." />,
          );
        }
      },
      HttpMethods.POST,
      getRegion(),
      null,
      sanitizedBody,
    );
  };
  const handlePreviewClick = async (data: FormValuesData): Promise<void> => {
    setSubmittingPreview(true);

    try {
      await handleApiCall(
        reduxDispatch,
        selectedRegion === RegionTypes.AU ? GET_SIGNATURE_AU : GET_SIGNATURE_UK,
        async (res) => {
          if (res?.status) {
            const signatureObj: SignatureObject = await getUserSignature(
              res?.result,
            );

            if (signatureObj?.base64 === '') {
              const errorMessage = selectedCompanyDetails?.disabledTypedSig
                ? 'Drawn signature required'
                : 'Signature required';
              toast(<Toast type="error" text={errorMessage} />);
              return;
            }

            handlePdf(signatureObj?.base64, data);
          }
        },
        HttpMethods.GET,
        getRegion(),
      );
    } finally {
      setSubmittingPreview(false);
    }
  };

  // Copy last weeks data
  const findTimeCard = (SysCoyGuid: string): Timecard | null => {
    const periodEnding: Moment = formValues?.periodEnding?.value;
    for (var i = 0; i <= timeCardListing.length - 1; i++) {
      if (
        timeCardListing[i].Company.length > 0 &&
        timeCardListing[i].Company[0].Guid === SysCoyGuid &&
        (isUpdateForm ? timeCardListing[i]?.Guid !== timeCardDetails?.Guid : true)
      ) {
        
        var PeriodEnding = moment(new Date(timeCardListing[i].PeriodEnding)).format(
          "YYYY-MM-DD"
        );
        
        if (PeriodEnding < moment(periodEnding).format("YYYY-MM-DD")) {
          return timeCardListing[i];
        }
      }
    }
    return null;
  };
  const handleCopyLastWeeksData = () => {
    if (!formValues?.periodEnding?.value) {
      toast(
        <Toast type="error" text={messages?.timeCard?.selectPeriodEnding} />,
      );
      return;
    }

    const companyGuid = selectedCompanyBriefDetails?.Guid;

    const timeCard = findTimeCard(companyGuid);
    const previousWeekTimings = timeCard?.Times[0]?.DailyTimes;

    if (timeCard) {
      const updatedWeekData = previousWeekTimings?.map(
        (dayObj: DailyTime, index: number) => {
          const {
            Date, Day, Note, customNote, ...rest
          } = dayObj;
          return {
            ...rest,
            Date: weekData[index]?.Date,
            Day: weekData[index]?.Day,
            Note: null,
            customNote: null,
          };
        },
      );

      setWeekData(updatedWeekData);
    } else {
      toast(
        <Toast type="error" text={messages?.timeCard?.noTimeCardAvailable} />,
      );
    }
  };
  const handleCopyYesterdayTimings = () => {
    const currDay: DailyTime = editFormMetadata?.data;
    let lastDay: DailyTime;
    const index = editFormMetadata?.rowIndex;

    if (index === 0) {
      lastDay = getDefaultWeekDay(formValues?.periodEnding?.value);
    } else {
      lastDay = {
        ...weekData[index - 1],
        Date: currDay?.Date,
        Day: currDay?.Day,
        Note: null,
      };
    }

    setYesterdayTimings(lastDay);
  };
  const Screen1 = () => (
    <Container
      showGoBack
      heading={messages?.timeCard?.heading}
      background={otherColour?.adminBg100}
      maxHeight
    >
      <Card
        showGoBack
        noContentPadding={isMobile}
        removeTitle
        contentCss={isMobile && { padding: '0 16px 24px !important' }}
        header={(
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <StyledHeading
                fontSize={fontSize?.h2}
                fontWeight={fontWeight?.semiBold}
              >
                {isUpdateForm
                  ? messages?.timeCard?.form?.editHeading
                  : messages?.timeCard?.form?.heading}
              </StyledHeading>
            </Grid>
          </Grid>
        )}
      >
        <Form onSubmit={handleSubmit(onSaveTimeCard)}>
          <OutlinedContainer
            borderRadius="0"
            padding="16px 0 0 0"
            noBorder
            styles={{
              borderTop: `1px solid ${brandColour?.primary10}`,
              borderBottom: !isMobile && `1px solid ${brandColour?.primary10}`,
              marginBottom: '24px',
            }}
          >
            <FormRow>
              <StyledHeading
                fontSize={fontSize?.h4}
                fontWeight={fontWeight?.semiBold}
              >
                {messages?.timeCard?.form?.subHeading}
              </StyledHeading>
            </FormRow>
            <FormRow columnSpacing={2} rowSpacing={2}>
              <FormRowItem lg={3} md={3} sm={6} xs={12}>
                {connectField('production', {
                  label: messages?.timeCard?.form?.production,
                  options: companyOptions?.map(mapIdNameToOption),
                })(MaterialAutocompleteInput)}
              </FormRowItem>
              <FormRowItem lg={3} md={3} sm={6} xs={12}>
                {connectField('periodEnding', {
                  label: messages?.timeCard?.form?.periodEnding,
                  onlyVisibleDay: selectedCompanyDetails?.PayEnding,
                  disabled: !selectedCompanyDetails,
                })(DateInput)}
              </FormRowItem>
              {selectedCompanyDetails?.departments ? (
                <FormRowItem lg={3} md={3} sm={6} xs={12}>
                  {connectField('departments', {
                    label: messages?.timeCard?.form?.department,
                    options: selectedCompanyDetails?.departments?.map(
                      mapDepartmentNameToOption,
                    ),
                  })(MaterialAutocompleteInput)}
                </FormRowItem>
              ) : (
                <FormRowItem lg={3} md={3} sm={6} xs={12}>
                  {connectField('department', {
                    label: messages?.timeCard?.form?.department,
                  })(TextInput)}
                </FormRowItem>
              )}
              <FormRowItem lg={3} md={3} sm={6} xs={12}>
                {connectField('position', {
                  label: messages?.timeCard?.form?.position,
                })(TextInput)}
              </FormRowItem>
              {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
              <>
                {customFields?.length > 0
                  && customFields?.map((field: CustomField) => (
                    <FormRowItem lg={3} md={3} sm={6} xs={12}>
                      <CustomFieldComponent
                        field={field}
                        connectField={connectField}
                      />
                    </FormRowItem>
                  ))}
              </>
            </FormRow>
          </OutlinedContainer>

          {!isMobile ? (
            <>
              <FormRow display="flex" justifyContent="flex-end" marginTop={2}>
                <FixedTitle
                  varient="Fixed12Medium"
                  color={brandColour?.primary100}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '2px',
                    cursor: 'pointer',
                  }}
                  onClick={handleCopyLastWeeksData}
                >
                  <ContentCopyIcon style={iconStyles} />
                  {messages?.timeCard?.copyTimes}
                </FixedTitle>
              </FormRow>
              <FormRow marginBottom={2}>
                <Table
                  emptyMessage={
                    !formValues?.periodEnding?.value
                    && 'Please select period ending date.'
                  }
                  disableTableSorting
                  editableFields={[
                    'Start',
                    'TotalMBDed',
                    'Finish',
                    'Payas',
                    'Note',
                  ]}
                  data={weekData || []}
                  actions={[
                    {
                      id: 'edit',
                      component: (
                        <EditOutlineIcon
                          style={{
                            ...iconStyles,
                            color: brandColour?.primary100,
                          }}
                        />
                      ),
                      onClick: (row, _, rowIndex) => {
                        showEditForm({
                          data: weekData[rowIndex],
                          rowIndex,
                        });
                      },
                    },
                  ]}
                  specs={[
                    {
                      id: 'Date',
                      label: messages?.timeCard?.form?.table?.date,
                      // format: (row: Date) => row,
                    },
                    {
                      id: 'Day',
                      label: messages?.timeCard?.form?.table?.day,
                    },
                    {
                      id: 'Start',
                      label: messages?.timeCard?.form?.table?.start,
                      format: (
                        row: string,
                        fieldKey: keyof DailyTime,
                        rowIndex: number,
                      ) => timeInputField(row, fieldKey, rowIndex),
                    },
                    {
                      id: 'TotalMBDed',
                      label: messages?.timeCard?.form?.table?.meal,
                      format: (
                        row: string,
                        fieldKey: keyof DailyTime,
                        rowIndex: number,
                      ) => timeInputField(row, fieldKey, rowIndex),
                    },
                    {
                      id: 'Finish',
                      label: messages?.timeCard?.form?.table?.finish,
                      format: (
                        row: string,
                        fieldKey: keyof DailyTime,
                        rowIndex: number,
                      ) => timeInputField(row, fieldKey, rowIndex),
                    },
                    {
                      id: 'TotalHours',
                      label: messages?.timeCard?.form?.table?.hours,
                    },
                    {
                      id: 'Payas',
                      label: messages?.timeCard?.form?.table?.payAs,
                      format: (
                        row: string,
                        fieldKey: keyof DailyTime,
                        rowIndex: number,
                      ) => dropListField(row, fieldKey, rowIndex),
                    },
                    {
                      id: 'customNote',
                      label: messages?.timeCard?.form?.table?.note,
                      format: (
                        row: string,
                        fieldKey: keyof DailyTime,
                        rowIndex: number,
                      ) => textInputField(row, fieldKey, rowIndex),
                    },
                  ]}
                />
              </FormRow>
              <FormRow marginBottom={3} justifyContent="flex-end" columnGap={1}>
                <FixedTitle varient="Fixed14Regular">
                  {messages?.timeCard?.todalHrs}
                </FixedTitle>
                <FixedTitle varient="Fixed14Bold">{getTotalHours()}</FixedTitle>
              </FormRow>
            </>
          ) : (
            <OutlinedContainer
              padding="24px 16px"
              styles={{ cursor: 'pointer' }}
              onClick={() => {
                setScreenIndex({
                  screen1: false,
                  screen2: true,
                  screen3: false,
                });
              }}
            >
              <Row>
                <Column>
                  <StyledHeading
                    fontWeight={fontWeight?.semiBold}
                    fontSize={fontSize?.h5}
                    lineHeight="21px"
                  >
                    {messages?.timeCard?.todalHrs}
                    {' '}
                    {getTotalHours()}
                  </StyledHeading>
                  <FixedTitle
                    varient="Fixed12Regular"
                    color={greyScaleColour?.grey100}
                  >
                    The above hours are total working hours
                  </FixedTitle>
                </Column>
                <KeyboardArrowRightIcon style={rightArrowStyles} />
              </Row>
            </OutlinedContainer>
          )}

          <OutlinedContainer
            borderRadius="0"
            padding="24px 0"
            noBorder
            styles={{
              borderTop: `1px solid ${brandColour?.primary10}`,
            }}
          >
            <FormRow>
              <StyledHeading
                fontSize={fontSize?.h4}
                fontWeight={fontWeight?.semiBold}
                lineHeight="26.4px"
              >
                {messages?.timeCard?.form?.attachment}
              </StyledHeading>
            </FormRow>
            <Grid container>
              {connectField('fileUpload', {
                files,
                setFiles,
                uploadFile,
                setUploadFile,
                acceptedFiles: '.pdf, image/png, image/jpg, image/jpeg',
                isPictureView: true,
                multiple: true,
                row: !isMobile,
                showLoading,
                warningSize: 7,
                fullWidth: false,
                showProgress: true
              })(DragAndDrop)}
            </Grid>
          </OutlinedContainer>
          {
            submitError &&  <FormRow>
            <FormRowItem>
              <FormError message={submitError} />
            </FormRowItem>
          </FormRow>
          }
          <Grid container justifyContent="space-between" marginBottom={3}>
            <Grid item>
              <Grid container spacing={2}>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="large"
                    type="submit"
                    disabled={submitting}
                    buttonColor={isMobile && brandColour?.primary100}
                    label={
                      messages?.general?.[isUpdateForm ? 'update' : 'save']
                    }
                  />
                </Grid>
                {!isMobile && (
                  <Grid item>
                    <Button
                      variant="outlined"
                      buttonColor={brand?.black}
                      color="primary"
                      size="large"
                      onClick={() => reduxDispatch(goBack())}
                      label={messages?.general?.cancel}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
            {/* Right Buttons */}
            <Grid item>
              <Grid container spacing={2}>
                <Grid item>
                  <Button
                    onClick={handleSubmit(handlePreviewClick)}
                    variant="outlined"
                    color="primary"
                    size="large"
                    type="submit"
                    buttonColor={
                      isMobile
                        ? greyScaleColour?.black100
                        : brandColour?.primary100
                    }
                    disabled={submittingPreview}
                    label={messages?.general?.preview}
                  />
                </Grid>
                <Grid item>
                  <Button
                    onClick={
                      isMobile
                        ? () => setSubmitTimeCardScreen(true)
                        : showDigitalForm
                    }
                    variant="outlined"
                    color="primary"
                    size="large"
                    disabled={submittingTimeCard}
                    label={messages?.general?.submit}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      </Card>
      <Modal
        icon={<TextSnippetOutlinedIcon />}
        show={digitalFormVisibility}
        heading="Digital timesheet"
        onClose={hideDigitalForm}
        fitContent
      >
        <DigitalTimeSheet
          onCancel={hideDigitalForm}
          onSubmit={(submitData: { email: string }) => new Promise<void>((resolve, reject) => {
            try {
              setSubmittingTimeCard(true);
              onSubmitTimeCard(submitData?.email);
              resolve();
            } catch (error) {
              reject(error);
            }
          })}
          submittingTimeCard={submittingTimeCard}
          email={selectedCompanyBriefDetails?.EmailAddress}
        />
      </Modal>
      {/* <Modal
        show={pdfVisibility}
        heading="TPH Payroll"
        onClose={hidePdf}
        fitContent
        maxWidth="1212px"
        isPdf
      >
        <PdfView url={pdfMetadata?.url} />
      </Modal> */}
      <Modal
        chipLabel={`${editFormMetadata?.data?.Date} | ${editFormMetadata?.data?.Day}`}
        icon={<EditOutlineIcon />}
        show={editFormVisibility}
        heading="Update time"
        onClose={() => {
          hideEditForm();
          setYesterdayTimings(null);
        }}
        subHeadingLink
        fitContent
        maxHeight="628px"
        handleHeadingClick={handleCopyYesterdayTimings}
      >
        <UpdateTimeForm
          onCancel={() => {
            hideEditForm();
            setYesterdayTimings(null);
          }}
          onSuccess={(updatedDay: DailyTime) => {
            hideEditForm();
            setWeekData((prevWeekData: DailyTime[]) => {
              const newWeekData = [...prevWeekData];
              newWeekData[editFormMetadata?.rowIndex] = updatedDay;
              return newWeekData;
            });
          }}
          passedData={editFormMetadata}
          yesterdayTimings={yesterdayTimings}
          setYesterdayTimings={setYesterdayTimings}
          companyDetails={selectedCompanyDetails}
        />
      </Modal>
    </Container>
  );

  if (submitTimeCardScreen) {
    return (
      <DigitalTimeSheetMobile
        handleTimeCardSubmit={(submitData: { email: string }) => new Promise<void>((resolve, reject) => {
          try {
            setSubmittingTimeCard(true);
            onSubmitTimeCard(submitData?.email);
          } catch (error) {
            toast(<Toast type="error" text={error} />);
          }
        })}
        submittingTimeCard={submittingTimeCard}
        email={selectedCompanyBriefDetails?.EmailAddress}
        setSubmitTimeCardScreen={setSubmitTimeCardScreen}
      />
    );
  }

  if (screenIndex?.screen1) {
    return Screen1();
  }

  return (
    <TimeCardTable
      weekData={weekData}
      companyDetails={selectedCompanyDetails}
      setScreenIndex={setScreenIndex}
      screenIndex={screenIndex}
      setWeekData={setWeekData}
    />
  );
};

export default AddTimeCard;
