import React from 'react';
import { Grid } from '@mui/material';
import { useFormReducer } from '@tphglobal/common/hooks';
import {
  Button,
  Form,
  FormError,
  FormRow,
  FormRowItem,
  OutlinedContainer,
  PasswordInput,
} from '@tphglobal/components';
import { brand, greyScaleColour } from '@tphglobal/common/theme/style.palette';
import { fontSize, fontWeight } from '@tphglobal/common/theme/style.typography';
import useWindowSize from '@tphglobal/common/hooks/windowSize';
import { breakpoints } from '@tphglobal/common/theme/style.layout';
import { updatePassword } from 'aws-amplify/auth';
import { StyledHeading } from '../styles';
import messages from '../../../messages';
import {
  confirmPassword,
  passwordValidator,
  required,
} from '../../../utils';
import ErrorHandler from '../../../utils/errorHandler';

interface Props {
  onCancel: () => void;
  onSuccess: () => void;
}

interface SubmitData {
  newPassword : string
  oldPassword : string
  confirmNewPassword : string
}

const validators = {
  oldPassword: [required(messages?.general?.validations?.oldPasswordRequired)],
  newPassword: [
    required(messages?.general?.validations?.newPasswordRequired),
    passwordValidator,
  ],
  confirmNewPassword: [
    required(messages?.general?.validations?.confirmNewPassword),
    confirmPassword(
      messages?.general?.validations?.passwordUnmatched,
      'newPassword',
    ),
  ],
};

const ChangePassword: React.FC<Props> = ({ onCancel, onSuccess }) => {
  const { width } = useWindowSize();

  const isMobile = width <= breakpoints.mobile;
  const {
    submitError, handleSubmit, connectField, setSubmitError,
  } = useFormReducer(validators);

  const onSubmit = async (data: SubmitData) => {
    if (data?.oldPassword === data?.confirmNewPassword) {
      setSubmitError(messages?.general?.validations?.differentNewPassRequired);
    } else {
      try {
        await updatePassword({
          oldPassword: data?.oldPassword,
          newPassword: data?.confirmNewPassword,
        });
        onSuccess();
      } catch (error) {
        setSubmitError(ErrorHandler(error?.name));
      }
    }
  };
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Grid padding="0 24px">
        <FormRow marginBottom={3}>
          <StyledHeading
            fontWeight={fontWeight?.semiBold}
            fontSize={fontSize?.h5}
            lineHeight="20.83px"
          >
            {messages?.profile?.password?.form?.subHeading}
          </StyledHeading>
        </FormRow>
        <FormRow minWidth={!isMobile ? '402px' : 'auto'} marginTop={1}>
          {connectField('oldPassword', {
            label: messages?.profile?.password?.form?.oldPassword,
          })(PasswordInput)}
        </FormRow>
        <FormRow minWidth={!isMobile ? '402px' : 'auto'}>
          {connectField('newPassword', {
            label: messages?.profile?.password?.form?.newPassword,
          })(PasswordInput)}
        </FormRow>
        <FormRow minWidth={!isMobile ? '402px' : 'auto'} marginBottom={0}>
          {connectField('confirmNewPassword', {
            label: messages?.profile?.password?.form?.confirmNewPassword,
          })(PasswordInput)}
        </FormRow>
        {submitError && (
          <FormRow marginTop={2}>
            <FormRowItem>
              <FormError
                message={submitError}
              />
            </FormRowItem>
          </FormRow>
        )}
      </Grid>

      <OutlinedContainer
        noBorder
        borderRadius="0"
        padding="14px 24px"
        styles={{ backgroundColor: greyScaleColour?.grey60, marginTop: '24px' }}
      >
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  variant="outlined"
                  buttonColor={brand?.black}
                  color="primary"
                  size="large"
                  label={messages?.general?.cancel}
                  onClick={onCancel}
                />
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  type="submit"
                  label={messages?.general?.update}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </OutlinedContainer>
    </Form>
  );
};

export default ChangePassword;
