import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import {
  emailValidator, FixedTitle, HttpMethods, required,
} from '@tphglobal/common/utils';
import {
  Button,
  Form,
  FormError,
  FormRow,
  FormRowItem,
  MaterialAutocompleteInput,
  PasswordInput,
  TextInput,
  CheckBoxInput,
  PhoneInput,
  Modal,
  Toast,
} from '@tphglobal/components';
import { useFormReducer, usePopupReducer } from '@tphglobal/common/hooks';
import { breakpoints } from '@tphglobal/common/theme/style.layout';
import { brand, greyScaleColour } from '@tphglobal/common/theme/style.palette';
import useWindowSize from '@tphglobal/common/hooks/windowSize';
import { mapIdNameToOption, regions, RegionTypes } from '@tphglobal/common/utils/constants';
import {
  confirmSignUp, fetchAuthSession, signIn, signUp,
} from 'aws-amplify/auth';
import { apiCall } from '@tphglobal/common/redux/actions';
import { toast } from 'react-toastify';
import configAmplify from '../../config/awsConfig';
import { fetchUserProfile, postLogin } from '../../redux/actions';
import ConfirmOtpForm from './ConfirmOtpForm';
import SidePanel from './sidePanel';
import { Container } from '../../components';
import {
  NoAccountTextContainer,
  StyledContent,
  StyledContentContainer,
  StyledFormContainer,
  StyledFormDetails,
  StyledFormHeading,
  StyledHeaderContainer,
  StyledInfoContainer,
  StyledLogo,
  StyledScreenWrapper,
  StyledSidePanelLowerTextMobile,
  StyledSmallLogo,
} from './styles';
import {
  confirmPassword, emptyPhoneValueValidator, emptyValueValidator, passwordValidator, routes,
} from '../../utils';
import messages from '../../messages';
import { GET_USER_DETAILS_AU, GET_USER_DETAILS_UK, UPDATE_LOGIN_AU, UPDATE_LOGIN_UK } from '../../api';
import ErrorHandler from '../../utils/errorHandler';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import handleApiCall from '../handleApiCall';

interface LoginData {
  username : string
  password : string
  region : RegionTypes | null
  email ?: string
}

interface LoginResponseResult {
  [key : string] : string
}

interface LoginResponse {
  result : [LoginResponseResult]
  status : boolean
}

interface SubmitData {
  region : {id : RegionTypes, label : RegionTypes}
  firstName : string
  lastName : string
  email : string
  phone : string
  password : string
  confirmPassword : string
  checkbox : boolean
}

const validators = {
  region: [required(messages?.general?.validations?.regionRequired)],
  firstName: [emptyValueValidator, required(messages?.general?.validations?.firstName)],
  lastName: [emptyValueValidator, required(messages?.general?.validations?.lastName)],
  email: [
    required(messages?.general?.validations?.emailRequired),
    emailValidator,
  ],
  phone: [emptyPhoneValueValidator, required(messages?.general?.validations?.phoneRequired)],
  password: [required(messages?.general?.validations?.passwordRequired), passwordValidator],
  confirmNewPassword: [required(messages?.general?.validations?.confirmNewPassword), confirmPassword(messages?.general?.validations?.passwordUnmatched, 'password')],
  checkbox: [required(messages?.general?.validations?.tncRequired)],
};

const Signup = () => {
  const {
    submitting,
    submitError,
    handleSubmit,
    connectField,
    setSubmitError,
  } = useFormReducer(validators);
  const reduxDispatch = useDispatch();
  const [error, setError] = useState<string>('');

  const [loginData, setLoginData] = useState<LoginData>({
    username: '',
    password: '',
    region: null,
    email: '',
  });
  const { width } = useWindowSize();
  const isMobile = width <= breakpoints.mobile;

  const {
    visibility: otpFormVisibility,
    showPopup: showOtpInputScreen,
    hidePopup: hideOtpForm,
    metaData: otpFormMetadata,
  } = usePopupReducer();

  const updateLogin = async (email: string, region : RegionTypes) => new Promise<LoginResponse>((resolve, reject) => {
    try {
      const sanitizedBody = {
        userEmail: email,
      };
      const apiUrl = region === RegionTypes.AU ? UPDATE_LOGIN_AU : UPDATE_LOGIN_UK;

      reduxDispatch(
        apiCall(
          apiUrl,
          resolve,
          reject,
          HttpMethods.PUT,
          sanitizedBody,
        ),
      );
    } catch (error) {
      toast(<Toast type="error" text={`POST call failed: ${JSON.parse(error.response.body)}`} />);
    }
  });

  const onSubmit = async (data: SubmitData) => {
    configAmplify(data.region?.id);
    const {
      firstName, lastName, email, phone, password,
    } = data;
    setLoginData({
      username: email,
      password,
      region: data.region?.id,
    });
    const parsedNumber = parsePhoneNumberFromString(('+').concat(phone))
    const countryCode = parsedNumber?.countryCallingCode
    const phoneUpdated = parsedNumber?.number;

    try {
      const { isSignUpComplete } = await signUp({
        username: email,
        password,
        options: {
          userAttributes: {
            email,
            phone_number: phoneUpdated,
            "custom:firstname": firstName,
            "custom:lastname": lastName,
            "custom:countrycode": countryCode,
          },
          autoSignIn: true,
        },
      });
      if (!isSignUpComplete) {
        showOtpInputScreen();
      }
    } catch (error) {
      setSubmitError(ErrorHandler(error?.name));
    }
  };

  const getUserDetails = async () => {
    handleApiCall(
      reduxDispatch,
      loginData?.region === RegionTypes.AU
        ? GET_USER_DETAILS_AU
        : GET_USER_DETAILS_UK,
      (data) => {
        reduxDispatch(fetchUserProfile(data));
      },
      HttpMethods?.GET,
      loginData?.region ? RegionTypes.AU : RegionTypes.UK,
    );
  }

  async function handleSignUpConfirmation(confirmationCode : string) {
    try {
      const { isSignUpComplete } = await confirmSignUp({
        username: loginData?.username,
        confirmationCode,
      });

      if (isSignUpComplete) {
        hideOtpForm();

        await signIn({
          username: loginData?.username,
          password: loginData?.password,
        });

        const idToken = (
          await fetchAuthSession()
        ).tokens?.idToken?.toString();

        if (idToken) {
          reduxDispatch(
            postLogin({ token: idToken, region: loginData?.region }),
          );
        }
        const user = await updateLogin(loginData?.email, loginData?.region);
        
        await getUserDetails()
        if (user?.status) {
          reduxDispatch(push(routes?.home?.root));
          toast(<Toast text="Signup successful  " />);
        } else {
          toast(<Toast type="error" text="Signup unsuccessful}" />);
        }
      }
    } catch (error) {
      toast(<Toast type="error" text={`error confirming sign up: ${error?.message}`} />);
      setError(error?.message);
    }
  }

  return (
    <>
      <Container hideTabNavigation hideSidebar noBorderRadius noPadding hasHeader={false} noMargin>
        <StyledScreenWrapper>
          <StyledFormContainer>
            <StyledContentContainer>
              <StyledHeaderContainer>
                {isMobile ? (
                  <StyledSmallLogo
                    src="/assets/images/smallLoginLogo.svg"
                    alt="smallLogo"
                  />
                ) : (
                  <StyledLogo src="/assets/images/loginLogo.svg" alt="logo" />
                )}
                <StyledInfoContainer>
                  <StyledFormHeading>
                    {messages?.signup?.heading}
                  </StyledFormHeading>
                  <FixedTitle
                    varient="Fixed14Regular"
                    color={greyScaleColour?.grey100}
                  >
                    {messages?.signup?.subHeading}
                  </FixedTitle>
                </StyledInfoContainer>
              </StyledHeaderContainer>

              <StyledContent>
                <StyledFormDetails>
                  {/* <StyledAuthSignup>
                  <StyledButton>
                    <StyledIcon
                      src='/assets/images/Facebook.svg'
                      alt='facebookIcon'
                    />
                    <StyledIconText>
                      {messages?.login?.form?.facebook}
                    </StyledIconText>
                  </StyledButton>
                  <StyledButton>
                    <StyledIcon
                      src='/assets/images/Google.svg'
                      alt='googleIcon'
                    />
                    <StyledIconText>
                      {messages?.login?.form?.google}
                    </StyledIconText>
                  </StyledButton>
                </StyledAuthSignup>
                <OrLogin>
                  <StyledLine />
                  <FixedTitle
                    varient='Fixed12Regular'
                    color={greyScaleColour?.grey90}
                  >
                    {messages?.signup?.form?.orSignup}
                  </FixedTitle>
                  <StyledLine />
                </OrLogin> */}
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <FormRow>
                      <FormRowItem>
                        {connectField('region', {
                          label: messages?.signup?.form?.region,
                          options: regions?.map(mapIdNameToOption),
                        })(MaterialAutocompleteInput)}
                      </FormRowItem>
                    </FormRow>
                    <FormRow columnSpacing={2}>
                      <FormRowItem>
                        {connectField('firstName', {
                          label: messages?.signup?.form?.firstName,
                        })(TextInput)}
                      </FormRowItem>
                      <FormRowItem>
                        {connectField('lastName', {
                          label: messages?.signup?.form?.lastName,
                        })(TextInput)}
                      </FormRowItem>
                    </FormRow>
                    <FormRow columnSpacing={2}>
                      <FormRowItem marginBottom={isMobile && '10px'} lg={6} md={6} sm={6} xs={12}>
                        {connectField('email', {
                          label: messages?.signup?.form?.email,
                        })(TextInput)}
                      </FormRowItem>
                      <FormRowItem lg={6} md={6} sm={6} xs={12}>
                        {connectField('phone', {
                          label: messages?.signup?.form?.phone,
                        })(PhoneInput)}
                      </FormRowItem>
                    </FormRow>
                    <FormRow columnSpacing={2}>
                      <FormRowItem marginBottom={isMobile && '10px'} lg={6} md={6} sm={6} xs={12}>
                        {connectField('password', {
                          label: messages?.signup?.form?.password,
                        })(PasswordInput)}
                      </FormRowItem>
                      <FormRowItem lg={6} md={6} sm={6} xs={12}>
                        {connectField('confirmNewPassword', {
                          label: messages?.signup?.form?.confirmNewPassword,
                        })(PasswordInput)}
                      </FormRowItem>
                    </FormRow>
                    <FormRow>
                      <FormRowItem>
                        {connectField('checkbox', {
                          label: messages?.signup?.form?.acceptTnC,
                        })(CheckBoxInput)}
                      </FormRowItem>
                    </FormRow>
                    {submitError && (
                    <FormRow>
                      <FormRowItem>
                        <FormError
                          message={submitError}
                        />
                      </FormRowItem>
                    </FormRow>
                    )}
                    <FormRow marginBottom="0">
                      <FormRowItem>
                        <Button
                          label={messages?.signup?.form?.signUp}
                          type="submit"
                          variant="outlined"
                          color="primary"
                          size="large"
                          disabled={submitting}
                        />
                      </FormRowItem>
                    </FormRow>
                  </Form>
                </StyledFormDetails>
                <NoAccountTextContainer>
                  <FixedTitle
                    varient="Fixed14Regular"
                    color={greyScaleColour?.grey100}
                  >
                    {messages?.signup?.form?.haveAccount}
                  </FixedTitle>
                  <FixedTitle
                    style={{ cursor: 'pointer' }}
                    varient="Fixed14Medium"
                    color={brand?.secondaryMain}
                    onClick={() => reduxDispatch(push(routes.login))}
                  >
                    {messages?.signup?.form?.signIn}
                  </FixedTitle>
                </NoAccountTextContainer>
              </StyledContent>
              <StyledSidePanelLowerTextMobile mt="45px">
                <NoAccountTextContainer>
                  <FixedTitle
                    varient="Fixed14Regular"
                    color={!isMobile ? brand.white : greyScaleColour.grey100}
                  >
                    {messages?.sidePanel?.needHelp}
                  </FixedTitle>
                  <FixedTitle
                    varient={isMobile ? 'Fixed14Medium' : 'Fixed14Bold'}
                    onClick={() => reduxDispatch(push(routes.getInTouch))}
                    color={!isMobile ? brand.white : brand?.secondaryMain}
                    style={{ cursor: 'pointer' }}
                  >
                    {messages?.sidePanel?.getInTouch}
                  </FixedTitle>
                </NoAccountTextContainer>
              </StyledSidePanelLowerTextMobile>
            </StyledContentContainer>
          </StyledFormContainer>
          <SidePanel />
        </StyledScreenWrapper>
      </Container>
      <Modal
        show={otpFormVisibility}
        heading="Confirm OTP"
        onClose={hideOtpForm}
        fitContent
      >
        <ConfirmOtpForm
          onCancel={hideOtpForm}
          error={error}
          setError={setError}
          onSubmit={(confirmationCode : string) => {
            handleSignUpConfirmation(confirmationCode);
          }}
          username={loginData?.username}
        />
      </Modal>
    </>
  );
};

export default Signup;
