import React, { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/system';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Box from '@mui/material/Box';
import { brandColour } from '@tphglobal/common/theme/style.palette';
import { DailyTime, Payas } from '../../../models';
import { getDefaultWeekDay } from '../../../utils/commonFunctions';

interface Props {
  list: Payas[];
  fieldKey: keyof DailyTime;
  setWeekData: (update: (weekData: DailyTime[]) => DailyTime[]) => void;
  rowIndex: number;
  value?: string
  periodEnding ?: string
}

const DropList = styled('div')({
  border: '1px solid #ccc',
  background: 'white',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
  maxHeight: '200px',
  overflowY: 'auto',
  padding: '5px 10px',
});

const StyledMenuItem = styled(MenuItem)({
  padding: '5px 10px',
});

const DropListInput: React.FC<Props> = ({
  list,
  fieldKey,
  setWeekData,
  rowIndex,
  value,
  periodEnding,
}) => {
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<string>();

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  useEffect(() => {
    setSelectedItem(value === '0' ? 'Select' : value);
  }, [value]);

  const handleSelectItem = (item: Payas) => {
    setSelectedItem(item?.Value);

    setWeekData((prevWeekData: DailyTime[]) => {
      const newWeekData = [...prevWeekData];

      if (rowIndex >= 0 && rowIndex < newWeekData.length) {
        let updatedDay = { ...newWeekData[rowIndex] };

        if (item?.Value === 'Did Not Work') {
          updatedDay = getDefaultWeekDay(periodEnding);
          updatedDay = {
            ...updatedDay,
            [fieldKey]: item.Value,
            customNote: `${item?.Value} `,
          };
        } else {
          updatedDay = {
            ...updatedDay,
            [fieldKey]: item.Value,
            customNote: `${item?.Value} `,
          };
        }

        newWeekData[rowIndex] = updatedDay;
      }

      return newWeekData;
    });
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box position="relative" display="inline-block">
        <Box
          component="span"
          onClick={handleClick}
          sx={{
            cursor: 'pointer',
            borderRadius: '4px',
            backgroundColor: open ? brandColour?.primary20 : 'white',
          }}
        >
          {selectedItem || fieldKey}
        </Box>
        {open && (
          <Box
            display="flex"
            position="absolute"
            top="100%"
            left="0"
            zIndex={100000}
          >
            <DropList>
              {list?.map((item: Payas) => (
                <StyledMenuItem
                  key={item?.Flag}
                  onClick={() => handleSelectItem(item)}
                >
                  {item?.Value}
                </StyledMenuItem>
              ))}
            </DropList>
          </Box>
        )}
      </Box>
    </ClickAwayListener>
  );
};

export default DropListInput;
