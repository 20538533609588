/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useFormReducer } from '@tphglobal/common/hooks';
import {
  Button,
  DragAndDrop,
  Form,
  FormError,
  FormRow,
  FormRowItem,
  OutlinedContainer,
  TextInput,
  Toast,
} from '@tphglobal/components';
import { brand, greyScaleColour } from '@tphglobal/common/theme/style.palette';
import { fontSize, fontWeight } from '@tphglobal/common/theme/style.typography';
import { RegionTypes } from '@tphglobal/common/utils/constants';
import { HttpMethods } from '@tphglobal/common/utils';
import { StyledHeading } from './styles';
import { ReduxState } from '../../redux/reducers';
import {
  ADD_DOCUMENT_AU,
  ADD_DOCUMENT_UK,
  UPLOAD_ATTACHMENT_AU,
  UPLOAD_ATTACHMENT_UK,
} from '../../api';
import messages from '../../messages';
import { emptyValueValidator, required } from '../../utils';
import handleApiCall from '../handleApiCall';
import { convertFileToBase64 } from '../../utils/commonFunctions';
import { FileAttachment } from '../../models';

interface Props {
  onCancel: () => void;
  onSuccess: () => void;
}

interface FileAttachmentObject {
  file: FileAttachment;
  fileId: string;
}

interface SubmitData {
  description : string
  fileUpload : FileAttachment
}

const validators = {
  description: [required(messages?.general?.validations?.descriptionRequired), emptyValueValidator],
  fileUpload: [required(messages?.general?.validations?.fileRequired)],
};

const AddDocument: React.FC<Props> = ({
  onCancel,
  onSuccess,
}) => {
  const reduxDispatch = useDispatch();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const userProfile = useSelector((state: ReduxState) => state?.profile);
  const userId = userProfile?.Guid;
  const selectedRegion = userProfile?.region;
  const {
    submitError, handleSubmit, connectField, change, setSubmitError, formValues,
  } = useFormReducer(validators);

  const [files, setFiles] = useState<FileAttachmentObject[]>([]);
  const [uploadFile, setUploadFile] = useState<File>();

  const handledocumentSummit = (data: SubmitData, file_name : string) => {
    const file = files[0]?.file;

    const ext = file?.filename.split('.').pop();
    const sanitizedBody = {
      Company: '//',
      ContentType: file?.ContentType,
      DocDesc: data?.description,
      DocumentType: ext.toUpperCase(),
      Encrypted: 'No',
      Filename: file?.filename,
      Production: null,
      PWHash: null,
      Salt: 'GYUFYDTFKHFYV',
      Timestamp: new Date().getTime(),
      url: file_name,
      // TimecardGuid : null, 
      // SysCoyGuid : null,
      DocType: 'Upload',
    };

    handleApiCall(
      reduxDispatch,
      selectedRegion === RegionTypes.AU ? ADD_DOCUMENT_AU : ADD_DOCUMENT_UK,
      () => {
        onSuccess();
        setSubmitting(false);
      },
      HttpMethods.POST,
      selectedRegion === RegionTypes.AU ? RegionTypes.AU : RegionTypes.UK,
      messages?.document?.addForm?.success?.heading,
      sanitizedBody,
      setSubmitError,
    );
  };

  const onSubmit = async (data: SubmitData) => {
    const file = files[0]?.file;
    const ext = file?.filename?.split('.')?.pop();
    const file_name = `${new Date().getTime()}.${ext}`;
    const upload_path = `documents/${userId}/${file_name}`;
    const content_type = file?.ContentType;
    let base64Data;
    if (uploadFile) {
      base64Data = await convertFileToBase64(uploadFile);
    }
    
    const sanitizedBody = {
      upload_path,
      content_type,
      base64Data,
    };

    setSubmitting(true);
    handleApiCall(
      reduxDispatch,
      selectedRegion === RegionTypes.AU
        ? UPLOAD_ATTACHMENT_AU
        : UPLOAD_ATTACHMENT_UK,
      () => {
        handledocumentSummit(data,file_name);
      },
      HttpMethods.POST,
      selectedRegion === RegionTypes.AU ? RegionTypes.AU : RegionTypes.UK,
      null,
      sanitizedBody,
      setSubmitError,
    );
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Grid padding="0 24px">
        <FormRow>
          {connectField('description', {
            label: messages?.document?.addForm?.description,
            multiline: true,
            rows: 3,
            autoHeight: true,
          })(TextInput)}
        </FormRow>
        <FormRow marginBottom="10px">
          <StyledHeading
            fontSize={fontSize?.h4}
            fontWeight={fontWeight?.semiBold}
            lineHeight="26.04px"
          >
            {messages?.document?.addForm?.attachment}
          </StyledHeading>
        </FormRow>
        <FormRow minWidth="562px" marginBottom={2}>
          {connectField('fileUpload', {
            acceptedFiles: '.pdf, image/png, image/jpg, image/jpeg',
            isPictureView: true,
            multiple: false,
            files,
            setFiles,
            setUploadFile,
            uploadFile,
          })(DragAndDrop)}
        </FormRow>
        {submitError && (
          <FormRow>
            <FormRowItem>
              <FormError
                message={submitError}
              />
            </FormRowItem>
          </FormRow>
        )}
      </Grid>

      <OutlinedContainer
        noBorder
        borderRadius="0"
        padding="14px 24px"
        styles={{ backgroundColor: greyScaleColour?.grey60, marginTop: '24px' }}
      >
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  onClick={() => onCancel()}
                  variant="outlined"
                  buttonColor={brand?.black}
                  color="primary"
                  size="large"
                  label={messages?.general?.cancel}
                />
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  type="submit"
                  disabled={submitting}
                  label={messages?.general?.add}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </OutlinedContainer>
    </Form>
  );
};

export default AddDocument;
