import { Grid, Radio, Typography } from '@mui/material';
import styled from 'styled-components';
import { fontSize, fontWeight } from '@tphglobal/common/theme/style.typography';
import {
  brand,
  brandColour,
  colors,
  greyScaleColour,
} from '@tphglobal/common/theme/style.palette';
import { respondTo } from '@tphglobal/common/theme/style.layout';

export const DetailCardHeading = styled(Typography)``;
export const StyledHeading = styled(Typography)``;
export const ProfileDetailsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const Row = styled.div<{ justifyContent?: string; gap?: string }>`
  display: flex;
  align-items: center;
  gap: ${({ gap }) => gap};
  justify-content: ${({ justifyContent }) => justifyContent};
`;

export const ProfileImage = styled.img<{ width?: string; height?: string }>`
  width: ${({ width }) => (width || '80px')};
  height: ${({ height }) => (height || '80px')};
  border-radius: 50%;
  object-fit: cover;
`;

export const ProfileDetails = styled.div<{ gap?: string }>`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.gap};
`;

export const StyledImage = styled.img`
  width: 100%;
`;

export const StyledChildren = styled(Grid)<{ gap: string }>`
  display: flex;
  flex-direction: column !important;
  gap: ${({ gap }) => gap};
`;

export const StyledCardContent = styled(Typography)`
  font-size: ${fontSize.b1};
  font-weight: ${fontWeight.regular};
  color: ${colors.grey100};
  line-height: normal !important;
`;

export const StyledLink = styled(Typography)`
  font-size: ${fontSize.b1};
  font-weight: ${fontWeight.medium} !important;
  color: ${brand.primaryMain};
  text-decoration: underline;
  cursor: pointer;
`;

export const StyledTableHeading = styled(Typography)`
  font-size: ${fontSize.b1} !important;
  font-weight: ${fontWeight.regular} !important;
  color: ${colors.grey100};
  margin-bottom: 8px !important;
  line-height: normal !important;
  min-width: 300px;
`;

export const StyledTableContent = styled(Typography)`
  font-size: ${fontSize.b1} !important;
  font-weight: ${fontWeight.medium} !important;
  color: ${colors.black};
  line-height: normal !important;
  min-width: 300px;
`;

export const StyledPhotoContainer = styled.div`
  width: 100px;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 11px 0px;
  border-radius: 9999px;
  background-color: ${brand.primaryMain};
`;

export const StyledPhoto = styled(Typography)`
  font-size: 52px !important;
  font-weight: ${fontWeight.regular} !important;
  cursor: pointer;
`;

export const StyledProfileContainer = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 9999px;
`;

export const StyledProfile = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 9999px;
`;

export const StyledPhotoContent = styled(Typography)`
  font-size: ${fontSize.b1} !important;
  font-weight: ${fontWeight.regular} !important;
  color: ${brand.primaryMain} !important;
  cursor: pointer;
`;

export const StyledFileInput = styled.input`
  display: none;
`;

export const VerticalLine = styled.div`
  border-left: 1px solid ${brandColour?.primary10};
  height: 100%;
  align-self: stretch;

  ${respondTo?.mobileOnlyPortrait} {
    display: none;
  }
`;

export const HorizintalLine = styled.div`
  border-bottom: 1px solid ${brandColour?.primary10};
  width: 100%;
  align-self: stretch;

  ${respondTo?.mobileOnlyPortrait} {
    border-bottom: 1px solid ${greyScaleColour?.grey70};
  }
`;

export const StyledDiv = styled.div`
  position: relative;
  width: 100%; 
  height: 200px;
  background-color: ${brand?.white};
  display: flex;
  align-items: center;
  border-radius: 16px;
  justify-content: center;
  overflow: hidden;
`;

export const Image = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  object-fit: contain;
`;

export const Placeholder = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: ${greyScaleColour?.grey90};
  font-size: ${fontSize?.h5};
  font-weight: ${fontWeight?.semiBold};
`;

export const EmptyBackground = styled.div`
  width: 100%;
  height: 100%;
  background-color: white; /* or any other background color */
  border-radius: 16px;
`;

export const Icons = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 10px;
`;

export const Icon = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  padding: 5px;
  color: white;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }
`;

export const OverlayContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(217, 217, 217, 0.25);
  backdrop-filter: blur(1.5px);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px; // font size
  text-align: center;
  z-index: 10;
  border-radius: 8px;
`;

export const Column = styled.div`
  width: 217px;
  gap: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StyledRow = styled.div<{gap ?: string}>`
  display : flex;
  align-items : center;
  gap : ${({gap}) => gap};
`