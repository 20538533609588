import moment, { Moment } from 'moment';
import { Option } from '@tphglobal/common/models';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { daysOfWeek } from './constant';
import { DailyTime, Department } from '../models';

interface FormFieldValue {
  id: string;
  label: string;
}

interface FormField {
  value: Moment | string | FormFieldValue;
  error: string;
}

interface FormValues {
  [key: string]: FormField;
}

export interface NewFormValues {
  [key: string]: string | Moment | FormFieldValue | boolean;
}

interface List {
  [key: string]: string
}

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const filterList = <T extends Record<string, any>>(
  searchInput: string,
  list: T[],
): T[] => {
  if (!searchInput) {
    return list;
  }

  const filtered = list.filter((listItem: T) => Object.values(listItem).some((value) => value?.toString()?.toLowerCase()?.includes(searchInput.toLowerCase())));

  return filtered;
};

export const convertFileToBase64 = (file: Blob | File | ArrayBuffer | string): Promise<string | ArrayBuffer | null> => new Promise((resolve, reject) => {
  let fileToProcess: Blob;

  // If the file is not a Blob, convert it to a Blob
  if (!(file instanceof Blob)) {
    try {
      fileToProcess = new Blob([file], { type: 'application/octet-stream' });
    } catch (conversionError) {
      reject(new Error('Provided file is not a Blob or File and conversion failed.'));
      return;
    }
  } else {
    fileToProcess = file;
  }

  // Use FileReader to read the file as a data URL
  const reader = new FileReader();
  reader.readAsDataURL(fileToProcess);

  reader.onload = () => resolve(reader.result);
  reader.onerror = (error) => reject(error);
});

export const generateWeekData = (endDate: string) => {
  const weekData : DailyTime[] = [];

  const endDateMoment = moment(endDate);
  for (let i = 0; i < 7; i += 1) {
    const date = endDateMoment.clone().subtract(i, 'days');
    weekData.unshift({
      CustomTimeFields: [],
      Date: date.format('DD/MM/YYYY'),
      Day: daysOfWeek[date.day()],
      Finish: '00:00',
      FinishMeal1: '00:00',
      FinishMeal2: '00:00',
      FinishMeal3: '00:00',
      MB1ND: '0',
      MB2ND: '0',
      MB3ND: '0',
      Note: null,
      Payas: '0',
      Start: '00:00',
      StartMeal: '00:00',
      StartMeal2: '00:00',
      StartMeal3: '00:00',
      TimeStamp: 123456,
      TotalHours: '00:00',
      TotalMBDed: '00:00',
      Travel1: '00:00',
      Travel2: '00:00',
      customNote: null,
    });
  }
  return weekData;
};

export const getDefaultWeekDay = (endDate: string) => {
  const endDateMoment = moment(endDate);

  const defaultDay : DailyTime = {
    CustomTimeFields: [],
    Date: endDateMoment.format('YYYY-MM-DD'),
    Day: daysOfWeek[endDateMoment.day()],
    Finish: '00:00',
    FinishMeal1: '00:00',
    FinishMeal2: '00:00',
    FinishMeal3: '00:00',
    MB1ND: '0',
    MB2ND: '0',
    MB3ND: '0',
    Note: '',
    Payas: '0',
    Start: '00:00',
    StartMeal: '00:00',
    StartMeal2: '00:00',
    StartMeal3: '00:00',
    TimeStamp: 123456,
    TotalHours: '00:00',
    TotalMBDed: '00:00',
    Travel1: '00:00',
    Travel2: '00:00',
    customNote: '',
  };

  return defaultDay;
};

export const toCamelCase = (str: string) => str
  ?.toLowerCase()
  ?.split(' ')
  ?.map((word: string, index: number) => (index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)))
  ?.join('');

export const truncateText = (text : string, maxLength : number) => {
  if (text?.length > maxLength) {
    return `${text?.substring(0, maxLength)}...`;
  }
  return text;
};

export const getIPAddress = async () => {
  try {
    const response = await fetch('https://api.ipify.org?format=json');
    const data = await response.json();
    return data.ip;
  } catch (error) {
    return null;
  }
};

export const mapDepartmentNameToOption = (entity: Department): Option => ({ id: entity?.name, label: entity?.name });

export const extractLocalNumber = (phoneNumber : string) => {
  const parsedNumber = parsePhoneNumberFromString(phoneNumber);

  if (parsedNumber && parsedNumber.isValid()) {
    return parsedNumber?.nationalNumber;
  }
  return null;
};

export function convertFormValues(oldFormValues: FormValues): NewFormValues {
  const newFormValues: NewFormValues = {};

  Object.entries(oldFormValues).forEach(([key, oldField]) => {
    let newField: string | Moment | FormFieldValue | boolean;
    
    if (typeof oldField?.value === 'string' || typeof oldField?.value === 'boolean' || moment.isMoment(oldField?.value) || oldField?.value === null || oldField?.value === undefined) {
      newField = oldField?.value ? oldField?.value : null;
    } else if (typeof oldField?.value === 'object' && oldField?.value !== null) {
      const { id, label } = oldField?.value as FormFieldValue;
      newField = { id, label };
    } else {      
      throw new Error(`Unexpected value type for key ${key}`);
    }

    newFormValues[key] = newField;
  });

  return newFormValues;
}

export const formatTime = (mili : number) : string => {
  const duration = moment.duration(mili);

  const hours = Math.floor(duration.asHours()).toString().padStart(2, '0');
  const minutes = duration.minutes().toString().padStart(2, '0');

  return `${hours}:${minutes}`
}