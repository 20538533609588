import { Grid, Link, Typography } from '@mui/material';
import styled from 'styled-components';
import {
  brand,
  brandColour,
  greyScaleColour,
} from '@tphglobal/common/theme/style.palette';
import { fontSize, fontWeight } from '@tphglobal/common/theme/style.typography';
import { respondTo } from '@tphglobal/common/theme/style.layout';

export const StyledDragDropForm = styled.form<{setMaxWidthNone ?: boolean}>`
  min-height: 145px;
  max-width: 601px;
  width: 100%;
  text-align: center;
  position: relative;
  cursor: pointer;

  ${respondTo?.mobileOnlyPortrait} {
    min-height: 202px;
  }
  ${respondTo.screenUp(1550)} {
    max-width: ${({setMaxWidthNone}) => setMaxWidthNone && 'none'};
  }
`;

export const StyledIcon = styled.img<{
  position?: string;
  right?: string;
  top?: string;
  padding?: string;
  cursor?: string;
  height?: string;
  width?: string;
}>`
  position: ${(props) => props.position};
  right: ${(props) => props.right};
  top: ${(props) => props.top};
  padding: ${(props) => props.padding};
  cursor: ${(props) => props.cursor || 'pointer'};
  height: ${(props) => props.height};
  width: ${(props) => props.width};
`;

export const StyledInputFile = styled.input`
  display: none;
`;

export const StyledFrame = styled.img`
  width: 100%;
  border-radius: 6px;
  height: 100px;
  max-width: 100px;
`;

export const StyledText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

export const StyledFrameContainer = styled.div<{ position?: string }>`
  width: 100px;
  height: 100px;
  cursor: pointer;
  position: ${(props) => props.position};
`;

export const StyledDragLabel = styled.label<{ dragActive?: boolean }>`
  height: 100%;
  display: flex;
  align-items: center;
  cursor : pointer;
  justify-content: center;
  border-radius: 4px;
  background-color: ${(props) =>
    props.dragActive ? brand?.textColour : brandColour?.primary10};
`;

export const StyledDragLabelDiv = styled.div`
  padding: 31px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20.4px;

  ${respondTo.screenUp(1550)} {
    padding : 40px 0;
  }
`;

export const StyledDragLabelText = styled(Typography)`
  color: ${greyScaleColour.grey100};
  padding-top: 20px;
`;

export const StyledDragActiveText = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;

export const StyledUploadButtonText = styled(Link)`
  color: ${brand?.secondaryMain} !important;
  cursor: pointer;
`;

export const StyledAttachmentContainer = styled(Grid)`
  margin-top: 16px;
`;

export const StyledAttachmentText = styled(Typography)`
  font-size: ${fontSize.b1} !important;
  font-weight: ${fontWeight.semiBold} !important;
`;

export const StyledFileText = styled(Typography)`
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  padding-left: 6px;
`;

export const StyledImageContainer = styled.div`
  border-radius: 6px;
  height: 202px;
  cursor: pointer;
  width: -webkit-fill-available;
`;

export const StyledImage = styled.img`
  object-fit: cover;
  border-radius: 6px;
  width: 100%;
  height: 100%;
`;

export const StyledInfoContainer = styled(Grid)`
  margin-top: 8px;
  justify-content: space-between;
`;

export const StyledAttachmentInformationText = styled(Typography)`
  font-size: ${fontSize.b3} !important;
  font-weight: ${fontWeight.regular} !important;
  color: ${greyScaleColour.grey100} !important;
`;

export const StyledIconLoading = styled.img`
  width: 38px;
  height: 38px;
`;

export const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left !important;
`;

export const StyledRow = styled.div<{gap ?: string}>`
  display: flex;
  align-items: center;
  gap : ${({gap}) => gap};
  justify-content: space-between;
`;

export const StyledContainerDirection = styled.div<{ row?: boolean }>`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: ${({row}) => row ? 'row' : 'column'};
  gap: ${({row}) => row ? '16px' : '10px'};
`;
