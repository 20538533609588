import React, { useEffect } from 'react';
import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import {
  Card,
  FloatingButton,
} from '@tphglobal/components';
import {
  otherColour,
} from '@tphglobal/common/theme/style.palette';
import { fontSize, fontWeight } from '@tphglobal/common/theme/style.typography';
import { push } from 'connected-react-router';
import useWindowSize from '@tphglobal/common/hooks/windowSize';
import { breakpoints } from '@tphglobal/common/theme/style.layout';
import { RegionTypes } from '@tphglobal/common/utils/constants';
import { HttpMethods } from '@tphglobal/common/utils';
import { Container } from '../../components';
import {
  StyledHeading,
} from './styles';
import messages from '../../messages';
import { ReduxState } from '../../redux/reducers';
import { routes } from '../../utils';
import ProfileInformation from './profileInfo/profileInformation';
import Password from './password/password';
import Settings from './settings/settings';
import HelpNSupport from './helpNsupport/HelpNSupport';
import SignatureDesktop from './signature/signatureDesktop';
import SignatureMobile from './signature/signatureMobile';
import ClearData from './clearData/clearData';
import handleApiCall from '../handleApiCall';
import { GET_USER_DETAILS_AU, GET_USER_DETAILS_UK } from '../../api';
import { fetchUserProfile } from '../../redux/actions';

const ProfileSection: React.FC = () => {
  const selectedRegion = useSelector((state: ReduxState) => state.profile)?.region;
  const reduxDispatch = useDispatch();
  const { width } = useWindowSize();

  const isMobile = width <= breakpoints.mobile;

  const fetchUserProfileOnSuccess = () => {
    handleApiCall(
      reduxDispatch,
      selectedRegion === RegionTypes.AU
        ? GET_USER_DETAILS_AU
        : GET_USER_DETAILS_UK,
      (data) => {
        reduxDispatch(fetchUserProfile(data));
      },
      HttpMethods?.GET,
      selectedRegion === RegionTypes.AU ? RegionTypes.AU : RegionTypes.UK,
    );
  };

  useEffect(() => {
    if (isMobile) {
      fetchUserProfileOnSuccess();
    }
  }, []);
  return (
    <Container
      heading={messages?.profile?.heading}
      background={otherColour?.adminBg100}
      maxHeight
    >
      <Card
        noContentPadding={isMobile}
        removeTitle
        contentCss={isMobile && { padding: '24px 16px 16px !important' }}
        showGoBack
        header={(
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <StyledHeading
                fontSize={fontSize?.h2}
                fontWeight={fontWeight?.semiBold}
              >
                {messages?.profile?.heading}
              </StyledHeading>
            </Grid>
          </Grid>
        )}
      >
        <Grid container rowGap="16px" marginBottom={!isMobile && 3}>
          {/* Edit profile */}
          <Grid item xs={12}>
            <ProfileInformation isMobile={isMobile} fetchProfileOnSuccess={fetchUserProfileOnSuccess} />
          </Grid>
          {/* Password */}
          <Grid item xs={12}>
            <Password isMobile={isMobile} />
          </Grid>
          {/* Settings */}
          <Grid item xs={12}>
            <Settings isMobile={isMobile} />
          </Grid>
          {/* Help & support */}
          {!isMobile && (
            <Grid item xs={12}>
              <HelpNSupport isMobile={isMobile} />
            </Grid>
          )}
          {/* Signature desktop */}
          {!isMobile && (
            <Grid item xs={12}>
              <SignatureDesktop isMobile={isMobile} />
            </Grid>
          )}
          {/* Signature mobile */}
          {isMobile && (
            <Grid item xs={12}>
              <SignatureMobile isMobile={isMobile} />
            </Grid>
          )}
          {/* Clear data */}
          <Grid item xs={12}>
            <ClearData isMobile={isMobile} fetchProfileOnSuccess={fetchUserProfileOnSuccess} />
          </Grid>
        </Grid>
        <FloatingButton
          onClick={() => reduxDispatch(push(routes?.support?.root))}
          ariaLabel="Help & Support"
          bottom="2.03%"
          right="1.36%"
        />
      </Card>
    </Container>
  );
};

export default ProfileSection;
