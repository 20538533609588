interface Config {
  apiUrl: string;
  API_URL_AU?: string;
  API_URL_UK?: string;
  featureFlags?: Record<string, boolean>;
}

declare global {
  interface Window {
    config?: Config;
  }
}

export enum FeatureLevel {
  production = 'production',
}

export default {
  ...(window?.config || {}),
  featureLevel: FeatureLevel.production,
};
