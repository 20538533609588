import React from 'react';
import { useDispatch } from 'react-redux';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import { Grid } from '@mui/material';
import { fontSize, fontWeight } from '@tphglobal/common/theme/style.typography';
import { brandColour } from '@tphglobal/common/theme/style.palette';
import { FixedTitle } from '@tphglobal/common/utils';
import useWindowSize from '@tphglobal/common/hooks/windowSize';
import { breakpoints } from '@tphglobal/common/theme/style.layout';
import {
  StyledColumn,
  StyledContainer,
  StyledHeading,
  StyledLocation,
} from './styles';
import messages from '../../messages';

const UK = () => {
  const { width } = useWindowSize();

  const isMobile = width <= breakpoints.mobile;
  const uriUK = encodeURIComponent("TPH Global Ltd, Units 3 and 4, Syd's Quay, Eel Pie Island, Twickenham TW1 3DY, United Kingdom")

  const detailIconStyles = {
    height: fontSize?.[isMobile ? 'h3' : 'h1'],
    width: fontSize?.[isMobile ? 'h3' : 'h1'],
    fill: brandColour?.primary100,
  };

  return (
    <Grid
    container
    xs={12}
    gap={2}
    justifyContent={!isMobile && 'space-between'}
  >
    <Grid
      paddingTop="0 !important"
      paddingLeft="0 !important"
      item
      lg={6.35}
      md={5.65}
      sm={12}
      xs={12}
    >
      <Grid xs={12} container>
      <Grid item lg={5.7} md={5.7} sm={12} xs={12}>
          <StyledContainer>
            <StyledColumn gap={isMobile ? '10px' : '12px'}>
              <StyledColumn>
                <LocationOnOutlinedIcon style={detailIconStyles} />
                <StyledHeading
                  fontWeight={fontWeight?.semiBold}
                  fontSize={isMobile ? '18px' : fontSize?.h4}
                  lineHeight={isMobile ? '23px' : '26px'}
                >
                  {messages?.getInTouch?.headOffice?.heading}
                </StyledHeading>
              </StyledColumn>
              <FixedTitle
                varient={isMobile ? 'Fixed14Regular' : 'Fixed16Medium'}
                style={{ marginBottom: '32px' }}
              >
               Units 3 and 4, Syd's Quay, Eel Pie Island, Twickenham TW1 3DY, United Kingdom
              </FixedTitle>
              {isMobile && <StyledLocation  src={`https://maps.google.com/maps?q=${uriUK}&t=&z=13&ie=UTF8&iwloc=&output=embed`} width="100%" height="167px" frameborder="0"/>}
            </StyledColumn>
          </StyledContainer>
        </Grid>
        <Grid item lg={5.7} md={5.7} sm={12} xs={12}>
        <StyledContainer>
            <StyledColumn gap={isMobile ? '10px' : '12px'}>
              <StyledColumn>
                <PhoneIphoneOutlinedIcon style={detailIconStyles} />
                <StyledHeading
                  fontWeight={fontWeight?.semiBold}
                  fontSize={isMobile ? '18px' : fontSize?.h4}
                  lineHeight={isMobile ? '23px' : '26px'}
                >
                  {messages?.getInTouch?.callUs?.heading}
                </StyledHeading>
              </StyledColumn>
              <a href="tel:+1234567890" style={{textDecoration : 'none'}}>
                  <FixedTitle
                    varient={isMobile ? 'Fixed14Regular' : 'Fixed16Medium'}
                    color='black'
                    >
                    +44(0) 333 6000 113
                  </FixedTitle>
                </a>
            </StyledColumn>
          </StyledContainer>
        </Grid>
      </Grid>
    </Grid>
    {!isMobile && (
    <Grid item lg={5} md={5.65} sm={12} xs={12} minHeight={'352px'}>
      <StyledLocation  src={`https://maps.google.com/maps?q=${uriUK}&t=&z=13&ie=UTF8&iwloc=&output=embed`} width="100%" height="167px" frameborder="0"/>
    </Grid>
    )}
  </Grid>
  );
};

export default UK;
