import {
  brand,
  brandColour,
  otherColour,
} from '@tphglobal/common/theme/style.palette';
import { FixedTitle } from '@tphglobal/common/utils';
import { Button, Card, FormRow } from '@tphglobal/components';
import React, { useState } from 'react';
import Table from '@tphglobal/components/table';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { fontSize, fontWeight } from '@tphglobal/common/theme/style.typography';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useDispatch } from 'react-redux';
import { Grid } from '@mui/material';
import moment from 'moment';
import { StyledHeading } from '../styles';
import messages from '../../../messages';
import { Container } from '../../../components';
import { CompanyDetailedEntity, DailyTime } from '../../../models';
import AddTimeCardForm from './addTimeCardForm';
import { getDefaultWeekDay } from '../../../utils/commonFunctions';

const iconStyles = {
  height: fontSize?.h4,
  width: fontSize?.h4,
  color: brandColour?.primary100,
};

interface Screens {
  screen1: boolean;
  screen2: boolean;
  screen3: boolean;
}

interface Props {
  weekData: DailyTime[];
  companyDetails: CompanyDetailedEntity;
  setScreenIndex: (screenIndex: Screens) => void;
  screenIndex: Screens;
  setWeekData: (update: (weekData: DailyTime[]) => DailyTime[]) => void;
}

const TimeCardTable: React.FC<Props> = ({
  weekData,
  companyDetails,
  setScreenIndex,
  screenIndex,
  setWeekData,
}) => {
  const [row, setRow] = useState<DailyTime>();
  const [rowIndex, setRowIndex] = useState<number | null>(null);

  const getTotalHours = (): string => {
    if (!weekData || weekData.length === 0) {
      return '00:00';
    }

    let totalMinutes = 0;
    let hasNegativeTotalHours = false;

    weekData.forEach((day: DailyTime) => {
      const totalHoursStr = day.TotalHours || '00:00';
      const [hours, minutes] = totalHoursStr.split(':').map(Number);

      const totalDayMinutes = hours * 60 + minutes;

      if (totalDayMinutes < 0) {
        hasNegativeTotalHours = true;
      }

      totalMinutes += totalDayMinutes;
    });

    const totalHours = Math.floor(totalMinutes / 60);
    const remainingMinutes = totalMinutes % 60;

    const formattedTotalHours = String(totalHours).padStart(2, '0');
    const formattedRemainingMinutes = String(remainingMinutes).padStart(2, '0');

    return `${formattedTotalHours}:${formattedRemainingMinutes}`;
  };

  const handleGoBack = () => {
    setScreenIndex({
      screen1: true,
      screen2: false,
      screen3: false,
    });
  };

  const Screen2 = () => (
    <Container
      showGoBack
      customGoBack={handleGoBack}
      heading={messages?.timeCard?.heading}
      background={otherColour?.adminBg100}
      maxHeight
    >
      <Card
        showGoBack
        noContentPadding
        removeTitle
        contentCss={{ padding: '0 16px 24px !important' }}
      >
        <FormRow
          display="flex"
          justifyContent="space-between"
          marginTop={3}
          marginBottom={2}
        >
          <StyledHeading
            fontSize="18px"
            fontWeight={fontWeight?.semiBold}
            lineHeight="23px"
          >
            {messages?.timeCard?.form?.subHeading}
          </StyledHeading>
          <FixedTitle
            varient="Fixed12Medium"
            color={brandColour?.primary100}
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '2px',
            }}
          >
            <ContentCopyIcon
              style={{
                ...iconStyles,
                height: fontSize?.h5,
                width: fontSize?.h5,
              }}
            />
            {messages?.timeCard?.copyTimes}
          </FixedTitle>
        </FormRow>
        <FormRow marginBottom={3}>
          <Table
            emptyMessage="Please select period ending date."
            data={weekData || []}
            actions={[
              {
                id: 'edit',
                component: (
                  <KeyboardArrowRightIcon
                    style={{
                      ...iconStyles,
                      color: brand?.secondaryMain,
                    }}
                  />
                ),
                onClick: (row, _, rowIndex) => {
                  setScreenIndex({
                    screen1: false,
                    screen2: false,
                    screen3: true,
                  });
                  setRowIndex(rowIndex);
                  setRow(row);
                },
              },
            ]}
            specs={[
              {
                id: 'Date',
                label: messages?.timeCard?.form?.table?.date,
                format: (row: Date) => moment(row).format('DD/MM/YYYY'),
              },
              {
                id: 'Day',
                label: messages?.timeCard?.form?.table?.day,
              },
              {
                id: 'TotalHours',
                label: messages?.timeCard?.form?.table?.hours,
              },
            ]}
          />
        </FormRow>
        <FormRow marginBottom={4} justifyContent="flex-end" columnGap={1}>
          <FixedTitle varient="Fixed14Regular">
            {messages?.timeCard?.todalHrs}
          </FixedTitle>
          <FixedTitle varient="Fixed14Bold">{getTotalHours()}</FixedTitle>
        </FormRow>
        <Grid container justifyContent="flex-end" columnGap={2}>
          <Grid item>
            <Button
              variant="outlined"
              buttonColor={brand?.black}
              color="primary"
              size="large"
              onClick={handleGoBack}
              label={messages?.general?.cancel}
            />
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              onClick={handleGoBack}
              label={messages?.general?.save}
            />
          </Grid>
        </Grid>
      </Card>
    </Container>
  );

  if (screenIndex?.screen3) {
    return (
      <AddTimeCardForm
        passedData={row}
        companyDetails={companyDetails}
        setScreenIndex={setScreenIndex}
        setWeekData={setWeekData}
        rowIndex={rowIndex}
        weekData={weekData}
      />
    );
  }
  return <Screen2 />;
};

export default TimeCardTable;
