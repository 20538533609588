import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import {
  SearchInputContainer,
  StyledActionItem,
  StyledSearchInput,
} from './styles';
import { colors } from '@tphglobal/common/theme/style.palette';
import messages from '../messages';

interface Props {
  connectFilter?: any;
  maxWidth?: string;
  searchInput?: string;
  setSearchInput?: (searchInput?: string) => void;
}

export const SearchInputButton: React.FC<any> = ({
  onChange,
  value,
  maxWidth,
  ...props
}) => (
  <StyledSearchInput
    {...props}
    maxWidth={maxWidth}
    value={value || ''}
    onChange={(e) => {
      if (onChange) {
        onChange(e?.target?.value);
      }
    }}
  />
);

const SearchInput: React.FC<Props> = ({
  connectFilter,
  maxWidth,
  searchInput,
  setSearchInput,
}) => (
  <StyledActionItem>
    <SearchInputContainer data-testid='search-input'>
      {connectFilter ? (
        connectFilter('search', {
          autoApplyFilters: true,
          placeholder: messages?.general?.search,
          maxWidth: maxWidth,
        })(SearchInputButton)
      ) : (
        <SearchInputButton
          placeholder={messages?.general?.search}
          value={searchInput}
          onChange={setSearchInput}
        />
      )}
      <SearchIcon
        fontSize='medium'
        style={{
          height: '21px',
          width: '21px',
          padding: '13.5px 0',
          color: colors?.black,
        }}
      />
    </SearchInputContainer>
  </StyledActionItem>
);

export default SearchInput;
