import { DatePicker } from "@mui/x-date-pickers";
import { respondTo } from "@tphglobal/common/theme/style.layout";
import { styled } from "styled-components";

export const StyledInputContainer = styled.div`
    width : 100%;
    min-width: 135px;

    ${respondTo?.mobileOnlyPortrait} {
        min-width : 100%;
    }
`

export const StyledDatePicker = styled(DatePicker)<{fullWidth?:boolean; }>`

    .MuiInputBase-input{
        width : 100%;
    }
   
`