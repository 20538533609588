import { Grid } from '@mui/material';
import {
  brand,
  brandColour,
  greyScaleColour,
  otherColour,
} from '@tphglobal/common/theme/style.palette';
import { fontSize, fontWeight } from '@tphglobal/common/theme/style.typography';
import {
  Button,
  Card,
  Modal,
  OutlinedContainer,
} from '@tphglobal/components';
import React, { useState } from 'react';
import { Id } from '@tphglobal/common/models';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import { usePopupReducer } from '@tphglobal/common/hooks';
import useWindowSize from '@tphglobal/common/hooks/windowSize';
import { breakpoints } from '@tphglobal/common/theme/style.layout';
import { FixedTitle } from '@tphglobal/common/utils';
import ContactUs from './contactUs';
import { iconStyles } from '../../utils/constant';
import Address from './address';
import Guides from './guides';
import {
  StyledContainer,
  StyledHeading,
  StyledOverWrapper,
  StyledSubscaleTab,
  StyledSubscaleTabContainer,
  StyledSubscaleTabText,
} from './styles';
import { Container } from '../../components';
import messages from '../../messages';

interface ActiveTab {
  id: Id;
  label: string;
  component: JSX.Element;
}

interface Props {
  tabs: ActiveTab[];
  activeTab: ActiveTab;
  setActiveTab: (tab : ActiveTab) => void;
}
const TabNavigation: React.FC<Props> = ({ tabs, activeTab, setActiveTab }) => (
  <StyledSubscaleTabContainer>
    {tabs.map((tab) => {
      const active = tab.id === activeTab.id;
      return (
        <StyledSubscaleTab
          key={tab.id}
          active={active}
          onClick={() => setActiveTab(tab)}
        >
          <StyledSubscaleTabText active={active}>
            {tab.label}
          </StyledSubscaleTabText>
        </StyledSubscaleTab>
      );
    })}
  </StyledSubscaleTabContainer>
);

const tabs : ActiveTab[] = [
  {
    id: 1,
    label: 'Guides',
    component: <Guides />,
  },
  {
    id: 2,
    label: 'Address ',
    component: <Address />,
  },
];

const Support = () => {
  const [activeTab, setActiveTab] = useState<ActiveTab>(tabs[0]);

  const {
    visibility: contactFormVisibility,
    showPopup: showContactForm,
    hidePopup: hideContactForm,
    metaData: contactFormMetadata,
  } = usePopupReducer();

  const { width } = useWindowSize();

  const isMobile = width <= breakpoints.mobile;

  return (
    <Container
      heading={messages?.support?.heading}
      background={otherColour?.adminBg100}
      maxHeight
    >
      <Card
        noContentPadding={isMobile}
        removeTitle
        contentCss={isMobile && { padding: '24px 16px !important' }}
        showGoBack
        header={(
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <StyledHeading
                fontSize={fontSize?.h2}
                fontWeight={fontWeight?.semiBold}
                lineHeight="36px"
              >
                {messages?.support?.heading}
              </StyledHeading>
            </Grid>
            <Grid item>
              <Button
                onClick={() => showContactForm()}
                variant="outlined"
                color="primary"
                size="large"
                label={messages?.support?.contactUs}
                icon={<ListAltOutlinedIcon style={iconStyles} />}
              />
            </Grid>
          </Grid>
        )}
      >
        {isMobile && (
          <>
            <StyledOverWrapper>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <StyledHeading
                    fontSize={fontSize?.h5}
                    fontWeight={fontWeight?.semiBold}
                    lineHeight="21px"
                    color={`${greyScaleColour?.black100} !important`}
                  >
                    {messages?.topPanel?.heading}
                  </StyledHeading>
                </Grid>
                <Grid item xs={12} paddingTop="10px !important">
                  <FixedTitle
                    varient="Fixed12Regular"
                    color={greyScaleColour?.grey100}
                  >
                    {messages?.topPanel?.subHeading}
                  </FixedTitle>
                </Grid>
                <Grid item paddingTop="10px !important">
                  <Button
                    onClick={showContactForm}
                    buttonColor={greyScaleColour?.black100}
                    variant="outlined"
                    color="primary"
                    size="large"
                    label={messages?.topPanel?.contactUs}
                    noHover
                    icon={<ListAltOutlinedIcon style={iconStyles} />}
                  />
                </Grid>
              </Grid>
            </StyledOverWrapper>
            <OutlinedContainer
              borderColor={greyScaleColour?.grey70}
              padding="18px 14px"
              styles={{ marginTop: '24px' }}
            >
              <Grid container rowGap="6px">
                <Grid item>
                  <StyledHeading
                    fontSize={fontSize?.h5}
                    fontWeight={fontWeight?.semiBold}
                    lineHeight="21px"
                    color={`${greyScaleColour?.black100} !important`}
                  >
                    {messages?.support?.addnMap}
                  </StyledHeading>
                </Grid>
                <Grid item>
                  <FixedTitle
                    varient="Fixed12Regular"
                    color={greyScaleColour?.grey100}
                  >
                    {messages?.support?.addnMapSubHeading}
                  </FixedTitle>
                </Grid>
                <Grid item marginTop="14px">
                  <Address />
                </Grid>
              </Grid>
            </OutlinedContainer>
          </>
        )}
        {!isMobile && (
          <OutlinedContainer
            borderRadius="0"
            padding="16px 0 0 0"
            noBorder
            styles={{ borderTop: `1px solid ${brandColour?.primary10}` }}
          >
            <StyledContainer>
              <TabNavigation
                tabs={tabs}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
              {activeTab.component}
            </StyledContainer>
          </OutlinedContainer>
        )}
      </Card>
      <Modal
        icon={<ListAltOutlinedIcon />}
        show={contactFormVisibility}
        heading="Contact us"
        onClose={hideContactForm}
        fitContent
      >
        <ContactUs
          onCancel={hideContactForm}
          onSuccess={() => {
            hideContactForm();
          }}
        />
      </Modal>
    </Container>
  );
};

export default Support;
