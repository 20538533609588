import { Amplify } from 'aws-amplify';
import { RegionTypes } from '@tphglobal/common/utils/constants';
import awsConfig from '.';

const configAmplify = (region : RegionTypes) => {
  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolClientId: awsConfig[`COGNITO_APP_CLIENT_ID_${region}`],
        userPoolId: awsConfig[`COGNITO_USER_POOL_ID_${region}`],
        loginWith: {
          oauth: {
            domain: awsConfig[`COGNITO_USER_POOL_DOMAIN_${region}`],
            scopes: [
              'openid',
              'email',
              'phone',
              'profile',
              'aws.cognito.signin.user.admin',
            ],
            redirectSignIn: awsConfig[`COGNITO_REDIRECT_SIGNIN_${region}`].split(','),
            redirectSignOut: awsConfig[`COGNITO_REDIRECT_SIGNOUT_${region}`].split(','),
            responseType: 'code',
          },
        },
      },
    },
    Storage: {
      S3: {
        bucket: awsConfig[`S3_BUCKET_${region}`],
        region: awsConfig[`AWS_REGION_${region}`],
      },
    },
    API: {
      REST: {
        freelancerPortal: {
          endpoint: awsConfig[`API_URL_${region}`],
          region: awsConfig[`AWS_REGION_${region}`],
        },
      },
    },
  });
};

export default configAmplify;
