import { Grid } from '@mui/material';
import React from 'react';
import { StyledView, StyledViewContainer } from './styles';

interface Props {
  url: string;
}

const PdfView: React.FC<Props> = ({ url }) => {
  return (
    <Grid padding={'0 24px'}>
      <StyledViewContainer>
        <StyledView scrolling="no" src={url} />
      </StyledViewContainer>
    </Grid>
  );
};

export default PdfView;
