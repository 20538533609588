import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { emailValidator, FixedTitle, required } from '@tphglobal/common/utils';
import {
  Button,
  Modal,
  OutlinedContainer,
} from '@tphglobal/components';
import { useFormReducer, usePopupReducer } from '@tphglobal/common/hooks';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import { routes } from '@tphglobal/freelance-portal/utils/routes';
import {
  brandColour,
  greyScaleColour,
} from '@tphglobal/common/theme/style.palette';
import { fontSize, fontWeight } from '@tphglobal/common/theme/style.typography';
import { Id } from '@tphglobal/common/models';
import { Grid } from '@mui/material';
import useWindowSize from '@tphglobal/common/hooks/windowSize';
import { breakpoints } from '@tphglobal/common/theme/style.layout';
import { StyledContainer } from '../support/styles';
import Australia from './australia';
import UK from './uk';
import ContactUs from './contactUs';
import TopPanel from './topPanel';
import {
  MobileContainer,
  StyledHeader,
  StyledHeading,
  StyledOverBannerWrapper,
  StyledOverBannerWrapperMobile,
  StyledOverWrapper,
  StyledReactOutContainer,
  StyledSubscaleTab,
  StyledSubscaleTabContainer,
  StyledSubscaleTabText,
  StyledWrapper,
} from './styles';
import messages from '../../messages';
import { iconStyles } from '../../utils/constant';
import { Container } from '../../components';

interface ActiveTab {
  id: Id;
  label: string;
  component: JSX.Element;
}

interface Props {
  tabs: ActiveTab[];
  activeTab: ActiveTab;
  setActiveTab: (activeTab : ActiveTab) => void;
}
const TabNavigation: React.FC<Props> = ({ tabs, activeTab, setActiveTab }) => (
  <StyledSubscaleTabContainer>
    {tabs.map((tab) => {
      const active = tab.id === activeTab.id;
      return (
        <StyledSubscaleTab
          key={tab.id}
          active={active}
          onClick={() => setActiveTab(tab)}
        >
          <StyledSubscaleTabText active={active}>
            {tab.label}
          </StyledSubscaleTabText>
        </StyledSubscaleTab>
      );
    })}
  </StyledSubscaleTabContainer>
);

const GetInTouch = () => {
  const { width } = useWindowSize();

  const isMobile = width <= breakpoints.mobile;

  const tabs = [
    {
      id: 1,
      label: 'Australia',
      component: <Australia />,
    },
    {
      id: 2,
      label: 'United Kingdom',
      component: <UK />,
    },
  ];
  const [activeTab, setActiveTab] = useState<ActiveTab>(tabs[0]);

  const {
    visibility: contactFormVisibility,
    showPopup: showContactForm,
    hidePopup: hideContactForm,
  } = usePopupReducer();

  return (
    <Container hideTabNavigation noBorderRadius hideSidebar noPadding hasHeader={false} noMargin>
      <StyledWrapper>
        <TopPanel showContactForm={showContactForm} />
        <MobileContainer>
          <StyledOverBannerWrapperMobile>
            <StyledOverWrapper>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <StyledHeader
                    fontSize={isMobile ? fontSize?.h5 : fontSize?.h4}
                    fontWeight={fontWeight?.semiBold}
                    lineHeight={isMobile ? '21px' : '26px'}
                    color={
                      isMobile && `${greyScaleColour?.black100} !important`
                    }
                  >
                    {messages?.topPanel?.heading}
                  </StyledHeader>
                </Grid>
                <Grid item xs={12} paddingTop={isMobile && '10px !important'}>
                  {!isMobile ? (
                    <StyledHeader fontSize={fontSize.b1} lineHeight="18px">
                      {messages?.topPanel?.subHeading}
                    </StyledHeader>
                  ) : (
                    <FixedTitle
                      varient="Fixed12Regular"
                      color={greyScaleColour?.grey100}
                    >
                      {messages?.topPanel?.subHeading}
                    </FixedTitle>
                  )}
                </Grid>
                <Grid item paddingTop={isMobile && '10px !important'}>
                  <Button
                    onClick={showContactForm}
                    buttonColor={
                      greyScaleColour?.[isMobile ? 'black100' : 'white100']
                    }
                    variant="outlined"
                    color="primary"
                    size="large"
                    label={messages?.topPanel?.contactUs}
                    noHover
                    icon={<ListAltOutlinedIcon style={iconStyles} />}
                  />
                </Grid>
              </Grid>
            </StyledOverWrapper>
          </StyledOverBannerWrapperMobile>

          <OutlinedContainer
            borderRadius="0"
            padding={isMobile ? '0' : '50px 50px 0 74px'}
            noBorder
            styles={!isMobile ? { borderTop: `1px solid ${brandColour?.primary10}` } : {}}
          >
            {!isMobile ? (
              <StyledHeading
                fontSize={fontSize?.h3}
                fontWeight={fontWeight?.semiBold}
                lineHeight="31.px"
              >
                {messages?.getInTouch?.heading}
              </StyledHeading>
            ) : (
              <FixedTitle varient="Fixed16Medium">
                {messages?.getInTouch?.heading}
              </FixedTitle>
            )}
            <StyledContainer>
              <TabNavigation
                tabs={tabs}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
              {activeTab.component}
            </StyledContainer>
          </OutlinedContainer>
        </MobileContainer>

        <Modal
          icon={<ListAltOutlinedIcon />}
          show={contactFormVisibility}
          heading="Contact us"
          onClose={hideContactForm}
          fitContent
        >
          <ContactUs
            onCancel={hideContactForm}
            onSuccess={() => {
              hideContactForm();
            }}
          />
        </Modal>
      </StyledWrapper>
    </Container>
  );
};

export default GetInTouch;
