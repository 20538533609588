import { Typography } from '@mui/material';
import { css, styled } from 'styled-components';
import {
  brandColour,
  greyScaleColour,
  otherColour,
} from '@tphglobal/common/theme/style.palette';
import { fontSize, fontWeight } from '@tphglobal/common/theme/style.typography';
import { respondTo } from '@tphglobal/common/theme/style.layout';

interface StyledContainerProps {
  fitContent?: boolean;
  maxWidth?: string;
}

export const StyledContainer = styled.div<StyledContainerProps>`
  width: ${({ fitContent }) => (fitContent ? 'fit-content' : '25%')};
  min-width: 374px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  ${({ fitContent }) =>
    fitContent &&
    css<StyledContainerProps>`
      max-width: ${({ maxWidth }) => maxWidth || '900px'};
    `};

    ${respondTo?.mobileOnlyPortrait} {
      min-width : 343px;
      max-width: 343px;
    }
`;

export const StyledHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  padding: 0 24px 24px 24px;
  border-bottom: 1px solid ${greyScaleColour?.grey70};

  ${respondTo?.mobileOnlyPortrait} {
    padding: 0 16px 16px 16px;
  }
`;

export const StyledHeading = styled(Typography)`
  font-size: ${fontSize?.h2} !important;
  font-weight: ${fontWeight?.semiBold} !important;
  line-height: 36.46px !important;
  
  ${respondTo?.mobileOnlyPortrait} {
    font-size: ${fontSize?.h4} !important;
    font-weight: ${fontWeight?.semiBold} !important;
    line-height: 26px !important;
  }
`;

export const StyledSubHeading = styled(Typography)`
  color: ${greyScaleColour.grey100};
  font-weight: ${fontWeight.medium} !important;
`;

export const StyledCloseContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 0;
  border: 1px solid ${greyScaleColour?.grey70}
`;

export const StyledFrameContainer = styled.div<{ position?: string }>`
  width: 100px;
  height: 100px;
  cursor: pointer;
  position: ${(props) => props.position};
`;

export const StyledHeadingImgContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledHeadingImg = styled.img`
  width: 72px;
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledRow = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

export const StyledChip = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 12px;
  border-radius: 10px;
  background-color: ${brandColour?.primary10};
`;

export const StyledChildren = styled.div<{deleteForm ?:boolean, removeOverflow?: boolean}>`
  overflow-y: ${({removeOverflow}) => removeOverflow ? 'hidden' : 'auto'};
  padding-top: ${({deleteForm}) => deleteForm ? '16px' : '24px'};
  // max-height: 470px;

  &::-webkit-scrollbar {
    display: none; /* Hide the scrollbar */
  }

  ${respondTo?.mobileOnlyPortrait} {
    overflow-x: hidden;
  }
`;
