import styled from 'styled-components';
import { Typography } from '@mui/material';
import { brand, colors } from '@tphglobal/common/theme/style.palette';

export const InfoItemContainer = styled.div`
    max-width : 600px;
    min-width : 272px;
    display :flex;
    flex-direction:column;
    gap : 8px;

`;

export const InfoItemLabelContainer = styled.div`
    border-bottom: 1px solid ${colors.grey25};
    display: flex;
    align-items: center;
`;

export const InfoItemLabel = styled(Typography)`
    color : ${colors.grey100};
    margin-bottom: 4px;
`;

export const InfoItemValueContainer = styled.div`
`;

export const InfoItemValue = styled(Typography)`
    color : ${brand.secondaryMain};
    word-wrap: break-word;
`;
