import { Typography } from '@mui/material';
import {
  brandColour,
  greyScaleColour,
} from '@tphglobal/common/theme/style.palette';
import { fontSize, fontWeight } from '@tphglobal/common/theme/style.typography';
import styled, { css } from 'styled-components';

export const StyledTabNavigation = styled.div`
  display: flex;
  align-items: center;
  position: sticky;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  background-color: ${greyScaleColour?.white100};
  padding: 12px 0 16px 0;
`;

export const StyledTab = styled.a`
  height: 100%;
  flex: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
`;

export const IconContainer = styled.div<{ active?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 10.5px 4px 11.5px;
  ${({ active }) =>
    active &&
    css`
      background-color: ${brandColour?.primary20};
      border-radius: 26px;
    `}
`;

export const StyledColumn = styled.div<{ gap?: string }>`
  gap: ${({ gap }) => gap};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const StyledRow = styled.div<{ gap?: string }>`
  gap: ${({ gap }) => gap};
  display: flex;
  align-items: center;
`;

export const Avatar = styled.img`
  border-radius: 50%;
  width: 20px;
  height: 20px;
`
export const ListItem = styled.div`
  display: flex;
  align-items : center;
  gap: 7px;
  cursor: pointer;
`
export const ListIcon  = styled.div`
  display : flex;
  align-items : center;
  justify-content: center;
`
export const ListText  = styled(Typography)`
  font-size : ${fontSize?.h5};
  font-weight: ${fontWeight?.semiBold};
  line-height: 18px;
  padding-top: 1px;
`
export const StyledLine = styled.div`
  border-top : 1px solid ${brandColour?.primary10};
  width : 100%;
`