import { css, styled } from 'styled-components';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Select, Typography } from '@mui/material';
import { fontSize } from '@tphglobal/common/theme/style.typography';
import {
  brand,
  brandColour,
  colors,
  greyScaleColour,
} from '@tphglobal/common/theme/style.palette';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { respondTo } from '@tphglobal/common/theme/style.layout';

export const StyledTableContainer = styled(TableContainer)``;

export const StyledTable = styled(Table)`
  border-radius: 8px !important: 
`;

export const StyledTableHead = styled(TableHead)``;
export const StyledTableBody = styled(TableBody)``;
export const StyledTableRow = styled(TableRow)`
  th {
    padding: 12px 0 !important;
  }
  th:first-child {
    padding-left: 24px !important;
    border-radius: 6px 0 0 6px;
  }
  th:last-child {
    padding-right: 24px !important;
    border-radius: 0 6px 6px 0 ;
  }
  td {
    padding: 19px 0 !important;
    border-bottom: 1px solid ${brandColour?.primary10};
  }
  td:first-child {
    padding-left: 24px !important;
  }
  td: last-child {
    padding: 18px 24px 18px 0 !important;
  }

  ${respondTo?.mobileOnlyPortrait} {
    th:first-child {
      padding-left: 14px !important;
      padding-right: 20px !important;
    }
    th:last-child {
      padding-right: 14px !important;
    }
    td {
      padding: 16px 0 !important;
      border-bottom: 1px solid ${brandColour?.primary10};
    }
    td:first-child {
      padding-left: 16px !important;
    }
    td: last-child {
      padding: 16px 14px 16px 0 !important;
    }
  }
`;
export const StyledTableCell = styled(TableCell)<{width ?: string}>`
  font-size: ${fontSize.b1} !important;
  line-height: 18px !important;
  width : ${({width}) => width} !important;
`;

export const StyledCellContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap:1px;

  ${respondTo?.mobileOnlyPortrait} {
    min-width : 75px;
  }
`;

export const StyledLoadmoreContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 64px;
`;
export const StyledLoadmoreCta = styled(Typography)`
  font-size: ${fontSize.b3};
  color: ${brand.primaryMain};
  cursor: pointer;
`;

export const StyledActionListContainer = styled.div`
  display: flex;
  gap: 24px;
  justify-content: flex-end;

  ${respondTo?.mobileOnlyPortrait} {
    gap: 16px;  
  }
`;

export const StyledActionItem = styled.div`
  height: 20px;
  width: 20px;
  color: ${brand.secondaryMain} !important;
  cursor: pointer;
`;

export const StyledNoDataInfoContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 16px 0;
`;

export const StyledNoDataInfo = styled(Typography)``;

export const StyledPaginationContainer = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
`;

export const StyledPaginationMainContainer = styled.div`
  display: flex;
  margin-left: auto;
  padding: 0 24px;
  gap: 32px;
`;

export const StyledPaginationLimitContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
`;
export const StyledInfo = styled(Typography)`
  color: ${colors.grey100};
`;

export const StyledSelectPage = styled(Select)`
  & .MuiSelect-select {
    padding: 8px 16px;
    padding-right: 40px !important;
    color: ${brand.secondaryMain} !important;
  }

  & .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }

  & .MuiSvgIcon-root {
    color: ${brand.secondaryMain} !important;
  }
`;

export const StyledPagesContainer = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

export const StyledPaginationShowContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledPageContainer = styled.div<{ active?: boolean }>`
  display: flex;
  padding: 4px 8px;
  height: 29px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  width: 24px;
  cursor: pointer;
  background: ${colors.grey30};
  color: ${colors.grey100};
  ${({ active }) =>
    active &&
    css`
      background: ${brand.primaryMain};
      color: ${colors.grey30};
    `}
`;

export const SpaceIndicator = styled.div`
  height: 10px;
  background-color: transparent;
`;

export const StyledVisibilityIcon = styled(VisibilityOutlinedIcon)`
  color: ${brand.secondaryMain} !important;
`;

export const StyledArrowDropIcon = styled(ArrowDropDownIcon)`
  color: ${greyScaleColour.grey100} !important;
`;
