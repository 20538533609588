import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import OnlyWith from '@tphglobal/common/onlyWith';
import { AuthenticationStatus } from '../redux/reducers/auth';
import {
  ForgotPassword,
  GetInTouch,
  Login,
  ResetPassword,
  Signup,
} from './auth';
import { routes } from '../utils';
import Profile from './profile';
import Dashboard from './home';
import config from '../config';
import timeCard from './timeCard';
import Company from './company/company';
import Document from './document/document';
import TimeCard from './timeCard/timeCard';
import Home from './home/home';
import Support from './support/support';
import AddCompany from './company/addCompany';
import AddTimeCard from './timeCard/addTimeCard';
import TimeCardTable from './timeCard/mobileView/timeCardTable';
import AddTimeCardForm from './timeCard/mobileView/addTimeCardForm';
import DigitalTimeSheetMobile from './timeCard/mobileView/digitalTimeSheetMobile';
import EditInfoMobile from './profile/mobileView/editInfoMobile';
import SettingsMobile from './profile/mobileView/settingsMobile';
import SignatureMobile from './profile/mobileView/signatureMobile';

const redirectToRoot = () => <Redirect to={routes.home.root} />;
const redirectToLogin = () => <Redirect to={routes.login} />;

const Screens: React.FC = () => (
  <>
    <OnlyWith
      status={AuthenticationStatus.AUTHENTICATED}
      isApplicableFeatureLevel={config?.featureLevel}
    >
      <Switch>
        <Route path={routes.home.root} component={Home} />
        <Route path={routes.profile} component={Profile} />
        <Route path={routes.editInfoMobile} component={EditInfoMobile} />
        <Route path={routes.settingsMobile} component={SettingsMobile} />
        <Route path={routes.signatureMobile} component={SignatureMobile} />
        <Route path={routes.support.root} component={Support} />
        <Route path={routes.timeCardListing.root} component={TimeCardTable} />
        <Route path={routes.addTimeCardForm.root} component={AddTimeCardForm} />
        <Route path={routes.digitalTimeSheetMobile.root} component={DigitalTimeSheetMobile} />
        <Route path={routes.addTimeCard.root} component={AddTimeCard} />
        <Route path={routes.editTimeCard.root} component={AddTimeCard} />
        <Route path={routes.addCompany.root} component={AddCompany} />
        <Route path={routes.editCompany.root} component={AddCompany} />
        <Route path={routes.timeCard.root} component={TimeCard} />
        <Route path={routes.company.root} component={Company} />
        <Route path={routes.document.root} component={Document} />
        <Route component={redirectToRoot} />
      </Switch>
    </OnlyWith>
    <OnlyWith status={AuthenticationStatus.NOT_AUTHENTICATED}>
      <Switch>
        <Route path={routes.login} component={Login} />
        <Route path={routes.getInTouch} component={GetInTouch} />
        <Route path={routes.signup} component={Signup} />
        <Route path={routes.forgotPassword} component={ForgotPassword} />
        <Route path={routes.resetPassword} component={ResetPassword} />
        <Route component={redirectToLogin} />
      </Switch>
    </OnlyWith>
  </>
);

export default Screens;
