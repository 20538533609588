import React from 'react';
import { StyledError, StyledPhoneInputContainer, StyledTelInput } from './styles';
import 'react-phone-input-2/lib/style.css';

interface Props {
  value?: string;
  onChange?: (value: string) => void;
  error?: string;
  disableErrorMode?: boolean;
  label?: string;
  defaultCountry?: any;
  additionalCountries?: any;
}

const PhoneInputComponent: React.FC<Props> = ({
  onChange,
  error,
  disableErrorMode,
  defaultCountry,
  additionalCountries = [],
  value,
  ...props
}) => {
  const handleOnChange = (value: string) => {
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <StyledPhoneInputContainer>
      <StyledTelInput
        value={value}
        preferredCountries={['au', 'gb' ,'nz', 'us']}
        specialLabel={''}
        countryCodeEditable={false}
        placeholder={'Mobile'}
        country={''}
        inputStyle={{
          width: '100%',
        }}
        onChange={handleOnChange}
        {...props}
      />
      {!disableErrorMode && error && <StyledError>{error}</StyledError>}
    </StyledPhoneInputContainer>
  );
};

export default PhoneInputComponent;
