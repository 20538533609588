import { Popover, Typography } from '@mui/material';
import { respondTo } from '@tphglobal/common/theme/style.layout';
import { brandColour } from '@tphglobal/common/theme/style.palette';
import { fontSize, fontWeight } from '@tphglobal/common/theme/style.typography';
import styled from 'styled-components';

export const StyledHeading = styled(Typography)`
`;
export const ListItem = styled.div`
  display: flex;
  align-items : center;
  gap: 7px;
  cursor: pointer;


  ${respondTo?.mobileOnlyPortrait} {

  }
`;

export const ListIcon = styled.div`
  display : flex;
  align-items : center;
  justify-content: center;
`;
export const ListText = styled(Typography)`
  font-size : ${fontSize?.h5};
  font-weight: ${fontWeight?.semiBold};
  line-height: 18px;
  padding-top: 1px;
`;

export const StyledLine = styled.div`
  border-top : 1px solid ${brandColour?.primary10};
  width : 100%;
`;

export const StyledPopover = styled(Popover)`
  ${respondTo?.mobileOnlyPortrait} {
    & .MuiPopover-paper {
      padding: 12px;
    }
  }
`;
