import { Id, Option } from '../models';
import { capitalizeLegend } from './commonFunctions';

import messages from '../messages';

export const headerItems = [
  {
    key: 'manage-locations',
    label: messages?.general?.headings?.manageLocations,
  },
  {
    key: 'manage-users',
    label: messages?.general?.headings?.manageUsers,
  },
  {
    key: 'about',
    label: messages?.general?.headings?.about,
  },
];

export const MAX_FILE_SIZE = 4;

// Enumerations
export enum Right {
  DASHBOARD = 'DASHBOARD',

  DEPARTMENTS = 'DEPARTMENTS',

  AUTHORITIES = 'AUTHORITIES',

  FACULTY = 'FACULTY',

  PROJECTS = 'PROJECTS',

  GENERALSETTINGS = 'GENERALSETTINGS',

  USERMANAGEMENT = 'USERMANAGEMENT',

  STUDENTS = 'STUDENTS',
}

export enum AuthenticationStatus {
  AUTHENTICATED = 'AUTHENTICATED',
  NOT_AUTHENTICATED = 'NOT_AUTHENTICATED',
}

export enum Role {
  ADMINISTRATOR = 'ADMINISTRATOR',
  INVALID = 'INVALID',
}

export const RolePrioriry: any = {
  ADMINISTRATOR: 1,
};

// Redux Action Types
export const APICALL = 'APICALL';

// Redux Actions
export const PAGINATED_APICALL = 'PAGINATED_APICALL';
export const PING = 'PING';

export const TOKEN_UPDATE = 'TOKEN_UPDATE';
export const TOKEN_REMOVE = 'TOKEN_REMOVE';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

export const FETCH_BASE_DATA = 'FETCH_BASE_DATA';

export const USER_PROFILE = 'USER_PROFILE';
export const SYSTEM_LOADER = 'SYSTEM_LOADER';

export const DEPARTMENTS = 'DEPARTMENTS';
export const FACULTY = 'FACULTY';
export const THEMES = 'THEMES';
export const AUTHORITIES = 'AUTHORITIES';
export const PROJECTS = 'PROJECTS';
export const PROJECTSLISTING = 'PROJECTSLISTING';
export const GENERALSETTINGSLISTING = 'GENERALSETTINGSLISTING';
export const USERMANAGEMENTLISTING = 'USERMANAGEMENTLISTING';
export const STUDENTS = 'STUDENTS';
export const THEMESLISTINGS = 'THEMESLISTINGS';
export const FORMS = 'FORMS';

// Form Actions
export const UPDATE_FORM = 'UPDATE_FORM';
export const VALIDATE_FORM = 'VALIDATE_FORM';
export const GET_INITIAL_STATE = 'GET_INITIAL_STATE';
export const RESET_FORM = 'RESET_FORM';
export const RESET_SPECIFIC = 'RESET_SPECIFIC';
export const REMOVE_PRISTINE = 'REMOVE_PRISTINE';
export const START_SUBMITTING = 'START_SUBMITTING';
export const ADD_PRISTINE = 'ADD_PRISTINE';
export const STOP_SUBMITTING = 'STOP_SUBMITTING';
export const UPDATE_SUBMIT_ERROR = 'UPDATE_SUBMIT_ERROR';
export const ADD_ITEM_TO_GROUP = 'ADD_ITEM_TO_GROUP';
export const DELETE_ITEM_FROM_GROUP = 'DELETE_ITEM_FROM_GROUP';
export const UPDATE_RECURSIVE_GROUP = 'UPDATE_RECURSIVE_GROUP';
export const CHECK_ERRORS = 'CHECK_ERRORS';
export const EMPTY_GROUP = 'EMPTY_GROUP';
export const ADD_GROUP_PRISTINE = 'ADD_GROUP_PRISTINE';

export const SAVE_ITEM_IN_STATE = 'SAVE_ITEM_IN_STATE';
export const ADD_OR_UPDATE_FORM = 'ADD_OR_UPDATE_FORM';
export const DELETE_FORM = 'DELETE_FORM';
export const DELETE_ITEM_FROM_STATE = 'DELETE_ITEM_FROM_STATE';
export const CLEAR_FORM = 'CLEAR_FORM';

export const resourceAllowedFiles = [
  'application/pdf',
  'text/plain',
  'video/mp4',
  'video/webm',
  'video/ogg',
  'video/avi',
  'video/mpeg',
  'video/quicktime',
  'video/x-msvideo',
];

export const allowedFiles = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'application/pdf',
];

export enum RegionTypes {
  UK = 'UK',
  AU = 'AU',
}

export const mapIdNameToOption = (entity: {
  id: Id;
  label: string;
}): Option => ({ id: entity?.id, label: capitalizeLegend(entity?.label) });

export const regions = [
  { id: 'AU', label: 'Australia' },
  { id: 'UK', label: 'United Kingdom' },
];
export const timeOptions = [
  { id: 'Type', label: 'Type' },
  { id: 'DropList', label: 'Drop list' },
];
export const productionOptions = [{ id: 'TPHGlobal', label: 'TPHGlobal' }];
