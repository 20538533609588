import React from 'react';
import { fontSize, fontWeight } from '@tphglobal/common/theme/style.typography';
import { brand, greyScaleColour } from '@tphglobal/common/theme/style.palette';
import { routes } from '@tphglobal/freelance-portal/utils/routes';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { Button } from '@tphglobal/components';
import { Grid } from '@mui/material';
import { FixedTitle } from '@tphglobal/common/utils';
import useWindowSize from '@tphglobal/common/hooks/windowSize';
import { breakpoints } from '@tphglobal/common/theme/style.layout';
import messages from '../../messages';
import {
  StyledTopPanel,
  StyledContentWrapper,
  GoBackWrapper,
  GoBackIcon,
  GoBackText,
  StyledHeadingWrapper,
  StyledHeader,
  StyledOverBannerWrapper,
  StyledOverWrapper,
} from './styles';
import { iconStyles } from '../../utils/constant';

interface Props {
  showContactForm : () => void
}

const TopPanel: React.FC<Props> = ({ showContactForm }) => {
  const reduxDispatch = useDispatch();
  const { width } = useWindowSize();

  const isMobile = width <= breakpoints.mobile;

  return (
    <StyledTopPanel>
      <StyledContentWrapper>
        <GoBackWrapper onClick={() => reduxDispatch(push(routes.login))}>
          <GoBackIcon
            src="../../assets/images/whiteGoBack.svg"
            alt="white-go-back"
          />
          <FixedTitle
            varient="Fixed14Regular"
            color={greyScaleColour?.white100}
          >
            {messages?.sidePanel?.goBackText}
          </FixedTitle>
        </GoBackWrapper>
        <StyledHeadingWrapper>
          <StyledHeader fontSize={isMobile ? '28px' : '55px'} lineHeight={isMobile ? '36px' : '63px'} fontWeight={fontWeight?.semiBold}>
            {messages?.sidePanel?.getInTouch}
          </StyledHeader>
          <StyledHeader fontSize={isMobile ? fontSize?.b2 : fontSize.b1}>
            {messages?.sidePanel?.subHeader}
          </StyledHeader>
        </StyledHeadingWrapper>
      </StyledContentWrapper>
      <StyledOverBannerWrapper>
        <StyledOverWrapper>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <StyledHeader
                fontSize={fontSize?.h4}
                fontWeight={fontWeight?.semiBold}
                lineHeight="26px"
              >
                {messages?.topPanel?.heading}
              </StyledHeader>
            </Grid>
            <Grid item xs={12}>
              <FixedTitle varient="Fixed14Regular" color={greyScaleColour?.grey70}>
                {messages?.topPanel?.subHeading}
              </FixedTitle>
            </Grid>
            <Grid item>
              <Button
                onClick={showContactForm}
                buttonColor={greyScaleColour?.white100}
                variant="outlined"
                color="primary"
                size="large"
                label={messages?.topPanel?.contactUs}
                noHover
                icon={<ListAltOutlinedIcon style={iconStyles} />}
              />
            </Grid>
          </Grid>
        </StyledOverWrapper>
      </StyledOverBannerWrapper>
    </StyledTopPanel>
  );
};

export default TopPanel;
