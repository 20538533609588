import {
  Button,
  Card,
  FormError,
  FormRow,
  FormRowItem,
  MaterialAutocompleteInput,
  PhoneInput,
  TextInput,
  Toast,
} from '@tphglobal/components';
import React, { useEffect, useRef, useState } from 'react';
import { brand, otherColour } from '@tphglobal/common/theme/style.palette';
import useWindowSize from '@tphglobal/common/hooks/windowSize';
import { breakpoints } from '@tphglobal/common/theme/style.layout';
import { Grid } from '@mui/material';
import { fontSize, fontWeight } from '@tphglobal/common/theme/style.typography';
import {
  emptyValueValidator,
  FixedTitle,
  HttpMethods,
  mapIdNameToOption,
  required,
} from '@tphglobal/common/utils';
import { useDispatch, useSelector } from 'react-redux';
import { useFormReducer } from '@tphglobal/common/hooks';
import { goBack } from 'connected-react-router';
import { RegionTypes, timeOptions } from '@tphglobal/common/utils/constants';
import { toast } from 'react-toastify';
import { emailValidator } from '../../../utils';
import { ReduxState } from '../../../redux/reducers';
import handleApiCall from '../../handleApiCall';
import {
  UPDATE_PROFILE_INFO_AU, UPDATE_PROFILE_INFO_UK, UPLOAD_PROFILE_PIC_AU, UPLOAD_PROFILE_PIC_UK,
} from '../../../api';
import { ProfileImage, StyledHeading } from '../styles';
import messages from '../../../messages';
import { Container } from '../../../components';
import { Option } from '@tphglobal/common/models';

interface SubmitData {
  email : string
  firstName : string
  lastName : string
  minutesStep : string
  number : string
  timeOption : {id : string, label : string}
}

const validators = {
  firstName: [required(messages?.genera?.validations?.firstName)],
  lastName: [required('')],
  email: [
    required(messages?.genera?.validations?.emailRequired),
    emailValidator,
  ],
  number: [required(messages?.genera?.validations?.phoneRequired)],
  minutesStep: [required(messages?.genera?.validations?.minutesStepRequired)],
  timeOption: [required(messages?.genera?.validations?.timeOptionRequired)],
};

const EditInfoMobile = () => {
  const reduxDispatch = useDispatch();
  const userProfile = useSelector((state: ReduxState) => state?.profile);
  const selectedRegion = userProfile?.region;
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<string>(
    userProfile?.profile_pic,
  );
  const [base64Image, setBase64Image] = useState<string | null>(null);
  const fileInputRef = useRef(null);

  const {
    submitError, handleSubmit, connectField, change, setSubmitError,
  } = useFormReducer(validators);

  const handleChangePhoto = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result as string);
        setBase64Image(reader.result as string); // Set Base64 string
      };
      reader.readAsDataURL(file); // Read file as Base64 string
    }
  };

  const handleImageUpload = (base64Image: string) => {
    const sanitizedBody = {
      base64Data: base64Image,
    };
    handleApiCall(
      reduxDispatch,
      selectedRegion === RegionTypes?.AU
        ? UPLOAD_PROFILE_PIC_AU
        : UPLOAD_PROFILE_PIC_UK,
      () => {
        setSubmitting(false);
        reduxDispatch(goBack());
        toast(<Toast text={messages?.profile?.editForm?.success?.updatedSuccessfully} />);
      },
      HttpMethods.POST,
      selectedRegion === RegionTypes?.AU ? RegionTypes.AU : RegionTypes.UK,
      null,
      sanitizedBody,
    );
  };

  const onSubmit = async (data: SubmitData) => {
    setSubmitting(true);

    const sanitizedBody = {
      FirstName: data?.firstName,
      LastName: data?.lastName,
      MinutsStep: data?.minutesStep,
      MobileNumber: data?.number,
      TimeOption: data?.timeOption?.id,
    };
    handleApiCall(
      reduxDispatch,
      selectedRegion === RegionTypes?.AU
        ? UPDATE_PROFILE_INFO_AU
        : UPDATE_PROFILE_INFO_UK,
      () => {
        if (base64Image) {
          handleImageUpload(base64Image);
        } else {
          setSubmitting(false);
          reduxDispatch(goBack());
        }
      },
      HttpMethods.PUT,
      selectedRegion === RegionTypes?.AU ? RegionTypes.AU : RegionTypes.UK,
      messages?.profile?.editForm?.success?.updatedSuccessfully,
      sanitizedBody,
    );
  };

  useEffect(() => {
    change('firstName', userProfile?.FirstName);
    change('lastName', userProfile?.LastName);
    change('email', userProfile?.EmailAddress);
    change('number', userProfile?.MobileNumber);
    change('minutesStep', userProfile?.MinutsStep);
    change('timeOption', {
      id: timeOptions?.find(
        (option: Option) => option?.id === userProfile?.TimeOption,
      )?.id,
      label: timeOptions?.find(
        (option: Option) => option?.id === userProfile?.TimeOption,
      )?.label,
    });
  }, []);

  return (
    <Container
      heading={messages?.profile?.editProfile}
      showGoBack
      background={otherColour?.adminBg100}
      maxHeight
    >
      <Card
        noContentPadding
        removeTitle
        contentCss={{ padding: '12px 16px !important' }}
        showGoBack
      >
        <Grid container rowGap="6px">
          <FormRow
            display="flex"
            flexDirection="column"
            alignItems="center"
            rowGap={1}
          >
            <ProfileImage width="100px" height="100px" src={selectedImage} />
            <FixedTitle
              varient="Fixed12Regular"
              color={brand?.secondaryMain}
              onClick={handleChangePhoto}
            >
              {messages?.profile?.changePhoto}
            </FixedTitle>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleFileChange}
              accept="image/*"
            />
          </FormRow>
          <FormRow columnSpacing={2}>
            <FormRowItem>
              {connectField('firstName', {
                label: messages?.profile?.editForm?.firstName,
              })(TextInput)}
            </FormRowItem>
            <FormRowItem>
              {connectField('lastName', {
                label: messages?.profile?.editForm?.lastName,
              })(TextInput)}
            </FormRowItem>
          </FormRow>
          <FormRow>
            {connectField('email', {
              label: messages?.profile?.editForm?.emailAddress,
              disabled : true
            })(TextInput)}
          </FormRow>
          <FormRow>
            {connectField('number', {
              label: messages?.profile?.editForm?.phoneNumber,
            })(PhoneInput)}
          </FormRow>
          <FormRow columnSpacing={2}>
            <FormRowItem xs={6}>
              {connectField('timeOption', {
                label: messages?.profile?.editForm?.timeOption,
                options: timeOptions?.map(mapIdNameToOption),
              })(MaterialAutocompleteInput)}
            </FormRowItem>
            <FormRowItem xs={6}>
              {connectField('minutesStep', {
                label: messages?.profile?.editForm?.minutesStep,
              })(TextInput)}
            </FormRowItem>
          </FormRow>
          {submitError && (
            <FormRow>
              <FormRowItem>
                <FormError
                  message={
                    messages?.departments?.addForm?.failure?.[submitError]
                  }
                />
              </FormRowItem>
            </FormRow>
          )}
        </Grid>
        <Grid
          container
          justifyContent="flex-end"
          columnSpacing={2}
          marginTop="22px"
        >
          <Grid item>
            <Button
              variant="outlined"
              buttonColor={brand?.black}
              color="primary"
              size="large"
              onClick={() => reduxDispatch(goBack())}
              label={messages?.general?.cancel}
            />
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              onClick={handleSubmit(onSubmit)}
              disabled={submitting}
              label={messages?.general?.update}
            />
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};

export default EditInfoMobile;
