import { Card, CardContent, Typography } from '@mui/material';
import styled from 'styled-components';
import { colors, otherColour } from '@tphglobal/common/theme/style.palette';

export const StyledCard = styled(Card)<{ bordered?: boolean }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  background: ${otherColour?.gradientBg5} !important;

  & .MuiCard-root {
    background: ${otherColour?.gradientBg5} !important;
  }
`;

export const StyledCardContent = styled(CardContent)<{ noContentPadding?: boolean }>`
  padding: ${({ noContentPadding }) => (noContentPadding ? '0px' : '0 24px')} !important;
  overflow-y: auto;
  flex-grow: 1;
  &::-webkit-scrollbar {
    display: none; /* Hide the scrollbar */
  }
  &::-webkit-scrollbar-track {
    background-color: #f2f2f2;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #999;
  }
`;

export const StyledHeader = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
`

export const StyledCardHeader = styled.div<{ noHeaderPadding?: boolean , noBottomMargin ?: boolean}>`
  padding: ${({ noHeaderPadding }) => (noHeaderPadding ? '0px' : '24px 24px 0 24px')} !important;
  position: relative;
  margin-bottom: ${({ noBottomMargin }) => (noBottomMargin ? '0px' : '16px')} !important;
`;
export const CardTitle = styled(Typography)`
  color: ${colors.black};
`;
