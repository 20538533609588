import React, { useRef, useState, forwardRef, useImperativeHandle } from 'react';
import SignaturePad from 'react-signature-pad-wrapper';
import { StyledContainer } from './styles';

interface Props {
  height?: number
}

const SignatureInput = forwardRef(({ height }: Props, ref) => {
  const sigPadRef : any = useRef();

  useImperativeHandle(ref, () => ({
    clear: () => {
      sigPadRef?.current.clear();
    },
    sign: () => {
      if (sigPadRef?.current.isEmpty()) {
        return null;
      }
      const newSignatureData = sigPadRef?.current.toDataURL();
      return newSignatureData;
    },
  }));

  return (
    <StyledContainer>
      <SignaturePad
        ref={sigPadRef}
        options={{ penColor: 'black' }}
        height={height || null}
      />
    </StyledContainer>
  );
});

export default SignatureInput;
