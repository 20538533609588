import styled from 'styled-components';
import { Grid } from '@mui/material';
import { brandColour, colors } from '@tphglobal/common/theme/style.palette';

export const StyledDynamicFieldContainer = styled.div`
  width: 100%;
`;

export const StyledFieldsContainer = styled.div<{ startZero?: boolean }>`
  padding: 1rem 1rem 0 1rem;
  border: ${(props) => (props.startZero ? '' : '1px solid #ddd')};
`;

export const StyledFieldList = styled.div<{ idx?: number }>`
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => (props.idx > 0 ? '40px' : '')};
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${brandColour?.primary10};
`;

export const StyledFieldItem = styled.div<{ idx?: number }>`
`;

export const StyledCrossButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const StyledDeleteButton = styled.div`
  padding: 15px;
  border-radius: 25px;
  background: ${colors.grey25};
  cursor : pointer;
  
  &: hover {
    background: ${colors.grey50};
  }
`;

export const StyledCard = styled.div<{ bg?: string, noPadding?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 32px;
  // margin-left: 8px;
  border-radius: 2px;
  padding: ${(props) => (props.noPadding ? '24px 0px' : '24px')};
  margin-bottom: 16px;
  background: ${(props) => (props.bg ? props.bg : colors.grey10)};
`;

export const StyledCardButton = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;

export const StyledDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-left: 8px;
  margin-bottom: 40px;
`;

export const StyledDetailRow = styled(Grid)`
    align-items : center;
    justify-content : space-between;
    gap : 64px;
`;
export const StyledDetailRowItem = styled(Grid)`
    flex : 1;
`;

export const StyledButton = styled.div<{ display?: string, padding?: string }>`
  display: ${(props) => props.display};
  padding: ${(props) => props.padding};
`;
