import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import { greyScaleColour } from '@tphglobal/common/theme/style.palette';
import styled from 'styled-components';

export const StyledHeading = styled(Typography)``;

export const StyledAccordionSummary = styled(AccordionSummary)<{
  flexGrow?: string;
}>`
  .MuiAccordionSummary-content {
    flex-grow: ${({ flexGrow }) => flexGrow};
  }
  .MuiAccordionSummary-expandIconWrapper {
    margin-left: 70px;
  }
`;

export const StyledAccordion = styled(Accordion)`
  background-color: ${greyScaleColour?.white100} !important;
  background: ${greyScaleColour?.white100} !important;
`;
export const StyledAccordionDetails = styled(AccordionDetails)`
  padding: 16px 0 0 0 !important;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Column = styled.div<{ gap?: string }>`
  gap: ${({ gap }) => gap};
  display: flex;
  flex-direction: column;
`;
