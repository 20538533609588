import React, { useEffect, useState } from 'react';
import {
  Avatar,
  IconContainer,
  ListIcon,
  ListItem,
  ListText,
  StyledColumn,
  StyledLine,
  StyledTab,
  StyledTabNavigation,
} from './styles';
import GridViewIcon from '@mui/icons-material/GridView';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import ArticleIcon from '@mui/icons-material/Article';
import { fontSize } from '@tphglobal/common/theme/style.typography';
import { brand, greyScaleColour } from '@tphglobal/common/theme/style.palette';
import { FixedTitle } from '@tphglobal/common/utils';
import routes from '@tphglobal/freelance-portal/utils/routes';
import { useLocation } from 'react-router-dom';
import { Popover } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LogoutIcon from '@mui/icons-material/Logout';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';

interface Props {
  profileImage?: string;
  handleLogout : () => void
}

const iconStyles = {
  height: fontSize?.h4,
  width: fontSize?.h4,
};

const tabs = [
  {
    id: 'home',
    label: 'Home',
    icon: GridViewIcon,
    path: routes?.home?.root,
  },
  {
    id: 'timeCard',
    label: 'TimeCard',
    icon: CreditCardIcon,
    path: routes?.timeCard?.root,
  },
  {
    id: 'company',
    label: 'Company',
    icon: CorporateFareIcon,
    path: routes?.company?.root,
  },
  {
    id: 'document',
    label: 'Document',
    icon: ArticleIcon,
    path: routes?.document?.root,
  },
];

const TabNavigation: React.FC<Props> = ({ profileImage, handleLogout }) => {
  const profileActionsItems = [
    {
      id: 'profile',
      icon: (
        <Avatar
          src={
            profileImage
              ? profileImage
              : '../../../assets/images/defaultProfile.png'
          }
        />
      ),
      label: 'Profile',
      path: routes?.profile,
    },
    {
      id: 'helpAndSupport',
      icon: (
        <HelpOutlineIcon
          style={{ ...iconStyles, fill: greyScaleColour?.grey100 }}
        />
      ),
      label: 'Help & support',
      path: routes?.support?.root,
    },
    {
      id: 'logout',
      icon: <LogoutIcon style={{ ...iconStyles }} color='warning' />,
      label: 'Logout',
      path: routes?.login,
    },
  ];

  const location = useLocation();
  const reduxDispatch = useDispatch();
  const [anchorPosition, setAnchorPosition] = useState({ top: 0, left: 0 });

  const [open, setOpen] = useState(false);

  useEffect(() => {
    const updateAnchorPosition = () => {
      const top = window.innerHeight - 240;
      const left = window.innerWidth;
      setAnchorPosition({ top, left });
    };

    updateAnchorPosition();
    window.addEventListener('resize', updateAnchorPosition);

    return () => {
      window.removeEventListener('resize', updateAnchorPosition);
    };
  }, []);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const id = open ? 'simple-popover' : undefined;

  return (
    <StyledTabNavigation>
      {tabs?.map((tab: any) => {
        const Icon = tab?.icon;
        const active = location.pathname.startsWith(tab.path);
        return (
          <StyledTab key={tab?.id} href={tab?.path}>
            <StyledColumn gap={'8px'}>
              <IconContainer active={active}>
                <Icon
                  style={{
                    ...iconStyles,
                    fill: active
                      ? brand?.secondaryMain
                      : greyScaleColour?.grey100,
                  }}
                />
              </IconContainer>
              <FixedTitle
                varient='Fixed12Regular'
                color={active ? brand?.secondaryMain : greyScaleColour?.grey100}
              >
                {tab?.label}
              </FixedTitle>
            </StyledColumn>
          </StyledTab>
        );
      })}
      <StyledTab onClick={handleClick}>
        <StyledColumn gap={'12px'}>
          <Avatar
            src={
              profileImage
                ? profileImage
                : '../../../assets/images/defaultProfile.png'
            }
          />
          <FixedTitle varient='Fixed12Regular' color={greyScaleColour?.grey100}>
            Profile
          </FixedTitle>
        </StyledColumn>
      </StyledTab>
      <Popover
        id={id}
        open={open}
        anchorReference='anchorPosition'
        onClose={handleClose}
        anchorPosition={anchorPosition}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {profileActionsItems?.map((obj: any, index) => {
          const first = index === 0;
          const last = index === profileActionsItems?.length - 1;
          let itemStyles;

          if (first) {
            itemStyles = {
              padding: '0 23px 12px 0',
            };
          } else if (last) {
            itemStyles = {
              padding: '12px 23px 0 0',
            };
          } else {
            itemStyles = {
              padding: '12px 0',
            };
          }
          return (
            <>
              <ListItem
                onClick={() => {
                  if(obj?.id === 'logout') {
                    handleLogout()
                  } else {
                    reduxDispatch(push(obj?.path));
                  }
                }}
                key={obj?.id}
                style={itemStyles}
              >
                <ListIcon>{obj?.icon}</ListIcon>
                <ListText>{obj?.label}</ListText>
              </ListItem>
              {!last && <StyledLine />}
            </>
          );
        })}
      </Popover>
    </StyledTabNavigation>
  );
};

export default TabNavigation;
