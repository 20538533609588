import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  FixedTitle,
  emailValidator,
  mapIdNameToOption,
  required,
} from '@tphglobal/common/utils';

import {
  Button,
  Form,
  FormError,
  FormRow,
  FormRowItem,
  MaterialAutocompleteInput,
  Modal,
  TextInput,
  Toast,
} from '@tphglobal/components';
import { useFormReducer, usePopupReducer } from '@tphglobal/common/hooks';
import {
  brand,
  colors,
  greyScaleColour,
} from '@tphglobal/common/theme/style.palette';
import { fontSize } from '@tphglobal/common/theme/style.typography';
import useWindowSize from '@tphglobal/common/hooks/windowSize';
import { breakpoints } from '@tphglobal/common/theme/style.layout';
import { resetPassword, ResetPasswordOutput } from 'aws-amplify/auth';
import { regions, RegionTypes } from '@tphglobal/common/utils/constants';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import ConfirmOtpForm from './ConfirmOtpForm';
import configAmplify from '../../config/awsConfig';
import SidePanel from './sidePanel';
import { Container } from '../../components';
import {
  NoAccountTextContainer,
  StyledContent,
  StyledContentContainer,
  StyledFormContainer,
  StyledFormDetails,
  StyledFormHeading,
  StyledHeaderContainer,
  StyledInfoContainer,
  StyledLinkContainer,
  StyledLogo,
  StyledScreenWrapper,
  StyledSidePanelLowerTextMobile,
  StyledSmallLogo,
} from './styles';
import messages from '../../messages';
import { routes } from '../../utils';
import ErrorHandler from '../../utils/errorHandler';

const validators = {
  region: [required(messages?.general?.validations?.regionRequired)],
  email: [
    required(messages?.general?.validations?.emailRequired),
    emailValidator,
  ],
};

const ForgotPassword = () => {
  const {
    submitting,
    handleSubmit,
    connectField,
    setSubmitError,
    submitError,
    formValues,
  } = useFormReducer(validators);
  const reduxDispatch = useDispatch();
  const { width } = useWindowSize();
  const [error, setError] = useState<string>('');
  const history = useHistory();

  const {
    visibility: otpFormVisibility,
    showPopup: showOtpInputScreen,
    hidePopup: hideOtpForm,
    metaData: otpFormMetadata,
  } = usePopupReducer();

  const isMobile = width <= breakpoints.mobile;

  const handleForgotPasswordNextSteps = (
    output: ResetPasswordOutput,
  ) => {
    const { nextStep } = output;
    switch (nextStep.resetPasswordStep) {
      case 'CONFIRM_RESET_PASSWORD_WITH_CODE':
        showOtpInputScreen();
        break;
      case 'DONE':
        toast(<Toast type="error" text="Successfully reset password." />);
        break;
      default:
    }
  };

  interface SubmitData {
    region : {id : RegionTypes, label : RegionTypes}
    email : string
  }
  const onSubmit = async (data: SubmitData) => {
    configAmplify(data.region?.id);
    try {
      const output = await resetPassword({
        username: data?.email?.toLowerCase(),
      });
      handleForgotPasswordNextSteps(output);
    } catch (error) {
      setSubmitError(ErrorHandler(error?.name));
    }
  };

  const iconStyle = {
    color: colors.grey100,
    width: `${fontSize.h5}`,
    height: `${fontSize.h5}`,
  };

  return (
    <Container
      hideTabNavigation
      hideSidebar
      noBorderRadius
      noPadding
      hasHeader={false}
      noMargin
    >
      <StyledScreenWrapper>
        <StyledFormContainer>
          <StyledContentContainer>
            <StyledHeaderContainer>
              {isMobile ? (
                <StyledSmallLogo
                  src="/assets/images/smallLoginLogo.svg"
                  alt="smallLogo"
                />
              ) : (
                <StyledLogo src="/assets/images/loginLogo.svg" alt="logo" />
              )}
              <StyledInfoContainer>
                <StyledFormHeading>
                  {messages?.forgotPassword?.heading}
                </StyledFormHeading>
                <FixedTitle
                  varient="Fixed14Regular"
                  color={greyScaleColour?.grey100}
                >
                  {messages?.forgotPassword?.subHeading}
                </FixedTitle>
              </StyledInfoContainer>
            </StyledHeaderContainer>

            <StyledContent>
              <StyledFormDetails>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <FormRow>
                    <FormRowItem>
                      {connectField('region', {
                        label: messages?.login?.form?.region,
                        options: regions?.map(mapIdNameToOption),
                      })(MaterialAutocompleteInput)}
                    </FormRowItem>
                  </FormRow>
                  <FormRow>
                    <FormRowItem>
                      {connectField('email', {
                        label: messages?.login?.form?.email,
                      })(TextInput)}
                    </FormRowItem>
                  </FormRow>
                  <FormRow>
                    <FormRowItem>
                      <Button
                        label={messages?.general?.proceed}
                        type="submit"
                        disabled={submitting}
                        variant="outlined"
                      />
                    </FormRowItem>
                  </FormRow>
                  <FormRow>
                    <FormRowItem justifyContent="center" alignItems="center">
                      <StyledLinkContainer href={routes.login}>
                        <ArrowBackIcon style={iconStyle} />
                        <FixedTitle
                          varient="Fixed12Regular"
                          color={greyScaleColour?.grey100}
                        >
                          {messages?.sidePanel?.goBackText}
                        </FixedTitle>
                      </StyledLinkContainer>
                    </FormRowItem>
                  </FormRow>
                </Form>
                {submitError && (
                  <FormRow>
                    <FormRowItem>
                      <FormError message={submitError} />
                    </FormRowItem>
                  </FormRow>
                )}
              </StyledFormDetails>
            </StyledContent>
            <StyledSidePanelLowerTextMobile mt="217px">
              <NoAccountTextContainer>
                <FixedTitle
                  varient="Fixed14Regular"
                  color={!isMobile ? brand.white : greyScaleColour.grey100}
                >
                  {messages?.sidePanel?.needHelp}
                </FixedTitle>
                <FixedTitle
                  varient={isMobile ? 'Fixed14Medium' : 'Fixed14Bold'}
                  onClick={() => reduxDispatch(push(routes.getInTouch))}
                  color={!isMobile ? brand.white : brand?.secondaryMain}
                  style={{ cursor: 'pointer' }}
                >
                  {messages?.sidePanel?.getInTouch}
                </FixedTitle>
              </NoAccountTextContainer>
            </StyledSidePanelLowerTextMobile>
          </StyledContentContainer>
        </StyledFormContainer>
        <SidePanel />
      </StyledScreenWrapper>
      <Modal
        show={otpFormVisibility}
        heading="Confirm OTP"
        onClose={hideOtpForm}
        fitContent
      >
        <ConfirmOtpForm
          onCancel={hideOtpForm}
          error={error}
          setError={setError}
          onSubmit={(confirmationCode : string) => {
            hideOtpForm();
            history.push(routes?.resetPassword, { data: { confirmationCode, username: formValues?.email?.value } });
          }}
          username={formValues?.email?.value}
          forgotPassword
        />
      </Modal>
    </Container>
  );
};
export default ForgotPassword;
