import { Typography } from '@mui/material';
import styled from 'styled-components';

export const StyledHeading = styled(Typography)`
`;

export const StyledRow = styled.div<{gap? : string}>`
    display: flex;
    align-items: center;
    gap: ${({ gap }) => gap};
`;

export const StyledColumn = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledLogo = styled.img``;

export const Row = styled.div<{gap?:string}>`
    display: flex;
    align-items: center;
    gap: ${({ gap }) => gap};
`;
