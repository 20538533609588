import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import {
  signIn,
  fetchAuthSession,
  resendSignUpCode,
  confirmSignUp,
} from 'aws-amplify/auth';

import {
  emailValidator,
  FixedTitle,
  HttpMethods,
  required,
} from '@tphglobal/common/utils';
import {
  Button,
  Form,
  FormError,
  FormRow,
  FormRowItem,
  MaterialAutocompleteInput,
  Modal,
  PasswordInput,
  TextInput,
  Toast,
} from '@tphglobal/components';
import { useFormReducer, usePopupReducer } from '@tphglobal/common/hooks';
import useWindowSize from '@tphglobal/common/hooks/windowSize';
import { breakpoints } from '@tphglobal/common/theme/style.layout';
import { brand, greyScaleColour } from '@tphglobal/common/theme/style.palette';
import { routes } from '@tphglobal/freelance-portal/utils/routes';
import {
  mapIdNameToOption,
  regions,
  RegionTypes,
} from '@tphglobal/common/utils/constants';
import { apiCall } from '@tphglobal/common/redux/actions';
import { toast } from 'react-toastify';
import { Container } from '../../components';
import configAmplify from '../../config/awsConfig';
import SidePanel from './sidePanel';
import {
  NoAccountTextContainer,
  OrLogin,
  StyledAuthSignup,
  StyledButton,
  StyledContent,
  StyledContentContainer,
  StyledFormContainer,
  StyledFormDetails,
  StyledFormHeading,
  StyledHeaderContainer,
  StyledIcon,
  StyledInfoContainer,
  StyledLine,
  StyledLink,
  StyledLogo,
  StyledScreenWrapper,
  StyledSidePanelLowerTextMobile,
  StyledSmallLogo,
} from './styles';
import messages from '../../messages';
import { UPDATE_LOGIN_AU, UPDATE_LOGIN_UK } from '../../api';
import { postLogin, setRegion } from '../../redux/actions';
import ConfirmOtpForm from './ConfirmOtpForm';
import ErrorHandler from '../../utils/errorHandler';

interface LoginResponseResult {
  [key: string]: string;
}

interface LoginResponse {
  result: [LoginResponseResult];
  status: boolean;
}

interface SubmitData {
  region: { id: RegionTypes; label: RegionTypes };
  email: string;
  password: string;
}

const validators = {
  region: [required(messages?.general?.validations?.regionRequired)],
  email: [
    required(messages?.general?.validations?.emailRequired),
    emailValidator,
  ],
  password: [required(messages?.general?.validations?.passwordRequired)],
};

const Login = () => {
  const {
    submitting,
    submitError,
    handleSubmit,
    connectField,
    setSubmitError,
    formValues,
  } = useFormReducer(validators);
  const reduxDispatch = useDispatch();
  const { width } = useWindowSize();
  const [error, setError] = useState<string>('');
  const [username, setUsername] = useState<string>('');
  const isMobile = width <= breakpoints.mobile;

  const {
    visibility: otpFormVisibility,
    showPopup: showOtpInputScreen,
    hidePopup: hideOtpForm,
    metaData: otpFormMetadata,
  } = usePopupReducer<{ data: SubmitData }>();

  const updateLogin = async (email: string) => new Promise<LoginResponse>((resolve, reject) => {
    try {
      const sanitizedBody = {
        userEmail: email,
      };
      const apiUrl = formValues?.region?.value?.id === RegionTypes.AU
        ? UPDATE_LOGIN_AU
        : UPDATE_LOGIN_UK;

      reduxDispatch(
        apiCall(apiUrl, resolve, (err) => {
          toast(<Toast type='error' text={ErrorHandler(err?.messgae)} />)
        }, HttpMethods.PUT, sanitizedBody),
      );
    } catch (error) {
      toast(
        <Toast
          type="error"
          text={`POST call failed: ${JSON.parse(error.response.body)}`}
        />,
      );
    }
  });

  const loginUser = async (data?: SubmitData) => {
    hideOtpForm();

    const idToken = (await fetchAuthSession()).tokens?.idToken?.toString();

    if (idToken) {
      reduxDispatch(postLogin({ token: idToken, region: data?.region?.id }));
    }
    const user = await updateLogin(data?.email);

    if (user?.status) {
      reduxDispatch(push(routes?.home?.root));
    } else {
      toast(<Toast type="error" text="Unsuccessful login. Please try agin." />);
    }
  };

  const onSubmit = async (data: SubmitData) => {
    configAmplify(data.region?.id);

    try {
      const { isSignedIn, nextStep } = await signIn({
        username: data.email,
        password: data.password,
        options: {
          userAttributes: {
            region: JSON.stringify(data.region),
          },
        },
      });

      if (!isSignedIn) {
        setUsername(data?.email);
        await resendSignUpCode({
          username: data?.email,
        });
        showOtpInputScreen({ data });
      } else {
        loginUser(data);
      }
    } catch (error) {
      setSubmitError(ErrorHandler(error?.name));
    }
  };

  const handleOtpConfirmation = async (confirmationCode: string) => {
    try {
      const { isSignUpComplete } = await confirmSignUp({
        username: otpFormMetadata?.data?.email,
        confirmationCode,
      });

      if (isSignUpComplete) {
        await signIn({
          username: otpFormMetadata?.data?.email,
          password: otpFormMetadata?.data?.password,
        });
        loginUser(otpFormMetadata?.data);
      }
    } catch (error) {
      toast(<Toast type="error" text="Invalid otp." />);
    }
  };
  return (
    <Container
      hideTabNavigation
      noBorderRadius
      hideSidebar
      noPadding
      hasHeader={false}
      noMargin
    >
      <StyledScreenWrapper>
        <StyledFormContainer>
          <StyledContentContainer>
            <StyledHeaderContainer>
              {isMobile ? (
                <StyledSmallLogo
                  src="/assets/images/smallLoginLogo.svg"
                  alt="smallLogo"
                />
              ) : (
                <StyledLogo src="/assets/images/loginLogo.svg" alt="logo" />
              )}
              <StyledInfoContainer>
                <StyledFormHeading>
                  {messages?.login?.heading}
                </StyledFormHeading>
                <FixedTitle
                  varient="Fixed14Regular"
                  color={greyScaleColour?.grey100}
                >
                  {messages?.login?.subHeading}
                </FixedTitle>
              </StyledInfoContainer>
            </StyledHeaderContainer>

            <StyledContent>
              <StyledFormDetails>
                {/* <StyledAuthSignup>
                  <StyledButton>
                    <StyledIcon
                      src='/assets/images/Facebook.svg'
                      alt='facebookIcon'
                    />
                    <FixedTitle varient='Fixed14Medium'>
                      {messages?.login?.form?.facebook}
                    </FixedTitle>
                  </StyledButton>
                  <StyledButton>
                    <StyledIcon
                      src='/assets/images/Google.svg'
                      alt='googleIcon'
                    />
                    <FixedTitle varient='Fixed14Medium'>
                      {messages?.login?.form?.google}
                    </FixedTitle>
                  </StyledButton>
                </StyledAuthSignup>
                <OrLogin>
                  <StyledLine />
                  <FixedTitle
                    varient='Fixed12Regular'
                    color={greyScaleColour?.grey90}
                  >
                    {messages?.login?.form?.orLogin}
                  </FixedTitle>
                  <StyledLine />
                </OrLogin> */}
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <FormRow>
                    <FormRowItem>
                      {connectField('region', {
                        label: messages?.login?.form?.region,
                        options: regions?.map(mapIdNameToOption),
                      })(MaterialAutocompleteInput)}
                    </FormRowItem>
                  </FormRow>
                  <FormRow>
                    <FormRowItem>
                      {connectField('email', {
                        label: messages?.login?.form?.email,
                      })(TextInput)}
                    </FormRowItem>
                  </FormRow>
                  <FormRow marginBottom="10px">
                    <FormRowItem>
                      {connectField('password', {
                        label: messages?.login?.form?.password,
                      })(PasswordInput)}
                    </FormRowItem>
                  </FormRow>
                  <FormRow>
                    <FormRowItem justifyContent="flex-end">
                      <StyledLink href={routes.forgotPassword}>
                        <FixedTitle
                          varient="Fixed14Regular"
                          color={greyScaleColour?.grey100}
                          style={{ cursor: 'pointer' }}
                        >
                          {messages?.login?.form?.forgotPassword}
                        </FixedTitle>
                      </StyledLink>
                    </FormRowItem>
                  </FormRow>
                  {submitError && (
                    <FormRow>
                      <FormRowItem>
                        <FormError message={submitError} />
                      </FormRowItem>
                    </FormRow>
                  )}
                  <FormRow marginBottom="0">
                    <FormRowItem>
                      <Button
                        label={
                          messages?.login?.form?.[isMobile ? 'signIn' : 'logIn']
                        }
                        type="submit"
                        variant="outlined"
                        color="primary"
                        size="large"
                        disabled={submitting}
                      />
                    </FormRowItem>
                  </FormRow>
                </Form>
              </StyledFormDetails>
              <NoAccountTextContainer>
                <FixedTitle
                  varient="Fixed14Regular"
                  color={greyScaleColour?.grey100}
                >
                  {messages?.login?.form?.noAccount}
                </FixedTitle>
                <FixedTitle
                  style={{ cursor: 'pointer' }}
                  varient="Fixed14Medium"
                  color={brand?.secondaryMain}
                  onClick={() => reduxDispatch(push(routes.signup))}
                >
                  {messages?.login?.form?.getStarted}
                </FixedTitle>
              </NoAccountTextContainer>
            </StyledContent>
            <StyledSidePanelLowerTextMobile mt="41px">
              <NoAccountTextContainer>
                <FixedTitle
                  varient="Fixed14Regular"
                  color={!isMobile ? brand.white : greyScaleColour.grey100}
                >
                  {messages?.sidePanel?.needHelp}
                </FixedTitle>
                <FixedTitle
                  varient={isMobile ? 'Fixed14Medium' : 'Fixed14Bold'}
                  onClick={() => reduxDispatch(push(routes.getInTouch))}
                  color={!isMobile ? brand.white : brand?.secondaryMain}
                  style={{ cursor: 'pointer' }}
                >
                  {messages?.sidePanel?.getInTouch}
                </FixedTitle>
              </NoAccountTextContainer>
            </StyledSidePanelLowerTextMobile>
          </StyledContentContainer>
        </StyledFormContainer>
        <SidePanel />
      </StyledScreenWrapper>
      <Modal
        show={otpFormVisibility}
        heading="Confirm OTP"
        onClose={hideOtpForm}
        fitContent
      >
        <ConfirmOtpForm
          onCancel={hideOtpForm}
          error={error}
          setError={setError}
          onSubmit={(confirmationCode: string) => {
            handleOtpConfirmation(confirmationCode);
          }}
          username={username}
        />
      </Modal>
    </Container>
  );
};

export default Login;
