import { Grid } from '@mui/material';
import {
  Button, Modal, ModalAction, OutlinedContainer, Toast,
} from '@tphglobal/components';
import React, { useState } from 'react';
import { fontSize, fontWeight } from '@tphglobal/common/theme/style.typography';
import {
  greyScaleColour,
  otherColour,
} from '@tphglobal/common/theme/style.palette';
import { useDispatch, useSelector } from 'react-redux';
import { usePopupReducer } from '@tphglobal/common/hooks';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { toast } from 'react-toastify';
import { RegionTypes } from '@tphglobal/common/utils/constants';
import { push } from 'connected-react-router';
import { HttpMethods } from '@tphglobal/common/utils';
import { ReduxState } from '../../../redux/reducers';
import handleApiCall from '../../handleApiCall';
import { iconStyles } from '../../../utils/constant';
import { CLEAR_DATA_AU, CLEAR_DATA_UK } from '../../../api';
import messages from '../../../messages';
import { StyledHeading } from '../styles';

interface Props {
  isMobile?: boolean;
  fetchProfileOnSuccess : () => void
}

const ClearData: React.FC<Props> = ({ isMobile, fetchProfileOnSuccess }) => {
  const reduxDispatch = useDispatch();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const selectedRegion = useSelector((state : ReduxState) => state?.profile?.region);

  const {
    visibility: deleteVisibility,
    showPopup: showdeleteForm,
    hidePopup: hidedeleteForm,
  } = usePopupReducer();

  const handleClearData = () => {
    setSubmitting(true);
    handleApiCall(
      reduxDispatch,
      selectedRegion === RegionTypes.AU ? CLEAR_DATA_AU : CLEAR_DATA_UK,
      () => {
        setSubmitting(false);
        fetchProfileOnSuccess();
        toast(<Toast text={messages?.profile?.clearData?.success} />);
        hidedeleteForm();
      },
      HttpMethods?.GET,
      selectedRegion === RegionTypes.AU ? RegionTypes.AU : RegionTypes.UK,
    );
  };

  return (
    <>
      <OutlinedContainer>
        <Grid rowSpacing={2} container justifyContent="space-between">
          <Grid item alignSelf="center">
            <StyledHeading
              fontWeight={fontWeight?.semiBold}
              fontSize={fontSize?.h5}
            >
              {messages?.profile?.clearData?.heading}
            </StyledHeading>
            <StyledHeading
              color={greyScaleColour?.grey100}
              fontSize={fontSize?.b2}
            >
              {messages?.profile?.clearData?.subHeading}
            </StyledHeading>
          </Grid>
          <Grid item alignSelf="center">
            <Button
              buttonColor={otherColour?.errorDefault}
              onClick={() => showdeleteForm()}
              variant="outlined"
              color="primary"
              size="large"
              label={messages?.profile?.clearData?.button}
              icon={<DeleteOutlineIcon style={iconStyles} />}
            />
          </Grid>
        </Grid>
      </OutlinedContainer>
      <Modal
        icon={<DeleteOutlineIcon />}
        show={deleteVisibility}
        heading={messages?.profile?.clearDataForm?.heading}
        onClose={hidedeleteForm}
        deleteForm
        fitContent
      >
        <ModalAction
          noConfirmationDelete
          // onSuccess={() => {
          //   toast(messages?.profile?.clearData?.success);
          // }}
          title={messages?.profile?.clearDataForm?.subHeading}
          onCancel={hidedeleteForm}
          buttonLabel="Clear Data"
          completeLabel
          submitting={submitting}
          customSubmit={handleClearData}
        />
      </Modal>
    </>
  );
};

export default ClearData;
