import { Fab } from '@mui/material';
import { respondTo } from '@tphglobal/common/theme/style.layout';
import { brand, brandColour } from '@tphglobal/common/theme/style.palette';
import styled, { css } from 'styled-components';

export const Button = styled(Fab)<{
  left?: string;
  right?: string;
  top?: string;
  bottom?: string;
}>`
  ${({ left }) =>
    left &&
    css`
      left: ${left};
    `}
  ${({ right }) =>
    right &&
    css`
      right: ${right};
    `}
    ${({ top }) =>
    top &&
    css`
      top: ${top};
    `}
    ${({ bottom }) =>
    bottom &&
    css`
      bottom: ${bottom};
    `}
    background-color: ${brandColour?.primary20} !important;
  box-shadow: none !important;
  z-index: 1000;

  ${respondTo?.mobileOnlyPortrait} {
    display : none !important;
  }
`;
