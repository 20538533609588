import React from 'react';
import { useDispatch } from 'react-redux';
import { OutlinedContainer } from '@tphglobal/components';
import { fontSize, fontWeight } from '@tphglobal/common/theme/style.typography';
import { FixedTitle } from '@tphglobal/common/utils';
import {
  brand,
  brandColour,
  greyScaleColour,
} from '@tphglobal/common/theme/style.palette';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid } from '@mui/material';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import ArrowOutwardOutlinedIcon from '@mui/icons-material/ArrowOutwardOutlined';
import useWindowSize from '@tphglobal/common/hooks/windowSize';
import { breakpoints } from '@tphglobal/common/theme/style.layout';
import { iconStyles } from '../../utils/constant';
import {
  StyledAccordionDetails,
  StyledDetailsContainer,
  StyledHeader,
  StyledHeading,
  StyledLocation,
  StyledRow,
} from './styles';
import messages from '../../messages';

const detailIconStyles = {
  height: '21px',
  width: '21px',
  fill: brandColour?.primary100,
};

const Address = () => {
  const { width } = useWindowSize();

  const isMobile = width <= breakpoints.mobile;

  const handleRedirect = (link : string) => {
    window.open(link, '_blank');
  }

  const uriAU = encodeURIComponent(messages?.getInTouch?.headOffice?.subHeading)
  const uriUK = encodeURIComponent("TPH Global Ltd, Units 3 and 4, Syd's Quay, Eel Pie Island, Twickenham TW1 3DY, United Kingdom")

  return (
    <Grid container xs={12} gap={2}>
      <Grid item xs={12}>
        <OutlinedContainer
          padding={isMobile && '18px 14px'}
          borderColor={isMobile && greyScaleColour?.grey70}
        >
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={(
                <ExpandMoreIcon
                  style={{ ...iconStyles, fill: brand?.secondaryMain }}
                />
              )}
              aria-controls="australia"
              id="australia"
            >
              <StyledHeader>
                <StyledHeading
                  fontWeight={fontWeight?.semiBold}
                  fontSize={fontSize?.h5}
                  lineHeight="20.83px"
                >
                  {messages?.support?.details?.austraila}
                </StyledHeading>
                {!isMobile && (
                  <FixedTitle
                    varient="Fixed12Regular"
                    color={greyScaleColour?.grey100}
                  >
                    {messages?.support?.details?.subHeading}
                  </FixedTitle>
                )}
              </StyledHeader>
            </AccordionSummary>
            <StyledAccordionDetails>
              <Grid container columnSpacing={3}>
                <Grid item xs={12} md={4} lg={4}>
                  <Grid container rowSpacing={!isMobile ? '14px' : '10px'}>
                    {/* Head office */}
                    <Grid item xs={12}>
                      <StyledDetailsContainer>
                        <StyledRow>
                          <LocationOnOutlinedIcon style={detailIconStyles} />
                          <FixedTitle varient="Fixed14Medium">
                            {messages?.support?.details?.head}
                          </FixedTitle>
                        </StyledRow>
                        <FixedTitle varient="Fixed14Regular">
                          {messages?.getInTouch?.headOffice?.subHeading}
                        </FixedTitle>
                        {!isMobile && (
                          <StyledRow cursor onClick={() => handleRedirect(`https://maps.google.com/maps?q=${uriAU}&t=&z=13&ie=UTF8&iwloc=&output=embed`)}>
                            <FixedTitle
                              varient="Fixed12Regular"
                              color={brandColour?.primary100}
                            >
                              {messages?.support?.details?.getDirections}
                            </FixedTitle>
                            <ArrowOutwardOutlinedIcon
                              style={{
                                ...detailIconStyles,
                                height: '14px',
                                width: '14px',
                              }}
                            />
                          </StyledRow>
                        )}
                      </StyledDetailsContainer>
                    </Grid>
                    {isMobile && (
                      <Grid item xs={12} md={8}>
                        <StyledLocation src={`https://maps.google.com/maps?q=${uriAU}&t=&z=13&ie=UTF8&iwloc=&output=embed`} width="100%" height="167px" frameborder="0" />
                      </Grid>
                    )}
                    {/* Postal address */}
                    <Grid item xs={12}>
                      <StyledDetailsContainer>
                        <StyledRow>
                          <LocationOnOutlinedIcon style={detailIconStyles} />
                          <FixedTitle varient="Fixed14Medium">
                            {messages?.support?.details?.postal}
                          </FixedTitle>
                        </StyledRow>
                        <FixedTitle varient="Fixed14Regular">
                          {messages?.getInTouch?.headOffice?.subHeading}
                        </FixedTitle>
                      </StyledDetailsContainer>
                    </Grid>
                    {/* Call us */}
                    <Grid item xs={12}>
                      <StyledDetailsContainer>
                        <StyledRow>
                          <PhoneIphoneOutlinedIcon style={detailIconStyles} />
                          <FixedTitle varient="Fixed14Medium">
                            {messages?.support?.details?.call}
                          </FixedTitle>
                        </StyledRow>
                          <a href="tel:+1234567890" style={{textDecoration : 'none'}}>
                            <FixedTitle varient="Fixed14Regular" color='black'>
                                +613 9829 2300
                            </FixedTitle>
                          </a>
                      </StyledDetailsContainer>
                    </Grid>
                  </Grid>
                </Grid>
                {!isMobile && (
                  <Grid item xs={12} md={8} lg={8}>
                    <StyledRow style={{ marginBottom: '4px' }}>
                      <LocationOnOutlinedIcon style={detailIconStyles} />
                      <FixedTitle varient="Fixed14Medium">
                        {messages?.support?.details?.headLocation}
                      </FixedTitle>
                    </StyledRow>
                    <StyledLocation src={`https://maps.google.com/maps?q=${uriAU}&t=&z=13&ie=UTF8&iwloc=&output=embed`} width="100%" height="300" frameborder="0" />
                  </Grid>
                )}
              </Grid>
            </StyledAccordionDetails>
          </Accordion>
        </OutlinedContainer>
      </Grid>
      <Grid item xs={12}>
        <OutlinedContainer
          padding={isMobile && '18px 14px'}
          borderColor={isMobile && greyScaleColour?.grey70}
        >
          <Accordion>
            <AccordionSummary
              expandIcon={(
                <ExpandMoreIcon
                  style={{ ...iconStyles, fill: brand?.secondaryMain }}
                />
              )}
              aria-controls="australia"
              id="australia"
            >
              <StyledHeader>
                <StyledHeading
                  fontWeight={fontWeight?.semiBold}
                  fontSize={fontSize?.h5}
                  lineHeight="20.83px"
                >
                  {messages?.support?.details?.uk}
                </StyledHeading>
                {!isMobile && (
                  <FixedTitle
                    varient="Fixed12Regular"
                    color={greyScaleColour?.grey100}
                  >
                    {messages?.support?.details?.subHeading}
                  </FixedTitle>
                )}
              </StyledHeader>
            </AccordionSummary>
            <StyledAccordionDetails>
              <Grid container columnSpacing={3}>
                <Grid item xs={12} md={4} lg={4}>
                  <Grid container rowSpacing={!isMobile ? '14px' : '10px'}>
                    {/* Head office */}
                    <Grid item xs={12}>
                      <StyledDetailsContainer>
                        <StyledRow>
                          <LocationOnOutlinedIcon style={detailIconStyles} />
                          <FixedTitle varient="Fixed14Medium">
                            {messages?.support?.details?.head}
                          </FixedTitle>
                        </StyledRow>
                        <FixedTitle varient="Fixed14Regular">
                          The River Unit
                          Phoenix Wharf
                          Eel Pie Island, Twickenham TW1 3DY
                          United Kingdon
                        </FixedTitle>
                        {!isMobile && (
                          <StyledRow cursor>
                            <FixedTitle
                              varient="Fixed12Regular"
                              color={brandColour?.primary100}
                              onClick={() => handleRedirect(`https://maps.google.com/maps?q=${uriUK}&t=&z=13&ie=UTF8&iwloc=&output=embed`)}
                            >
                              {messages?.support?.details?.getDirections}
                            </FixedTitle>
                            <ArrowOutwardOutlinedIcon
                              style={{
                                ...detailIconStyles,
                                height: '14px',
                                width: '14px',
                              }}
                            />
                          </StyledRow>
                        )}
                      </StyledDetailsContainer>
                    </Grid>
                    {isMobile && (
                      <Grid item xs={12} md={8}>
                        <StyledLocation src={`https://maps.google.com/maps?q=${uriUK}&t=&z=13&ie=UTF8&iwloc=&output=embed`} width="100%" height="167px" frameborder="0"/>
                      </Grid>
                    )}
                    {/* Call us */}
                    <Grid item xs={12}>
                      <StyledDetailsContainer>
                        <StyledRow>
                          <PhoneIphoneOutlinedIcon style={detailIconStyles} />
                          <FixedTitle varient="Fixed14Medium">
                            {messages?.support?.details?.call}
                          </FixedTitle>
                        </StyledRow>
                        <a href="tel:+1234567890" style={{textDecoration : 'none'}}>
                          <FixedTitle varient="Fixed14Regular" color='black'>
                            +44(0) 333 6000 113
                          </FixedTitle>
                        </a>
                      </StyledDetailsContainer>
                    </Grid>
                  </Grid>
                </Grid>
                {!isMobile && (
                  <Grid item xs={12} md={8} lg={8}>
                    <StyledRow style={{ marginBottom: '4px' }}>
                      <LocationOnOutlinedIcon style={detailIconStyles} />
                      <FixedTitle varient="Fixed14Medium">
                        {messages?.support?.details?.headLocation}
                      </FixedTitle>
                    </StyledRow>
                    <StyledLocation src={`https://maps.google.com/maps?q=${uriUK}&t=&z=13&ie=UTF8&iwloc=&output=embed`} width="100%" height="300" frameborder="0"></StyledLocation>
                  </Grid>
                )}
              </Grid>
            </StyledAccordionDetails>
          </Accordion>
        </OutlinedContainer>
      </Grid>
    </Grid>
  );
};

export default Address;
