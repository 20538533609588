import React from 'react';
import { Container } from './styles';

interface ContainerProps {
  children?: JSX.Element | JSX.Element[];
  borderColor?: string;
  padding?: string;
  margin?: string;
  borderRadius?: string;
  styles?: React.CSSProperties;
  noBorder?: boolean
  centered?: boolean
  onClick?: () => void
}

const OutlinedContainer: React.FC<ContainerProps> = ({
  children,
  borderColor,
  padding,
  margin,
  borderRadius,
  styles,
  noBorder,
  centered,
  onClick,
}) => {
  return (
    <Container
      borderColor={borderColor}
      padding={padding}
      margin={margin}
      borderRadius={borderRadius}
      noBorder={noBorder}
      style={styles}
      centered={centered}
      onClick={onClick}
    >
      {children}
    </Container>
  );
};

export default OutlinedContainer;
