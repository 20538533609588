import React from 'react';
import { useLocation } from 'react-router-dom';
import { FixedTitle } from '@tphglobal/common/utils/theme';
import {
  StyledSidebarContainer,
  StyledSidebarInnerContainer,
  StyledHeadingIconContainer,
  StyledMenuItemContainer,
  StyledMenuItemBoxContainer,
  StyledMenuItem,
  StyledLogoIcon,
  StyledRow,
} from './styles';

import { greyScaleColour } from '@tphglobal/common/theme/style.palette';

interface Props {
  onClose?: () => void;
  isOpen?: boolean;
  activeButton?: string;
  sidebarOpen: boolean;
  menuItems?: any
}

const Sidebar: React.FC<Props> = ({ sidebarOpen, menuItems }) => {
  const location = useLocation();
  return (
    <StyledSidebarContainer sidebarOpen={sidebarOpen}>
      <StyledSidebarInnerContainer>
        <StyledHeadingIconContainer>
          <StyledLogoIcon
            src={
              !sidebarOpen
                ? '../../../assets/images/logo.svg'
                : '../../../assets/images/smallLogo.svg'
            }
            alt='logoicon'
          />
        </StyledHeadingIconContainer>
        <StyledMenuItemContainer>
          <StyledMenuItemBoxContainer>
            {menuItems.map((menuItem : any) => {
              const active = location.pathname.startsWith(menuItem.path);
              return (
                <StyledMenuItem
                  active={active}
                  href={menuItem.path}
                  key={menuItem.key}
                  sidebarOpen={sidebarOpen}
                >
                  <StyledRow>
                    {menuItem?.icon}
                    {!sidebarOpen && (
                      <FixedTitle
                        varient='Fixed14Medium'
                        color={greyScaleColour?.white100}
                      >
                        {menuItem.label}
                      </FixedTitle>
                    )}
                  </StyledRow>
                </StyledMenuItem>
              );
            })}
          </StyledMenuItemBoxContainer>
        </StyledMenuItemContainer>
      </StyledSidebarInnerContainer>
    </StyledSidebarContainer>
  );
};

export default Sidebar;
