import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { goBack, push } from 'connected-react-router';
import {
  StyledAvatar,
  StyledToolbar,
  StyledAppBar,
  StyledAvatarContainer,
  StyledHeader,
  StyledGoBackButton,
  StyledIconHeadingContainer,
  StyledHeaderTitle,
  StyledSidebarToggleIcon,
  StyledHeading,
  StyledHeadingWrapper,
  StyledIcon,
  ListItem,
  ListIcon,
  ListText,
  StyledLine,
  StyledRow,
  StyledImage,
} from './styles';
import { StyledLogoIcon } from '../sidebar/styles';
import { fontSize, fontWeight } from '@tphglobal/common/theme/style.typography';
import { greyScaleColour } from '@tphglobal/common/theme/style.palette';
import messages from '../messages';
import moment from 'moment';
import { Popover, Typography } from '@mui/material';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import routes from '@tphglobal/freelance-portal/utils/routes';
import { FixedTitle } from '@tphglobal/common/utils/theme';

interface HeaderCustomProps {
  showGoBack?: boolean;
  heading?: string;
  sidebarOpen: boolean;
  setSidebarOpen: (sidebarOpen: boolean) => void;
  handleLogout: () => void
  profileImage ?: string
  userProfile ?: any
}

const Header: React.FC<HeaderCustomProps> = ({
  showGoBack,
  heading,
  sidebarOpen,
  setSidebarOpen,
  handleLogout,
  profileImage,
  userProfile,
  ...props
}) => {
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };
  const reduxDispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const profileActionsItems = [
    {
      id: 'profile',
      icon: <PermIdentityOutlinedIcon color='primary' />,
      label: 'Profile',
      onClick: () => reduxDispatch(push(routes?.profile))
    },
    {
      id: 'logout',
      icon: <LogoutIcon color='warning' />,
      label: 'Logout',
      onClick: handleLogout
    },
  ];
  
  return (
    <>
      <StyledHeader {...props}>
        <StyledAppBar>
          <StyledToolbar>
            <StyledGoBackButton>
              <StyledSidebarToggleIcon
                src={
                  sidebarOpen
                    ? '../../../assets/images/arrowOpenIcon.svg'
                    : '../../../assets/images/arrowIcon.svg'
                }
                alt='arrow'
                position='absolute'
                right='-11px'
                top='96px'
                onClick={toggleSidebar}
              />
              <StyledHeadingWrapper>
                <StyledRow>
                  <FixedTitle
                    varient='Fixed16Medium'
                    color={greyScaleColour?.white100}
                  >
                    Welcome {userProfile?.FirstName}
                  </FixedTitle>
                  <StyledImage src="../../../assets/images/wavingHand.svg" />
                </StyledRow>
                <FixedTitle
                  varient='Fixed12Medium'
                  color={greyScaleColour?.grey70}
                >
                  {messages?.header?.lastLogin}
                  {moment().format('MMMM D, YYYY')}
                </FixedTitle>
              </StyledHeadingWrapper>
            </StyledGoBackButton>
            <StyledAvatarContainer onClick={(e : any) => {handleClick(e)}}>
              {
                profileImage ? (
                  <StyledAvatar src={profileImage} />
                ) : (
                  <StyledAvatar src="../../../assets/images/defaultProfile.png" />
                )
              }
              <StyledIcon src='../../../assets/images/downArrowIcon.svg' />
            </StyledAvatarContainer>
          </StyledToolbar>
        </StyledAppBar>
      </StyledHeader>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        style={{ padding: '16px' }}
      >
        {profileActionsItems?.map((obj: any, index) => (
          <>
            <ListItem
              onClick={obj?.onClick}
              key={obj?.id}
              style={{
                padding:
                  index != profileActionsItems?.length - 1
                    ? '0 23px 14px 0'
                    : '14px 23px 0 0',
              }}
            >
              <ListIcon>{obj?.icon}</ListIcon>
              <ListText>{obj?.label}</ListText>
            </ListItem>
            {index != profileActionsItems?.length - 1 && <StyledLine />}
          </>
        ))}
      </Popover>
    </>
  );
};

export default Header;
