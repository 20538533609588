import { Grid } from "@mui/material";
import { OutlinedContainer, Stepper } from "@tphglobal/components";
import React from "react";
import { FixedTitle } from "@tphglobal/common/utils";
import { greyScaleColour } from "@tphglobal/common/theme/style.palette";
import { fontSize, fontWeight } from "@tphglobal/common/theme/style.typography";
import messages from "../../messages";
import { StyledHeading } from "./styles";
import { ActivityLog } from "./home";

interface Props {
  isMobile?: boolean;
  activityFeed?: ActivityLog[];
}

const RecentActivity: React.FC<Props> = ({ isMobile, activityFeed }) => (
  <OutlinedContainer padding={isMobile && "16px 0"}>
    <Grid container gap="6px" marginBottom="22px">
      <Grid item xs={12}>
        <StyledHeading
          lineHeight="20px"
          fontWeight={fontWeight?.semiBold}
          fontSize={fontSize?.h5}
        >
          {messages?.home?.activityFeed}
        </StyledHeading>
      </Grid>
      <Grid item>
        <FixedTitle varient="Fixed12Regular" color={greyScaleColour?.grey100}>
          {messages?.home?.activitySubHeading}
        </FixedTitle>
      </Grid>
    </Grid>
    <Stepper items={activityFeed} />
  </OutlinedContainer>
);

export default RecentActivity;
