import {
  Button,
  Card,
  CheckBoxInput,
  Form,
  FormError,
  FormRow,
  FormRowItem,
  MaterialAutocompleteInput,
  OutlinedContainer,
  TextInput,
  TimeInput,
  Toast,
} from '@tphglobal/components';
import React, { useEffect, useState } from 'react';
import {
  brand,
  brandColour,
  greyScaleColour,
  otherColour,
} from '@tphglobal/common/theme/style.palette';
import moment from 'moment';
import { useFormReducer } from '@tphglobal/common/hooks';
import {
  emptyValueValidator,
  FixedTitle,
  mapFlagToValue,
  required,
} from '@tphglobal/common/utils';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { fontSize, fontWeight } from '@tphglobal/common/theme/style.typography';
import { Accordion, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CustomCheckbox from '@tphglobal/components/checkBoxInput';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DateInput from '@tphglobal/components/materialDateInput';
import {
  StyledAccordionDetails,
  StyledAccordionSummary,
  StyledHeading,
} from '../styles';
import { getDefaultWeekDay, toCamelCase } from '../../../utils/commonFunctions';
import {
  CompanyDetailedEntity,
  CustomField,
  DailyTime,
  Payas,
  TimeOption,
  Validators,
} from '../../../models';
import CustomFieldComponent from '../customFieldComponent';
import messages from '../../../messages';
import { Container } from '../../../components';
import { ReduxState } from '../../../redux/reducers';

interface Screens {
  screen1 : boolean
  screen2 : boolean
  screen3 : boolean
}

interface FormData {
  StartMeal: Date;
  StartMeal2: Date;
  StartMeal3: Date;
  FinishMeal1: Date;
  FinishMeal3: Date;
  FinishMeal2: Date;
  MB1ND: boolean;
  MB2ND: boolean;
  MB3ND: boolean;
  Start: Date;
  Finish: Date;
  Payas: {id : string, label : string};
  Travel1: string;
  Note: string | null;
  Travel2: string;
  TotalMBDed: string;
}

interface Props {
  setScreenIndex: (screenIndex: Screens) => void;
  passedData: DailyTime;
  companyDetails: CompanyDetailedEntity;
  setWeekData: (update: (weekData: DailyTime[]) => DailyTime[]) => void;
  rowIndex: number | null;
  weekData : DailyTime[]
}

let validators : Validators = {
  Payas: [required(messages?.general?.validations?.payAsRequired)],
};
const iconStyles = {
  height: fontSize?.h4,
  width: fontSize?.h4,
  color: brandColour?.primary100,
};
const expandIconStyles = {
  height: fontSize?.h3,
  width: fontSize?.h3,
  fill: brand?.secondaryMain,
};

const AddTimeCardForm: React.FC<Props> = ({
  setScreenIndex,
  passedData,
  companyDetails,
  setWeekData,
  rowIndex,
  weekData,
}) => {
  const {
    submitting,
    submitError,
    handleSubmit,
    connectField,
    change,
    setSubmitError,
    formValues,
  } = useFormReducer(validators);

  const userProfile = useSelector((state: ReduxState) => state?.profile);
  const [yesterdayTimings, setYesterdayTimings] = useState<DailyTime>();

  const getTotalHours = () => {
    if (formValues?.Payas?.value?.id === 'DNW') {
      return '00:00';
    }
    const startMB1 = moment(formValues?.StartMeal?.value, 'HH:mm');
    const startMB2 = moment(formValues?.StartMeal2?.value, 'HH:mm');
    const startMB3 = moment(formValues?.StartMeal3?.value, 'HH:mm');
    const finishMB1 = moment(formValues?.FinishMeal1?.value, 'HH:mm');
    const finishMB2 = moment(formValues?.FinishMeal2?.value, 'HH:mm');
    const finishMB3 = moment(formValues?.FinishMeal3?.value, 'HH:mm');

    const startWorkTime = moment(formValues?.Start?.value, 'HH:mm');
    const finishWorkTime = moment(formValues?.Finish?.value, 'HH:mm');
    const travelToTime = moment(formValues?.Travel1?.value, 'HH:mm');
    const travelFromTime = moment(formValues?.Travel2?.value, 'HH:mm');
    const defaultMealBreakTime = moment(formValues?.TotalMBDed?.value, 'HH:mm');

    const calculateMealBreakDuration = () => {
      const totalMealBreakDuration = moment.duration(0);

      if (
        startMB1.isValid()
        && finishMB1.isValid()
        && finishMB1.isAfter(startMB1)
      ) {
        totalMealBreakDuration.add(moment.duration(finishMB1.diff(startMB1)));
      }
      if (
        startMB2.isValid()
        && finishMB2.isValid()
        && finishMB2.isAfter(startMB2)
      ) {
        totalMealBreakDuration.add(moment.duration(finishMB2.diff(startMB2)));
      }
      if (
        startMB3.isValid()
        && finishMB3.isValid()
        && finishMB3.isAfter(startMB3)
      ) {
        totalMealBreakDuration.add(moment.duration(finishMB3.diff(startMB3)));
      }

      if (totalMealBreakDuration.asMilliseconds() > 0) {
        if (formValues?.MB1ND?.value) {
          totalMealBreakDuration.subtract(
            moment.duration(finishMB1.diff(startMB1)),
          );
        }
        if (formValues?.MB2ND?.value) {
          totalMealBreakDuration.subtract(
            moment.duration(finishMB2.diff(startMB2)),
          );
        }
        if (formValues?.MB3ND?.value) {
          totalMealBreakDuration.subtract(
            moment.duration(finishMB3.diff(startMB3)),
          );
        }

        return totalMealBreakDuration;
      }
      return moment.duration(
        defaultMealBreakTime.diff(moment().startOf('day')),
      );
    };

    const mealBreakDuration = calculateMealBreakDuration();

    if (startWorkTime.isValid() && finishWorkTime.isValid()) {
      if (finishWorkTime.isBefore(startWorkTime)) {
        return '00:00';
      }

      const totalWorkDuration = moment.duration(
        finishWorkTime.diff(startWorkTime),
      );

      if (
        mealBreakDuration.asMilliseconds() >= totalWorkDuration.asMilliseconds()
      ) {
        return '00:00';
      }

      let netDuration = totalWorkDuration.subtract(mealBreakDuration);

      if (!companyDetails?.TravelToHide) {
        const travelToDuration = moment.duration(
          travelToTime.diff(moment().startOf('day')),
        );
        netDuration = netDuration.add(travelToDuration);
      }

      if (!companyDetails?.TravelFromHide) {
        const travelFromDuration = moment.duration(
          travelFromTime.diff(moment().startOf('day')),
        );
        netDuration = netDuration.add(travelFromDuration);
      }

      const totalHours = moment
        .utc(netDuration.asMilliseconds())
        .format('HH:mm');
      return totalHours;
    }

    return '00:00';
  };

  useEffect(() => {
    change(
      'Payas',
      passedData?.Payas !== '0' && {
        id: companyDetails?.Payas?.find(
          (opt: Payas) => opt?.Value === passedData?.Payas,
        )?.Flag,
        label: passedData?.Payas,
      },
    );
    change('Start', moment(passedData?.Start, 'HH:mm'));
    change('TotalMBDed', moment(passedData?.TotalMBDed, 'HH:mm'));
    change('Finish', moment(passedData?.Finish, 'HH:mm'));
    change('StartMeal', moment(passedData?.StartMeal, 'HH:mm'));
    change('StartMeal2', moment(passedData?.StartMeal2, 'HH:mm'));
    change('StartMeal3', moment(passedData?.StartMeal3, 'HH:mm'));
    change('FinishMeal1', moment(passedData?.FinishMeal1, 'HH:mm'));
    change('FinishMeal2', moment(passedData?.FinishMeal2, 'HH:mm'));
    change('FinishMeal3', moment(passedData?.FinishMeal3, 'HH:mm'));
    change('Travel1', moment(passedData?.Travel1, 'HH:mm'));
    change('Travel2', moment(passedData?.Travel2, 'HH:mm'));
    change('MB1ND', passedData?.MB1ND === '1');
    change('MB2ND', passedData?.MB2ND === '1');
    change('MB3ND', passedData?.MB3ND === '1');
    change('MB3ND', passedData?.MB3ND === '1');
    change('Note', passedData?.Note);
    change('Date', moment(passedData?.Date));

    if (passedData?.CustomTimeFields?.length > 0) {
      passedData?.CustomTimeFields.forEach((field: CustomField) => {
        change(toCamelCase(field?.Prompt), field?.Value);
      });
    }

    const newValidators: Validators = { ...validators };
    companyDetails?.CustomTimeFields?.forEach((field: CustomField) => {
      if (field?.required === 1) {
        newValidators[toCamelCase(field?.Prompt)] = [
          required('Field cannot be empty, please enter some value.'),
        ];
      }
    });
    validators = newValidators;
  }, []);

  const handleGoBack = () => {
    setScreenIndex({
      screen1: false,
      screen2: true,
      screen3: false,
    });
  };

  const onSubmit = async (data: FormData) => {
    const startMB1 = moment(data?.StartMeal || '00:00', 'HH:mm');
    const startMB2 = moment(data?.StartMeal2 || '00:00', 'HH:mm');
    const startMB3 = moment(data?.StartMeal3 || '00:00', 'HH:mm');
    const finishMB1 = moment(data?.FinishMeal1 || '00:00', 'HH:mm');
    const finishMB2 = moment(data?.FinishMeal2 || '00:00', 'HH:mm');
    const finishMB3 = moment(data?.FinishMeal3 || '00:00', 'HH:mm');
    const defaultMealBreakTime = moment(data?.TotalMBDed || '00:00', 'HH:mm');

    const calculateMealBreakDuration = () => {
      const totalMealBreakDuration = moment.duration(0);
      let accordianUsed = false;
      if (startMB1.isAfter(finishMB1)) {
        accordianUsed = true;
        setSubmitError('End meal break 1 cannot be before start meal break 1');
        return undefined;
      }
      if (startMB2.isAfter(finishMB2)) {
        accordianUsed = true;
        setSubmitError('End meal break 2 cannot be before start meal break 2');
        return undefined;
      }
      if (startMB3.isAfter(finishMB3)) {
        accordianUsed = true;
        setSubmitError('End meal break 3 cannot be before start meal break 3');
        return undefined;
      }

      return accordianUsed
        ? totalMealBreakDuration
        : moment.duration(defaultMealBreakTime.diff(moment().startOf('day')));
    };

    const mealBreakTime = calculateMealBreakDuration();
    const startWorkTime = moment(data?.Start || '00:00', 'HH:mm');
    const finishWorkTime = moment(data?.Finish || '00:00', 'HH:mm');
    const travelToTime = moment(data?.Travel1 || '00:00', 'HH:mm');
    const travelFromTime = moment(data?.Travel2 || '00:00', 'HH:mm');

    if (
      startWorkTime.isValid()
      && finishWorkTime.isValid()
      && mealBreakTime.isValid()
    ) {
      if (finishWorkTime.isBefore(startWorkTime)) {
        setSubmitError('End work time cannot be before start work time');
        return;
      }

      const totalWorkDuration = moment.duration(
        finishWorkTime.diff(startWorkTime),
      );

      if (mealBreakTime.asMinutes() > totalWorkDuration.asMinutes()) {
        setSubmitError(
          'Meal break cannot be longer than the total work duration',
        );
        return;
      }
    } else {
      if (!startWorkTime?.isValid()) {
        setSubmitError('Invalid start work time');
      } else if (!finishWorkTime?.isValid()) {
        setSubmitError('Invalid finish work time');
      } else if (!mealBreakTime?.isValid()) {
        setSubmitError('Invalid meal break time');
      } else if (!travelToTime?.isValid() && companyDetails?.TravelToHide) {
        setSubmitError('Invalid travel to time');
      } else if (!travelFromTime?.isValid() && companyDetails?.TravelFromHide) {
        setSubmitError('Invalid travel from time');
      } else if (!startMB1?.isValid() || !finishMB1?.isValid()) {
        setSubmitError('Invalid start or finish meal break 1 time');
      } else if (!startMB2?.isValid() || !finishMB2?.isValid()) {
        setSubmitError('Invalid start or finish meal break 2 time');
      } else if (!startMB3?.isValid() || !finishMB3?.isValid()) {
        setSubmitError('Invalid start or finish meal break 3 time');
      }
      return;
    }
    setSubmitError('');

    const updatedWeekData: DailyTime = { ...passedData };

    Object.keys(data).forEach((key: keyof FormData) => {
      if (key === 'Note') {
        updatedWeekData[key] = data[key];
      } else if (key === 'Payas') {
        updatedWeekData[key] = data[key]?.label;
      } else if (key === 'MB1ND' || key === 'MB2ND' || key === 'MB3ND') {
        updatedWeekData[key] = data[key] ? '1' : '0';
      } else {
        updatedWeekData[key] = moment(data[key]).format('HH:mm');
      }
    });

    (updatedWeekData?.CustomTimeFields || []).forEach(
      (timeField: CustomField, index: number) => {
        const key = toCamelCase(timeField?.Prompt);

        if (key in data) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          updatedWeekData.CustomTimeFields[index].Value = (data as { [key: string]: any })[key];
        }
      },
    );
    updatedWeekData.customNote = `${data?.Payas?.label || data?.Payas} ${
      data?.Note || ''
    }`;
    updatedWeekData.TotalHours = getTotalHours();
    updatedWeekData.Date = passedData?.Date;

    setWeekData((weekData: DailyTime[]): DailyTime[] => {
      const newWeekData = [...weekData];
      newWeekData[rowIndex] = updatedWeekData;
      return newWeekData;
    });
    handleGoBack();
  };

  useEffect(() => {
    getTotalHours();
  }, [formValues?.Start, formValues?.Finish, formValues?.TotalMBDed]);

  const handleCopyYesterdayTimings = () => {
    const currDay: DailyTime = passedData;
    let lastDay: DailyTime;
    const index = rowIndex;
    if (index === 0) {
      lastDay = getDefaultWeekDay(passedData?.Date);
    } else {
      lastDay = {
        ...weekData[index - 1],
        Date: currDay?.Date,
        Day: currDay?.Day,
        Note: '',
      };
    }
    setYesterdayTimings(lastDay);
  };
  useEffect(() => {
    if (yesterdayTimings) {
      change(
        'Payas',
        yesterdayTimings?.Payas !== '0' && {
          id: companyDetails?.Payas?.find(
            (opt: Payas) => opt?.Value === yesterdayTimings?.Payas,
          )?.Flag,
          label: yesterdayTimings?.Payas,
        },
      );
      change('Start', moment(yesterdayTimings?.Start, 'HH:mm'));
      change('TotalMBDed', moment(yesterdayTimings?.TotalMBDed, 'HH:mm'));
      change('Finish', moment(yesterdayTimings?.Finish, 'HH:mm'));
      change('StartMeal', moment(yesterdayTimings?.StartMeal, 'HH:mm'));
      change('StartMeal2', moment(yesterdayTimings?.StartMeal2, 'HH:mm'));
      change('StartMeal3', moment(yesterdayTimings?.StartMeal3, 'HH:mm'));
      change('FinishMeal1', moment(yesterdayTimings?.FinishMeal1, 'HH:mm'));
      change('FinishMeal2', moment(yesterdayTimings?.FinishMeal2, 'HH:mm'));
      change('FinishMeal3', moment(yesterdayTimings?.FinishMeal3, 'HH:mm'));
      change('Travel1', moment(yesterdayTimings?.Travel1, 'HH:mm'));
      change('Travel2', moment(yesterdayTimings?.Travel2, 'HH:mm'));
      change('MB1ND', yesterdayTimings?.MB1ND === '1');
      change('MB2ND', yesterdayTimings?.MB2ND === '1');
      change('MB3ND', yesterdayTimings?.MB3ND === '1');
      change('MB3ND', yesterdayTimings?.MB3ND === '1');
      change('Note', yesterdayTimings?.Note);
      change('Date', moment(yesterdayTimings?.Date));
    }
  }, [yesterdayTimings]);

  return (
    <Container
      showGoBack
      customGoBack={handleGoBack}
      heading={messages?.timeCard?.heading}
      background={otherColour?.adminBg100}
      maxHeight
    >
      <Card
        showGoBack
        noContentPadding
        removeTitle
        contentCss={{ padding: '0 16px 24px !important' }}
      >
        <FormRow
          display="flex"
          justifyContent="space-between"
          marginTop={3}
          marginBottom={2}
        >
          <StyledHeading
            fontSize="18px"
            fontWeight={fontWeight?.semiBold}
            lineHeight="23px"
          >
            {messages?.timeCard?.form?.subHeading}
          </StyledHeading>
          <FixedTitle
            varient="Fixed12Medium"
            color={brandColour?.primary100}
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '2px',
            }}
            onClick={handleCopyYesterdayTimings}
          >
            <ContentCopyIcon
              style={{
                ...iconStyles,
                height: fontSize?.h5,
                width: fontSize?.h5,
              }}
            />
            {messages?.timeCard?.copyYesterday}
          </FixedTitle>
        </FormRow>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Grid container>
            <Grid
              container
              marginBottom="12px"
              columnSpacing={2}
              rowSpacing={2}
            >
              <Grid item xs={12}>
                {connectField('Payas', {
                  label: messages?.timeCard?.updateTime?.payAs,
                  options: companyDetails?.Payas?.map(mapFlagToValue),
                })(MaterialAutocompleteInput)}
              </Grid>
              <Grid item xs={12}>
                {connectField('Date', {
                  label: messages?.timeCard?.updateTime?.date,
                  disabled: true,
                })(DateInput)}
              </Grid>
              <Grid item xs={12}>
                <StyledHeading
                  fontWeight={fontWeight?.bold}
                  lineHeight="18px"
                >
                  {messages?.timeCard?.updateTime?.day}
                  :
                  {passedData?.Day}
                </StyledHeading>
              </Grid>
              <Grid item xs={12}>
                {!companyDetails?.TravelToHide && connectField('Travel1', {
                  label: messages?.timeCard?.updateTime?.travelTo,
                  disabled:
                    formValues?.Payas?.value === 'Did Not Work',
                  disableDropDown: userProfile?.TimeOption === TimeOption.Type,
                })(TimeInput)}
              </Grid>
              <Grid item xs={12}>
                {!companyDetails?.TravelFromHide && connectField('Travel2', {
                  label: messages?.timeCard?.updateTime?.travelFrom,
                  disabled:
                    formValues?.Payas?.value?.id === 'DNW',
                  disableDropDown: userProfile?.TimeOption === TimeOption.Type,
                })(TimeInput)}
              </Grid>
              <Grid item xs={12}>
                {connectField('Start', {
                  label: messages?.timeCard?.updateTime?.startWork,
                  disabled:
                    formValues?.Payas?.value?.id === 'DNW',
                  disableDropDown: userProfile?.TimeOption === TimeOption.Type,
                })(TimeInput)}
              </Grid>
              <Grid item xs={12}>
                {connectField('TotalMBDed', {
                  label: messages?.timeCard?.updateTime?.mealBreak,
                  disabled:
                    formValues?.Payas?.value?.id === 'DNW',
                  disableDropDown: userProfile?.TimeOption === TimeOption.Type,
                })(TimeInput)}
              </Grid>

              {formValues?.Payas?.value?.id !== 'DNW' && (
              <Grid item xs={12}>
                <Accordion>
                  <StyledAccordionSummary
                    expandIcon={<ExpandMoreIcon style={expandIconStyles} />}
                    aria-controls="australia"
                    id="australia"
                  >
                    <FixedTitle
                      varient="Fixed14Medium"
                      color={brand?.secondaryMain}
                    >
                      {messages?.timeCard?.updateTime?.mbDetails}
                    </FixedTitle>
                  </StyledAccordionSummary>

                  <StyledAccordionDetails>
                    <OutlinedContainer
                      borderRadius="8px"
                      padding="16px"
                      borderColor={greyScaleColour?.grey70}
                    >
                      <FormRow>
                        <FixedTitle
                          varient="Fixed14Regular"
                          color={brand?.secondaryMain}
                        >
                          {messages?.timeCard?.updateTime?.mealBreak}
                        </FixedTitle>
                      </FormRow>
                      <Grid container xs={12} spacing={2}>
                        <Grid item xs={12}>
                          {connectField('StartMeal', {
                            label: `${messages?.timeCard?.updateTime?.startMB}1`,
                            disableDropDown:
                              userProfile?.TimeOption === TimeOption.Type,
                          })(TimeInput)}
                        </Grid>
                        <Grid item xs={12}>
                          {connectField('FinishMeal1', {
                            label: `${messages?.timeCard?.updateTime?.finishMB}1`,
                            disableDropDown:
                              userProfile?.TimeOption === TimeOption.Type,
                          })(TimeInput)}
                        </Grid>
                        <Grid item>
                          {connectField('MB1ND', {
                            label: messages?.timeCard?.updateTime?.nonDed1,
                            flexDirection: 'row-reverse',
                          })(CustomCheckbox)}
                        </Grid>
                        <Grid item xs={12}>
                          {connectField('StartMeal2', {
                            label: `${messages?.timeCard?.updateTime?.startMB}2`,
                            disableDropDown:
                              userProfile?.TimeOption === TimeOption.Type,
                          })(TimeInput)}
                        </Grid>
                        <Grid item xs={12}>
                          {connectField('FinishMeal2', {
                            label: `${messages?.timeCard?.updateTime?.finishMB}2`,
                            disableDropDown:
                              userProfile?.TimeOption === TimeOption.Type,
                          })(TimeInput)}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          display="flex"
                          alignSelf="center"
                        >
                          {connectField('MB2ND', {
                            label: messages?.timeCard?.updateTime?.nonDed2,
                            flexDirection: 'row-reverse',
                          })(CustomCheckbox)}
                        </Grid>
                        <Grid item xs={12}>
                          {connectField('StartMeal3', {
                            label: `${messages?.timeCard?.updateTime?.startMB}3`,
                            disableDropDown:
                              userProfile?.TimeOption === TimeOption.Type,
                          })(TimeInput)}
                        </Grid>
                        <Grid item xs={12}>
                          {connectField('FinishMeal3', {
                            label: `${messages?.timeCard?.updateTime?.finishMB}3`,
                            disableDropDown:
                              userProfile?.TimeOption === TimeOption.Type,
                          })(TimeInput)}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          display="flex"
                          alignSelf="center"
                        >
                          {connectField('MB3ND', {
                            label: messages?.timeCard?.updateTime?.nonDed3,
                            flexDirection: 'row-reverse',
                          })(CustomCheckbox)}
                        </Grid>
                      </Grid>
                    </OutlinedContainer>
                  </StyledAccordionDetails>
                </Accordion>
              </Grid>
              )}
              <Grid item xs={12}>
                {connectField('Finish', {
                  label: messages?.timeCard?.updateTime?.finishWork,
                  disabled:
                    formValues?.Payas?.value?.id === 'DNW',
                  disableDropDown: userProfile?.TimeOption === TimeOption.Type,
                })(TimeInput)}
              </Grid>

              <Grid item>
                <FixedTitle varient="Fixed14Bold">
                  {messages?.timeCard?.updateTime?.totalHours}
                  {' '}
                  { getTotalHours()}

                </FixedTitle>
              </Grid>
              <Grid item xs={12}>
                {connectField('Note', {
                  label: messages?.timeCard?.updateTime?.note,
                  multiline: true,
                  rows: 3,
                  autoHeight: true,
                })(TextInput)}
              </Grid>
              {companyDetails?.CustomTimeFields?.map((field: CustomField) => (
                <Grid item xs={12}>
                  <CustomFieldComponent
                    field={field}
                    connectField={connectField}
                  />
                </Grid>
              ))}
            </Grid>
            {submitError && (
              <FormRow>
                <FormRowItem>
                  <FormError message={submitError} />
                </FormRowItem>
              </FormRow>
            )}
          </Grid>
          <OutlinedContainer
            noBorder
            borderRadius="0"
            padding="14px 24px"
            styles={{
              backgroundColor: greyScaleColour?.grey60,
              marginTop: '24px',
            }}
          >
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Grid container spacing={2}>
                  <Grid item>
                    <Button
                      variant="outlined"
                      buttonColor={brand?.black}
                      color="primary"
                      size="large"
                      onClick={handleGoBack}
                      label={messages?.general?.cancel}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="large"
                      type="submit"
                      label={messages?.general?.update}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </OutlinedContainer>
        </Form>
      </Card>
    </Container>
  );
};

export default AddTimeCardForm;
