import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { routes } from '../../utils';
import ProfileSection from './profileSection';
import EditInfoMobile from './mobileView/editInfoMobile';
import SignatureMobile from './mobileView/signatureMobile';

export default () : JSX.Element => (
  <Switch>
    <Route
      exact
      key="profile"
      path={routes.profile}
      component={ProfileSection}
    />
    <Route
      exact
      key="editInfoMobile"
      path={routes.editInfoMobile}
      component={EditInfoMobile}
    />
    <Route
      exact
      key="signatureMobile"
      path={routes.signatureMobile}
      component={SignatureMobile}
    />
  </Switch>
);
