import { AccordionDetails, Typography } from '@mui/material';
import {
  brand,
  brandColour,
  colors,
  greyScaleColour,
} from '@tphglobal/common/theme/style.palette';
import { fontSize, fontWeight } from '@tphglobal/common/theme/style.typography';
import styled, { css } from 'styled-components';
import { respondTo } from '@tphglobal/common/theme/style.layout';
import Location from '../../assets/images/location.jpg';
import LocationMobile from '../../assets/images/mobileDirection.png';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-top: 10px;
`;

export const StyledSubscaleTabContainer = styled.div<{ noMargin?: boolean }>`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: ${({ noMargin }) => (noMargin ? '0px' : '16px')};
`;

export const StyledSubscaleTab = styled.div<{ active?: boolean }>`
  padding: 0 6px 10px 6px;
  cursor: pointer;
  ${({ active }) => active
    && css`
      padding-bottom: 8px;
      border-bottom: 2px solid ${brand?.secondaryMain};
    `}
`;

export const StyledSubscaleTabText = styled(Typography)<{ active?: boolean }>`
  line-height: 20px !important;
  font-size: ${fontSize.h5} !important;
  font-weight: ${fontWeight.semiBold} !important;
  color: ${greyScaleColour?.grey100};

  ${({ active }) => active && css`
    font-weight: ${fontWeight?.regular};
    color: ${brand.secondaryMain} !important;
  `}
`;

export const StyledLocation = styled.iframe<{height ?: string}>`
  width: 100%;
  height: ${({ height }) => height}; 
  border-radius: 16px;

  ${respondTo?.mobileOnlyPortrait} {
  border-radius : 7px;
  }
`;

export const StyledDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 6px;

  ${respondTo?.mobileOnlyPortrait} {
    padding: 16px 0;
  }
`;

export const StyledRow = styled.div<{cursor ?: boolean}>`
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: ${({ cursor }) => cursor && 'pointer'};
`;

export const StyledHeader = styled.div`
  display: flex;
  flex-direction : column;
  gap: 6px;
`;

export const StyledHeading = styled(Typography)``;

export const StyledAccordionDetails = styled(AccordionDetails)`
      padding: 24px 0 !important;

      ${respondTo?.mobileOnlyPortrait} {
        padding: 0 !important;
      }
  `;

export const StyledOverWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: ${brandColour?.primary20};
    border-radius : 23.48px;
    padding: 20px 18px;
  `;
