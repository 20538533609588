// Stepper.tsx
import React from 'react';
import { Box, Typography, Paper, Divider } from '@mui/material';
import { styled } from '@mui/system';
import {
  brand,
  brandColour,
  greyScaleColour,
} from '@tphglobal/common/theme/style.palette';
import { fontSize, fontWeight } from '@tphglobal/common/theme/style.typography';
import {
  ActivityBox,
  Circle,
  CircleContainer,
  Line,
  StyledContainer,
} from './styles';
import { FixedTitle } from '@tphglobal/common/utils/theme';
import moment from 'moment';

interface ActivityLog {
  Description : string
  Guid : string
  IPAddress : string
  TTL : number
  Timestamp : number
  Type : string
  UserGuid : string
}

interface StepperProps {
  items: ActivityLog[];
}

const StepperLine = ({ first, last }: { first?: boolean; last?: boolean }) => {
  return (
    <StyledContainer style={{ flexDirection: 'column', alignItems: 'center' }}>
      <Line color={first ? 'white' : brandColour?.primary50} />
      <Circle />
      <Line color={last ? 'white' : brandColour?.primary50} />
    </StyledContainer>
  );
};

const Stepper: React.FC<StepperProps> = ({ items }) => {
  return (
    <Box>
      {items &&
        items?.map((activity: any, index) => (
          <StyledContainer>
            <StepperLine
              first={index === 0}
              last={index === items?.length - 1}
            />
            <ActivityBox
              key={index}
              first={index === 0}
              last={index === items?.length - 1}
            >
              <FixedTitle
                varient='Fixed14Medium'
                color={greyScaleColour?.black100}
              >
                {activity.Description}
              </FixedTitle>
              <FixedTitle
                varient='Fixed10Regular'
                color={greyScaleColour?.grey100}
              >
                {moment(activity?.timestamp).format('MMMM D, YYYY')}{' '}
                &nbsp;&nbsp; {activity.Type}
              </FixedTitle>
            </ActivityBox>
          </StyledContainer>
        ))}
    </Box>
  );
};

export default Stepper;
