import {
  AppBar, Avatar, Menu, MenuItem, Toolbar, Typography,
} from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { brand, brandColour, colors, greyScaleColour, otherColour } from '@tphglobal/common/theme/style.palette';
import { baseFontSize, fontSize, fontWeight } from '@tphglobal/common/theme/style.typography';
import MenuOpenOutlinedIcon from '@mui/icons-material/MenuOpenOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import LogoutIcon from '@mui/icons-material/Logout';
import { respondTo } from '@tphglobal/common/theme/style.layout';

export const StyledHeader = styled.div`
  position: sticky;
  z-index: 100;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${respondTo.mobileOnlyPortrait} { 
    display: none;
  }
`;

export const StyledInnerLeftContainer = styled.div`
  display: flex;
  gap: 20px;
`

export const StyledInnerRightContainer = styled.div`
  height: 44px;
  display: flex;
  gap: 14px;
  align-items: center;
  position: relative;
`

export const StyledRow = styled.div`
  display: flex;
  align-item: center;
  gap: 4px;
`

export const StyledImage = styled.img`
  height: 16px;
  width: 16px;
  
`
export const StyledActionMenu = styled(Menu)`
  & .MuiPaper-root {
    margin-top: 5px !important;
    border-radius: 16px !important;
    width: 198px;
    right: 20px !important;
    left: unset !important;
    box-shadow: 0px 30px 40px 0px #0000001A !important;
    border: 1px solid ${greyScaleColour.grey70} !important;
  }
  & .MuiList-root {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  & .MuiMenuItem-root {
    min-width: unset !important;
    padding: 16px !important;
    gap: 12px !important;
  }
`;

export const StyledProfileMenuIcon = styled(PersonOutlineIcon)`
  color: ${brandColour.primary100} !important;
`

export const StyledLogoutMenuIcon = styled(LogoutIcon)`
  color: ${otherColour.errorDefault} !important;
`

export const StyledActionMenuItem = styled(MenuItem)<{ noBorder?: boolean }>`
  border-bottom: ${({ noBorder }) =>
    noBorder ? 'none' : `0.5px solid ${greyScaleColour.grey70}`}!important;
  padding: 12px 16px !important;
  min-width: 320px !important;
`;

export const StyledRoleText = styled(Typography)`
  font-size: ${fontSize.b2} !important;
  font-weight: ${fontWeight.regular} !important;
  line-height: 18px !important;
  color: ${greyScaleColour.grey90} !important;
`
export const StyledMenuOpenIcon = styled(MenuOpenOutlinedIcon)`
  color: ${greyScaleColour.grey100} !important;
`

export const StyledExpandMoreIcon = styled(ExpandMoreIcon)`
  color: ${greyScaleColour.grey100} !important;
`

export const StyledExpandLessIcon = styled(ExpandLessIcon)`
  color: ${greyScaleColour.grey100} !important;
`

export const StyledAppBar = styled(AppBar)`
  background: transparent !important;
  box-shadow: none !important;
  position: relative !important;
`;

export const StyledSidebarToggleIcon = styled.img<{
  position?: string;
  right?: string;
  top?: string;
  padding?: string;
  cursor?: string;
  height?: string;
  width?: string;
}>`
  cursor: ${(props) => props.cursor || 'pointer'};
  height: 14px;
  width: 14px;
  background: linear-gradient(to bottom, #fff, #eee);
  border-radius: 50%;
`;

export const StyledIcon = styled.img``

export const StyledDropdownContainer = styled.div<{ width?: string }>`
  width: ${({ width }) => width};
  flex-grow: 1;
  display: flex;
  align-items: center;
`;

export const StyledAvatarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  text-decoration: none;
  gap: 6px;
  cursor: pointer;
`;

export const StyledGoBackButton = styled.div`
  display:flex;
  align-items : center;
  gap: 18px;
  flex:1;
`;

export const StyledHeading = styled(Typography)`

`

export const StyledHeadingWrapper = styled(Typography)`
  display: flex;
  flex-direction: column;
`

export const StyledIconHeadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content : center;
  border : 1px solid ${colors.grey50};
  padding: 4px 4px;
  border-radius: 5px;
  margin-right: 20px;
`;

export const StyledHeaderTitle = styled.div`
    color: ${brand.secondaryMain};
    font-size: ${fontSize.h1};
    font-weight: ${fontWeight.semiBold};
    // text-transform: uppercase; /* Convert text to uppercase */
    padding: 10px; /* Add padding for spacing */
`;

export const StyledToolbar = styled(Toolbar)`
  justify-content: flex-end !important;
`;

export const StyledAvatar = styled.img <{ cursor?: string }>`
  height: 36px !important;
  width: 36px !important;
  border-radius: 50%; 
  font-size: ${fontSize.h2} !important;
  cursor: ${({ cursor }) => cursor};
  background-color: ${brand.primaryMain} !important;
`;

export const ListItem = styled.div`
  display: flex;
  align-items : center;
  gap: 7px;
  cursor: pointer;
`
export const ListIcon  = styled.div`
  display : flex;
  align-items : center;
  justify-content: center;
`
export const ListText  = styled(Typography)`
  font-size : ${fontSize?.h5};
  font-weight: ${fontWeight?.semiBold};
  line-height: 18px;
`
export const StyledLine = styled.div`
  border-top : 1px solid ${brandColour?.primary10};
  width : 100%;
`