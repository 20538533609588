import styled, { css } from 'styled-components';
import { Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { brandColour, greyScaleColour } from '@tphglobal/common/theme/style.palette';
import { fontSize, fontWeight } from '@tphglobal/common/theme/style.typography';

export const StyledStepperContainer = styled.div`
  display: flex;
  gap: 2px;
`;
export const StyledStepperText = styled(Typography)<{ active?: boolean }>`
  font-size: ${fontSize.h5} !important;
  font-weight: ${fontWeight.regular} !important;
  line-height: 24px !important;
  color: ${(prop) =>
    !prop.active
      ? greyScaleColour.grey100
      : greyScaleColour.secondaryMain} !important;
  cursor: ${(prop) => (!prop.active ? 'pointer' : 'not-allowed')} !important;
`;

export const StyledStepperIcon = styled(ChevronRightIcon)`
  color: ${greyScaleColour.grey100} !important;
`;

export const ActivityBox = styled.div<{first ?: boolean, last ?: boolean}>`
padding: 12px 16px;
  ${({first}) => first && css`
    padding-top : 0;
  `};
  ${({last}) => last && css`
    padding-bottom : 0;
  `};
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: flex-start;
`

export const Line = styled.div<{color ?: string}>`
    display : flex;
    flex-direction: column;
    flex: 1;
    align-self: center;
    border-left: 1px solid ${(props) => props.color};
`
export const Circle = styled.div`
    display : flex;
    width : 6px;  
    height : 6px;  
    border-radius: 50%;
    border: 1px solid ${brandColour?.primary50}};
`


export const CircleContainer = styled.div`
  display: flex;
  align-items: center; /* Center the circle vertically */
`

export const StyledContainer = styled.div`
    display : flex;
`