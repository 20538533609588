import React, { useState } from 'react';
import { Grid } from '@mui/material';
import {
  Button,
  FormError,
  FormRow,
  FormRowItem,
  OTPInput,
  OutlinedContainer,
} from '@tphglobal/components';
import { brand, greyScaleColour } from '@tphglobal/common/theme/style.palette';
import { resendSignUpCode, resetPassword } from 'aws-amplify/auth';
import messages from '../../messages';
import { required } from '../../utils';

interface Props {
  onCancel: () => void;
  onSubmit: (confirmationCode: string) => void;
  error : string
  setError : (error : string) => void
  username : string
  forgotPassword ?: boolean
}

const ConfirmOtpForm: React.FC<Props> = ({
  error, setError, onCancel, onSubmit, username, forgotPassword,
}) => {
  const [otp, setOtp] = React.useState('');

  const handleChange = (otp : string) => {
    setOtp(otp);
  };

  const handleOtpSubmit = () => {
    if (otp?.length < 6) {
      setError('Invalid otp');
    }
    onSubmit(otp);
  };

  const handleResendCode = async () => {
    if (forgotPassword) {
      await resetPassword({ username });
    } else {
      await resendSignUpCode({ username });
    }
  };

  return (
    <>
      <Grid padding="0 24px">
        <FormRow>
          <OTPInput otp={otp} setOtp={setOtp} error={error} onChange={handleChange} />
        </FormRow>
      </Grid>

      <OutlinedContainer
        noBorder
        borderRadius="0"
        padding="14px 24px"
        styles={{ backgroundColor: greyScaleColour?.grey60, marginTop: '24px' }}
      >
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  onClick={() => onCancel()}
                  variant="outlined"
                  buttonColor={brand?.black}
                  color="primary"
                  size="large"
                  label={messages?.general?.cancel}
                />
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  label="Confirm"
                  onClick={handleOtpSubmit}
                />
              </Grid>
              <Grid item>
                <Button
                  onClick={handleResendCode}
                  variant="outlined"
                  buttonColor={brand?.black}
                  color="primary"
                  size="large"
                  label="Resend"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </OutlinedContainer>
    </>
  );
};

export default ConfirmOtpForm;
