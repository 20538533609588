import React from 'react';
import { fontSize, fontWeight } from '@tphglobal/common/theme/style.typography';
import { brand, greyScaleColour } from '@tphglobal/common/theme/style.palette';
import { routes } from '@tphglobal/freelance-portal/utils/routes';
import useWindowSize from '@tphglobal/common/hooks/windowSize';
import { breakpoints } from '@tphglobal/common/theme/style.layout';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { FixedTitle } from '@tphglobal/common/utils';
import messages from '../../messages';
import {
  StyledPanel,
  StyledSidePanelLogoImage,
  StyledSidePanelUpperMiddleText,
  StyledSidePanelLowerMiddleText,
  StyledSidePanelLowerText,
  StyledHeadingContent,
  NoAccountTextContainer,
  NoAccountText,
} from './styles';

const SidePanel: React.FC = () => {
  const reduxDispatch = useDispatch();
  const { width } = useWindowSize();

  const isMobile = width <= breakpoints.mobile;

  return (
    <StyledPanel>
      <StyledSidePanelLogoImage>
        <StyledHeadingContent>
          <StyledSidePanelUpperMiddleText>
            {messages.sidePanel.upperMiddleText}
          </StyledSidePanelUpperMiddleText>
          <FixedTitle style={{ fontSize: isMobile && '12px' }} varient="Fixed14Regular" color={greyScaleColour?.white100}>
            {messages.sidePanel.lowerMiddleText}
          </FixedTitle>
        </StyledHeadingContent>
      </StyledSidePanelLogoImage>
      <StyledSidePanelLowerText>
        <NoAccountTextContainer>
          <FixedTitle
            varient="Fixed14Regular"
            color={!isMobile ? brand.white : greyScaleColour.grey100}
          >
            {messages?.sidePanel?.needHelp}
          </FixedTitle>
          <FixedTitle
            varient={isMobile ? 'Fixed14Medium' : 'Fixed14Bold'}
            onClick={() => reduxDispatch(push(routes.getInTouch))}
            color={!isMobile ? brand.white : brand?.secondaryMain}
            style={{ cursor: 'pointer' }}
          >
            {messages?.sidePanel?.getInTouch}
          </FixedTitle>
        </NoAccountTextContainer>
      </StyledSidePanelLowerText>
    </StyledPanel>
  );
};

export default SidePanel;
